export function splitGroupedGraphData<T>(
    data: Array<T>, 
    extractRangeProperty: (arg: T) => string,
    splitFunction: (arg: string) => Array<string>
): Array<Array<T>> {
    const splitData = [];
    let currentGroup = [];
  
    for (let i = 0; i < data.length; i++) {
      currentGroup.push(data[i]);
  
      if (i < data.length - 1) {
        const currentRange = extractRangeProperty(data[i]);
        const nextRange = extractRangeProperty(data[i+1])
  
        const currentEnd = splitFunction(currentRange)[1];
        const nextStart = splitFunction(nextRange)[0];
  
        if (parseInt(nextStart, 10) - parseInt(currentEnd, 10) > 1) {
          splitData.push(currentGroup);
          currentGroup = [];
        }
      }
    }
  
    splitData.push(currentGroup);
  
    return splitData;
}