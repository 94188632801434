import { DefectDataItem } from "../../types/DefectsDataItem";
import { SharedDefectItem } from "../../types/SharedDefectItem";
import { idwalGradeBody, sharedDefectStatusDropdownValueTemplate, shipBodyTemplate, vesselTypeBodyTemplate } from "../Shared/BodyTemplates";
import { DisplayModalContent } from "../Shared/DisplayModal";
import { gradeFilterTemplate, inspectionDateFilterTemplate } from "../Shared/FilterTemplates";
import { defectPriorityTemplate, descriptionTemplate, estimateDefectCostTemplate, gradingAreaTemplate, inspectionDateFieldTemplate, notesPlaceholderTemplate, recommendationsTemplate } from "./BodyTemplates";
import {DefectIdLink} from "./DefectIdLink";
import { DefectRectificationButton } from "./DefectRectificationButton";
import { DefectOpenStatus } from "./DefectStatus";
import { DefectTableFields } from "./DefectTableFields";
import { estimatedCostFilter } from "./Filters/EstimatedCostFilter";
import { gradingAreaFilter } from "./Filters/GradingAreaFilter";
import { imagesFilter } from "./Filters/ImagesFilter";
import { priorityFilter } from "./Filters/PriorityFilter";
import { rectificationFilter } from "./Filters/RectificationFilter";
import { shipTypeFilter } from "./Filters/ShipTypeFilter";
import { statusFilter } from "./Filters/StatusFilter";
import { technicalManagerFilter } from "./Filters/TechnicalManagerFilter";
import { SharedDefectTableFields } from "./SharedDefectTableFields";
import { ViewImageButton } from "./ViewImageButton";
import {DefectActionMenu} from "./DefectActionMenu";
import {vesselBodyTemplate} from "@idwal/idwal-react-components";

export const keywordSearchFilterFields = [
    DefectTableFields.DEFECT_ID,
    DefectTableFields.NAME_FILTER_FIELD,
    DefectTableFields.PRIORITY,
    DefectTableFields.GRADING_AREA_FIELD,
    DefectTableFields.DESCRIPTION,
    DefectTableFields.RECOMMENDATIONS,
    DefectTableFields.ESTIMATE_DEFECT_COST_FIELD,
    DefectTableFields.VESSEL_NAME,
    DefectTableFields.IMO,
    DefectTableFields.TYPE,
    DefectTableFields.SUB_TYPE,
    DefectTableFields.INSPECTION_DATE,
    DefectTableFields.TECHNICAL_MANAGER,
    DefectTableFields.NOTES,
];

export const keywordSharedDefectsSearchFilterFields = [
    SharedDefectTableFields.DEFECT_ID,
    SharedDefectTableFields.GRADING_AREA_FIELD,
    SharedDefectTableFields.DESCRIPTION,
    SharedDefectTableFields.RECOMMENDATIONS,
    SharedDefectTableFields.ESTIMATE_DEFECT_COST_FIELD,
    SharedDefectTableFields.NAME_FILTER_FIELD,
    SharedDefectTableFields.TYPE,
    SharedDefectTableFields.SUB_TYPE,
    SharedDefectTableFields.INSPECTION_DATE,
];

const defaultDescriptionLength = 75;

export const columnTemplates = (
    aiGradingActive: boolean,
    handleColumnUpdate: (defectId: number, updatedColumn: string, value: any) => void,
    handleReadMoreClick: (content: DisplayModalContent) => void,
    setVesselViewOpen: (rowData: any) => void
) => {
    const columns = [
        { column: DefectTableFields.DEFECT_ID, bodyTemplate: (rowData: DefectDataItem) => DefectIdLink(rowData, false) },
        { column: DefectTableFields.ACTIONS, bodyTemplate: DefectActionMenu },
        { column: DefectTableFields.PRIORITY, bodyTemplate: defectPriorityTemplate },
        { column: DefectTableFields.GRADING_AREA_FIELD, bodyTemplate: gradingAreaTemplate },
        { column: DefectTableFields.VESSEL_NAME, bodyTemplate: (e: any) => vesselBodyTemplate(e, setVesselViewOpen) },
        { column: DefectTableFields.IDWAL_GRADE, bodyTemplate: (e: any) => idwalGradeBody(e, aiGradingActive) },
        { column: DefectTableFields.TYPE, bodyTemplate: vesselTypeBodyTemplate },
        { column: DefectTableFields.ESTIMATE_DEFECT_COST_FIELD, bodyTemplate: estimateDefectCostTemplate },
        { column: DefectTableFields.INSPECTION_DATE, bodyTemplate: inspectionDateFieldTemplate },
        { column: DefectTableFields.STATUS, bodyTemplate: (rowData: DefectDataItem) => DefectOpenStatus(rowData, handleColumnUpdate) },
        { column: DefectTableFields.NOTES, bodyTemplate: notesPlaceholderTemplate },
        { column: DefectTableFields.IMAGES, bodyTemplate: ViewImageButton },
        { column: DefectTableFields.DESCRIPTION, bodyTemplate: (rowData: DefectDataItem) => descriptionTemplate(rowData, handleReadMoreClick, defaultDescriptionLength) },
        { column: DefectTableFields.RECOMMENDATIONS, bodyTemplate: (rowData: DefectDataItem) => recommendationsTemplate(rowData, handleReadMoreClick, defaultDescriptionLength) },
    ];

    columns.push({ column: DefectTableFields.RECTIFICATION, bodyTemplate: (rowData: DefectDataItem) => DefectRectificationButton(rowData, false)})

    return columns
}

export const filterTemplates = (defectTableData: DefectDataItem[]) => {
    const filters = [
        { column: DefectTableFields.PRIORITY, filterTemplate: priorityFilter },
        { column: DefectTableFields.GRADING_AREA_FIELD, filterTemplate: (options: any) => gradingAreaFilter(options, defectTableData) },
        { column: DefectTableFields.TYPE, filterTemplate: (options: any) => shipTypeFilter(options, defectTableData) },
        { column: DefectTableFields.IMAGES, filterTemplate: imagesFilter },
        { column: DefectTableFields.ESTIMATE_DEFECT_COST_FIELD, filterTemplate: estimatedCostFilter },
        { column: DefectTableFields.INSPECTION_DATE, filterTemplate: inspectionDateFilterTemplate },
        { column: DefectTableFields.IDWAL_GRADE, filterTemplate: gradeFilterTemplate },
        { column: DefectTableFields.TECHNICAL_MANAGER, filterTemplate: (options: any) => technicalManagerFilter(options, defectTableData) },
        { column: DefectTableFields.STATUS, filterTemplate: statusFilter }, 
    ]

    filters.push({ column: DefectTableFields.RECTIFICATION, filterTemplate: rectificationFilter })

    return filters;
}

export const sharedDefectsTemplates = (
    handleReadMoreClick: (content: DisplayModalContent) => void) => {
    const columns = [
        { column: DefectTableFields.ACTIONS, bodyTemplate: DefectActionMenu },
        { column: SharedDefectTableFields.DEFECT_ID, bodyTemplate: (rowData: DefectDataItem) => DefectIdLink(rowData, true) },
        { column: SharedDefectTableFields.PRIORITY, bodyTemplate: defectPriorityTemplate },
        { column: SharedDefectTableFields.GRADING_AREA_FIELD, bodyTemplate: gradingAreaTemplate },
        { column: SharedDefectTableFields.VESSEL_NAME, bodyTemplate: (e: any) => shipBodyTemplate(e) },
        { column: SharedDefectTableFields.ESTIMATE_DEFECT_COST_FIELD, bodyTemplate: estimateDefectCostTemplate },
        { column: SharedDefectTableFields.INSPECTION_DATE, bodyTemplate: inspectionDateFieldTemplate },
        { column: SharedDefectTableFields.STATUS, bodyTemplate: (row: SharedDefectItem) => sharedDefectStatusDropdownValueTemplate(row.status) },
        { column: SharedDefectTableFields.IMAGES, bodyTemplate: ViewImageButton },
        { column: SharedDefectTableFields.DESCRIPTION, bodyTemplate: (rowData: SharedDefectItem) => descriptionTemplate(rowData, handleReadMoreClick, defaultDescriptionLength) },
        { column: SharedDefectTableFields.RECOMMENDATIONS, bodyTemplate: (rowData: SharedDefectItem) => recommendationsTemplate(rowData, handleReadMoreClick, defaultDescriptionLength) },
    ];

    columns.push({ column: SharedDefectTableFields.RECTIFICATION, bodyTemplate: (rowData: DefectDataItem) => DefectRectificationButton(rowData, true)})

    return columns
}

export const filterSharedDefectsTemplates = (defectTableData: SharedDefectItem[]) => {
    const filters = [
        { column: DefectTableFields.PRIORITY, filterTemplate: priorityFilter },
        { column: DefectTableFields.GRADING_AREA_FIELD, filterTemplate: (options: any) => gradingAreaFilter(options, defectTableData) },
        { column: DefectTableFields.IMAGES, filterTemplate: imagesFilter },
        { column: DefectTableFields.ESTIMATE_DEFECT_COST_FIELD, filterTemplate: estimatedCostFilter },
        { column: DefectTableFields.INSPECTION_DATE, filterTemplate: inspectionDateFilterTemplate },
        { column: DefectTableFields.STATUS, filterTemplate: statusFilter }, 
    ]

    filters.push({ column: DefectTableFields.RECTIFICATION, filterTemplate: rectificationFilter })

    return filters;
}
