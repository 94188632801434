import { useEffect, useState } from "react";
import { FileUpload, FileUploadHandlerEvent} from "primereact/fileupload";
import { ProgressBar } from "primereact/progressbar";
import { useTranslation } from "react-i18next";
import { AssetFile } from "../../types/AssetFile";
import './AssetUpload.css';
import AssetsService from "../../services/AssetsService";
import { errorToast } from "@idwal/idwal-react-components";
import { AssetGallery } from "./AssetGallery";
import { mapSearchResponseToSignedUrls } from "../../utils/AssetHelper";
import { DOC_FILE_EXTENSIONS, DOC_FILE_TYPES, EXCEL_FILE_EXTENSIONS, EXCEL_FILE_TYPES, OTHER_FILE_EXTENSIONS, OTHER_FILE_TYPE } from "../../types/FileExtensionTypes";

const { assetsUrlsRequest, deleteAsset, requestAssetMetaData, searchAssets, uploadAsset } = new AssetsService();

const MAX_UPLOAD_SIZE_MB = 20;
const BYTES_PER_MB = 1048576;

const allowedFileTypes = [ 
    ...DOC_FILE_EXTENSIONS, 
    ...DOC_FILE_TYPES,
    ...EXCEL_FILE_EXTENSIONS, 
    ...EXCEL_FILE_TYPES, 
    ...OTHER_FILE_EXTENSIONS, 
    ...OTHER_FILE_TYPE
];

/**
 * AssetUpload for Asset Service
 * @param props
 * @returns
 */
export const AssetUpload = (props: {
    additionalHeaders?: Record<string, string>;
    disabled?: boolean;
    imo: string;
    label?: string;
}) => {
    const { t } = useTranslation("locale");
    const [progress, setProgress] = useState(0);
    const [assets, setAssets] = useState<AssetFile[]>([]);
    const [loading, setLoading] = useState(false);
    const [deletionState, setDeletionState] = useState<Record<string, boolean>>({});
    const [defectId, setDefectId] = useState("");


    async function fetchExistingAssets(signal : AbortSignal) {
        try {
            setLoading(true);

            let queryString = `imo=${props.imo}`

            if (props.additionalHeaders) {
                Object.entries(props.additionalHeaders).forEach(([headerKey, headerValue]) => {
                    queryString += `&${headerKey}=${headerValue}`;
                });
            }

            const searchResponse = await searchAssets(queryString);

            const assetIds = searchResponse.map((response: any) => response.asset_id);

            if (assetIds.length) {
                const signedUrlsResponse = await assetsUrlsRequest(assetIds);

                const convertedAssetFiles = await mapSearchResponseToSignedUrls(searchResponse, signedUrlsResponse,signal);

                if (convertedAssetFiles.length) {
                    setAssets(convertedAssetFiles);
                }
            }
            else {
                setAssets([]);
            }

        } catch {
            if (!signal.aborted) {
                errorToast(t("assets.errorFetchingTheAssets"));
            }
        }

        setLoading(false);
    }

    useEffect(() => {
        if (props.additionalHeaders?.hasOwnProperty('defect-id')) {
            const newId = props.additionalHeaders['defect-id'];
            if (newId != defectId) {
                setDefectId(newId);
            }
        }
    }, [props.additionalHeaders]);

    useEffect(() => {
        if(defectId.length){
            const abortController = new AbortController();
            const signal = abortController.signal;

            fetchExistingAssets(signal);

            return() => {
                abortController.abort();
            }

        }
    }, [defectId]);

    // Custom upload handler to request asset metadata before upload
    const uploadHandler = async (event: FileUploadHandlerEvent) => {
        if (event.files.length) {            
            const files = event.files as unknown as AssetFile[];

            const uploadedAssets: AssetFile[] = [];

            for (let i = 0; i < files.length; i++) {
                try {
                    setProgress((i + 1) / files.length * 100)

                    const asset = files[i];

                    const assetMetadata = await requestAssetMetaData(asset, props.imo, props.additionalHeaders);

                    if (assetMetadata) {                        
                        await uploadAsset(asset, assetMetadata, props.additionalHeaders);
                        
                        uploadedAssets.push({ 
                            objectURL: asset.objectURL ?? URL.createObjectURL(asset as unknown as Blob),
                            name: asset.name,
                            lastModified: asset.lastModified,
                            webkitRelativePath: asset.webkitRelativePath,
                            size: asset.size,
                            type: asset.type,
                            metadata: assetMetadata 
                        });
                    }
                } catch {
                    errorToast(t("assets.errorUploadingAsset"));
                }
            }

            if (uploadedAssets.length) {
                setAssets(assets.concat(uploadedAssets));
            }

            event.options.clear();
            setProgress(0);
        }
    }

    // Drag and drop bypasses FileUpload accept prop so check here too
    const onBeforeDrop = (event: DragEvent) => {
        if (event.dataTransfer?.files.length) {

            return Array.from(event.dataTransfer.files)
                .every((file) => {                    
                    if (allowedFileTypes.some((allowed) => file.type.includes(allowed) || allowedFileTypes.some(extension => file.name.endsWith(extension)))) {
                        return true
                    }

                    errorToast(`${t("assets.unsupportedFileType")}: ${file.name}`)

                    return false
                });
        }

        return false;
    }

    const onDeleteAsset = async (deletedAsset: AssetFile) => {
        if (!props.disabled) {
            let assetId: string | undefined = undefined;
            setDeletionState(prev => ({ ...prev, [deletedAsset.objectURL]: true }));
    
            const filteredAssets = assets.filter((asset) => {
                if (asset.objectURL === deletedAsset.objectURL) {
                    assetId = asset.metadata?.assetId;
                    return false;
                }
    
                return true;
            });
    
            if (assetId) {
                try {
                    await deleteAsset(assetId);                
                    setAssets(filteredAssets);
                } catch (error) {                
                    errorToast(t("assets.errorDeletingAsset", { fileName: deletedAsset.name }));
                }
                
                setDeletionState(prev => {
                    const newState = { ...prev };
                    delete newState[deletedAsset.objectURL];
                    return newState;
                });
            }
        }
    }

    const validationFailHandler = (file: File) => {        
        errorToast(t("assets.maxSizeExceeded", { maxSize: MAX_UPLOAD_SIZE_MB, fileName: file.name }))
    }

    const progressBarTemplate = <ProgressBar value={progress} />;
    const chooseOptions = { className: 'p-button-sm p-button-secondary' };
    const uploadOptions = { className: 'p-button-sm p-button-secondary' };
    const cancelOptions = { className: 'p-button-danger p-button-sm p-button-secondary' };

    const fileUpload = <FileUpload
        style={{position: 'relative'}}
        data-cy="asset-upload"
        multiple
        disabled={props.disabled}
        accept={allowedFileTypes.join(", ")} 
        customUpload
        maxFileSize={MAX_UPLOAD_SIZE_MB * BYTES_PER_MB} 
        uploadHandler={uploadHandler} 
        progressBarTemplate={progressBarTemplate} 
        onBeforeDrop={onBeforeDrop} 
        onValidationFail={validationFailHandler}
        chooseOptions={chooseOptions}
        uploadOptions={uploadOptions} 
        cancelOptions={cancelOptions}/>;

    return (
        <>
            {props.label ? (
                <>
                    <div className="pt-3 col-3">
                        <label>{t("defectRectification.uploadLabel")}</label>
                    </div>
                    <div className="pt-0 col-9 file-uploader-container">
                        {fileUpload}
                        <div className="drag-and-drop-message">{t("assets.dragAndDropFiles")}</div>
                    </div>
                </>
            ) : null}
            <AssetGallery assets={assets} onDelete={onDeleteAsset} loading={loading} deletionState={deletionState}/>
        </>
    );
};

