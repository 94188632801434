import { AppThunk } from "../store";
import { setData, setError, setLoading, setPreviousRecipients } from "../slices/sharedDefectsSlice";
import { getPreviousRecipients, getSharedDefects } from "../../services/SharedDefectsService";
import { appendCustomFields } from "../../utils/SharedDefectsTableDataHelper";
import { sortDefectsByPriorityCostAndDate } from "../../utils/DefectsTableDataHelper";
import { selectUserId } from "../selectors/userSelectors";

export const fetchSharedDefects = (): AppThunk => async (dispatch, getState) => {
    const userId = selectUserId(getState());

    if (userId) {
        dispatch(setLoading(true));
        dispatch(setError(false));

        try {
            const response = await getSharedDefects(userId);
    
            if (response?.length) {
                const data = appendCustomFields(response);
                const sortedData = sortDefectsByPriorityCostAndDate(data);

                dispatch(setData(sortedData));
            } else {
                throw Error('no data');
            }
        } catch (e) {
            console.error(e);
            dispatch(setError(true));
        } finally {
            dispatch(setLoading(false));
        }
    }
};

export const fetchPreviousRecipients = (): AppThunk => async (dispatch) => {
    try {
        const response = await getPreviousRecipients();

        if (response.data) {
            dispatch(setPreviousRecipients(response.data));
        } else {
            throw Error('no data');
        }
    } catch (e) {
        console.error(e);
        dispatch(setError(true));
    } finally {
        dispatch(setLoading(false));
    }
};