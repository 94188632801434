import { FleetTable } from "../components/FleetTable/FleetTable"
import { StatsTiles } from "../components/StatsTiles/StatsTiles";

const Fleets = () => {
    return (
        <div>
            <StatsTiles />
            <FleetTable />
        </div>
    );
}

export default Fleets;