import { formatDefectId } from "@idwal/idwal-utilities/lib/helpers";
import { SharedDefectItem } from "../types/SharedDefectItem";
import { getDefectEstimateCostField, getGradingAreaFieldValue } from "./DefectsTableDataHelper";

export function getOldestDate(startDate:string | null, endDate:string | null) {
  // If both are not available, return an empty string
  if (!startDate && !endDate) {
      return '';
  }

  // If end date is not available, return the start date
  if (!startDate && endDate) {
      return formatDate(endDate);
  }

  // If start date is not available, return the end date
  if (!endDate && startDate) {
      return formatDate(startDate);
  }

  // If both dates are available, compare and return the oldest
  if (startDate && endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    return start <= end ? formatDate(start.toString()) : formatDate(end.toString());
  } 
}

function formatDate(dateString: string) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 since months are 0-indexed
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function appendCustomFields(data: any) {
  data.forEach((x: SharedDefectItem) => {
    x.nameFilterField = x.shipName + " " + x.imo;
      
    if (x.images && x.images.length > 0) {
        x.containsImages = true;
    }
    else {
        x.containsImages = false;
    }
    x.inspectionDate = getOldestDate(x.inspectionStart, x.inspectionEnd);
    x.gradingAreaField = getGradingAreaFieldValue(x.gradingArea);
    x.estimateDefectCostField = getDefectEstimateCostField(x.estimateDefectCostRangeId, x.estimateDefectCost);
    x.formattedDefectId = formatDefectId(x.defectId);
  });

  return data;
}