import { RootState } from "../store";

export const selectUser = (state: RootState) => state.user;

export const selectUserId = (state: RootState) => state.user.userId;

export const selectUserCapabilities = (state: RootState) => state.user.userCapabilities;

export const selectUserDetails = (state: RootState) => state.user.userDetails;

export const selectUserToken = (state: RootState) => state.user.userToken;

export const selectSelectedGroupName = (state: RootState) => state.user.selectedGroupName;

export const selectAiGradingAccess = (state: RootState) => state.user.aiGradingAccess;

export const selectAiGradingActive = (state: RootState) => state.user.aiGradingActive;

export const selectHasFleetAccess = (state: RootState) => state.user.hasFleetAccess;
