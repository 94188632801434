
import "./WarningMessage.css";

export interface WarningMessageProps {
    message: string,
    onClose: () => void;
}

export const WarningMessage = ({
    message, 
    onClose
}: WarningMessageProps) => (
    <div className="warning-message">
        <i className="pi pi-exclamation-triangle warning-message__icon"/>
        <p>{message}</p>
        <i 
            onClick={onClose} 
            onKeyDown={(e) => e.keyCode === 13 && onClose}
            className="pi pi-times warning-message__icon"
        />
    </div>
);