import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Trans, useTranslation } from "react-i18next";
import { errorToast, successToast } from "@idwal/idwal-react-components";
import { DefectDataItem } from "../../types/DefectsDataItem";
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { selectFilteredDefects, selectSelectedVessel, selectShowCloseDefectsDialog } from '../../store/selectors/defectSelectors';
import { setSelectedVessel, setShowCloseDefectsDialog, updateData } from '../../store/slices/defectSlice';
import { updateDefects } from '../../services/DefectService';

const RECTIFIED_ID = 3;

type CloseDefectsDialogProps = {
    filteredData?: DefectDataItem[];
}

export const CloseDefectsDialog = (props: CloseDefectsDialogProps) => {
    const { filteredData } = props;

    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();
    
    const filteredDefects = useAppSelector(selectFilteredDefects);
    const showCloseDefectsDialog = useAppSelector(selectShowCloseDefectsDialog);
    const selectedVessel = useAppSelector(selectSelectedVessel);

    const [defectsToClose, setDefectsToClose] = useState<DefectDataItem[]>([]);

    useEffect(() => {
        if (showCloseDefectsDialog) {
            let defectsToClose: DefectDataItem[] = [];

            if (selectedVessel) {
                defectsToClose =
                    filteredDefects.filter((i) => i.status === RECTIFIED_ID && i.vesselName === selectedVessel);
            } else if (filteredData?.length) {
                defectsToClose = filteredData.filter((i: any) => i.status === RECTIFIED_ID);
            }

            setDefectsToClose(defectsToClose);
        }
    }, [showCloseDefectsDialog]);

    const closeDialog = () => {
        dispatch(setShowCloseDefectsDialog(false));
        
        if (selectedVessel) {
            dispatch(setSelectedVessel(""));
        }
    }

    const updateDefectRequests = async (defectUpdateData: DefectDataItem[]) => {
        const updateDefectsRequest = defectUpdateData.map((defect: DefectDataItem) => {
            return {
                inspectionId: defect.inspectionId,
                Statuses: defect.status,
                defectId: defect.defectId
            }
        });
        return await updateDefects(updateDefectsRequest);
    }

    const closeRectifiedDefects = async () => {
        if(defectsToClose.length == 0){
            errorToast(t("closeDefectsDialog.errorMessage"));
            closeDialog();
            return;
        }

        const updatedDefects = defectsToClose.map((defect: DefectDataItem) => ({...defect, status: 1 }));

        const apiResponse = await updateDefectRequests(updatedDefects);

        if (apiResponse.status === 200) {
            dispatch(updateData(updatedDefects));
            successToast(t("closeDefectsDialog.closeMessage"));
            closeDialog();
            return;
        }

        errorToast(t("closeDefectsDialog.errorMessage"));
        closeDialog();
    }

    const closeDefectsButtons = () => {

        if (!defectsToClose.length){
            return
        }

        return <Button
            className={"close-defects-button"}
            disabled={false}
            label={t("closeDefectsDialog.closeDefects")}
            onClick={closeRectifiedDefects}
            autoFocus
            data-cy="request-submit"/>

    }

    const footer = () => {
        return <div className="flex justify-content-center flex-wrap">
            <div className="flex align-items-center justify-content-center">
                <Button
                    label={t("closeDefectsDialog.cancel")}
                    className="p-button-text md:w-8rem"
                    onClick={() => closeDialog()}
                    data-cy="request-cancel"/>
                {closeDefectsButtons()}
            </div>
        </div>
    }

    const closeDefectsMessage = () => {

        if(defectsToClose.length == 0){
            return <Trans i18nKey={"closeDefectsDialog.noDefects"} components={[<b key={"closeDefectsDialog.message"}/>]}/>
        }

        return (
            <Trans i18nKey={"closeDefectsDialog.message"} components={[<b key={"closeDefectsDialog.message"}/>]}
                   values={{ defectCount: defectsToClose.length }}/>
        )
    }

    return <Dialog header={t("closeDefectsDialog.title")}
                   closable={false}
                   footer={footer}
                   blockScroll={true}
                   className={"close-defects"}
                   visible={showCloseDefectsDialog} style={{fontSize: "22px", width: '680px', padding: '24px'}} onHide={() => closeDialog()}
                   draggable={false}>
        <p className="close-defects-text">{closeDefectsMessage()}</p>
    </Dialog>

};