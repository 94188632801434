import React from "react";

export const inspectionDateTemplate = (rowData: any) => {
    if (rowData.inspectionDate) {
        return (
            <React.Fragment>
                <div className="inspection-date-cell">
                    <span className="text-center">{new Date(rowData.inspectionDate).toLocaleDateString("en-GB")}</span>
                    <small className="text-center white-space-normal">{rowData.location + ", " + rowData.country}</small>
                </div>
            </React.Fragment>
        )
    }
}