import { useAuthenticator } from "@aws-amplify/ui-react";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { AUTH_TIMEOUT, AUTH_URL_PROTOCOL } from "../../cognito/authConfig";
import Unauthorised from "../../pages/Unauthorised";
import { getUserInfo } from "../../services/UserService";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useAppSelector } from "../../hooks/storeHooks";
import { selectHasFleetAccess } from "../../store/selectors/userSelectors";

export const SecuredRoute = () => {
    const { authStatus, user } = useAuthenticator((context) => [context.authStatus, context.user]);
    const [authorisedUser, setAuthorisedUser] = useState<boolean | null>(null);

    const hasFleetAccess = useAppSelector(selectHasFleetAccess);
    const ldClient = useLDClient();

    // We have hit a roadblock where this timeout is required, hopefully we can find another way...
    useEffect(() => {
        const timer = setTimeout(() => {
            if (authStatus !== "authenticated") {
                redirectToSSO();
            }
            async function checkTermsAccepted() {
                const userInfo = await getUserInfo(user?.username);
                const acknowledgement = userInfo?.acknowledgement;

                if (!acknowledgement) {
                    redirectToSSO();
                }
            }

            checkTermsAccepted();

            if (!user) {
                setAuthorisedUser(false);
            } else {
                setAuthorisedUser(hasFleetAccess);
                if (ldClient) {
                    ldClient.identify({
                        key: user.username,
                        email: user.attributes?.email
                    })
                }
            }
        }, AUTH_TIMEOUT);
        return () => clearTimeout(timer);
    }, [authStatus, user]);

    const redirectToSSO = () => {
        window.location.assign(
            `${AUTH_URL_PROTOCOL}://${process.env.REACT_APP_AUTH_URL}?redirect=${window.location.href}`
        );
    };

    if (authStatus !== "authenticated" || authorisedUser === null) {
        return (
            <div className="flex h-screen justify-items-center align-items-center">
                <ProgressSpinner />
            </div>
        );
    } else {
        return authorisedUser ? <Outlet /> : <Unauthorised />;
    }
};
