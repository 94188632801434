import {FilterInformation, ShowAllFiltersDialog} from "../components/ShowAllFilters/ShowAllFiltersDialog";
import {getBackgroundFilterIndex} from "../utils/advancedFilter/helpers";
import {TFunction, useTranslation} from "react-i18next";
import {
    combinedFilterStateToFilterInformation,
    combineFiltersByLabel,
    getTotalFilterCount
} from "../utils/FilterInformation";
import {FilterDialogAdapterProps} from "../types/FilterDialogAdapterProps";
import {GlobalFilterValue,} from "../types/GlobalFilterValue";
import {getAgeRangeLabels} from "../components/Filter/GlobalAgeRangeBands";
import { useAppDispatch, useAppSelector } from "../hooks/storeHooks";
import { selectGlobalFilters } from "../store/selectors/filtersSelectors";
import { clearAllFilters } from "../store/thunks/filtersThunks";
import { selectAdvancedFilters } from "../store/selectors/advancedFiltersSelectors";
import { clearForShowAllModal, toggleOpenState } from "../store/thunks/advancedFiltersThunks";

function getGlobalFilterInformation(globalFilters: GlobalFilterValue, filterToFind: keyof GlobalFilterValue, title: string, callback?: Function, t?: TFunction) {
    const globalFilterInformation: Array<FilterInformation> =
        globalFilters[filterToFind] && globalFilters[filterToFind]!.length > 0
            ?
            [{
                title,
                leftHandPills: callback ? callback(globalFilters[filterToFind]!, t) : globalFilters[filterToFind]!,
                displayEquivalent: true
            }]
            : [];
    return globalFilterInformation;
}

export function ShowAllFiltersDialogAdapter({
    visible,
    setVisible
}: Readonly<FilterDialogAdapterProps>) {
    const { 
        appliedFilters, 
        totalForegroundFilterCount,
        totalBackgroundFilterCount,
    } = useAppSelector(selectAdvancedFilters);

    const globalFilters = useAppSelector(selectGlobalFilters);

    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();

    const toggleShowAllFiltersModal = () => {
        setVisible(!visible);
    };

    const clearAllFiltersForShowAllModal = () => {
        toggleShowAllFiltersModal();
        dispatch(clearForShowAllModal());
        dispatch(clearAllFilters());
    };

    const editFiltersForShowAllModal = () => {
        dispatch(toggleOpenState());
        toggleShowAllFiltersModal();
    };

    const combinedAdvancedFilters = combineFiltersByLabel(
        appliedFilters.foreground,
        appliedFilters.background[
            getBackgroundFilterIndex(appliedFilters.marketDataToggleSelected)
        ]
    );

    const advancedFilterInformation = combinedFilterStateToFilterInformation(combinedAdvancedFilters, t);

    const globalVesselAgeTypeInformation = getGlobalFilterInformation(globalFilters, "vesselAgeBands", t("fleetGraph.vesselAge"), getAgeRangeLabels, t);
    let advancedFiltersForSorting : Array<FilterInformation> = advancedFilterInformation.filter((filterInfo: FilterInformation) => {
        return filterInfo.title !== "fleetTable.vesselAge";
    });

    const combinedFilterInformation = [
        ...advancedFiltersForSorting,
        ...globalVesselAgeTypeInformation,
    ];

    const totalFilterCount = getTotalFilterCount(
        totalForegroundFilterCount,
        totalBackgroundFilterCount
    );

    return (
        <ShowAllFiltersDialog
            visible={visible}
            onHide={toggleShowAllFiltersModal}
            activeFilterCount={totalFilterCount}
            benchmarkingAgainstMarketData={appliedFilters.marketDataToggleSelected}
            filterInformation={combinedFilterInformation}
            clearAllClickHandler={clearAllFiltersForShowAllModal}
            editFiltersClickHandler={editFiltersForShowAllModal}
        />
    )
}