export enum FilterType {
    MultiSelectWithSearch,
    Multiselect,
    SingleSelect,
    Range
}

export enum FilterScope {
    ForegroundOnly,
    ForegroundAndMarketData,
    ForegroundAndFleetData,
    ALL
}

export interface FilterDialogState<T> {
    type: T;
    name: string;
    selected: boolean;
    onClick: (id: string) => void;
    selectedFleetItemCount: number;
    selectedBackgroundFleetDataItemCount: number;
    selectedBackgroundMarketDataItemCount: number;
    filterScope: FilterScope;
    backgroundMarketMessage?: string,
    backgroundFleetMessage?: string,
    defaultSearchTerm?: string
}

export interface MultiSelectFilterItems {
    items: Array<{
        name: string,
        value: string,
        checked: boolean,
    }>,
    onUpdateSelction: (update: any) => void;
}

export interface RangeFilterItem {
    title: string,
    subtitle: string,
    step: number,
    rangeValue: [number, number];
    rangeLimits: [number, number];
    onUpdateRange: (newRange: [number, number]) => void;
}

export interface SingleSelectItem {
    name: string;
    value: number;
    checked: boolean;
    key: number;
    readonly default: boolean;
}

export interface SingleSelectFilterItem {
    items: Array<SingleSelectItem>,
    selectedItemKey: number;
    onUpdateSelection: (update: any) => void;
}

export interface IMultiSelectState extends FilterDialogState<FilterType.Multiselect> {
    fleetFilterState: MultiSelectFilterItems
    backgroundFleetFilterState: MultiSelectFilterItems
    backgroundMarketFilterState: MultiSelectFilterItems
}

export interface IMultiSelectSearchState extends FilterDialogState<FilterType.MultiSelectWithSearch> {
    fleetFilterState: MultiSelectFilterItems
    backgroundFleetFilterState: MultiSelectFilterItems
    backgroundMarketFilterState: MultiSelectFilterItems
}

export interface IRangeFilterState extends FilterDialogState<FilterType.Range> {
    fleetFilterState: RangeFilterItem
    backgroundFleetFilterState: RangeFilterItem
    backgroundMarketFilterState: RangeFilterItem
}

export interface ISingleSelectState extends FilterDialogState<FilterType.SingleSelect> {
    fleetFilterState: SingleSelectFilterItem
    backgroundFleetFilterState: SingleSelectFilterItem
    backgroundMarketFilterState: SingleSelectFilterItem
}
  
export type FilterSection = IMultiSelectState | IRangeFilterState | IMultiSelectSearchState | ISingleSelectState;

export enum BackgroundFilterScopeSelection {
    FLEET,
    MARKET
}

export interface FilterDialogProps {
    visible: boolean,
    backgroundFilterScopeSelection: BackgroundFilterScopeSelection;
    showWarningMessage: boolean;
    saving: boolean;
    filterSections: Array<FilterSection>,
    onHide: () => void;
    onApply: () => void;
    onClear: () => void;
    onChangeBackgroundFilter: () => void;
    onCloseWarningMessage: () => void;
}
