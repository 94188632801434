import { ReactElement, useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { useTranslation } from "react-i18next";
import { GlobalFilterProps } from "../../types/GlobalFilterProps";
import { Skeleton } from "primereact/skeleton";
import { getAgeRangeConfig} from "./GlobalAgeRangeBands";
import { sendGAEvent } from "../../AnalyticsService";
import {
    GLOBAL_FILTER_CHANGE,
    TECHNICAL_MANAGER_SELECTED,
    VESSEL_AGE_SELECTED,
    VESSEL_NAME_SELECTED
} from "../../constants/analytics";
import LOCAL_TABLE_CONFIG_KEY from "../../types/TableLocalConfigKeys";
import {Button} from "primereact/button";
import { storeGroupLocalStorageItem } from "../../utils/UserLocalStorage";
import { useSearchParams } from "react-router-dom";
import { setSelectedVessel } from "../../store/slices/defectSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectFilters } from "../../store/selectors/filtersSelectors";
import { setGlobalFilters, setSelectedTechnicalManagers, setSelectedVesselAgeBands, setSelectedVessels, setSelectedVesselTypes } from "../../store/slices/filtersSlice";
import { clearAllFilters } from "../../store/thunks/filtersThunks";
import { selectSelectedGroupName, selectUserDetails } from "../../store/selectors/userSelectors";
import { clearForShowAllModal, updateAppliedTechnicalManagers, updateAppliedVesselAges, updateAppliedVessels, updateAppliedVesselTypes } from "../../store/thunks/advancedFiltersThunks";
import { selectFleet, selectFleetLoading } from "../../store/selectors/fleetSelectors";
import { setupFilteredDefects } from "../../store/thunks/defectThunks";

export const GlobalFilter = (props: GlobalFilterProps): ReactElement => {
    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();

    const [searchParams] = useSearchParams();

    const { 
        globalFilters,
        globalFiltersLoading,
        selectedVesselTypes,
        selectedTechnicalManagers,
        selectedVesselAgeBands,
        selectedVessels,
    } = useAppSelector(selectFilters);
    const {
        distinctVessels,
        distinctVesselTypes,
        distinctTechnicalManagers,
    } = useAppSelector(selectFleet);
    const fleetLoading = useAppSelector(selectFleetLoading);
    const userDetails = useAppSelector(selectUserDetails);
    const selectedGroupName = useAppSelector(selectSelectedGroupName);

    const [vesselDropdownOpen, setVesselDropdown] = useState<boolean>(false);
    const [technicalManagerDropdownOpen, setTechnicalManagerDropdownOpen] = useState<boolean>(false);
    const [vesselTypeDropdownOpen, setVesselTypeDropdown] = useState<boolean>(false);
    const [selectVessageAgeRangeDropdownOpen, setSelectedVesselAgeRangeDropdown] = useState<boolean>(false);

    const username = userDetails?.userName;

    useEffect(() => {
        if (distinctVessels.length) {
            const vesselParam = searchParams.get("vessel");

            if (vesselParam && distinctVessels.includes(vesselParam)) {
                onClearClick();
                onVesselsChange([vesselParam]);
            }
        }
    }, [distinctVessels.length]);
    
    const onClearClick = () => {
        dispatch(clearAllFilters());
        dispatch(clearForShowAllModal());
        dispatch(setSelectedVessel(""));
    }

    const updateFilterStateAndLocalStorage = (newState: any, username?: string) => {
        dispatch(setGlobalFilters(newState));
        dispatch(setupFilteredDefects());
        username && storeGroupLocalStorageItem(username, selectedGroupName, LOCAL_TABLE_CONFIG_KEY.GLOBAL_FILTER_STATE, newState);
    }
    const onVesselsChange = (input: string[]) => {
        sendGAEvent(GLOBAL_FILTER_CHANGE, VESSEL_NAME_SELECTED); 
        dispatch(setSelectedVessels(input));
        updateFilterStateAndLocalStorage({ ...globalFilters, vessels: input }, username);
        dispatch(updateAppliedVessels(input));
    }
    const onTechnicalManagersChange = (input: string[]) => {
        sendGAEvent(GLOBAL_FILTER_CHANGE, TECHNICAL_MANAGER_SELECTED);
        dispatch(setSelectedTechnicalManagers(input));
        updateFilterStateAndLocalStorage({ ...globalFilters, technicalManagers: input }, username);
        dispatch(updateAppliedTechnicalManagers(input));
    }
    const onVesselTypeChange = (input: string[]) => {
        dispatch(setSelectedVesselTypes(input));
        updateFilterStateAndLocalStorage({ ...globalFilters, vesselTypes: input }, username)
        dispatch(updateAppliedVesselTypes(input));
    }
    const onVesselAgeChange = (input: string[]) => {
        sendGAEvent(GLOBAL_FILTER_CHANGE, VESSEL_AGE_SELECTED);
        dispatch(setSelectedVesselAgeBands(input));
        updateFilterStateAndLocalStorage({ ...globalFilters, vesselAgeBands: input }, username)
        dispatch(updateAppliedVesselAges(input));
    }
    const vesselAgeBands = getAgeRangeConfig(t);

    const dropdownIsOpenOrNonEmpty = (dropdownOpen: boolean, selectedItems: Array<string>) =>
        (dropdownOpen && selectedItems.length > 0 || selectedItems.length > 0);

    const renderDropdownLabel = (selected: boolean, selectedlabel: string, unselectedLabel: string) => (
        (selected) ? 
            <label>{selectedlabel}</label> :
            <label>{unselectedLabel}</label>
    );

    if (fleetLoading || globalFiltersLoading) {
        return (
            <div className={"graph-container mb-4 p-4"}>
                <Skeleton shape="circle" width="100%" height="5.375rem" className="mr-2"></Skeleton>
            </div>
        )
    }

    const columnClasses = "col-fixed w-15rem global-filter-column";

    return (
        <div hidden={props.hidden} className={"pb-4 pr-4 pt-5"}>
            <div className={"flex flex-column align-items-start md:flex-row md:align-items-center"}>
                <div className={"flex mr-3 align-items-center min-w-5rem"}>
                    <p style={{whiteSpace:"nowrap" }} className={"mb-3 md:mb-0"}>{t("fleetGraph.filterBy")}</p>
                </div>
                <div className={"grid w-full"}>
                    <div className={columnClasses}>
                        <span className="p-float-label">
                            <MultiSelect 
                                data-cy="shipType-filter-input" 
                                value={selectedVessels} 
                                onChange={(e) => onVesselsChange(e.value)} 
                                options={distinctVessels} 
                                maxSelectedLabels={2} 
                                className="p-column-filter w-14rem global-filter-column-content"
                                onShow={() => setVesselDropdown(true)}
                                onHide={() => setVesselDropdown(false)}
                                filter
                                showClear
                            />
                            {
                                renderDropdownLabel(
                                    dropdownIsOpenOrNonEmpty(vesselDropdownOpen, selectedVessels),
                                    t("fleetGraph.vessels"),
                                    t("fleetGraph.allVessels")
                                )
                            }
                        </span>             
                    </div>
                    <div className={columnClasses}>
                        <span className="p-float-label">
                            <MultiSelect 
                                data-cy="shipType-filter-input" 
                                value={selectedVesselTypes} 
                                options={distinctVesselTypes} 
                                onChange={(e: any) => onVesselTypeChange(e.value)} 
                                maxSelectedLabels={2}
                                className="p-column-filter w-14rem global-filter-column-content"
                                onShow={() => setVesselTypeDropdown(true)}
                                onHide={() => setVesselTypeDropdown(false)}
                                showClear
                            />
                            {
                                renderDropdownLabel(
                                    dropdownIsOpenOrNonEmpty(vesselTypeDropdownOpen, selectedVesselTypes),
                                    t("fleetGraph.vesselType"),
                                    t("fleetGraph.allVesselTypes")
                                )
                            }
                        </span>     
                    </div>
                    <div className={columnClasses}>
                         <span className="p-float-label">
                            <MultiSelect 
                                data-cy="vesselAge-filter-input" 
                                value={selectedVesselAgeBands} 
                                options={vesselAgeBands} 
                                onChange={(e: any) => onVesselAgeChange(e.value)} 
                                maxSelectedLabels={2} 
                                className="p-column-filter w-14rem global-filter-column-content"
                                onShow={() => setSelectedVesselAgeRangeDropdown(true)}
                                onHide={() => setSelectedVesselAgeRangeDropdown(false)}
                                showClear
                            />
                            {
                                renderDropdownLabel(
                                    dropdownIsOpenOrNonEmpty(selectVessageAgeRangeDropdownOpen, selectedVesselAgeBands),
                                    t("fleetGraph.vesselAge"),
                                    t("fleetGraph.allVesselAges")
                                )
                            }
                        </span>  
                    </div>
                    <div className={columnClasses}>
                        <span className="p-float-label">
                            <MultiSelect
                                data-cy="shipType-filter-input"
                                value={selectedTechnicalManagers}
                                onChange={(e) => onTechnicalManagersChange(e.value)}
                                options={distinctTechnicalManagers}
                                maxSelectedLabels={2}
                                className="p-column-filter w-14rem global-filter-column-content"
                                onShow={() => setTechnicalManagerDropdownOpen(true)}
                                onHide={() => setTechnicalManagerDropdownOpen(false)}
                                filter
                                showClear
                            />
                            {
                                renderDropdownLabel(
                                    dropdownIsOpenOrNonEmpty(technicalManagerDropdownOpen, selectedTechnicalManagers),
                                    t("benchmarkModal.technicalManager"),
                                    t("fleetGraph.allTechnicalManagers")
                                )
                            }
                        </span>
                    </div>
                    <div className={columnClasses}>
                        <Button
                            id={"clearAllFilters"}
                            className={"p-button p-button-outlined p-button-secondary secondary-outlined-button"}
                            data-cy="clear-all-filters"
                            onClick={onClearClick}>
                            {t("fleetGraph.clear")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
} 