import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { getTranslationObject } from "../../../services/Translation";
import { SelectItemOptionsType } from "primereact/selectitem";
import { DefectPriority } from "../../../types/DefectPriority";
import { FILTER_MAX_MULTISELECT_LABELS_TO_SHOW } from "../../../utils/Constants";

const t = getTranslationObject();

/**
 * This filter template provides a list of priorities in a menu
 * It searches by string given
 * @param options 
 * @returns 
 */
export const priorityFilter = (options: any) => {
    const prioritiesFilterOptions: SelectItemOptionsType = [
        { label: t("defectsTable.high"), value: DefectPriority.high },
        { label: t("defectsTable.medium"), value: DefectPriority.medium },
        { label: t("defectsTable.low"), value: DefectPriority.low }];

    return (
        <MultiSelect
            data-cy="priority-filter-input"
            value={options.value}
            options={prioritiesFilterOptions}
            onChange={(e: MultiSelectChangeEvent) => options.filterApplyCallback(e.value)}
            placeholder={t("defectsTable.showAll")}
            className="p-column-filter"
            maxSelectedLabels={FILTER_MAX_MULTISELECT_LABELS_TO_SHOW}
            showClear
        />
    );
};

