import {FleetDataItem} from "../../types/FleetDataItem";
import {StatTile} from "../../types/StatTile";

export class LowestIdwalGradeVesselsTile implements StatTile {

    fleetInspections: FleetDataItem[]

    constructor(fleetInspections: FleetDataItem[]) {
        this.fleetInspections = fleetInspections;
    }

    getTileData() {
        if (this.fleetInspections.length === 0) {
            return null;
        }

        let lowestGradeVessel = this.fleetInspections[0];

        for (let i = 1; i < this.fleetInspections.length; i++) {
            const currentVessel = this.fleetInspections[i];
            if (currentVessel?.inspection?.idwalGrade) {
                if (currentVessel.inspection.idwalGrade < lowestGradeVessel.inspection.idwalGrade!) {
                    lowestGradeVessel = currentVessel;
                }
            }
        }

        const vesselsWithLowestGrade = this.fleetInspections.filter((entry) => entry.inspection.idwalGrade === lowestGradeVessel.inspection.idwalGrade);

        if (vesselsWithLowestGrade && vesselsWithLowestGrade.length > 0) {

            const lowestGradeVesselsNames = vesselsWithLowestGrade.map((entry) => entry.vessel.vesselName).join(", ");

            if(vesselsWithLowestGrade[0].inspection.idwalGrade == 0){
                return null;
            }

            return {
                data: vesselsWithLowestGrade,
                tileKey: "lowestIdwalGradeVessels",
                statistic: vesselsWithLowestGrade[0].inspection.idwalGrade,
                details: lowestGradeVesselsNames,
                icon: "lowest-grade-vessel-icon"
            }
        }

        return null;
    }
}