import { DefectSummaryDataItem } from "../../types/DefectSummaryDataItem";
import LOCAL_TABLE_CONFIG_KEY from "../../types/TableLocalConfigKeys";
import { DefectTabs } from "../../types/DefectTabs";
import { defaultDefectsFiltersConfig } from "../DefectsTable/Filters/FilterTemplates";
import { DefectStatus } from "../../types/DefectStatus";
import { classNames } from "primereact/utils";
import { DefectTableFields } from "../DefectsTable/DefectTableFields";
import { FilterMatchMode } from "primereact/api";
import {
    DEFECTS_SUMMARY,
    DEFECTS_SUMMARY_CLOSED_STATUS_CLICK,
    DEFECTS_SUMMARY_RECTIFIED_STATUS_CLICK,
    DEFECTS_SUMMARY_IN_PROGRESS_STATUS_CLICK,
    DEFECTS_SUMMARY_OPEN_STATUS_CLICK,
} from "../../constants/analytics";
import { sendGAEvent } from "../../AnalyticsService";
import { useAppDispatch } from "../../hooks/storeHooks";
import { setActiveTab } from "../../store/slices/defectSlice";
import { resetTableFilters } from "../../store/thunks/filtersThunks";

type DefectSummaryStatusLinkProps = {
    rowData: DefectSummaryDataItem;
    status: DefectStatus;
}

export const DefectSummaryStatusLink = (props: DefectSummaryStatusLinkProps) => {
    const { rowData, status} = props;

    const dispatch = useAppDispatch();

    let value = rowData.closedStatusCount;
    let colour = "defect-status-closed";
    let gaEvent = DEFECTS_SUMMARY_CLOSED_STATUS_CLICK;

    if (status === DefectStatus.rectified) {
        value = rowData.rectifiedStatusCount;
        colour = "defect-status-rectified";
        gaEvent = DEFECTS_SUMMARY_RECTIFIED_STATUS_CLICK;
    }

    if (status === DefectStatus.inProgress) {
        value = rowData.inProgressStatusCount;
        colour = "defect-status-in-progress";
        gaEvent = DEFECTS_SUMMARY_IN_PROGRESS_STATUS_CLICK;
    }

    if (status === DefectStatus.open) {
        value = rowData.openStatusCount;
        colour = "defect-status-open";
        gaEvent = DEFECTS_SUMMARY_OPEN_STATUS_CLICK;
    }

    const navigateToDefectsTab = () => {
        if (value) {
            dispatch(resetTableFilters(LOCAL_TABLE_CONFIG_KEY.DEFECTS, {
                ...defaultDefectsFiltersConfig,
                [DefectTableFields.NAME_FILTER_FIELD]: { value: rowData.vesselName },
                [DefectTableFields.STATUS]: { value: [status], matchMode: FilterMatchMode.IN },
            }));
            dispatch(setActiveTab(DefectTabs.defects));
            sendGAEvent(DEFECTS_SUMMARY, gaEvent);
        }
    }

    return (
        <button 
            className={classNames("font-semibold defect-status-link-btn", colour, value ? "cursor-pointer underline" : null)}
            onClick={navigateToDefectsTab}
        >
            {value}
        </button>
    );
};
