import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { getTranslationObject } from "../../../services/Translation";
import { DefectDataItem } from "../../../types/DefectsDataItem";
import { FILTER_MAX_MULTISELECT_LABELS_TO_SHOW } from "../../../utils/Constants";
const t = getTranslationObject();

/**
 * This filter template provides a list of ship types in a filter menu
 * It searches by string given
 * @param options 
 * @param shipTypes 
 * @returns 
 */
export const shipTypeFilter = (options: any, defectData: DefectDataItem[] | undefined) => {
    let distinctShipTypes = [] as string[];
    if (defectData) {
        const shipTypes = defectData.flatMap((e: DefectDataItem) => {return e.vessel ? e.vessel.type : ""});
        if (shipTypes) {
            distinctShipTypes = Array.from(new Set([...shipTypes.filter(Boolean)])); // Vessel should never be null, but handle by removing any nulls
        }

    }

    return (
        <MultiSelect
            data-cy="shipType-filter-input"
            value={options.value}
            options={distinctShipTypes}
            onChange={(e: MultiSelectChangeEvent) => options.filterApplyCallback(e.value)}
            placeholder={t("defectsTable.showAll")}
            className="p-column-filter"
            maxSelectedLabels={FILTER_MAX_MULTISELECT_LABELS_TO_SHOW}
            filter
            showClear
        />
    );
};

