import { FleetGraphDataResponse } from "../types/FleetGraphDataResponse";
import { postRequest } from "./DataRequest";
import { FleetGraphDataItem } from "../types/FleetGraphDataItem";
import { BackgroundFilters } from "../types/AdvancedFilters";
import { getMinTeuRange } from "../utils/advancedFilter/helpers";

export const getFleetGraphData = async (backgroundFilterState: BackgroundFilters, inspectionDateRange?: Date[]): Promise<FleetGraphDataResponse> => {
    const body = {
        "country_of_build": backgroundFilterState.countryOfBuild.data,
        "dwt": backgroundFilterState.dwt.changed 
            ? backgroundFilterState.dwt.data
            : [],
        "teu": backgroundFilterState?.teu.changed
            ? [getMinTeuRange(backgroundFilterState.teu),  backgroundFilterState.teu.data[1]]
            : [],
        "inspection_date_range": inspectionDateRange ?? []
    };
    
    const response: any = await postRequest("/Fleet/marketData", body);

    if (!response) {
        return Promise.reject(
            {
                message: "Cannot load graph data"
            }
        );
    }

    const fleetDataItems = response.data?.results as FleetGraphDataItem[];

    if (!fleetDataItems?.length) {
        return Promise.reject(
            {
                message: "Cannot load graph data"
            }
        );
    }
    return Promise.resolve(
        {
            message: fleetDataItems.filter(x => x.grade)
        }
    );
};
