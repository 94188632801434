import { AppThunk } from "../store";
import { setDataTableFilterData, setDefectsSummaryTableFilterData, setDefectsTableFilterData, setFleetTableFilterData, setGlobalFilters, setKeywordSearch, setSelectedCountries, setSelectedTechnicalManagers, setSelectedVesselAgeBands, setSelectedVessels, setSelectedVesselTypes, setSubGradeTableFilterData } from "../slices/filtersSlice";
import { selectFilters, selectGlobalFilters, selectKeywordSearch } from "../selectors/filtersSelectors";
import LOCAL_TABLE_CONFIG_KEY from "../../types/TableLocalConfigKeys";
import { getGroupLocalStorageItem, removeGroupLocalStorageItem } from "../../utils/UserLocalStorage";
import { defaultFleetFiltersConfig } from "../../components/FleetTable/FilterTemplates";
import { defaultDefectsFiltersConfig } from "../../components/DefectsTable/Filters/FilterTemplates";
import { defaultSubgradeFiltersConfig } from "../../components/SubGrades/FilterTemplates";
import { defaultDefectsSummaryFilterConfig } from "../../components/DefectsSummary/DefectsSummaryConfig";
import { selectUser } from "../selectors/userSelectors";
import { DataTableFilterMeta } from "primereact/datatable";
import { getCustomColumnInfo } from "../../components/FleetTable/Columns/ColumnHelper";
import { FilterMatchMode } from "primereact/api";
import { cloneDeep} from "lodash";
import { selectFleetDataItems } from "../selectors/fleetSelectors";
import { setupFilteredDefects } from "./defectThunks";

const getClearedGlobalState = (filter: DataTableFilterMeta) => {
    const filterState = cloneDeep(filter);
    if (filterState['global'] && 'value' in filterState['global']) {
        filterState['global'].value = "";
    }
    return filterState;
}

export const loadGlobalFilterStateFromLocalStorage = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const { userDetails, selectedGroupName } = selectUser(state);
    const globalFilters = selectGlobalFilters(state);

    if (userDetails?.userName && selectedGroupName) {
        const storedSelectionsForGroup = getGroupLocalStorageItem(userDetails.userName, selectedGroupName, LOCAL_TABLE_CONFIG_KEY.GLOBAL_FILTER_STATE);
        const parsedStoredSelectionState = JSON.parse(storedSelectionsForGroup as any);

        dispatch(setSelectedVessels(parsedStoredSelectionState?.vessels || []));
        dispatch(setSelectedVesselTypes(parsedStoredSelectionState?.vesselTypes || []));
        dispatch(setSelectedVesselAgeBands(parsedStoredSelectionState?.vesselAgeBands || []));
        dispatch(setSelectedTechnicalManagers(parsedStoredSelectionState?.technicalManagers || []));

        dispatch(setGlobalFilters({ 
            ...globalFilters, 
            vessels: parsedStoredSelectionState?.vessels || [],
            vesselTypes: parsedStoredSelectionState?.vesselTypes || [],
            vesselAgeBands: parsedStoredSelectionState?.vesselAgeBands || [],
            technicalManagers: parsedStoredSelectionState?.technicalManagers || []
        }));
    }
}

export const clearAllFilters = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const { userDetails, selectedGroupName } = selectUser(state);
    const { dataTableFilterData, globalFilters, keywordSearch } = selectFilters(state);

    dispatch(setDataTableFilterData({
        table: getClearedGlobalState(dataTableFilterData.table),
        defects: getClearedGlobalState(dataTableFilterData.defects),
        subGrade: getClearedGlobalState(dataTableFilterData.subGrade),
        defectsSummary: getClearedGlobalState(dataTableFilterData.defectsSummary),
        sharedDefects: getClearedGlobalState(dataTableFilterData.sharedDefects)
    }))

    const clearedKeywordSearch = {
        table: "",
        defects: "",
        subGrade: "",
        defectsSummary: ""
    }

    dispatch(setKeywordSearch({
        ...keywordSearch,
        ...clearedKeywordSearch
    }));

    dispatch(setGlobalFilters({
        ...globalFilters,
        vessels: [],
        vesselTypes: [],
        technicalManagers: [],
        vesselAgeBands: [],
    }));

    localStorage.removeItem(LOCAL_TABLE_CONFIG_KEY.SUBGRADE);
    localStorage.removeItem(LOCAL_TABLE_CONFIG_KEY.DEFECTS);
    localStorage.removeItem(LOCAL_TABLE_CONFIG_KEY.FLEET);
    localStorage.removeItem(LOCAL_TABLE_CONFIG_KEY.DEFECTS_SUMMARY);

    if (userDetails?.userName) {
        removeGroupLocalStorageItem(userDetails.userName, selectedGroupName, LOCAL_TABLE_CONFIG_KEY.GLOBAL_FILTER_STATE);
    }

    dispatch(setSelectedVessels([]));
    dispatch(setSelectedVesselTypes([]));
    dispatch(setSelectedVesselAgeBands([]));
    dispatch(setSelectedCountries([]));
    dispatch(setSelectedTechnicalManagers([]));

    const filterReset = {
        table: defaultFleetFiltersConfig,
        defects: defaultDefectsFiltersConfig,
        subGrade: defaultSubgradeFiltersConfig,
        defectsSummary: defaultDefectsSummaryFilterConfig
    }

    dispatch(setDataTableFilterData({
        ...dataTableFilterData,
        ...filterReset
    }));

    dispatch(setupFilteredDefects());
};

export const resetTableFilters = (tableKey: LOCAL_TABLE_CONFIG_KEY, filterData?: any): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const keywordSearch = selectKeywordSearch(state);

    // Prevents race condition with resetting and changing tab from links
    setTimeout(() => {
        if (tableKey === LOCAL_TABLE_CONFIG_KEY.FLEET) {
            const fleetDataItems = selectFleetDataItems(state);

            const fleetTableCleared = getClearedGlobalState(defaultFleetFiltersConfig);
            const customColumns = getCustomColumnInfo(fleetDataItems);
            customColumns.forEach((column) => {
                fleetTableCleared[column.name] = {value: "", matchMode: FilterMatchMode.CONTAINS};
            });

            dispatch(setFleetTableFilterData(fleetTableCleared));

            dispatch(setKeywordSearch({
                ...keywordSearch,
                table: ""
            }));

            localStorage.removeItem(tableKey);
        }

        if (tableKey === LOCAL_TABLE_CONFIG_KEY.DEFECTS) {
            dispatch(setDefectsTableFilterData(filterData ?? getClearedGlobalState(defaultDefectsFiltersConfig)));

            dispatch(setKeywordSearch({
                ...keywordSearch,
                defects: ""
            }));

            localStorage.removeItem(tableKey);
        }

        if (tableKey === LOCAL_TABLE_CONFIG_KEY.DEFECTS_SUMMARY) {
            dispatch(setDefectsSummaryTableFilterData(filterData ?? getClearedGlobalState(defaultDefectsSummaryFilterConfig)));

            dispatch(setKeywordSearch({
                ...keywordSearch,
                defectsSummary: ""
            }));

            localStorage.removeItem(tableKey);
        }

        if (tableKey === LOCAL_TABLE_CONFIG_KEY.SUBGRADE) {
            dispatch(setSubGradeTableFilterData(filterData ?? getClearedGlobalState(defaultSubgradeFiltersConfig)));

            dispatch(setKeywordSearch({
                ...keywordSearch,
                subGrade: ""
            }));

            localStorage.removeItem(tableKey);
        }
    }, 200);
}
