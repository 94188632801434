import { TabPanel, TabView, TabViewTabChangeEvent } from "primereact/tabview";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DeviationGraph } from "../components/SubGrades/DeviationGraph";
import { SubgradeTable } from "../components/SubGrades/SubgradeTable";
import { ANALYTICS_TAB_CLICK, SUB_GRADE_FLEET_DEVIATION_CLICK, SUB_GRADE_OVERVIEW_CLICK } from "../constants/analytics";
import { sendGAEvent } from "../AnalyticsService";

const SubGradesPage = () => {
    const {t} = useTranslation("locale");
    const [activeTab, setActiveTab] = useState(0);

    const onTabChange = (e: TabViewTabChangeEvent) => {
        //Trigger window resize event to invoke plotly resize handler on tab change
        window.dispatchEvent(new Event('resize'));
        setActiveTab(e.index);
        switch (e.index) {
            case 0:
                sendGAEvent(ANALYTICS_TAB_CLICK, SUB_GRADE_FLEET_DEVIATION_CLICK); 
                break;
            case 1:
                sendGAEvent(ANALYTICS_TAB_CLICK, SUB_GRADE_OVERVIEW_CLICK); 
                break;
        }
    }

    return (
        <TabView renderActiveOnly={true} activeIndex={activeTab} onTabChange={onTabChange} className={"tab-container"}>
            <TabPanel headerClassName={"fleet-deviation-tab"} contentClassName={"fleet-deviation-tab-content"} header={t("subgrades.deviation")}>
                <DeviationGraph />
            </TabPanel>
            <TabPanel headerClassName={"subgrade-overview-tab"} contentClassName={"subgrade-overview-tab-content"} header={t("subgrades.subgradeOverview")}>
                <SubgradeTable />
            </TabPanel>
        </TabView>
    )
}

export default SubGradesPage;