import {DefectDataItem} from "../../types/DefectsDataItem";
import {useTranslation} from "react-i18next";
import { useRef, useState } from "react";
import ImageViewer, {ImageViewerMethods} from "../Shared/ImageViewer";
import AssetsService from "../../services/AssetsService";
import {errorToast} from "@idwal/idwal-react-components";
import {MenuButton} from "../MenuButton/MenuButton";
import {navigateToRectificationView} from "../../utils/DefectRectificationHelpers";
import {DEFECTS, RECTIFICATIONS} from "../../types/RouteNames";
import {useLocation, useNavigate} from "react-router-dom";
import { sendGAEvent } from "../../AnalyticsService";
import { ANALYTICS_BUTTON_CLICK, DEFECTS_ACTIONS_VIEW_DEFECT_CLICK, DEFECTS_ACTIONS_VIEW_IMAGES_CLICK } from "../../constants/analytics";
import { useAppSelector } from "../../hooks/storeHooks";
import { selectSharedDefects } from "../../store/selectors/sharedDefectsSelectors";
import { selectFilteredDefects } from "../../store/selectors/defectSelectors";

const CUSTOMER_PORTAL_ROUTE = "Inspection";

export const DefectActionMenu = (data: DefectDataItem) => {
    const {t} = useTranslation("locale");
    const { data: sharedDefects } = useAppSelector(selectSharedDefects);
    const filteredDefects = useAppSelector(selectFilteredDefects);

    const navigate = useNavigate();
    const { pathname } = useLocation();
    
    const [loading, setLoading] = useState(false);
    const imageViewerRef = useRef<ImageViewerMethods | null>(null);
    const guid = data.inspection?.guid;
    const reportUrl = `${process.env.REACT_APP_ID_PLATFORM_CUSTOMER_REPORT_URL_ROOT}/${CUSTOMER_PORTAL_ROUTE}/${guid}`

    const isDefectView = pathname.includes(DEFECTS);

    const handleImageClick = async () => {
        if (loading) {
            return;
        }
        const images = JSON.parse(data.images);
        const assetIds = images.map((asset: any) => asset?.Id);
        setLoading(true);
        await new AssetsService().assetsUrlsRequest(assetIds)
            .then(signedUrlsResponse => {
                if (signedUrlsResponse?.length) {
                    const signedUrls = signedUrlsResponse.map((asset: any) => Object.values(asset)[0]);
                    if (imageViewerRef.current) {
                        imageViewerRef.current.handleImageClick(signedUrls);
                    }
                } else {
                    errorToast(t("imageViewer.errorMessage"))
                }
            })
            .catch(error => {
                errorToast(t("imageViewer.errorMessage"));
            });
        setLoading(false);
    }

    let menuItems = [
        {
            label: t("menuButton.viewDefect"),
            icon: <img className="pr-2" alt="view_defect" src="./view-defect-menu.svg"></img>,
            command: () => {
                if (isDefectView) {
                    navigateToRectificationView(filteredDefects, data.defectId, navigate, DEFECTS);
                } else {
                    navigateToRectificationView(sharedDefects, data.defectId, navigate, RECTIFICATIONS);
                }
                sendGAEvent(ANALYTICS_BUTTON_CLICK, DEFECTS_ACTIONS_VIEW_DEFECT_CLICK);
            }
        }
    ];

    if (isDefectView) {
        menuItems.push({
                label: t("menuButton.viewReport"),
                icon: <img className="pr-2" alt="view_report" src="./view-report-menu.svg"></img>,
                command: () => {
                    const win = window.open(reportUrl, '_blank');
                    if (win != null) {
                        win.focus();
                    }

                }
            }
        );

    }

    if (!(data.images === null || data.images === "" || data.images === "[]" || data.images === "null")) {
        menuItems.push({
            label: t("menuButton.viewImages"),
            icon: <img className="pr-2" alt="request_inspection" src="./view-images-menu.svg"></img>,
            command: () => {
                handleImageClick().then(r => {
                });
                sendGAEvent(ANALYTICS_BUTTON_CLICK, DEFECTS_ACTIONS_VIEW_IMAGES_CLICK);
            }
        })
    }

    return (
        <div className="flex align-items-center justify-content-center">
            <ImageViewer ref={imageViewerRef}/>
            <MenuButton menu={menuItems}/>
        </div>
    )
}
