import React from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { ConfirmPopup } from "primereact/confirmpopup";
import { errorToast } from "@idwal/idwal-react-components";
import {
  defectStatusDropdownOptionTemplate,
  defectStatusDropdownValueTemplate,
} from "../Shared/BodyTemplates";
import { getDefectStatusValue } from "../../utils/DefectsTableDataHelper";
import { DefectStatus } from "../../types/DefectStatus";
import { DefectDataItem } from "../../types/DefectsDataItem";
import { updateDefect } from "../../services/DefectService";

/**
 * Allows the defect status to be updated
 * @param data 
 * @param handleColumnUpdate 
 * @returns 
 */
export const DefectOpenStatus = ( data: DefectDataItem, handleColumnUpdate: (defectId: number, updatedColumn: string, value: any) => void) => {
  const { t } = useTranslation("locale");

  /**
   * Update the defects status once selected
   * @param defect 
   * @param newStatus 
   */
  const updateStatus = async (defect: DefectDataItem, newStatus: DefectStatus) => {
    try {
      const result = await updateDefect(defect.defectId, {
        inspectionId: defect.inspectionId,
        Statuses: newStatus,
      });

      if (result.status === 200) {
        handleColumnUpdate(defect.defectId, "status", newStatus);
      } else {
        errorToast(t("defectsTable.statusUpdateErrorMessage"));
      }
    } catch (error) {
      console.error(error);
      errorToast(t("defectsTable.statusUpdateErrorMessage"));
    }
  };

  // Get status text from ID
  const statusText = getDefectStatusValue(data.status);

  return (
    <React.Fragment>
        <Dropdown
          onChange={(e) => updateStatus(data, e.value.code)}
          data-cy="defect-table-input-status-dropdown"
          value={data.status}
          options={[
            { name: t("defectsTable.defectStatusOpen"), 
              code: DefectStatus.open 
            },
            {
              name: t("defectsTable.defectStatusInProgress"),
              code: DefectStatus.inProgress,
            },
            {
              name: t("defectsTable.defectStatusRectified"),
              code: DefectStatus.rectified,
            },
            { name: t("defectsTable.defectStatusClosed"), 
              code: DefectStatus.closed 
            },
          ]}
          valueTemplate={defectStatusDropdownValueTemplate}
          itemTemplate={defectStatusDropdownOptionTemplate}
          optionLabel="name"
          placeholder={statusText}
          className="w-full"
        />
      <ConfirmPopup />
    </React.Fragment>
  );
};