export const ANALYTICS_BUTTON_CLICK="button_click";
export const ANALYTICS_TAB_CLICK="tab_click";

export const HOME_CLICK="home_home_click";
export const HOME_EXPORT="home_export_click";

export const GRADE_CLICK="grade_idwal_grade_click";
export const GRADE_CONDITION_GRADE_CLICK="grade_condition_grade_click";
export const GRADE_MANAGEMENT_GRADE_CLICK="grade_management_grade_click";

export const SUB_GRADE_CLICK="sub_grade_sub_grade_click";
export const SUB_GRADE_FLEET_DEVIATION_CLICK="sub_grade_fleet_deviation_click";
export const SUB_GRADE_VESSEL_DEVIATION_CLICK="sub_grade_vessel_deviation_click";
export const SUB_GRADE_OVERVIEW_CLICK="sub_grade_overview_click";
export const SUB_GRADE_OVERVIEW_EXPORT_CLICK="sub_grade_overview_export_click";

export const DEFECTS_CLICK="defects_defects_click";
export const DEFECTS_FLEET_DEFECTS_BY_GRADING_AREA_CLICK="defects_by_grading_area_click";
export const DEFECTS_EXPORT="defects_overview_export";
export const DEFECTS_EXPORT_DEFECT_TABLE_CLICK="defects_export_defect_table_click";
export const DEFECTS_EXPORT_DEFECT_ACTION_PLAN_CLICK="defects_export_defect_action_plan_click";
export const DEFECTS_EXPORT_DEFECT_ACTION_PLAN_IMAGES_CLICK="defects_export_defect_action_plan_images_click";
export const DEFECTS_SHARE_CLICK="defects_share_click";

export const DEFECTS_SHARE_ADD_USER_CLICK="defects_share_add_user_click";

export const DEFECTS_ID_CLICK="defects_id_click";
export const DEFECTS_ACTIONS_VIEW_IMAGES_CLICK="defects_actions_view_images_click";
export const DEFECTS_ACTIONS_VIEW_DEFECT_CLICK="defects_actions_view_defect_click";
export const DEFECTS_IMAGES_CLICK="defects_images_click";
export const DEFECTS_RECTIFICATION_SAVE_CLICK="defects_rectification_save_click";

export const DEFECTS_SUMMARY="defect_summary";
export const DEFECTS_SUMMARY_TAB="defect_summary_tab_click";
export const DEFECTS_SUMMARY_SHARE_DEFECTS_ACTION="defects_summary_share_defects_action";
export const DEFECTS_SUMMARY_CLOSE_DEFECTS_ACTION="defects_summary_close_defects_action";
export const DEFECTS_SUMMARY_VIEW_GRADING_ACTION="defects_summary_view_grading_action";
export const DEFECTS_SUMMARY_VIEW_INSIGHTS_ACTION="defects_summary_view_insights_action";
export const DEFECTS_SUMMARY_VIEW_REPORT_ACTION="defects_summary_view_report_action";
export const DEFECTS_SUMMARY_HIGH_PRIORITY_CLICK="defects_summary_high_priority_click";
export const DEFECTS_SUMMARY_MEDIUM_PRIORITY_CLICK="defects_summary_medium_priority_click";
export const DEFECTS_SUMMARY_LOW_PRIORITY_CLICK="defects_summary_low_priority_click";
export const DEFECTS_SUMMARY_CLOSED_STATUS_CLICK="defects_summary_closed_status_click";
export const DEFECTS_SUMMARY_RECTIFIED_STATUS_CLICK="defects_summary_rectified_status_click";
export const DEFECTS_SUMMARY_IN_PROGRESS_STATUS_CLICK="defects_summary_in_progress_status_click";
export const DEFECTS_SUMMARY_OPEN_STATUS_CLICK="defects_summary_open_status_click";

export const DEFECTS_INSIGHTS="defect_insights";
export const DEFECTS_INSIGHTS_TAB="defect_insights_tab_click";
export const DEFECTS_INSIGHTS_VIEW_VESSELS="defect_insights_view_vessels";
export const DEFECTS_INSIGHTS_VIEW_TECHNICAL_MANAGERS="defect_insights_view_technical_managers";

export const NOTIFICATIONS_DOWNLOAD_CLICK="notifications_download_click";

export const GLOBAL_FILTER_CHANGE="global_filter_change";
export const VESSEL_NAME_SELECTED="vessel_name_selected";
export const TECHNICAL_MANAGER_SELECTED="vessel_name_selected";
export const VESSEL_AGE_SELECTED="vessel_age_selected";

export const ADVANCED_FILTER_CATEGORY = "advanced_filters";
export const VESSEL_VIEW_OPENED = "opened";
export const VESSEL_VIEW = "vessel_view";
export const INSPECTION_REQUESTED = "inspection_requested";
export const REPORT_VIEWED = "report_viewed";
export const ADVANCED_FILTER_APPLY="advanced_filter_apply";
export const ADVANCED_FILTER_RESET="advanced_filter_reset";

export const ADVANCED_FILTER_MARKET_DATA_TOGGLE_CLICK="advanced_filter_market_data_toggle_click";
export const ADVANCED_FILTER_SELECTION_CHANGED="advanced_filter_selection_changed";
export const ADVANCED_FILTER_BROADER_FILTER_WARNING_MESSAGE_DISMISSED="advanced_filter_broader_filter_warning_message_dismissed";

export enum AdvancedFilterSelectionChanged {
    ForegroundCountryOfBuild = "advanced_filter_foreground_country_of_build_selection_changed",
    BackgroundCountryOfBuild = "advanced_filter_background_country_of_build_selection_changed",
    ForegroundTechnicalManager = "advanced_filter_foreground_technical_manager_selection_changed",
    BackgroundTechnicalManager = "advanced_filter_background_technical_manager_selection_changed",
    ForegroundVesselType = "advanced_filter_foreground_vessel_type_selection_changed",
    ForegroundDwt = "advanced_filter_foreground_dwt_selection_changed",
    BackgroundFleetDwt = "advanced_filter_background_fleet_dwt_selection_changed",
    BackgroundMarketDwt = "advanced_filter_background_market_dwt_selection_changed",
    ForegroundTeu = "advanced_filter_foreground_teu_selection_changed",
    BackgroundFleetTeu = "advanced_filter_background_fleet_teu_selection_changed",
    BackgroundMarketTeu = "advanced_filter_background_market_teu_selection_changed",
    ForegroundVessel = "advanced_filter_foreground_vessel_changed",
    ForegroundInspectionDateRange = "advanced_filter_foreground_inspection_date_range_changed",
    BackgroundFleetInspectionDateRange = "advanced_filter_background_fleet_inspection_date_range_changed",
    BackgroundMarketInspectionDateRange = "advanced_filter_background_market_inspection_date_range_changed",
    ForegroundVesselAge = "advanced_filter_foreground_vessel_age_changed",
    BackgroundVesselAge = "advanced_filter_background_vessel_age_changed",
    BackgroundFleetVessel = "advanced_filter_background_fleet_vessel_changed",
}
