import {StatTile} from "../../types/StatTile";
import {VesselSubgradeResponse} from "../../types/VesselSubgradeResponse";
import {FleetDataItem} from "../../types/FleetDataItem";
import {getVesselWithLowestSubgrade} from "./getVesselWithLowestSubgrade";

export class VesselWithLowestCrewWelfareTile implements StatTile {
    subgradeData: VesselSubgradeResponse
    inspectionData: FleetDataItem[]

    constructor(subgradeData: VesselSubgradeResponse, inspectionData: FleetDataItem[]) {
        this.subgradeData = subgradeData;
        this.inspectionData = inspectionData;
    }

    getTileData() {
        const vesselWithLowestCrewWelfare = getVesselWithLowestSubgrade(this.subgradeData, this.inspectionData, "Crew Welfare");

        if (vesselWithLowestCrewWelfare?.vessel) {
            return ({
                data: vesselWithLowestCrewWelfare,
                tileKey: "vesselWithLowestCrewWelfare",
                statistic: "SUB GRADE",
                statisticFontSize: "1.68rem",
                details: vesselWithLowestCrewWelfare.vessel,
                icon: "crew-welfare-icon"
            });
        }

        return null;
    }
}
