import { DefectPriority } from "../../types/DefectPriority";
import { DefectStatus } from "../../types/DefectStatus";
import { DefectSummaryDataItem } from "../../types/DefectSummaryDataItem";
import { DefectSummaryActionMenu } from "./DefectSummaryActionMenu";
import { DefectSummaryInspectionSince } from "./DefectSummaryInspectionSince";
import { DefectSummaryPriorityLink } from "./DefectSummaryPriorityLink";
import { DefectSummaryStatusLink } from "./DefectSummaryStatusLink";
import { DefectSummaryTotalDefectsLink } from "./DefectSummaryTotalDefectsLink";

export const vesselNameTemplate = (rowData: DefectSummaryDataItem) => {
    return (
            <div className="justify-content-start">
                <div>
                    <p>{rowData.vesselName}</p>
                    <small>{rowData.imo}</small>
                </div>
            </div>
    );
}

export const vesselTypeBodyTemplate = (rowData: DefectSummaryDataItem) => {
    return (
        <div>
            <p>{rowData.vesselType}</p>
            <small>{rowData.subType}</small>
        </div>
    );
};

export const defectSummaryActionMenuTemplate = (rowData: DefectSummaryDataItem) => 
    <DefectSummaryActionMenu rowData={rowData} />

export const defectSummaryTotalDefectsLinkTemplate = (rowData: DefectSummaryDataItem) => 
    <DefectSummaryTotalDefectsLink rowData={rowData} />

export const defectSummaryPriorityLinkTemplate = (priority: DefectPriority) => (rowData: DefectSummaryDataItem) =>
    <DefectSummaryPriorityLink rowData={rowData} priority={priority} />

export const defectSummaryStatusLinkTemplate = (status: DefectStatus) => (rowData: DefectSummaryDataItem) =>
    <DefectSummaryStatusLink rowData={rowData} status={status} />

export const defectSummaryInspectionSinceTemplate = (rowData: DefectSummaryDataItem) =>
    <DefectSummaryInspectionSince rowData={rowData} />