import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { DefectStatus } from "../../../types/DefectStatus";
import { getTranslationObject } from "../../../services/Translation";
import { FILTER_MAX_MULTISELECT_LABELS_TO_SHOW } from "../../../utils/Constants";

const t = getTranslationObject();

export const statusFilter = (options: any) => {
  const statusFilterOptions = [ 
    { value: DefectStatus.open, label: t("defectsTable.defectStatusOpen") },
    { value: DefectStatus.closed, label: t("defectsTable.defectStatusClosed") },
    { value: DefectStatus.inProgress, label: t("defectsTable.defectStatusInProgress") },
    { value: DefectStatus.rectified, label: t("defectsTable.defectStatusRectified") }
  ];


  return (
    <MultiSelect
      data-cy="status-filter-input"
      value={options.value}
      options={statusFilterOptions}
      onChange={(e: MultiSelectChangeEvent) => options.filterApplyCallback(e.value)}
      placeholder={t("defectsTable.showAll")}
      className="p-column-filter"
      maxSelectedLabels={FILTER_MAX_MULTISELECT_LABELS_TO_SHOW}
      showClear
    />
  );
}
