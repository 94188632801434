import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { DefectDataItem } from "../../types/DefectsDataItem";
import { ANALYTICS_BUTTON_CLICK, DEFECTS_EXPORT } from "../../constants/analytics";
import { sendGAEvent } from "../../AnalyticsService";
import { SharedDefectItem } from "../../types/SharedDefectItem";
import { useCapabilities } from "../../hooks/useCapabilities";
import { Capability } from "../../types/Capability";
import { triggerExportDap } from "../../services/DefectService";

export const SharedExportButton = ((props: {
    dataTableRef: React.RefObject<DataTable<any>>,
    filteredData: (DefectDataItem | SharedDefectItem)[],
}) => {
    const { t } = useTranslation("locale");
    const { capabilities } = useCapabilities([
        Capability.CAP_FLEETAPP_EXPORT_DAP
    ]);

    const [exportLoading, setExportLoading] = useState<boolean>(false);

    if (!capabilities[Capability.CAP_FLEETAPP_EXPORT_DAP]) {
        return null;
    }

    const handleExportButtonClick = async () => {
        if (!props.filteredData.length) {
            //reset table state when filtered data is not set 
            props.dataTableRef.current?.restoreState();
        }
        sendGAEvent(ANALYTICS_BUTTON_CLICK, DEFECTS_EXPORT);

        setExportLoading(true);

        await triggerExportDap(props.filteredData, true);

        setExportLoading(false);
    }

    return (
        <div className={"shared-defect-btn"}>              
            <Button icon="pi pi-file-export" iconPos="left" loading={exportLoading} onClick={handleExportButtonClick} label={t("fleetTable.exportDAP")} data-cy="export-button" />
        </div>     
    )
});