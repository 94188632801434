import { RootState } from "../store";

export const selectAdvancedFilters = (state: RootState) => state.advancedFilters;

export const selectFitlerState = (state: RootState) => state.advancedFilters.filterState;

export const selectAppliedFilters = (state: RootState) => state.advancedFilters.appliedFilters;

export const selectShowWarningMessage = (state: RootState) => state.advancedFilters.showWarningMessage;

export const selectTotalForegroundFilterCount = (state: RootState) => state.advancedFilters.totalForegroundFilterCount;

export const selectTotalBackgroundFilterCount = (state: RootState) => state.advancedFilters.totalBackgroundFilterCount;
