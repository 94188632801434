import axios from "axios";
import { AgeOfOpenDefectsData, ClosedRatioData, CumulativeDefectData } from "../types/DefectInsightsData";

export const getInsightsClosedRatioData = async (userToken: string) => {
    const response = await dataRequest("insights/data", userToken, { dataset: "closed_ratio" });

    const data: ClosedRatioData[] = JSON.parse(response.data);

    return data;
}

export const getInsightsCumulativeDefectData = async (userToken: string) => {
    const response = await dataRequest("insights/data", userToken, { dataset: "cumulative_defects" });

    const data: CumulativeDefectData[] = JSON.parse(response.data);

    return data;
}

export const getInsightsAgeOfOpenDefectsData = async (userToken: string) => {
    const response = await dataRequest("insights/data", userToken, { dataset: "age_of_open_defects" });

    const data: AgeOfOpenDefectsData[] = JSON.parse(response.data);

    return data;
}

const baseUrl  : string = process.env.REACT_APP_DEFECT_INSIGHTS_API_URL ?? "";

export const dataRequest = async (endpoint: string, userToken: string, params?: any, data?: any) => {
    return axios.get(baseUrl + endpoint, {
        headers:  {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": 'Bearer ' + userToken
        },
        data,
        params
    }).then(result => result.data);
}