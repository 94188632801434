import { SingleSelectItem } from "./FilterDialog";

export enum AdvancedFilterStateType {
    MultiSelectState,
    RangeState,
    BooleanState,
    SingleSelect
}

export interface FilterState<T> {
    label: string,
    type: T
}

export type FilterType = MultiSelectFilterState | RangeFilterState | SingleSelectFilterState;

export interface MultiSelectFilterState extends FilterState<AdvancedFilterStateType.MultiSelectState> {
    data: Array<string>
}

export interface RangeFilterState extends FilterState<AdvancedFilterStateType.RangeState> {
    data: [number, number],
    limit: [number, number],
    changed: boolean
}

export interface BooleanFilterState extends FilterState<AdvancedFilterStateType.BooleanState> {
    enabled: boolean
}

export interface SingleSelectFilterState extends FilterState<AdvancedFilterStateType.SingleSelect> {
    data: SingleSelectItem[]
}

export interface ForegroundFilters {
    selectedVessels: MultiSelectFilterState;
    vesselType: MultiSelectFilterState;
    countryOfBuild: MultiSelectFilterState;
    technicalManager: MultiSelectFilterState;
    dwt: RangeFilterState;
    teu: RangeFilterState;
    inspectionDateRange: SingleSelectFilterState;
    vesselAge: MultiSelectFilterState;
}

export interface BackgroundFilters {
    selectedVessels: MultiSelectFilterState;
    vesselType: MultiSelectFilterState;
    countryOfBuild: MultiSelectFilterState;
    technicalManager: MultiSelectFilterState;
    dwt: RangeFilterState;
    teu: RangeFilterState;
    inspectionDateRange: SingleSelectFilterState;
    vesselAge: MultiSelectFilterState;
}

export interface AdvancedFilterSelectionState {
    foreground: ForegroundFilters,
    background: [BackgroundFilters, BackgroundFilters],
    marketDataToggleSelected: boolean
}