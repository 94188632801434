import { MultiSelect } from "primereact/multiselect";
import { getTranslationObject } from "../../../services/Translation";
import { FILTER_MAX_MULTISELECT_LABELS_TO_SHOW } from "../../../utils/Constants";

const t = getTranslationObject();

/**
 * This filter template allows users to filter by defects with images or not
 * It searches by string given
 * @param options 
 * @returns 
 */
export const imagesFilter = (options: any) => {
    const imagesFilterOptions = [
      { label: t("defectsTable.withImages"), value: true },
      { label: t("defectsTable.withoutImages"), value: false },
    ];

    return (
      <MultiSelect
        data-cy="images-filter-input"
        value={options.value}
        options={imagesFilterOptions}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder={t("defectsTable.showAll")}
        className="p-column-filter"
        maxSelectedLabels={FILTER_MAX_MULTISELECT_LABELS_TO_SHOW}
        showClear
      />
    );
};
