import {FleetDataResponse} from "../types/FleetDataResponse";
import {dataRequest, postRequest} from "./DataRequest";
import mockData from "./mockFleetTableData.json"
import mockUserDetails from "./mockUserDetails.json"
import {FleetDataItem} from "../types/FleetDataItem";
import {ShipData} from "../types/ShipData";
import {UserDetails} from "../types/UserDetails";
import {AIGradingAccess} from "../types/AIGradingAccess";
import {Notes, RiskLevels} from "../types/GroupDetails";
import { CUSTOM_COLUMN_PREFIX } from "../utils/Constants";
import { InspectionAndGradeDataResponse } from "../types/InspectionAndGradeDataResponse";
import { getCurrentSessionIdToken } from "../queries/AuthToken";
import axios from "axios";
import { AiGradeInfo } from "@idwal/idwal-react-components";
import { debounce } from "lodash";
import { InternalGradeData } from "../types/InternalGradeData";

export const getFleetData = async (): Promise<FleetDataResponse> => {
    const request: FleetDataResponse = await dataRequest("/Fleet");
    let items : FleetDataItem[] = [];

    if (request.userDetails?.fleet) {
        const { fleet, groupDetails } = request.userDetails;

        if (groupDetails) {
            const fleetParsed = JSON.parse(fleet);

            Object.values(fleetParsed).forEach(ship => {
                const shipData : ShipData = ship as ShipData;
                let group = groupDetails[shipData.group];
                shipData.riskLevel = 0;
                shipData.notes = "";
    
                if (group) {
                    addSpecialColumnData(group, shipData);
    
                    const item:  FleetDataItem ={
                        inspection : {},
                        vessel : shipData,
                        trending: null
                    }
        
        
        
                    Object.keys(group).forEach(key => {
                        if (key.startsWith(CUSTOM_COLUMN_PREFIX)) {
                            if (group[key]) {
                                if (group[key][shipData.imo]) {
                                    item[key] = group[key][shipData.imo];
                                } else {
                                    item[key] = "";
                                }
            
                                item[`${key}.dataType`] = group[key]["dataType"];
                            }
                        }
                    });
        
                    items.push(item);
                }
            });
        }
    }

    return Promise.resolve({
        fleetDataItems: items,
        userDetails: request.userDetails as UserDetails,
        scheduledDates: request.scheduledDates
    });
}

const addSpecialColumnData = (group: { scheduledInspectionInterval?: number; csvFile?: string; aiGradingAccess?: AIGradingAccess; specialColumnsEnabled: string; riskLevels: RiskLevels; notes: Notes; [p: string]: any }, shipData: ShipData) => {
    if (group?.hasOwnProperty("riskLevels") && group['riskLevels'].hasOwnProperty(shipData.imo)) {
        shipData.riskLevel = Number(group['riskLevels'][shipData.imo]);
    }

    if (group?.hasOwnProperty("notes") && group['notes'].hasOwnProperty(shipData.imo)) {
        shipData.notes = group['notes'][shipData.imo];
    }
}

export const getMockFleetData = async (): Promise<FleetDataResponse> => {
    return Promise.resolve({
        fleetDataItems: mockData,
        userDetails: mockUserDetails,

    });
}

const debouncedUpdateFleetAppState = debounce((appState) => postRequest("/Users/UpdateUser", appState), 1000);

export const updateFleetAppState = (appState: any) => {
    debouncedUpdateFleetAppState(appState);
}

export const getFleetToken = async () => {
    const response = await dataRequest("/Fleet/token");
    return response.token;
}

const setAiGradeData = (aiGradeData: AiGradeInfo) => {
    if (aiGradeData?.predictedGradeMin && aiGradeData.predictedGradeMax && aiGradeData.predictedGrade) {
        aiGradeData.predictedGrade = Math.round(aiGradeData.predictedGrade);
        aiGradeData.predictedGradeMin = Math.round(aiGradeData.predictedGradeMin);
        aiGradeData.predictedGradeMax = Math.round(aiGradeData.predictedGradeMax);
    }

    if (aiGradeData?.evolutionGradeMin && aiGradeData.evolutionGradeMax) {
        aiGradeData.evolutionGradeMin = Math.round(aiGradeData.evolutionGradeMin);
        aiGradeData.evolutionGradeMax = Math.round(aiGradeData.evolutionGradeMax);
    }
}

export const getInspectionAndGradeData = async () : Promise<InspectionAndGradeDataResponse> => {
    const baseUrl: string = process.env.REACT_APP_IDWAL_API_ENDPOINT ?? "";
    const url = baseUrl + "/Fleet/Inspection";

    const token = await getCurrentSessionIdToken();

    return axios.post<any>(url, null, {
        headers: {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + token
        }
    }).then(result => {
        const fleetDataItems: FleetDataItem[] = [];
        let groupNames : string[] = [];

        for (const key in result.data.combinedData) {
            const resultAiGradeData = result.data.combinedData[key].vesselGradeInfo;
            const resultInspection = result.data.combinedData[key].inspection;
            const resultVessel = result.data.combinedData[key].vessel;
            const resultDate = result.data.combinedData[key].scheduledDate;
            const scoreTrends = result.data.combinedData[key].scoreTrends;
            const scoreHistory = resultInspection?.scoreHistory;

            if(!groupNames.includes(resultVessel.group)){
                groupNames.push(resultVessel.group);
            }

            setAiGradeData(resultAiGradeData);

            fleetDataItems.push({
                inspection : resultInspection,
                vessel : resultVessel,
                aiGrades: resultAiGradeData ?? {},
                scheduledDate: resultDate,
                scoreTrends: scoreTrends,
                scoreHistory: scoreHistory
            });

        }
        return {
            fleetDataItems: fleetDataItems,
            groupCount : groupNames.length
        }
    });
}

export const getInternalGradeData = async (imos: string) : Promise<InternalGradeData[]> => {
    const baseUrl: string = process.env.REACT_APP_IDWAL_API_ENDPOINT ?? "";
    const url = baseUrl + `/Grades?imos=${imos}`;

    const token = await getCurrentSessionIdToken();

    return axios.get<any>(url, {
        headers: {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + token
        }
    }).then(result => {
        return result.data.data;
    });
}