import { useTranslation } from "react-i18next";
import { DefectDataItem } from "../../types/DefectsDataItem";
import { Card } from "primereact/card";
import { getPriorityIcon } from "../DefectsTable/BodyTemplates";
import { SharedDefectItem } from "../../types/SharedDefectItem";
import { formatDefectId } from "@idwal/idwal-utilities/lib/helpers";
import "./DefectRectificationDetails.css";
import { getGradingAreaFieldValue } from "../../utils/DefectsTableDataHelper";
import { useAppSelector } from "../../hooks/storeHooks";
import { selectFleetDataItems } from "../../store/selectors/fleetSelectors";

/**
 * Form for defect rectification
 * Uses a ref so that its form can be captrued externally
 * @param props 
 * @returns 
 */
export const DefectRectificationDetails = ( props: {defect: DefectDataItem | SharedDefectItem}) => {
    const { t } = useTranslation("locale");

    const fleetDataItems = useAppSelector(selectFleetDataItems);

    const matchingFleetData = fleetDataItems.find((fleetItem) => fleetItem.inspection.guid === props.defect.inspectionId);

    const getShipName = () => {
        if ("vessel" in props.defect && "vesselName" in props.defect.vessel) {
            return props.defect.vessel.vesselName;
        }

        if ("shipName" in props.defect) {
            return props.defect.shipName;
        }


        if (matchingFleetData) {
            return matchingFleetData.vessel.vesselName;
        }

        return ""
    }

    const getLabel = (labelKey: string) => {
        const labelText = t(labelKey);
        return (
            <div className="overflow-wrap-text"><label>{labelText}</label></div>
          );
    }

    const getGradingArea = () => {
        if (props.defect.gradingAreaField) {
            return props.defect.gradingAreaField
        }

        return getGradingAreaFieldValue(props.defect.gradingArea);
    }

    const getInspectionDate = () => {
        if (props.defect.inspectionDate) {
            return props.defect.inspectionDate
        }

        if (matchingFleetData?.inspection.lastInspected) {
            return new Date(matchingFleetData.inspection.lastInspected).toLocaleDateString("en-GB");
        }

        return ""
    }

    const renderDetail = (labelKey: string, value: any, fieldId: string) => {
        return (
            <>
                <div className="pt-0 pb-1 col-12 xl:col-3">
                    {getLabel(labelKey)}
                </div>
                <div id={fieldId} data-cy={`rectification-details-${fieldId}`} className="pt-0 pb-3 col-12 xl:col-9">
                    {value}
                </div>
            </>
        );
    };

    return (
       <Card
            header={
                <h2
                    style={{
                        border: "none",
                        marginLeft: "0",
                        whiteSpace: "nowrap"
                    }}
                >
                    {t("defectRectification.detailsTitle")}
                </h2>
            }
        >
            <div className="mt-1 grid">
                <div className="col-12">
                    <div className="grid">
                        {renderDetail("defectsTable.defectID", formatDefectId(props.defect.defectId), "defect-id")}
                        {renderDetail("defectRectification.detailsVesselLabel", getShipName(), "vessel-name")}
                        {renderDetail("defectRectification.detailsInspectionDateLabel", getInspectionDate(), "inspection-date")}
                        {renderDetail("defectRectification.detailsPriorityLabel", getPriorityIcon(props.defect.priority), "priority")}
                        {renderDetail("defectRectification.detailsGradingAreaLabel", getGradingArea(), "grading-area")}
                        {renderDetail("defectRectification.detailsDescriptionLabel", props.defect.description, "description")}
                        {renderDetail("defectRectification.detailsRecommendationsLabel", props.defect.recommendations, "recommendation")}
                    </div>
                </div>
            </div>
            
        </Card>
    );
};
