import {DefectDataItem} from "../../types/DefectsDataItem";
import {DefectStatus} from "../../types/DefectStatus";
import {DefectPriority} from "../../types/DefectPriority";
import {StatTile} from "../../types/StatTile";

export class VesselWithMostHighPriorityOpenDefectsTile implements StatTile {

    defectsData : DefectDataItem[]

    constructor(defectsData: DefectDataItem[]) {
        this.defectsData = defectsData;
    }

    getTileData() {
        const openDefectsPerVessel = new Map();
        this.defectsData.forEach((item) => {
            if (item.status == DefectStatus.open && item.priority == DefectPriority.high) {
                const key = item.vessel.imo;
                const value = openDefectsPerVessel.get(key);
                if (!value) {
                    openDefectsPerVessel.set(key, { vesselName: item.vessel.vesselName, defectCount: 1 });
                } else {
                    openDefectsPerVessel.set(key, { vesselName: item.vessel.vesselName , defectCount: value.defectCount + 1 });
                }
            }
        });

        let vesselWithMostDefects = { defectCount: 0, vesselName: "" }

        openDefectsPerVessel.forEach(x => {
            const defectCount = x.defectCount;

            if (vesselWithMostDefects.defectCount < defectCount){
                vesselWithMostDefects = x;
            }
        })

        if (vesselWithMostDefects?.vesselName) {
            return {
                data: vesselWithMostDefects,
                tileKey: "vesselWithMostHighPriorityOpenDefects",
                statistic: vesselWithMostDefects.defectCount,
                statisticSubText: "highPriorityDefects",
                details: vesselWithMostDefects.vesselName,
                icon: "open-priority-defects-icon"
            };
        }

        return null;
    }
}
