import { DefectSummaryDataItem } from "../../types/DefectSummaryDataItem";
import LOCAL_TABLE_CONFIG_KEY from "../../types/TableLocalConfigKeys";
import { DefectTabs } from "../../types/DefectTabs";
import { defaultDefectsFiltersConfig } from "../DefectsTable/Filters/FilterTemplates";
import { DefectTableFields } from "../DefectsTable/DefectTableFields";
import { FilterMatchMode } from "primereact/api";
import { useAppDispatch } from "../../hooks/storeHooks";
import { setActiveTab } from "../../store/slices/defectSlice";
import { resetTableFilters } from "../../store/thunks/filtersThunks";

type DefectSummaryTotalDefectsLinkProps = {
    rowData: DefectSummaryDataItem
} 

export const DefectSummaryTotalDefectsLink = (props: DefectSummaryTotalDefectsLinkProps) => {
    const { rowData } = props;
    
    const dispatch = useAppDispatch();

    const navigateToDefectsTab = () => {
        dispatch(resetTableFilters(LOCAL_TABLE_CONFIG_KEY.DEFECTS, {
            ...defaultDefectsFiltersConfig,
            [DefectTableFields.NAME_FILTER_FIELD]: { value: rowData.vesselName, matchMode: FilterMatchMode.CONTAINS },
            [DefectTableFields.STATUS]: { value: [], matchMode: FilterMatchMode.IN },
        }));
        dispatch(setActiveTab(DefectTabs.defects));
    }

    return (
        <button className="font-semibold underline cursor-pointer read-more-btn total-defect-btn" onClick={navigateToDefectsTab}>
            {rowData.totalDefects}
        </button>
    );
};
