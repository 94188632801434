import {GlobalFilterValue} from "../../types/GlobalFilterValue";

const formatLastInspectedAsDate = (lastInspected: string | Date) => {
    let inspectionDate = lastInspected;
    if (!(lastInspected instanceof Date)) {
        if (lastInspected.includes("T")) {
            let dateParts = lastInspected.split("T")[0].split("-");
            inspectionDate = new Date(+dateParts[0], +dateParts[1] - 1, +dateParts[2]);
        } else {
            let dateParts = lastInspected.split("/");
            inspectionDate = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
        }
    }

    return inspectionDate;
}

export const applyStringFilter = (stringsInFilter: string[] | undefined, stringFromRowData: string): boolean => {
    if (!stringsInFilter?.length) return true;

    return stringsInFilter.includes(stringFromRowData)
}

export const applyInspectionDateRangeFilter = (
    selectedInspectionDateRange: Date[] | undefined,
    lastInspected: string | Date | null | undefined,
    aiGradingActive: boolean,
    vesselHasAiGrades: boolean = false) : boolean => {
    if (!selectedInspectionDateRange?.length) return true;

    const validDateRange = selectedInspectionDateRange[0] != null;

    if (!validDateRange) {
        return true;
    }

    if (!lastInspected) {
        if (aiGradingActive && vesselHasAiGrades) {
            return true;
        }

        return false;
    }

    const startDate = new Date(selectedInspectionDateRange[0]);

    const inspectionDate = formatLastInspectedAsDate(lastInspected);

    if (selectedInspectionDateRange[1] != null) {
        const endDate = new Date(selectedInspectionDateRange[1]);

        if (inspectionDate instanceof Date && startDate instanceof Date && endDate instanceof Date) {    
            return inspectionDate.getTime() >= startDate.getTime() && inspectionDate.getTime() <= endDate.getTime();
        }

        return false;
    }

    if (inspectionDate instanceof Date && startDate instanceof Date) {
        return inspectionDate.getTime() == startDate.getTime();
    }

    return false;
}

export const applyVesselAgeFilter = (selectedVesselAgeBands: string[] | undefined, shipAge: number) : boolean => {
    if (!selectedVesselAgeBands?.length) return true;

    let match = false;
    selectedVesselAgeBands.forEach(x => {
        const min = Number(x.split("-")[0].trim())
        const max = Number(x.split("-")[1].trim())

        if (shipAge >= min && shipAge <= max) {
            match = true;
        }
    });

    return match;
}

export function applyGlobalFilterWithoutVesselName(
    globalFilter: GlobalFilterValue,
    vesselType: string,
    shipAge: number,
) {
    return applyStringFilter(globalFilter?.vesselTypes, vesselType)
        && applyVesselAgeFilter(globalFilter?.vesselAgeBands, shipAge)
}

export function applyGlobalFilter(
    globalFilter: GlobalFilterValue,
    vesselName: string,
    vesselType: string,
    technicalManager: string,
    shipAge: number,
) {
    return applyStringFilter(globalFilter?.vessels, vesselName)
        && applyStringFilter(globalFilter?.vesselTypes, vesselType)
        && applyVesselAgeFilter(globalFilter?.vesselAgeBands, shipAge)
        && applyStringFilter(globalFilter?.technicalManagers, technicalManager);
}