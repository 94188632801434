import {StatTile} from "../../types/StatTile";
import {VesselSubgradeResponse} from "../../types/VesselSubgradeResponse";
import {FleetDataItem} from "../../types/FleetDataItem";
import {getVesselWithLowestSubgrade} from "./getVesselWithLowestSubgrade";

export class VesselWithLowestPscPerformanceTile implements StatTile {

    subgradeData : VesselSubgradeResponse
    inspectionData : FleetDataItem[]

    constructor(subgradeData: VesselSubgradeResponse, inspectionData: FleetDataItem[]) {
        this.subgradeData = subgradeData;
        this.inspectionData = inspectionData;
    }

    getTileData() {
        const vesselWithLowestPscPerformance = getVesselWithLowestSubgrade(this.subgradeData, this.inspectionData, "PSC Performance");

        if (vesselWithLowestPscPerformance?.vessel) {
            return ({
                data: vesselWithLowestPscPerformance,
                tileKey: "vesselWithLowestPscPerformance",
                statistic: "SUB GRADE",
                statisticFontSize: "1.68rem",
                details: vesselWithLowestPscPerformance.vessel,
                icon: "psc-performance-icon"
            });
        }

        return null;
    }
}
