import React, { ReactElement, useState } from "react";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import "./AddNewColumn.css";
import {AddNewColumnFunction} from "../../types/AddNewColumnFunction";
import {SharedTableColumnConfig} from "../../types/SharedTableColumnConfig";
import { CUSTOM_COLUMN_PREFIX } from "../../utils/Constants";

const MAX_COLUMNS = 30;
const COLUMN_NAME_LIMIT = 28;

export interface AddNewColumnProps {
    setDialogVisible: (visible: boolean) => void;
    onAddColumn: AddNewColumnFunction;
    columns: SharedTableColumnConfig[];
}

export const AddNewColumn = (props: AddNewColumnProps): ReactElement => {
    const {t} = useTranslation("locale");
    const [dataType, setDataType] = useState("");
    const [columnName, setColumnName] = useState("");
    const dataTypes = [t("columnEdit.text"), t("columnEdit.numerical")];

    const disableNameInput = columnName?.length > COLUMN_NAME_LIMIT;

    const getFormErrorMessage = (varToCheck: boolean, errorMsg: string) => {
        return varToCheck && <small className={"p-error error-message mt-1"}>{errorMsg}</small>;
    };


    const columnLimitReached: boolean = props.columns.filter(col => col.field.startsWith(CUSTOM_COLUMN_PREFIX)).length > MAX_COLUMNS;

    const columnNameExists: boolean = props.columns.some(col => col.field.endsWith(columnName)) && !!columnName;

    const cannotAddColumn = disableNameInput || columnLimitReached || !columnName || !dataType || columnNameExists;

    const saveAndClose = () => {
        props.onAddColumn(columnName, dataType);
        props.setDialogVisible(false);
    }


    return (
        <div className={"flex flex-column"}>
            <div className={"flex justify-content-between new-column-modal-row"}>
                <p className={"flex align-items-center new-column-modal-label"}>{t("columnEdit.columnNameLabel")}</p>
                <InputText value={columnName} onChange={e => setColumnName(e.target.value)} id="column-name" data-cy="column-name" className={`flex flex-grow-1 ${(disableNameInput || columnNameExists) ? "p-invalid" : ""}`}/>
            </div>
            {getFormErrorMessage(disableNameInput, t("columnEdit.nameTooLong"))}
            {getFormErrorMessage(columnNameExists, t("columnEdit.columnNameExists"))}



            <div className={"flex justify-content-between new-column-modal-row"}>
                <p className={"flex align-items-center new-column-modal-label"}>{t("columnEdit.dataTypeLabel")}</p>
                <Dropdown value={dataType} onChange={e => setDataType(e.value)} id="column-data-type" data-cy="column-data-type" options={dataTypes} placeholder={t("columnEdit.dataTypePlaceholder")} className={"flex flex-grow-1"}/>
            </div>

            <div className="flex justify-content-center new-column-modal-row">
                <Button onClick={() => props.setDialogVisible(false)} label={t("columnEdit.cancel")} className={"p-button-text mr-5"} />
                <Button disabled={cannotAddColumn} onClick={saveAndClose} label={t("columnEdit.saveAndClose")} />
            </div>
            <div className={"mt-2 flex justify-content-center new-column-modal-row"}>
                {getFormErrorMessage(columnLimitReached, t("columnEdit.tooManyColumns"))}
            </div>
        </div>
    )
}