import { Dialog } from "primereact/dialog";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { RemoveCustomColumnFunction } from "../../types/RemoveCustomColumnFunction";
import { Button } from "primereact/button";
import { CUSTOM_COLUMN_PREFIX } from "../../utils/Constants";

export interface DeleteColumnDialogDialogMethods {
    showDialog: (show: boolean, columnToDelete: string) => void;
}

export interface DeleteColumnDialogProps {
    onRemoveColumn: RemoveCustomColumnFunction;
    columns: any[];
    setColumns: React.SetStateAction<any>;
}

export const DeleteColumnDialog = forwardRef<DeleteColumnDialogDialogMethods, DeleteColumnDialogProps>((props, ref) => {
    const [visible, setVisible] = useState(false);
    const [columnToDelete, setColumnToDelete] = useState("");
    const {t} = useTranslation("locale");

    useImperativeHandle(ref, () => ({
        showDialog: (show: boolean, columnToDelete: string) => {
            setVisible(show);
            setColumnToDelete(columnToDelete);
        }
    }));

    const columnDeleteMessage = () => {
        if (!columnToDelete) {
            return;
        }

        const columnName = columnToDelete.replace(CUSTOM_COLUMN_PREFIX, "");

        return (
            <Trans 
                i18nKey={"deleteColumnDialog.message"} 
                components={[<b key={"deleteColumnDialog.message"}/>]} 
                values={{ columnName: columnName }} />
        )
    }

    const deleteColumn = () => {
        if (!props.onRemoveColumn || !columnToDelete) {
            return;
        }

        props.onRemoveColumn(columnToDelete);
        props.setColumns(props.columns.filter((column: any) => column.field !== columnToDelete));
        setVisible(false);
    }

    const cancelDeleteColumn = () => {
        setVisible(false);
    }

    return (
        <Dialog 
            id="delete-column-dialog"
            data-cy="delete-column-dialog"
            header={t("deleteColumnDialog.title")}
            style={{ width: "42.5rem" }}
            closable={false}
            blockScroll={true}
            visible={visible}
            onHide={() => setVisible(false)}
            draggable={false}>
                <p 
                    id="delete-column-message" 
                    data-cy="delete-column-message">
                        {columnDeleteMessage()}
                </p>
                <div className="flex justify-content-center mt-5">
                    <Button 
                        id="cancel-delete-column" 
                        data-cy="cancel-delete-column"
                        onClick={cancelDeleteColumn}
                        className="p-button-text mr-5"
                        label={t("deleteColumnDialog.cancel")} />
                    <Button 
                        id="confirm-delete-column"
                        data-cy="confirm-delete-column"
                        onClick={deleteColumn} 
                        label={t("deleteColumnDialog.confirm")} />
                </div>
        </Dialog>
    )
});