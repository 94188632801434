import {FleetDataItem} from "../../types/FleetDataItem";
import {StatTile} from "../../types/StatTile";

export class AverageIdwalGradeTile implements StatTile {

    fleetInspections : FleetDataItem[]

    constructor(fleetInspections: FleetDataItem[]) {
        this.fleetInspections = fleetInspections;
    }

    getTileData() {

        const totalIdwalGrade = this.fleetInspections
            .reduce((total, fleetEntry) => {
                return total + (fleetEntry.inspection.idwalGrade ?? 0);
            }, 0);

        const averageIdwalGrade=  totalIdwalGrade / this.fleetInspections.length;

        if (averageIdwalGrade){
            return {
                data: null,
                tileKey: "averageIdwalGrade",
                statistic: Math.round(averageIdwalGrade),
                details: "ACROSS ENTIRE FLEET",
                icon: "average-grade-icon"
            }
        }

        return null;
    }
}