import { AdvancedFilterSelectionState, AdvancedFilterStateType, BackgroundFilters, ForegroundFilters, RangeFilterState } from "../types/AdvancedFilters";
import {defaultFleetInspectionDateRangeOptions, getMinTeuRange} from "./advancedFilter/helpers";
import {applyInspectionDateRangeFilter} from "../components/Shared/GlobalFilter";
import {getDateRange} from "../components/Filter/GlobalDateRange";
import {GlobalFilterInspectionDateRange} from "../types/GlobalFilterValue";
import {TFunction} from "react-i18next";
import { COUNTRY_OF_BUILD, DWT, INSPECTION_DATE, TECHNICAL_MANAGER, TEU, VESSELS, VESSEL_TYPE , VESSEL_AGE } from "../constants/filterNames";
import { storeUserLocalStorageItem } from "./UserLocalStorage";
import LOCAL_TABLE_CONFIG_KEY from "../types/TableLocalConfigKeys";

export const defaultForegroundState: ForegroundFilters = {
    selectedVessels: {
        label: VESSELS,
        type: AdvancedFilterStateType.MultiSelectState,
        data: []
    },
    vesselType: {
        label: VESSEL_TYPE,
        type: AdvancedFilterStateType.MultiSelectState,
        data: []
    },
    countryOfBuild: {
        label: COUNTRY_OF_BUILD,
        type: AdvancedFilterStateType.MultiSelectState,
        data: []
    },
    technicalManager: {
        label: TECHNICAL_MANAGER,
        type: AdvancedFilterStateType.MultiSelectState,
        data: []
    },
    dwt: {
        label: DWT,
        data: [1000, 10000],
        limit: [0, 0],
        changed: false,
        type: AdvancedFilterStateType.RangeState,
    },
    teu: {
        label: TEU,
        data: [0, 0],
        limit: [0, 0],
        changed: false,
        type: AdvancedFilterStateType.RangeState,
    },
    inspectionDateRange: {
        label: INSPECTION_DATE,
        type: AdvancedFilterStateType.SingleSelect,
        data: [],
    },
    vesselAge: {
        label: VESSEL_AGE,
        type: AdvancedFilterStateType.MultiSelectState,
        data: []
    }
}

export const defaultBackgroundState: BackgroundFilters = {
    selectedVessels: {
        label: VESSELS,
        type: AdvancedFilterStateType.MultiSelectState,
        data: []
    },
    vesselType: {
        label: VESSEL_TYPE,
        type: AdvancedFilterStateType.MultiSelectState,
        data: []
    },
    countryOfBuild: {
        label: COUNTRY_OF_BUILD,
        type: AdvancedFilterStateType.MultiSelectState,
        data: []
    },
    technicalManager: {
        label: TECHNICAL_MANAGER,
        type: AdvancedFilterStateType.MultiSelectState,
        data: []
    },
    dwt: {
        label: DWT,
        data: [0, 0],
        limit: [0, 0],
        changed: false,
        type: AdvancedFilterStateType.RangeState,
    },
    teu: {
        label: TEU,
        data: [0, 0],
        limit: [0, 0],
        changed: false,
        type: AdvancedFilterStateType.RangeState,
    },
    inspectionDateRange: {
        label: INSPECTION_DATE,
        type: AdvancedFilterStateType.SingleSelect,
        data: [],
    },
    vesselAge: {
        label: VESSEL_AGE,
        type: AdvancedFilterStateType.MultiSelectState,
        data: []
    }
}

function applyCountryFilter(countryOfBuild?: string, selectedCountriesOfBuild?: Array<string>) {
    if (!selectedCountriesOfBuild?.length) return true;

    return selectedCountriesOfBuild.includes(countryOfBuild ?? "");
}

function applyTechnicalManagerFilter(technicalManager?: string, selectedTechnicalManagers?: Array<string>) {
    if (!selectedTechnicalManagers?.length) return true;

    return selectedTechnicalManagers.includes(technicalManager ?? "");
}
export function applyDwtFilter(dwt: number, dwtFilterState: RangeFilterState) {
    return dwtFilterState.changed
        ? dwt >= dwtFilterState.data[0] && dwt <= dwtFilterState.data[1]
        : true
}

function applyTeuFilter(teuFilterState: RangeFilterState, teu?: number) {
    if (!teuFilterState?.data || !teuFilterState.changed || teu === undefined) {
        return true;
    }

    const minRange = getMinTeuRange(teuFilterState);
    const maxRange = teuFilterState.data[1]; 

    return teu >= minRange && teu <= maxRange;
}

export function applyVesselFilter(vessel?: string, selectedVessels?: Array<string>) {
    if (!selectedVessels?.length) return true;

    return selectedVessels.includes(vessel ?? "");
}

export function getDateRangeFromAdvancedFilterInspectionDate(t: TFunction | undefined, advancedFilters: AdvancedFilterSelectionState) {
    const inspectionDateRangeOptions = defaultFleetInspectionDateRangeOptions(t);
    const defaultDateRangeKey = inspectionDateRangeOptions.find(e => e.default)?.key ?? 0;
    const selectedDateRangeKey = advancedFilters.foreground.inspectionDateRange.data.find(e => e.checked)?.key ?? defaultDateRangeKey;
    const dateRange = getDateRange(selectedDateRangeKey);
    const newInspectionDateRange: GlobalFilterInspectionDateRange = {
        code: selectedDateRangeKey,
        dateRange: [dateRange.startDate, dateRange.endDate],
    };
    return newInspectionDateRange;
}

export interface AttributesToFilterAgainst {
    aiGradingActive: boolean,
    countryOfBuild?: string,
    technicalManager?: string,
    dwt?: number,
    teu?: number,
    lastInspected?: string | null,
    vesselHasAiGrades?: boolean,
    vessel?: string
}

export function applyForegroundAdvancedFilters(
    advancedFilters: AdvancedFilterSelectionState,
    attributesToFilterAgainst: AttributesToFilterAgainst,
    t?: TFunction
) {
    const inspectionDateRange = getDateRangeFromAdvancedFilterInspectionDate(t, advancedFilters);

    return applyCountryFilter(
        attributesToFilterAgainst.countryOfBuild,
        advancedFilters?.foreground.countryOfBuild.data
    ) && applyDwtFilter(
        attributesToFilterAgainst.dwt as number,
        advancedFilters.foreground.dwt
    )  && applyTechnicalManagerFilter(
        attributesToFilterAgainst.technicalManager,
        advancedFilters?.foreground.technicalManager.data
    ) && applyTeuFilter(
        advancedFilters.foreground.teu,
        attributesToFilterAgainst.teu
    ) && applyInspectionDateRangeFilter(
            inspectionDateRange.dateRange,
        attributesToFilterAgainst.lastInspected,
        attributesToFilterAgainst.aiGradingActive,
        attributesToFilterAgainst.vesselHasAiGrades
    )
}

export function applyBackgroundAdvancedFilters(
    advancedFilters: AdvancedFilterSelectionState,
    attributesToFilterAgainst: AttributesToFilterAgainst,
    t?: TFunction
) {
    const backgroundFilterSelection = advancedFilters?.marketDataToggleSelected 
        ? 1 
        : 0
    const inspectionDateRange = getDateRangeFromAdvancedFilterInspectionDate(t, advancedFilters);

    return applyCountryFilter(
        attributesToFilterAgainst.countryOfBuild,
        advancedFilters?.background[backgroundFilterSelection].countryOfBuild.data
    ) && applyDwtFilter(
        attributesToFilterAgainst.dwt as number,
        advancedFilters.background[backgroundFilterSelection].dwt
    )  && applyTechnicalManagerFilter(
        attributesToFilterAgainst.technicalManager,
        advancedFilters?.background[backgroundFilterSelection].technicalManager.data
    ) && applyTeuFilter(
        advancedFilters.background[backgroundFilterSelection].teu,
        attributesToFilterAgainst.teu
    ) && applyInspectionDateRangeFilter(
        inspectionDateRange.dateRange,
        attributesToFilterAgainst.lastInspected,
        attributesToFilterAgainst.aiGradingActive,
        attributesToFilterAgainst.vesselHasAiGrades
    ) && applyVesselFilter(
        attributesToFilterAgainst.vessel,
        advancedFilters.background[backgroundFilterSelection].selectedVessels.data
    )
}

export const storeAppliedState = (username: string | undefined, newState: AdvancedFilterSelectionState) => {
    if (username) {
        storeUserLocalStorageItem(
            username, 
            LOCAL_TABLE_CONFIG_KEY.ADVANCED_FILTER_APPLIED_STATE, 
            newState
        );
    }
}

export const parseStoredState = (storedSelectionState?: string | null): AdvancedFilterSelectionState | null | undefined => {
    try {
        if (storedSelectionState) {
            return JSON.parse(storedSelectionState);
        } else {
            return null
        }
    } catch(e) {
        console.error(e)
        return null;
    }
};