import {FilterMatchMode, FilterService} from "primereact/api";
import {buildColumnConfig} from "../Shared/TableFunctions";
import {getTranslationObject} from "../../services/Translation";
import {customInspectionDateFilter} from "../Shared/FilterTemplates";
import {SharedTableColumnConfig} from "../../types/SharedTableColumnConfig";
import {SubgradeTableFields} from "./SubgradeTableFields";
import LOCAL_TABLE_CONFIG_KEY from "../../types/TableLocalConfigKeys";
import {getLocalStorageOrderedColumns} from "../../utils/TableUtils";
import {customGradeFilter} from "@idwal/idwal-react-components";

const t = getTranslationObject();

export const defaultSubgradeFiltersConfig = {
    "global": { value: null, matchMode: FilterMatchMode.CONTAINS },
    [SubgradeTableFields.NAME_FILTER_FIELD]: { value: null, matchMode: FilterMatchMode.CONTAINS },
    [SubgradeTableFields.TYPE]: { value: null, matchMode: FilterMatchMode.IN },
    [SubgradeTableFields.IDWAL_GRADE]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.TECHNICAL_MANAGER]: { value: null, matchMode: FilterMatchMode.IN },
    [SubgradeTableFields.INSPECTION_DATE]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.CONDITION_SCORE]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.MANAGEMENT_SCORE]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.DESIGN_AND_CONSTRUCTION]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.HULL]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.MOORING_DECKS]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.WEATHER_DECKS_AND_FITTINGS]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.BALLAST_TANK_AND_SYSTEMS]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.ACCOMMODATION]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.BRIDGE_AND_NAVIGATION_EQUIPMENT]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.ENGINE_ROOM_AND_MACHINERY]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.FIRE_FIGHTING_EQUIPMENT_AND_SYSTEMS]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.LIFESAVING_APPLIANCES]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.SAFE_WORKING_ENVIRONMENT]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.POLLUTION_CONTROL]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.ONBOARD_MANAGEMENT]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.VESSEL_CAPABILITIES_AND_CARGO_SYSTEMS]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.FORTHCOMING_REGULATORY_COMPLIANCE]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.CREW_WELFARE]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.CREW_PERFORMANCE]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.SAFETY_MANAGEMENT]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.PLANNED_MAINTENANCE_SYSTEM]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.CLASSIFICATION_AND_CERTIFICATION]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [SubgradeTableFields.SUBGRADES.PSC_PERFORMANCE]: { value: null, matchMode: FilterMatchMode.CUSTOM },
};

export const getDefaultSubgradeColumnsConfig = () => {
    const columns = [
        buildColumnConfig(SubgradeTableFields.TYPE, t("fleetTable.vesselType"), "text",
            { filterField: SubgradeTableFields.TYPE, filterPlaceholder: t("fleetTable.searchByType"), filterMatchMode: FilterMatchMode.IN }),
        buildColumnConfig(SubgradeTableFields.IDWAL_GRADE, t("fleetTable.idwalGrade"), "numeric",
            { filterField: SubgradeTableFields.IDWAL_GRADE, showFilterMenu: false, showFilterMatchModes: true },
            { justifyContent: "center" }),
        buildColumnConfig(SubgradeTableFields.TECHNICAL_MANAGER, t("fleetTable.technicalManager"), "text",
            { filterField: SubgradeTableFields.TECHNICAL_MANAGER, filterMatchMode: FilterMatchMode.IN }),
        buildColumnConfig(SubgradeTableFields.INSPECTION_DATE, t("subgrades.inspectionDate"), "date",
            { filterField: SubgradeTableFields.INSPECTION_DATE, showFilterMenu: false, showFilterMatchModes: true },
            { justifyContent: "center" }),
        buildColumnConfig(
            SubgradeTableFields.CONDITION_SCORE,
            t("fleetTable.conditionGrade"),
            "numeric",
            { filterField: SubgradeTableFields.CONDITION_SCORE, showFilterMenu: false, showFilterMatchModes: true },
            { justifyContent: "center", textAlign: "center" }
        ),
        buildColumnConfig(
            SubgradeTableFields.MANAGEMENT_SCORE,
            t("fleetTable.managementGrade"),
            "numeric",
            { filterField: SubgradeTableFields.MANAGEMENT_SCORE, showFilterMenu: false, showFilterMatchModes: true },
            { justifyContent: "center", textAlign: "center" }
        ),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.DESIGN_AND_CONSTRUCTION),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.HULL),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.MOORING_DECKS),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.WEATHER_DECKS_AND_FITTINGS),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.BALLAST_TANK_AND_SYSTEMS),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.ACCOMMODATION),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.BRIDGE_AND_NAVIGATION_EQUIPMENT),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.ENGINE_ROOM_AND_MACHINERY),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.FIRE_FIGHTING_EQUIPMENT_AND_SYSTEMS),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.LIFESAVING_APPLIANCES),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.SAFE_WORKING_ENVIRONMENT),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.POLLUTION_CONTROL),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.ONBOARD_MANAGEMENT),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.VESSEL_CAPABILITIES_AND_CARGO_SYSTEMS),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.FORTHCOMING_REGULATORY_COMPLIANCE),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.CREW_WELFARE),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.CREW_PERFORMANCE),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.SAFETY_MANAGEMENT),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.PLANNED_MAINTENANCE_SYSTEM),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.CLASSIFICATION_AND_CERTIFICATION),
        buildSubgradeColumnConfig(SubgradeTableFields.SUBGRADES.PSC_PERFORMANCE)
    ]

    const localOrderedColumnsConfig = getLocalStorageOrderedColumns(LOCAL_TABLE_CONFIG_KEY.SUBGRADE_ORDERED_COLUMNS, columns);
    if (localOrderedColumnsConfig)
    {
        return localOrderedColumnsConfig;
    }

    return columns;
}

export const buildSubgradeColumnConfig = (subgrade: string) : SharedTableColumnConfig => {
    const area = subgrade.split(".")[1];

    return buildColumnConfig(`subgrades.${area}`, t(`subgrades.areas.${area}`), "numeric",
        { filterField: `subgrades.${area}`, showFilterMenu: false, showFilterMatchModes: true },
        { justifyContent: "center", textAlign: "center" })
}

export const getStaticColumnsConfig = () => {
    return [
        buildColumnConfig(SubgradeTableFields.NAME_FILTER_FIELD, t("fleetTable.vessel"), "text",
            { filterField: SubgradeTableFields.NAME_FILTER_FIELD, filterPlaceholder: t("fleetTable.searchByName") }),
    ]
}

export const registerCustomFilters = () => {
    FilterService.register(`custom_${SubgradeTableFields.IDWAL_GRADE}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.DESIGN_AND_CONSTRUCTION}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.HULL}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.MOORING_DECKS}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.WEATHER_DECKS_AND_FITTINGS}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.BALLAST_TANK_AND_SYSTEMS}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.ACCOMMODATION}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.BRIDGE_AND_NAVIGATION_EQUIPMENT}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.ENGINE_ROOM_AND_MACHINERY}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.FIRE_FIGHTING_EQUIPMENT_AND_SYSTEMS}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.LIFESAVING_APPLIANCES}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.SAFE_WORKING_ENVIRONMENT}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.POLLUTION_CONTROL}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.ONBOARD_MANAGEMENT}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.VESSEL_CAPABILITIES_AND_CARGO_SYSTEMS}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.FORTHCOMING_REGULATORY_COMPLIANCE}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.CREW_WELFARE}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.CREW_PERFORMANCE}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.SAFETY_MANAGEMENT}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.PLANNED_MAINTENANCE_SYSTEM}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.CLASSIFICATION_AND_CERTIFICATION}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.SUBGRADES.PSC_PERFORMANCE}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.INSPECTION_DATE}`, customInspectionDateFilter);
    FilterService.register(`custom_${SubgradeTableFields.CONDITION_SCORE}`, customGradeFilter);
    FilterService.register(`custom_${SubgradeTableFields.MANAGEMENT_SCORE}`, customGradeFilter);
}
