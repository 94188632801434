import { useRef, useState } from "react";
import { errorToast } from "@idwal/idwal-react-components";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import AssetsService from "../../services/AssetsService";
import { DefectDataItem } from "../../types/DefectsDataItem";
import ImageViewer, { ImageViewerMethods } from "../Shared/ImageViewer";
import { sendGAEvent } from "../../AnalyticsService";
import { ANALYTICS_BUTTON_CLICK, DEFECTS_IMAGES_CLICK } from "../../constants/analytics";

/**
 * View image button for the defect table, when clicked opens the image viewer with all defect images
 * @param data 
 * @returns 
 */
export const ViewImageButton = (data: DefectDataItem) => {
    const { t } = useTranslation("locale");

    const [loading, setLoading] = useState(false);

    const imageViewerRef = useRef<ImageViewerMethods | null>(null);

    /**
     * When button clicked, get all pre signed URLS and open the image viewer
     * @returns 
     */
    const handleImageClick = async () => {     
        if (loading) {
            return;
        }

        const images = JSON.parse(data.images);
        const assetIds = images.map((asset: any) => asset?.Id);
        setLoading(true);
        await new AssetsService().assetsUrlsRequest(assetIds)
            .then(signedUrlsResponse => {
                if (signedUrlsResponse?.length) {
                    const signedUrls = signedUrlsResponse.map((asset: any) => Object.values(asset)[0]);
                    if (imageViewerRef.current) {
                        imageViewerRef.current.handleImageClick(signedUrls);
                    }
                } else {
                    errorToast(t("imageViewer.errorMessage"))
                }
            })
            .catch(error => {
                errorToast(t("imageViewer.errorMessage"));
            });
        setLoading(false);
        sendGAEvent(ANALYTICS_BUTTON_CLICK, DEFECTS_IMAGES_CLICK);
    }

    if (data.images === null || data.images === "" || data.images === "[]" || data.images === "null") {
        return <><ImageViewer ref={imageViewerRef} /></>;
    }

    return (
        <div className="flex align-items-center justify-content-center">
            <ImageViewer ref={imageViewerRef} />
            <Button
                key={data.defectId}
                loading={loading}
                onClick={handleImageClick}
                tooltip={t("defectsTable.viewImages")}
                icon="pi pi-images"
                tooltipOptions={{ position: "left" }}
                className={"request-inspection p-button p-component p-button-rounded p-button-primary p-button-text p-button-icon-only"}
                data-cy={`defect-${data.defectId}-images-button`} />
        </div>
    )
};