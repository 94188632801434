import { FleetDataItem } from "../../types/FleetDataItem";
import { StatTile } from "../../types/StatTile";
import { STAT_TILE_ID_INSPECTIONS_COMPLETED } from "../Constants";

export class IDInspectionsCompletedInContractYear implements StatTile {
    fleetDataItems: Array<FleetDataItem>
    contractDetails: { contractStartDate: number; contractEndDate: number; } | null
    detailsText: string

    constructor(
        fleetDataItems: Array<FleetDataItem>, 
        contractDetails: { contractStartDate: number; contractEndDate: number; } | null,
        detailsText: string
    ) {
        this.fleetDataItems = fleetDataItems;
        this.contractDetails = contractDetails;
        this.detailsText = detailsText
    }

    getTileData() {
        if (!this.contractDetails) {
            return null
        }

        const contractStart = new Date(this.contractDetails.contractStartDate * 1000);
        const contractEnd = new Date(this.contractDetails.contractEndDate * 1000);
        const today = new Date();
        const isBetweenContractDates = (today >= contractStart && today <= contractEnd);

        if (!isBetweenContractDates) {
           return null
        }

        const ID_INSPECTION_TYPE = 'ID Condition Inspection';

        const toScoreHistory = (fleetDataItem: FleetDataItem) => fleetDataItem.scoreHistory;
    
        const completedInspections = this.fleetDataItems.flatMap(toScoreHistory).filter((inspection) => {
            const dateOfInspection = 
                inspection?.dateFrom && 
                new Date(inspection?.dateFrom);

            // Have to cast this as any due to inconsistent types
            const inspectionType = inspection?.inspectionType as any;

            return (
                    dateOfInspection 
                &&
                    inspectionType?.toLowerCase() === ID_INSPECTION_TYPE.toLowerCase()
                && 
                (
                    dateOfInspection >= contractStart && 
                    dateOfInspection <= contractEnd
                )
            )
        });

        return {
            data: completedInspections,
            tileKey: STAT_TILE_ID_INSPECTIONS_COMPLETED,
            statistic: "x" + completedInspections.length,
            details: this.detailsText,
            icon: "inspections-completed-icon",
            additionalData: 'details'
        }
    }
}
