import {FleetDataItem} from "../../types/FleetDataItem";
import {STAT_TILE_OVERDUE , INSPECTION_STATUS_FULL_REPORT} from "../Constants";
import {StatTile} from "../../types/StatTile";


export class VesselsOverdueInspectionTile implements StatTile {

    fleetInspections : FleetDataItem[]

    constructor(fleetInspections: FleetDataItem[]) {
        this.fleetInspections = fleetInspections;
    }

    getTileData() {
        const today = new Date();

        const overdueInspections = this.fleetInspections.filter((entry) => {
            if (!entry.scheduledDate) {
                return false;
            }

            return entry.scheduledDate <= today && (!entry.inspection || (entry.inspection.isScheduled === false && entry.inspection.status === INSPECTION_STATUS_FULL_REPORT));
        });

        if (overdueInspections && overdueInspections.length > 0) {

            const dropDownData = overdueInspections.map((inspection) => {
                return `${inspection.vessel.vesselName} ${inspection.vessel.imo}`
            });

            const vesselsOverdueForInspectionNames = overdueInspections.map((entry) => entry.vessel.vesselName).join(", ");
            return {
                data: overdueInspections,
                tileKey: STAT_TILE_OVERDUE,
                statistic: "x" + overdueInspections.length,
                details: vesselsOverdueForInspectionNames,
                icon: "overdue-inspection-icon",
                additionalData : dropDownData
            };
        }

        return null;
    }
}