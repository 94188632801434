import { getUserNotifications } from "../../services/NotificationService";
import { setError, setLoading, setRead, setUnread } from "../slices/notificationsSlice";
import { AppThunk } from "../store";

export const fetchNotifications = (): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(false));

    try {
        const read = await getUserNotifications(true);

        if (read) {
            dispatch(setRead(read));
        }

        const unread = await getUserNotifications(false);

        if (unread) {
            dispatch(setUnread(unread));
        }

        if (!read || !unread) {
            throw Error('no data');
        }
    } catch (e) {
        console.error(e);
        dispatch(setError(true));
    } finally {
        dispatch(setLoading(false));
    }
};