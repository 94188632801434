import { NotificationTypes } from "../../types/NotificationTypes";
import { NotificationTitleMap } from "../../types/NotificationsModalProps";

export const NOTIFICATIONS_FILTER_OPTIONS = [
    {
        value: true,
        label: "Read"
    },
    {
        value: false,
        label: "Unread"
    }
];

export const DEFECT_RECTIFIED_NOTIFICAITON_KEY = "DEFECT_RECTIFIED_FLEET";
export const FLEET_DEFECT_EXTRACTION_EXCEL_GENERATED = "FLEET_DEFECT_EXTRACTION_EXCEL_GENERATED";

export enum NOTIFICATION_CARD_TYPES {
    CTA = "cta",
    INFO = "info"
}

export const NOTIFICATION_TYPE_MAP: NotificationTypes = {
    defectRectifiedFleet: NOTIFICATION_CARD_TYPES.CTA,
    defectExcelGeneratedPlan: NOTIFICATION_CARD_TYPES.CTA
};

export const NOTIFICATION_ICON_MAP: NotificationTypes = {
    defectRectifiedFleet: "defect_rectified_notification_icon.svg",
    defectExcelGeneratedPlan: "defect_generated_excel_plan_notification_icon.svg",
};

export const NOTIFICATION_TITLE_MAP: NotificationTitleMap = {
    defectRectifiedFleet: "defectRectified",
    defectExcelGeneratedPlan: "defectExcelGeneratedPlan",
}

export const NOTIFICATION_KEY_MAP: NotificationTypes = {
    defectRectifiedFleet: "defectRectifiedFleet",
    defectExcelGeneratedPlan: "defectExcelGeneratedPlan",
}

export const PERMITTED_NOTIFICATIONS = [
    DEFECT_RECTIFIED_NOTIFICAITON_KEY,
    FLEET_DEFECT_EXTRACTION_EXCEL_GENERATED
];