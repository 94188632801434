import { DataTableExportFunctionEvent } from "primereact/datatable";
import { DefectPriority } from "../types/DefectPriority";
import { getDefectStatusValue, getGradingAreaFieldValue } from "./DefectsTableDataHelper";

/**
 * Overrides the csv export function to handle data view
 * @param data 
 * @returns 
 */
export function customCsvExport(data: DataTableExportFunctionEvent<any>) {
  const { field, data: value, rowData } = data;
  return prepareDefectDataForExport(field, value, rowData)
}

/**
 * Removes commas, slashes, doubles spaces, new lines etc
 * @param value 
 * @returns 
 */
const sanitiseTextForCSV = (value: string) : string =>  {
    if (value) {
        value = value.trim();
        return value.replace(/\r|\n|\\|,|\s{2,}|\\/g, ' '); // replace any dodgey charactors that can break CSV
    }
    return "";
}

/**
 * Reverse engineers the priortiy map to get the key from the value
 * @param priority 
 * @returns 
 */
const getPriorityTextFromValue = (priority: number) => {
    for (const key in DefectPriority) {
        if (DefectPriority[key].toString() === priority.toString()) {
            return key;
        }
    }
    return "";
}

/**
 * Prepares defect data for export
 * @param field 
 * @param content 
 * @param rowData 
 * @returns 
 */
export const prepareDefectDataForExport = (field: string, content: any, rowData?: any) : string => {
    switch (field) {
        case "priority":
            return getPriorityTextFromValue(content)?.toUpperCase();
        case "gradingAreaField":
            return content;
        case "status":
            return getDefectStatusValue(content).toString().toUpperCase();
        case "formattedDefectId":
        case "type":
        case "estimateDefectCostField":
        case "inspectionDate":
        case "shipName":
        case "technicalManager":
        case "idwalGrade":
        case "shortGenName":
        case "typeCode":
            return content;
        case "vesselName": {
            const imo = rowData['inspection']['shipImo'];

            if (imo) {
                return `${content} - ${imo}`;
            }

            return content;
        }
        case "description":
        case "recommendations":
        case "notes":
            return sanitiseTextForCSV(content); 
        case "images":
            if (content) {
                const parsedValue = JSON.parse(content);
                if (parsedValue) {
                    return parsedValue.length;
                }
            }
            return "";
        default:
            return "";
    }
}