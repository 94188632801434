import axios from "axios";
import { getCurrentSessionIdToken } from "../queries/AuthToken";
import { RequestInspectionInfo } from "../types/RequestInspectionInfo";

export async function requestInspection(requestInfo: RequestInspectionInfo) {
    if (!requestInfo.toEmail) {
        // If we failed to get an email to send to from the user profile info, then fallback to the email specified in config.sh to ensure we send an email out
        requestInfo.toEmail = process.env.REACT_APP_REQUEST_INSPECTION_EMAIL;
    }

    console.info("Requesting inspection ", requestInfo);

    const message = {
        "toAddress": requestInfo.toEmail,
            "smtpParams": {
                "/idwal/no-reply-user/office-365-mail-server/password": "password",
                "/idwal/no-reply-user/office-365-mail-server/username": "username",
                "/idwal/office-365-mail-server/host": "host",
                "/idwal/office-365-mail-server/service": "service",
                "/idwal/office-365-mail-server/port": "port",
                "/idwal/no-reply-user/EmailFromAddress": "emailFromAddress",
                "/idwal/no-reply-user/EmailFromName": "emailFromName"
            },
            "subject": "Inspection Request",
            "emailTemplate": "fleet/inspection-request",
            "emailContent": {
                "vesselNames": requestInfo.vesselNames.toString(),
                "vesselImos": requestInfo.vesselImos.toString(),
                "comment": requestInfo.comment,
                "organisation": requestInfo.organisation,
                "name": requestInfo.name,
                "email": requestInfo.email,
                "groupName": requestInfo.groupName
            }
    };

    const url : string = process.env.REACT_APP_IDWAL_API_ENDPOINT ?? "";
    const token = await getCurrentSessionIdToken();

    return new Promise<boolean>((resolve, reject) => {
        axios({
            method: "POST",
            url: url + "/email",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            data: JSON.stringify(message)
        }).then((res) => {
            console.info("Inspection request sent", res);
            resolve(res.status === 200); 
        })
        .catch((rej) => {
            console.error("Error sending inspection request", rej);
            reject(false);
        });
    });
}