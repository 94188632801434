import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

/**
 * Component popup which is used to ensure changes are saved
 * @param props
 * @returns
 */
export const UnSavedChangesPrompt = (props: {
  hasUnsavedChanges: boolean;
  continueAction: () => void;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation("locale");

  const renderFooter = () => {
    return (
      <div>
        <Button
          label={t("unsavedChangesPrompt.keepEditing")}
          onClick={() => props.setVisible(false)}
          className="p-button-text"
        />
        <Button
          label={t("unsavedChangesPrompt.leaveAnyway")}
          onClick={() => props.continueAction()}
          autoFocus
        />
      </div>
    );
  };

  if (props.hasUnsavedChanges) {
    return (
      <Dialog
        header={t("unsavedChangesPrompt.leaveWithoutSaving")}
        visible={props.visible}
        style={{ width: "40vw" }}
        footer={renderFooter()}
        onHide={() => props.setVisible(false)}
        closable={false}
        draggable={false}
      >
        <p>{t("unsavedChangesPrompt.loseChangesWarning")}</p>
      </Dialog>
    );
  } else {
    return <></>;
  }
};
