import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DefectDataItem } from "../../types/DefectsDataItem";
import { SharedDefectItem } from "../../types/SharedDefectItem";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Timeline } from "primereact/timeline";
import { DefectActivity } from "../../types/DefectActivity";
import dayjs from "dayjs";
import "./DefectRectificationActivity.css";
import { fetchRectificationActivity } from "../../store/thunks/rectificationThunks";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectRectification } from "../../store/selectors/rectificationSelector";

type DefectRectificationActivityProps = {
    className?: string;
    defect: DefectDataItem | SharedDefectItem
}

export const DefectRectificationActivity = (props: DefectRectificationActivityProps) => {
    const { className, defect } = props;

    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();

    const { activityData, activityLoading, activityError } = useAppSelector(selectRectification);

    useEffect(() => {
        dispatch(fetchRectificationActivity(defect));
    }, [defect]);

    const formatDate = (dateString: string) => {
        let formattedDate = "";

        if (dateString) {
            if (Number.isNaN(Number(dateString))) {
                formattedDate = `${dateString} 00:00 - `;
            } else {
                formattedDate = `${dayjs(Number(dateString) * 1000).format("DD/MM/YYYY HH:mm")} - `
            }
        }
      
        return formattedDate;
    }

    const itemTemplate = (item: DefectActivity) => (
        <span className="defect-rectification-activity-text">{formatDate(item.createdAt)}
            <span className={item.createdAt ? "defect-rectification-activity-subtext" : "defect-rectification-activity-text"}>{item.activity}</span>
        </span>
    );

    const markerTemplate = (item: DefectActivity) => (
        <div className={item.createdAt ? "defect-rectification-activity-marker" : "p-timeline-event-marker"} />
    );

    let content = <Timeline className="defect-rectification-activity" value={activityData} content={itemTemplate} marker={markerTemplate} />;

    if (activityLoading) {
        content = <ProgressSpinner className="flex align-self-center" />;
    }

    if (activityError) {
        content = <p>{t("defectRectification.getActivityError")}</p>;
    }

    return (
        <Card
            header={
                <h2
                    style={{
                        border: "none",
                        marginLeft: "0",
                        whiteSpace: "nowrap"
                    }}
                >
                    {t("defectRectification.activityTimeline")}
                </h2>
            }
            className={className}
        >
            {content}
        </Card>
    );
};
