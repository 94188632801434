import React from "react";
import "./DefectsTable.css";
import { getTranslationObject } from "../../services/Translation";
import { DefectPriority } from "../../types/DefectPriority";
import { InputText } from "primereact/inputtext";
import { ReadMoreButton } from "./ReadMore";
import { DisplayModalContent } from "../Shared/DisplayModal";
import { addEllipsis } from "../../utils/TableUtils";

const t = getTranslationObject();

export const gradingAreaTemplate = (rowData: any) => {
  return <p>{ rowData.gradingAreaField }</p>
}

export const defectPriorityTemplate = (rowData: any) => {
  return (
    <React.Fragment>
      <div className="text-center">
        <p>
          {getPriorityIcon(rowData.priority)}
        </p>
      </div>
    </React.Fragment>
  );
}

export const estimateDefectCostTemplate = (rowData: any) => {
  return (
    <React.Fragment>
      <p>{rowData.estimateDefectCostField}</p>
    </React.Fragment>
  );
}

export const inspectionDateFieldTemplate = (rowData: any) => {
  let inspectionDate = rowData.inspectionDate;

  return (
      <React.Fragment>
          <p>{inspectionDate || ""}</p>
      </React.Fragment>
  );
};

export const notesPlaceholderTemplate = (rowData: any) => {
  let value = rowData.notes
  if(value === null || value === undefined){
    value = ""
  }
  if(value?.length === 0 || value === undefined){
    return <p className="p-inputtext line-height-2 p-component ml-1 w-full defect-note">{t('defectsTable.notesPlaceholder')}</p>
  } else {
    return(
      <span>{rowData.notes}</span>
    )
  }
}

export const notesEditorTemplate = (rowData: any) => {
  let value = rowData.rowData.notes
  if(value === null || value === undefined){
    value = ""
  }
  return(
    <React.Fragment>
      <div>
      <InputText type="text" placeholder={t('defectsTable.notesPlaceholder')} value={value} className={value?.length > 500 ? "p-invalid ml-1 w-full" : "ml-1 w-full"} onChange={(e) => rowData.editorCallback(e.target.value)} />
      {value?.length > 500 ? (<small className="p-error block ml-1">{t('defectsTable.notesValidation')}</small>) : null}
      </div>
    </React.Fragment>
  )
};

export const descriptionTemplate = (rowData: any, handleReadMoreClick: (content: DisplayModalContent) => void, maxLength: number) => {
  let shipName = rowData?.vesselName ? rowData.vesselName : rowData.shipName;

  return columnText(rowData.description, shipName, "description", handleReadMoreClick, maxLength);
}

export const recommendationsTemplate = (rowData: any,  handleReadMoreClick: (content: DisplayModalContent) => void, maxLength: number) => {
  let shipName = rowData?.vesselName ? rowData.vesselName : rowData.shipName;

  return columnText(rowData.recommendations, shipName, "recommendation", handleReadMoreClick, maxLength);
}

/**
 * Column that displays text and shows ReadMore button if content is > maxLangth
 * @param content 
 * @param vesselName 
 * @param contentType 
 * @param handleReadMoreClick 
 * @param maxLength
 * @returns 
 */
const columnText = (content: string, vesselName: string, contentType: string,  handleReadMoreClick: (content: DisplayModalContent) => void, maxLength: number) => {  
    return (
        <p className="flex flex-column">
            <span className="line-clamp">{addEllipsis(content, maxLength)}</span>
            {
                content.length >= maxLength ?
                    <ReadMoreButton vesselName={vesselName} content={content} contentType={contentType} handleReadMoreClick={handleReadMoreClick}/>
                    : null
            }
        </p>
    );
}

/**
 * Returns icon for defect priortiy,defaults to medium
 * @param priority 
 * @returns 
 */
export const getPriorityIcon = (priority: number) => {
  if (priority === DefectPriority.high) {
    return <img
        src="/high.svg"
        className="priority-icon-size"
    />
  } 

  if (priority === DefectPriority.medium) {
    return <img
        src="/medium.svg"
        className="priority-icon-size"
    />
  } 

  if (priority === DefectPriority.low) {
    return <img
        src="/low.svg"
        className="priority-icon-size"
    />
  } 

  return <img
        src="/medium.svg"
        className="priority-icon-size"
  />
}