import React, { ReactElement, useRef, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import "./ColumnReorder.css";
import { ColumnReorderProps } from "../../types/ColumnReorderProps";
import { DeleteColumnDialog, DeleteColumnDialogDialogMethods } from "../DeleteColumnDialog.tsx/DeleteColumnDialog";
import { CUSTOM_COLUMN_PREFIX } from "../../utils/Constants";

export const ColumnReorder = (props: ColumnReorderProps): ReactElement => {
    const {t} = useTranslation("locale");
    const [columns, setColumns] = useState(props.columns);
    const deleteColumnDialog = useRef<DeleteColumnDialogDialogMethods>(null);

    const itemTemplate = (item: any) => {
        return (
            <div>
                {item.header}
            </div>
        )
    };

    const footerTemplate = () => {
        return (
            <div className="flex justify-content-center">
                <Button onClick={saveAndClose} label={t("columnReorder.saveAndClose")} />
            </div>
        )
    }

    const handleRowReorder = (e: any) => {
        setColumns(e.value);
    }

    const saveAndClose = () => {
        props.setDialogVisible(false);
        if (props.onColumnReorder) {
            props.onColumnReorder(columns);
        }
    }

    const deleteCustomColumn = (columnName: string) => {
        if (!props.onRemoveColumn || !deleteColumnDialog.current) {
            return;
        }

        deleteColumnDialog.current.showDialog(true, columnName);
    }

    const actionsTemplate = (column: any) => {
        return (
            <div id="column-item" style={{minWidth: "3em"}}>
                {column.field.startsWith(CUSTOM_COLUMN_PREFIX) && 
                    <Button 
                        className="p-button-text p-button-danger p-button-icon-only delete-column-button" 
                        onClick={() => deleteCustomColumn(column.field)}
                        tooltip={t("columnReorder.deleteTooltip")}
                    >
                        <span className="p-button-icon p-c pi pi-trash"></span>
                    </Button>}
            </div>
        )
    }

    return (
        <>
            {props.onRemoveColumn && 
                <DeleteColumnDialog 
                    ref={deleteColumnDialog} 
                    onRemoveColumn={props.onRemoveColumn} 
                    columns={props.columns}
                    setColumns={setColumns}
                />
            }
            <div className="card" id="reorder-columns" data-cy="reorder-columns">
                <DataTable
                    id="reorder-table"
                    className="reorder-table"
                    data-cy="reorder-table"
                    footer={footerTemplate()}
                    value={columns}
                    reorderableRows
                    scrollable
                    scrollHeight="60vh"
                    onRowReorder={handleRowReorder}>
                    <Column className="col-1 row-reorder" rowReorder />
                    <Column className="col-10" field="header" body={itemTemplate} header={t("columnReorder.columnHeader")} />
                    <Column headerClassName="column-actions" body={actionsTemplate} />
                </DataTable>
            </div>
        </>
    )
}