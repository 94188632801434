import { useState, forwardRef, useImperativeHandle } from "react";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";

export interface DisplayModalMethods {
    showContent: (content: DisplayModalContent) =>  void;
}

export interface DisplayModalContent {
    title: string,
    body: string
}

const DisplayModal = forwardRef<DisplayModalMethods>((props, ref): any => {
    const [visible, setVisible] = useState(false);
    const [content, setContent] = useState<DisplayModalContent>({
        title: "",
        body: ""
    });
    const { t } = useTranslation("locale");

    useImperativeHandle(ref, () => ({
        showContent: (modalContent: DisplayModalContent) => {
            setVisible(true);
            setContent({
                ...modalContent
            })
        }
    }));

    const closeModal = () => {
        setVisible(false);
        setContent({
            title: "",
            body: ""
        })
    };

    return (
        <Dialog blockScroll header={content.title} visible={visible} style={{ width: '50rem' }} onHide={() => setVisible(false)} draggable={false}>
            <p className="m-0">
                { content.body }
            </p>
            <div className="flex align-items-center justify-content-center mt-4">
                <Button onClick={() => closeModal()} className="p-button p-component p-button-text"> 
                    <span data-cy="close-modal-btn" className="p-button-label p-c w-9rem">
                        { t('displayModal.close') }
                    </span>
                </Button>
            </div>
        </Dialog>
    );
});

export default DisplayModal;
