import {VesselSubgradeResponse} from "../../types/VesselSubgradeResponse";
import {SubgradeBackingData} from "../../types/SubgradeBackingData";
import {calculateAverageSectionGrade, calculateDeviationPercentages} from "../SubgradeDataHelper";
import {StatTile} from "../../types/StatTile";

export class SubgradeWithWorstDeviationAcrossFleetTile implements StatTile {

    subgradeData : VesselSubgradeResponse
    subgradeBackingData: SubgradeBackingData

    constructor(subgradeData: VesselSubgradeResponse, subgradeBackingData: SubgradeBackingData) {
        this.subgradeData = subgradeData;
        this.subgradeBackingData = subgradeBackingData;
    }

    getTileData() {
        const sectionAverages = calculateAverageSectionGrade(this.subgradeData);
        const deviations = calculateDeviationPercentages(sectionAverages, this.subgradeBackingData);


        let worstArea = { area: "", deviation: 0 }
        Object.entries(deviations).forEach(x => {
            const area = x[0];
            const deviation = x[1];

            if (worstArea.deviation > deviation) {
                worstArea = { area: area, deviation: deviation}
            }
        })

        if (worstArea?.area) {
            return {
                data: worstArea,
                tileKey: "subgradeWithWorstDeviationAcrossFleet",
                statistic: "SUB GRADE",
                statisticFontSize: "1.68rem",
                details: worstArea.area,
                icon: "worst-deviation-subgrade-icon"
            };
        }

        return null;
    }
}
