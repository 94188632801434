import {useTranslation} from "react-i18next";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { DefectRectificationForm } from "../components/DefectRectification/DefectRectificationForm";
import { DefectRectificationDetails } from "../components/DefectRectification/DefectRectificationDetails";
import { errorToast, successToast } from "@idwal/idwal-react-components";
import { Card } from "primereact/card";
import { UnSavedChangesPrompt } from "../components/unsavedChangesPrompt/unsavedChangesPrompt";
import { DEFECTS, RECTIFICATIONS } from "../types/RouteNames";
import { sendGAEvent } from "../AnalyticsService";
import { ANALYTICS_BUTTON_CLICK, DEFECTS_RECTIFICATION_SAVE_CLICK } from "../constants/analytics";
import { DefectStatus } from "../types/DefectStatus";
import { Capability } from "../types/Capability";
import { useCapabilities } from "../hooks/useCapabilities";
import { DefectRectificationActivity } from "../components/DefectRectification/DefectRectificationActivity";
import { useAppDispatch, useAppSelector } from "../hooks/storeHooks";
import { selectRectification } from "../store/selectors/rectificationSelector";
import { createRectification, fetchRectification } from "../store/thunks/rectificationThunks";
import { ProgressSpinner } from "primereact/progressspinner";
import { selectDefectsLoading } from "../store/selectors/defectSelectors";
import { selectSharedDefectsLoading } from "../store/selectors/sharedDefectsSelectors";

const DefectRectification = (props: {
    sharedDefect?: boolean;
}) => {
    const { sharedDefect } = props;

    const formRef = useRef();
    const dispatch = useAppDispatch();

    const { t } = useTranslation("locale");
    const navigate = useNavigate();
    const { defectId } = useParams();

    const { data: rectification, loading, error, defect } = useAppSelector(selectRectification);
    const defectsLoading = useAppSelector(selectDefectsLoading);
    const sharedDefectsLoading = useAppSelector(selectSharedDefectsLoading);
    const dataLoading = sharedDefect ? sharedDefectsLoading : defectsLoading;

    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
    const [unsavedModalVisible, setUnsavedModalVisible] = useState<boolean>(false);

    useEffect(() => {
        if (!dataLoading) {
            dispatch(fetchRectification(defectId, sharedDefect));
        }
    }, [dataLoading]);

    const { capabilities } = useCapabilities([
        Capability.CAP_FLEETAPP_CLOSE_DEFECTS
    ]);

    const navigateBack = () => {
        navigate(sharedDefect ? `/${RECTIFICATIONS}` : `/${DEFECTS}`);
    }

    const disabled = defect?.status === DefectStatus.closed && !capabilities[Capability.CAP_FLEETAPP_CLOSE_DEFECTS];

    if (!defect || loading) {
        return (
            <div className="flex h-screen justify-items-center align-items-center">
                <ProgressSpinner />
            </div>
        );
    }

    if (error) {
        errorToast(t("defectRectification.getError"));
    }

    /**
     * Disard changes, show popup if unsaved
     */
    const cancel = () => {
        if (hasUnsavedChanges) {
            setUnsavedModalVisible(true);
        }
        else {
            navigateBack();
        }
    }

    /**
     * Save to defect rectification service and return back to table
     */
    const save = async () => {
        setSaveLoading(true);
        sendGAEvent(ANALYTICS_BUTTON_CLICK, DEFECTS_RECTIFICATION_SAVE_CLICK);
        if (formRef.current && defect) {
            const current = formRef.current as any;
            const formData = current.submitForm();

            setSaveLoading(true);

            const created = await dispatch(createRectification(formData, defect, sharedDefect));

            setSaveLoading(false);

            if (created) {
                if (defect.rectificationId) {
                    successToast(t("defectRectification.updateMessage"));
                } else {
                    successToast(t("defectRectification.createMessage"));
                }
                
                navigateBack();
            }
        }
    }

    return (
        <div className="p-5 w-full">
            <UnSavedChangesPrompt
                visible={unsavedModalVisible}
                setVisible={setUnsavedModalVisible}
                hasUnsavedChanges
                continueAction={navigateBack}
            />
            <div className="grid align-items-start justify-content-center flex-grow-1">
                <div className="col-12 lg:col-6">
                    <DefectRectificationDetails defect={defect}/>
                    <DefectRectificationActivity defect={defect} className="mt-4" />
                </div>

                <div className="col-12 lg:col-6">
                    <Card
                        header={
                            <h2
                                style={{
                                    border: "none",
                                    marginLeft: "0",
                                    whiteSpace: "nowrap"
                                }}
                            >
                                {t("defectRectification.title")}
                            </h2>
                        }
                    >
                        <DefectRectificationForm
                            fromRef={formRef}
                            defect={defect}
                            disabled={disabled}
                            rectificationDetails={rectification}
                            setHasUnsavedChanges={setHasUnsavedChanges}
                        />
                    </Card>
                </div>
            </div>

            <div className="mt-6 w-full flex align-items-center justify-content-center gap-4">
                <Button
                    className="p-button p-component p-button-text"
                    label={t("defectRectification.cancel")}
                    data-cy="cancel"
                    onClick={cancel}
                />
                <Button
                    className="p-button p-button-primary"
                    label={t("defectRectification.save")}
                    data-cy="save"
                    loading={saveLoading}
                    onClick={save}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}

export default DefectRectification;