import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "primereact/column";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { SharedTableColumnConfig } from "../../types/SharedTableColumnConfig";
import { columnWidth } from "../../utils/DefectsTableDataHelper";
import { getSharedDefectsColumnsConfig, registerCustomFilters } from "./Filters/FilterTemplates";
import DisplayModal, { DisplayModalContent, DisplayModalMethods } from "../Shared/DisplayModal";
import { filterSharedDefectsTemplates, keywordSharedDefectsSearchFilterFields, sharedDefectsTemplates } from "./Constants";
import { SharedDefectsHeader } from "../Shared/SharedDefectsHeader";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectSharedDefects } from "../../store/selectors/sharedDefectsSelectors";
import { errorToast } from "@idwal/idwal-react-components";
import { useFlags } from "launchdarkly-react-client-sdk";
import { selectDataTableFilterData } from "../../store/selectors/filtersSelectors";
import { setSharedDefectsTableFilterData } from "../../store/slices/filtersSlice";
import { setPage } from "../../store/slices/sharedDefectsSlice";

export const SharedDefectsTable = () => {
    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();

    const { data: sharedDefects, loading, error, page } = useAppSelector(selectSharedDefects);
    const dataTableFilterData = useAppSelector(selectDataTableFilterData);

    const { defectMapping } = useFlags();
    
    const displayModal = useRef<DisplayModalMethods>(null);
    const defectTable = useRef<any>(null);

    const columns = getSharedDefectsColumnsConfig(defectMapping);
    const [filteredData, setFilteredData] = useState<any>([]);
    const [first, setFirst] = useState(page.first);
    const [rows, setRows] = useState(page.row);

    useEffect(() => {
        if (sharedDefects && defectTable.current !== null) {
            setFilteredData(sharedDefects);
        }
    }, [sharedDefects])

    if (error) {
        errorToast(t("defectsTable.apiFail"));
        return <div />;
    }

    registerCustomFilters();

    /**
     * Opens up read more modal
     * @param content 
     */
    const handleReadMoreClick = (content: DisplayModalContent) => {      
        if (displayModal.current) {
            displayModal.current.showContent(content);
        }
    }

    const onPage = (event: any) => {
        setFirst(event.first);
        setRows(event.rows);
        dispatch(setPage({ row: event.rows, first: event.first}))
    }

    const header = () => <SharedDefectsHeader
        columns={columns}
        disabled={loading}
        selectedColumns={columns}
        filters={dataTableFilterData.sharedDefects}
        dataTableRef={defectTable}
        filteredData={filteredData}
    />

    const onFilter = (event: DataTableStateEvent) => {
        dispatch(setSharedDefectsTableFilterData(event.filters));
    }

    const renderColumnComponent = (columnConfig: SharedTableColumnConfig[]) => {
        const result = columnConfig.map((col) => {
            return (
                <Column
                    key={col.field}
                    field={col.field}
                    dataType={col.dataType}
                    header={col.header}
                    filterMatchMode={col.filterMatchMode}
                    frozen={col.frozen}
                    className={(col.showFilterMenu ?? false) ? "has-filter-menu" : ""}
                    headerClassName={`${col.field.split(".").slice(-1).pop()}-header`}
                    sortable={col.sortable ?? false}
                    showFilterMenu={col.showFilterMenu ?? false}
                    showFilterMatchModes={col.showFilterMatchModes ?? false}
                    filter={col.filter ?? false}
                    filterField={col.filterField}
                    filterFunction={col.filterFunction}
                    body={sharedDefectsTemplates(handleReadMoreClick).find((e: any) => e.column === col.field)?.bodyTemplate ?? null}
                    bodyStyle={{ textAlign: col.textAlign ?? "left", justifyContent: col.justifyContent ?? "start" }}
                    style={columnWidth(col.field)}
                    filterElement={filterSharedDefectsTemplates(sharedDefects).find(e => e.column === col.field)?.filterTemplate ?? null}
                    filterPlaceholder={col.filterPlaceholder ?? ""}
                />
            )
        });
        return result;
    }

    return (
        <>
            <DisplayModal ref={displayModal} />
            <div className="table-container shared-defects-table-container" data-cy="shared-defects-table-container">
                <DataTable
                    ref={defectTable}
                    paginator
                    first={first}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    rows={rows}
                    onPage={onPage}
                    globalFilterFields={keywordSharedDefectsSearchFilterFields}
                    filters={dataTableFilterData.sharedDefects}
                    scrollable
                    scrollHeight="70vh"
                    data-cy="defects-table"
                    filterDisplay="row"
                    header={header}
                    value={sharedDefects}
                    onValueChange={setFilteredData}
                    responsiveLayout="scroll"
                    onFilter={onFilter}
                    loading={loading}
                    emptyMessage={t("defectsTable.noDataMessage")}
                    removableSort
                    stripedRows
                >
                    {renderColumnComponent(columns)}
                </DataTable>
            </div>
        </>
      );
};