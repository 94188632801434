import { DefectGradingArea } from "../types/DefectGradingArea";

export const DefectGradingAreaMapping: DefectGradingArea = {
  "1": "Bridge and Navigation Equipment",
  "2": "Accommodation",
  "3": "Lifesaving Appliances",
  "4": "Mooring Decks",
  "5": "Engine Room and Machinery",
  "6": "Vessel Capabilities and Cargo Systems",
  "7": "Ballast Tanks and Systems",
  "8": "Weather Decks and Fittings",
  "9": "Hull",
  "10": "Pollution Control",
  "11": "Onboard Management",
  "12": "Safe Working Environment",
  "13": "Forthcoming Regulatory Compliance",
  "14": "Crew Welfare",
  "15": "Crew Performance",
  "16": "Safety Management",
  "17": "Planned Maintenance System (PMS)",
  "18": "Classification and Certification",
  "19": "Commercial trading capability",
  "20": "PSC Performance",
  "21": "Fire Fighting Equipment and Systems",
  "22": "External Deck Areas & Fittings",
  "23": "Passenger Systems & Facilities",
  "24": "Design and Construction",
  "25": "General",
  "26": "External Passenger Areas",
  "27": "Crew Areas",
  "28": "Internal Passenger Areas",
};

export const GENERIC_DEFECT = "25";