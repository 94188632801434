import { DefectSummaryTableFields } from "../components/DefectsSummary/DefectSummaryTableFields";
import { DefectPriority } from "../types/DefectPriority";
import { DefectStatus } from "../types/DefectStatus";
import { DefectSummaryDataItem } from "../types/DefectSummaryDataItem";
import { DefectDataItem } from "../types/DefectsDataItem";

function calculateDaysSince(inspectionDate: Date | undefined | null) {
    if (!inspectionDate) {
        return null;
    }

    const timeDiff = new Date().getTime() - new Date(inspectionDate).getTime();

    return Math.round(timeDiff / (1000 * 60 * 60 * 24));
}

export const tallyDefectSummaryData = (defectData: DefectDataItem[]) =>
    defectData.reduce((acc: DefectSummaryDataItem[], curr: DefectDataItem) => {
        const { imo } = curr.vessel;
        const defectIndex = acc.findIndex((obj) => obj.imo === imo);

        // if we have existing ship already, add on top of the existing object
        if (defectIndex !== -1) {                    
            const existing = acc[defectIndex];

            const updatedEntry = {
                ...existing,
                ...getExistingTallyCounts(curr, existing),
                totalDefects: existing.totalDefects + 1,
            }

            acc[defectIndex] = updatedEntry;
        }
        else {
            acc.push({
                ...getNewTallyCounts(curr),
                imo: imo,
                vesselName: curr.vesselName,
                totalDefects: 1,
                inspected: curr.inspection.lastInspected ? new Date(curr.inspection.lastInspected) : null,
                inspectionGuid: curr.inspection.guid,
                daysSinceInspected: calculateDaysSince(curr.inspection.lastInspected),
                technicalManager: curr.technicalManager,
                vesselType: curr.type,
                subType: curr.subType,
            })
        }

        return acc;
    }, []);

const getNewTallyCounts = (item: DefectDataItem) => ({
    highPriorityCount: item.priority === DefectPriority.high ? 1 : 0,
    mediumPriorityCount: item.priority === DefectPriority.medium ? 1 : 0,
    lowPriorityCount: item.priority === DefectPriority.low ? 1 : 0,
    closedStatusCount: item.status === DefectStatus.closed ? 1 : 0,
    rectifiedStatusCount: item.status === DefectStatus.rectified ? 1 : 0,
    inProgressStatusCount: item.status === DefectStatus.inProgress ? 1 : 0,
    openStatusCount: item.status === DefectStatus.open ? 1 : 0,
})

const getExistingTallyCounts = (item: DefectDataItem, existing: DefectSummaryDataItem) => ({
    highPriorityCount: item.priority === DefectPriority.high ? existing.highPriorityCount + 1 : existing.highPriorityCount,
    mediumPriorityCount: item.priority === DefectPriority.medium ? existing.mediumPriorityCount + 1 : existing.mediumPriorityCount,
    lowPriorityCount: item.priority === DefectPriority.low ? existing.lowPriorityCount + 1 : existing.lowPriorityCount,
    closedStatusCount: item.status === DefectStatus.closed ? existing.closedStatusCount + 1 : existing.closedStatusCount,
    rectifiedStatusCount: item.status === DefectStatus.rectified ? existing.rectifiedStatusCount + 1 : existing.rectifiedStatusCount,
    inProgressStatusCount: item.status === DefectStatus.inProgress ? existing.inProgressStatusCount + 1 : existing.inProgressStatusCount,
    openStatusCount: item.status === DefectStatus.open ? existing.openStatusCount + 1 : existing.openStatusCount,
})

export const filterBySelected = (items: JSX.Element[], selected: (string | null)[]) => 
        items.filter((item) => selected.includes(item.key))

export const sortByReordered = (items: JSX.Element[], reordered: (string | null)[]) =>
    reordered.reduce((acc: JSX.Element[], reorder) => {
        const match = items.find((item) => item.key === reorder);

        if (match) {
            acc.push(match);
        }

        return acc;
    }, []);

export const mapSelectedColumns = (columns: { header: string, field: string; }[]) => {
    const selected: string[] = columns.map((column) => column.field);

    const priorityIndex = selected.indexOf((DefectSummaryTableFields.PRIORITY));

    if (priorityIndex !== -1) {
        selected.splice(priorityIndex, 0, ...[
            DefectSummaryTableFields.HIGH_PRIORITY_COUNT,
            DefectSummaryTableFields.MEDIUM_PRIORITY_COUNT,
            DefectSummaryTableFields.LOW_PRIORITY_COUNT,
        ])
    }

    const statusIndex = selected.indexOf((DefectSummaryTableFields.STATUS));

    if (statusIndex !== -1) {
        selected.splice(statusIndex, 0, ...[
            DefectSummaryTableFields.CLOSED_STATUS,
            DefectSummaryTableFields.RECTIFIED_STATUS,
            DefectSummaryTableFields.IN_PROGRESS_STATUS,
            DefectSummaryTableFields.OPEN_STATUS,
        ])
    }

    return selected;
}

export const mapElementsToSelectItemOptions = (elements: JSX.Element[]) => 
    elements.map((column) => ({ header: column.props['header'], field: `${column.key}`}));
    