import { StatsCard, errorToast } from "@idwal/idwal-react-components";
import {ReactElement, useEffect, useRef, useState} from "react";
import "./StatsTiles.css";
import { LoadingStatTiles } from "../Loading/LoadingStatTiles";
import { useTranslation } from "react-i18next";
import { StatsCardProps } from "@idwal/idwal-react-components/dist/types/StatsCard";
import {calculateFleetStatistics} from "../../utils/calculateStatistics"
import {FleetDataItem} from "../../types/FleetDataItem";
import {VesselSubgradeData} from "../../types/VesselSubgradeResponse";
import {DefectDataItem} from "../../types/DefectsDataItem";
import {getDefectsForGroup, getFleetItemsForGroup, getSelectedGroupContractDetails, getSubGradesForGroup} from "../../utils/GroupDropDownHelper";
import { STAT_TILE_OVERDUE , STAT_TILE_DUE_INSPECTION } from "../../utils/Constants";
import { Panel } from "primereact/panel";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectDefect } from "../../store/selectors/defectSelectors";
import { selectSelectedGroupName } from "../../store/selectors/userSelectors";
import { selectFleetGraph } from "../../store/selectors/fleetGraphSelectors";
import { selectFleet, selectFleetData, selectShowStatsTilesPanel } from "../../store/selectors/fleetSelectors";
import { setRequestInspectionDialog } from "../../store/slices/fleetSlice";
import { StatTileConfig } from "../../types/StatTileConfig";
import { useFlags } from "launchdarkly-react-client-sdk";

const MAX_VISIBLE_TILES = 4;

export const StatsTiles = (): ReactElement => {
    const dispatch = useAppDispatch();

    const [statTiles, setStatTiles] = useState<ReactElement[]>([]);
    const selectedGroupName = useAppSelector(selectSelectedGroupName);
    const showStatsTilesPanel = useAppSelector(selectShowStatsTilesPanel);
    const panel = useRef<Panel>(null);
    const { releaseStatTileIdInspectionsCompleted } = useFlags();

    const { inspectionAndGradeData, inspectionAndGradeDataLoading, inspectionAndGradeDataError, data: fleetDataResponse } = useAppSelector(selectFleet);
    const { subgradeData, subgradeError, subgradeLoading, subgradeBackingData, subgradeBackingLoading, subgradeBackingError } = useAppSelector(selectFleetGraph);
    const { data: defects, error: isDefectsError, error: defectsError, loading: isDefectsLoading } = useAppSelector(selectDefect);
    const isLoading = inspectionAndGradeDataLoading || subgradeLoading || isDefectsLoading || subgradeBackingLoading;
    const allDataLoaded = inspectionAndGradeData && subgradeData && defects.length && subgradeBackingData;

    useEffect(() => {
        if (allDataLoaded) {
            const fleetDataItems : FleetDataItem[] = getFleetItemsForGroup(selectedGroupName, inspectionAndGradeData?.groupCount,inspectionAndGradeData?.fleetDataItems);
            const groupSubGrades: {[key: string]: VesselSubgradeData} = getSubGradesForGroup(selectedGroupName,subgradeData,inspectionAndGradeData);
            const defectDataItems : DefectDataItem[] = getDefectsForGroup(selectedGroupName,defects,inspectionAndGradeData);
            const groupDetails = getSelectedGroupContractDetails(selectedGroupName, fleetDataResponse.userDetails?.groupDetails);

            const fleetStatistics = calculateFleetStatistics(
                fleetDataItems,
                groupSubGrades,
                subgradeBackingData,
                defectDataItems,
                groupDetails,
                t("statsCards.contractYear"),
                releaseStatTileIdInspectionsCompleted
            );

            setStatTiles(mapToStatsTiles(fleetStatistics).slice(0, MAX_VISIBLE_TILES));
        }
    }, [allDataLoaded, isLoading, selectedGroupName]);

    useEffect(() => {
        if(!showStatsTilesPanel){
            panel.current?.collapse(undefined);
            return;
        }

        panel.current?.expand(undefined);
    }, [showStatsTilesPanel]);

    const {t} = useTranslation("locale");

    const isError = inspectionAndGradeDataError || subgradeError || isDefectsError || subgradeBackingError;

    if (isError) {
        console.error(inspectionAndGradeDataError);
        console.error(subgradeError);
        console.error(defectsError);
        console.error(subgradeBackingError);
        errorToast("Failed to load fleet statistics");
        return <div />;
    }

    const mapToStatsTiles = (statTileConfigs: StatTileConfig[]) => statTileConfigs.map((tile: any) => {
        let onClick;
        if(tile.tileKey == STAT_TILE_OVERDUE || tile.tileKey == STAT_TILE_DUE_INSPECTION){
            onClick = (props: StatsCardProps)=> {
                if (props.data) {
                    dispatch(setRequestInspectionDialog({
                        show: true,
                        vessels: props.data,
                    }));
                }
            }
        }

        return buildStatsTile({
            tileKey: tile.tileKey,
            statistic: tile.statistic,
            statisticTitle: t(`statsCards.${tile.tileKey}`), statisticDetails: t(tile.details),
            statisticSubText: tile.statisticSubText ? t(`statsCards.${tile.statisticSubText}`) : undefined,
            statisticFontSize: tile.statisticFontSize,
            icon: tile.icon,
            color: tile.colour,
            onClick: onClick,
            data: tile.additionalData
        });
    });

    const buildStatsTile = (props: StatsCardProps): ReactElement => {
        return (
            <StatsCard
                key={props.tileKey}
                statistic={props.statistic}
                statisticFontSize={props.statisticFontSize}
                statisticSubText={props.statisticSubText}
                statisticTitle={props.statisticTitle}
                statisticDetails={props.statisticDetails}
                icon={props.icon}
                color={props.color}
                data={props.data}
            onClick={props.onClick}/>
        );  
    };

    const colSize = 12 / Math.min(MAX_VISIBLE_TILES, statTiles.length);

    return (
        <Panel unstyled className="stats-tile-panel" ref={panel} toggleable headerTemplate={<></>}>
            <div hidden={!isLoading} className="stats-tile-container">
                <LoadingStatTiles />
            </div>
            <div hidden={isLoading || !showStatsTilesPanel} className="stats-tile-container" data-cy="statistics-tiles">
                <div className="grid flex-row">
                    {
                        statTiles.map((tile, index) => {
                        return (
                            <div key={`${tile.key}`} className={`stats-tile col-12 md:col-6 lg:col-${colSize}`} data-cy={`stats-tile-${index}`}>
                                {tile}
                            </div>

                        );
                    })}
                </div>
            </div>
        </Panel>
    );
}