import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FleetNotification } from '../../types/NotificationsModalProps';

export interface NotificationsState {
    read: FleetNotification[];
    unread: FleetNotification[];
    loading: boolean;
    error: boolean;
}

export const initialState: NotificationsState = {
    read: [],
    unread: [],
    loading: true,
    error: false,
};

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setRead: (state, action: PayloadAction<FleetNotification[]>) => {
            state.read = action.payload;
        },
        setUnread: (state, action: PayloadAction<FleetNotification[]>) => {
            state.unread = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload;
        },
    },
});

export const { 
    setRead,
    setUnread,
    setLoading,
    setError,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
