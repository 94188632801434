import React from "react";
import {MultiSelect, MultiSelectChangeEvent} from "primereact/multiselect";
import { getTranslationObject } from "../../services/Translation";
import {SelectItemOptionsType} from "primereact/selectitem";
import {RiskLevel} from "../../types/RiskLevel";
import { getStatusArray } from "../FleetTable/Constants";
import { FILTER_MAX_MULTISELECT_LABELS_TO_SHOW } from "../../utils/Constants";

const t = getTranslationObject();

export const gradeFilterTemplate = (options: any) => {
  const dropdownOptions = [
    t("sharedTable.gradeFilter90+"),
    t("sharedTable.gradeFilter70-89"),
    t("sharedTable.gradeFilter50-69"),
    t("sharedTable.gradeFilter30-49"),
    t("sharedTable.gradeFilter0-29"),
  ];

  return (
    <MultiSelect
      data-cy="grade-filter-input"
      value={options.value}
      options={dropdownOptions}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder={t("sharedTable.showAll")}
      className="p-column-filter"
      maxSelectedLabels={FILTER_MAX_MULTISELECT_LABELS_TO_SHOW}
      showClear
    />
  );
};

/**
 * This filter template provides a list of date ranges in a filter menu
 * It searches by string given
 * @param options
 * @returns
 */
export const inspectionDateFilterTemplate = (options: any) => {
  const dropdownOptions = [
    t("defectsTable.inspectionDateLast7Days"),
    t("defectsTable.inspectionDateLastMonth"),
    t("defectsTable.inspectionDateLast6Months"),
    t("defectsTable.inspectionDateLastYear"),
    t("defectsTable.inspectionDate12Months+"),
    t("defectsTable.inspectionDate24Months+"),
  ];

  return (
      <MultiSelect
          data-cy="inspection-date-filter-input"
          value={options.value}
          options={dropdownOptions}
          onChange={(e: MultiSelectChangeEvent) => options.filterApplyCallback(e.value)}
          placeholder={t("defectsTable.showAll")}
          className="p-column-filter"
          maxSelectedLabels={FILTER_MAX_MULTISELECT_LABELS_TO_SHOW}
          showClear
      />
  );
};

export const customInspectionDateFilter = (value: any, filter: any) => {
  if (filter === null || !filter.length) return true;

  if (value === null || value === undefined) return false;

  let match = false;

  filter.forEach((x: any) => {
    match = customInspectionDateFilterSwitch(x, value, match);
  });

  return match;
}

const customInspectionDateFilterSwitch = (filterQuery: string, value: any, match: boolean): boolean => {
  if (match) {
    return match;
  }

  const dateParts = value.split("/");
  const tmpVal = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);

  let dateFrom = new Date();
  let dateTo = new Date();

  switch (filterQuery) {
    case t("defectsTable.inspectionDateLast7Days"):
      dateFrom.setDate(dateFrom.getDate() - 7);
      break;
    case t("defectsTable.inspectionDateLastMonth"):
      dateFrom.setDate(1);
      dateFrom.setMonth(dateFrom.getMonth() - 1);
      break;
    case t("defectsTable.inspectionDateLast6Months"):
      dateFrom.setMonth(dateFrom.getMonth() - 6);
      break;
    case t("defectsTable.inspectionDateLastYear"):
      dateFrom.setMonth(dateFrom.getMonth() - 12);
      break;
    case t("defectsTable.inspectionDate12Months+"):
      dateFrom.setMonth(dateFrom.getMonth() - 23);
      break;
    case t("defectsTable.inspectionDate24Months+"):
      dateFrom.setMonth(dateFrom.getMonth() - 24);
      break;
  }

  if (tmpVal.getTime() >= dateFrom.getTime() && tmpVal.getTime() <= dateTo.getTime()) {
    return true;
  }
  
  return match;
}

export const shipTypeFilterTemplate = (options: any, shipTypes: string[]) => {
  return <MultiSelect data-cy="shipType-filter-input" value={options.value} options={shipTypes} onChange={(e) => options.filterApplyCallback(e.value)} placeholder= {t("fleetTable.showAll")} className="p-column-filter" maxSelectedLabels={FILTER_MAX_MULTISELECT_LABELS_TO_SHOW} showClear />;
}

export const riskLevelFilterTemplate = (options: any) => {
  const prioritiesFilterOptions: SelectItemOptionsType = [
    { label: t("riskLevel.none"), value: RiskLevel.none },
    { label: t("riskLevel.veryLow"), value: RiskLevel.very_low },
    { label: t("riskLevel.low"), value: RiskLevel.low },
    { label: t("riskLevel.medium"), value: RiskLevel.medium },
    { label: t("riskLevel.high"), value: RiskLevel.high },
    { label: t("riskLevel.veryHigh"), value: RiskLevel.very_high }
  ];

  return (
      <MultiSelect
          data-cy="riskLevel-filter-input"
          value={options.value}
          options={prioritiesFilterOptions}
          onChange={(e: MultiSelectChangeEvent) => options.filterApplyCallback(e.value)}
          placeholder={t("fleetTable.showAll")}
          className="p-column-filter"
          maxSelectedLabels={FILTER_MAX_MULTISELECT_LABELS_TO_SHOW}
          showClear
      />
  );
}


export const technicalManagerFilterTemplate = (options: any, technicalManagers: string[]) => {
  return <MultiSelect data-cy="technicalManager-filter-input" value={options.value} options={technicalManagers} onChange={(e) => options.filterApplyCallback(e.value)} placeholder={t("fleetTable.showAll")} className="p-column-filter" maxSelectedLabels={FILTER_MAX_MULTISELECT_LABELS_TO_SHOW} filter showClear />;
}

export const flagFilterTemplate = (options: any, flags: string[]) => {
  return <MultiSelect data-cy="flag-filter-input" value={options.value} options={flags} onChange={(e) => options.filterApplyCallback(e.value)} placeholder={t("fleetTable.showAll")} className="p-column-filter" maxSelectedLabels={FILTER_MAX_MULTISELECT_LABELS_TO_SHOW} filter showClear />;
}

export const buildCountryFilterTemplate = (options: any, countriesOfBuild: string[]) => {
  return <MultiSelect data-cy="build-country-filter-input" value={options.value} options={countriesOfBuild} onChange={(e) => options.filterApplyCallback(e.value)} placeholder={t("fleetTable.showAll")} className="p-column-filter" maxSelectedLabels={FILTER_MAX_MULTISELECT_LABELS_TO_SHOW} filter showClear />;
}

export const multiSelectFilterTemplate = (options: any, filterOptions: SelectItemOptionsType[]) => {
  return <MultiSelect 
            data-cy="multi-select-filter-input"
            value={options.value}
            options={filterOptions}
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder={t("fleetTable.showAll")}
            className="p-column-filter"
            maxSelectedLabels={FILTER_MAX_MULTISELECT_LABELS_TO_SHOW}
            showClear
          />;

}

export const customVesselInspectionStatusFilter = (value: any, filter: any) => {
  if (filter === null || !filter.length) {
    // If the filter is empty or null, no filtering is applied
    return true;
  }

  // Check if any filter condition is met
  return filter.some((filterValue: any) => {
    if (filterValue === t("inspectionStatuses.neverInspected")) {
      // Match if the value is null or undefined
      return value === null || value === undefined;
    } else {
      // Match if the statusArray includes the value
      const statusArray = getStatusArray(filterValue);
      return statusArray.includes(value);
    }
  });
};
