import { DefectDataItem } from "../../types/DefectsDataItem";
import { Link, useNavigate } from "react-router-dom";
import { getRectificationUrl } from "../../utils/DefectRectificationHelpers";
import { DEFECTS, RECTIFICATIONS } from "../../types/RouteNames";
import { sendGAEvent } from "../../AnalyticsService";
import { ANALYTICS_BUTTON_CLICK, DEFECTS_ID_CLICK } from "../../constants/analytics";
import { useAppSelector } from "../../hooks/storeHooks";
import { selectSharedDefects } from "../../store/selectors/sharedDefectsSelectors";
import { selectFilteredDefects } from "../../store/selectors/defectSelectors";

export const DefectIdLink = (data: DefectDataItem, isSharedDefect: boolean) => {
    const { data: sharedDefects } = useAppSelector(selectSharedDefects);
    const filteredDefects = useAppSelector(selectFilteredDefects);

    const navigate = useNavigate();

    const onClick = () => {
        sendGAEvent(ANALYTICS_BUTTON_CLICK, DEFECTS_ID_CLICK);
    }

    const rectificationUrl = () => {
        if (isSharedDefect) {
            return getRectificationUrl(sharedDefects, data.defectId, navigate, RECTIFICATIONS);
        }
        
        return getRectificationUrl(filteredDefects, data.defectId, navigate, DEFECTS);
    }

    return (
        <Link to={rectificationUrl()} className="font-semibold underline cursor-pointer read-more-btn" onClick={onClick}>
            {data.formattedDefectId}
        </Link>
    );
};
