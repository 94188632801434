import { BackgroundFilterScopeSelection, FilterScope, FilterType, SingleSelectItem } from "./FilterDialog"

export interface MultiSelectOptions {
    name: string,
    value: string
}

export enum SortType {
    None,
    Alphabetical
}

export interface BuildSelectionFilterSectionProps {
    selectedSection: string
    selectedFilterScope: BackgroundFilterScopeSelection
    sectionName: string,
    allLabelText: string,
    multiselectType: FilterType.Multiselect | FilterType.MultiSelectWithSearch,
    filterScope: FilterScope,
    defaultSearchTerm: string,
    gaForegroundTag: string,
    gaBackgroundTag: string,
    sortType : SortType,

    // Options
    foregroundOptions: Array<MultiSelectOptions>,
    backgroundMarketOptions: Array<MultiSelectOptions>,
    backgroundFleetOptions: Array<MultiSelectOptions>,

    //Counts
    foregroundCount: number,
    backgroundFleetCount: number,
    backgroundMarketCount: number,

    // State
    foregroundSelectionState: Array<string>,
    backgroundMarketSelectionState: Array<string>,
    backgroundFleetSelectionState: Array<string>,

    backgroundMarketDataMessage?: string,
    backgroundFleetDataMessage?: string,

    onSelectSection: (sectionName: string) => void,
    onUpdateForegroundSelection: (newState: Array<string>) => void
    onUpdateBackgroundFleetSelection: (newState: Array<string>) => void,
    onUpdateBackgroundMarketSelection: (newState: Array<string>) => void,
    backgroundMarketMessage?: string,
    backgroundFleetMessage?: string
} 

export interface BuildSingleSelectFilterSectionProps {
    selectedSection: string;
    selectedFilterScope: BackgroundFilterScopeSelection;
    sectionName: string;
    filterScope: FilterScope;
    gaForegroundTag: string;
    gaBackgroundFleetTag: string;
    gaBackgroundMarketTag: string;
    foregroundOptions: Array<SingleSelectItem>;
    backgroundMarketOptions: Array<SingleSelectItem>;
    backgroundFleetOptions: Array<SingleSelectItem>;
    foregroundSelectionState: SingleSelectItem[];
    backgroundMarketSelectionState: SingleSelectItem[];
    backgroundFleetSelectionState: SingleSelectItem[];
    foregroundCount: number,
    backgroundMarketCount: number,
    backgroundFleetCount: number,
    onSelectSection: (sectionName: string) => void;
    onUpdateForegroundSelection: (newState: SingleSelectItem[]) => void;
    onUpdateBackgroundFleetSelection: () => void;
    onUpdateBackgroundMarketSelection: (update: SingleSelectItem[]) => void;
    backgroundFleetDataMessage?: string;
    backgroundMarketDataMessage?: string;
}