import { TFunction } from "react-i18next";
import { sendGAEvent } from "../../AnalyticsService";
import { ANALYTICS_BUTTON_CLICK, NOTIFICATIONS_DOWNLOAD_CLICK } from "../../constants/analytics";
import { FleetNotification } from "../../types/NotificationsModalProps";
import { downloadFile } from "../AssetHelper";
import { NOTIFICATION_KEY_MAP, NOTIFICATION_TITLE_MAP } from "./Constants";
    
import i18next from "i18next";
import { NotificationTypes } from "../../types/NotificationTypes";
import { formatDefectId } from "@idwal/idwal-utilities/lib/helpers";

export function getNotificationSubTexts(notification: FleetNotification): { subText1?: string, subText2?: string } {
    let subText1: string | undefined;
    let subText2: string | undefined;

    switch (notification.key) {
        case NOTIFICATION_KEY_MAP.defectRectifiedFleet:
            subText1 = notification.data?.vesselName;
            subText2 = notification.data?.vesselImo;
            break;
        case NOTIFICATION_KEY_MAP.defectExcelGeneratedPlan:
            subText1 = i18next.t("notifications.subTexts.defectExcelGeneratedPlan.subText1");
            subText2 = i18next.t("notifications.subTexts.defectExcelGeneratedPlan.subText2");
            break;
    }

    return { subText1, subText2 };
}

/**
 * An attempt at a generic interface for the notification CTA
 * @param notification 
 * @returns 
 */
export function getNotificationAction(notification: FleetNotification): { action: (context: any) => void, markAsRead: boolean } {
    switch (notification.key) {
        case NOTIFICATION_KEY_MAP.defectRectifiedFleet:
            return {
                action: (context: any) => {
                    const defectId = notification.data?.defectId ?? notification.data?.defect?.defectId;
                    if (defectId) {
                        context.navigateToRectificationView(context.defects, defectId, context.navigate, context.DEFECTS);
                    }
                },
                markAsRead: true
            };
        case NOTIFICATION_KEY_MAP.defectExcelGeneratedPlan:
            return {
                action: () => {
                    downloadFile(notification.data.fileUrl, getDapFileName(notification.data.fileUrl));
                    sendGAEvent(ANALYTICS_BUTTON_CLICK, NOTIFICATIONS_DOWNLOAD_CLICK);
                },
                markAsRead: true
            };
        default:
            return {
                action: () => {},
                markAsRead: true
            };
    }
}

export function getNotificationTitle(notification: FleetNotification, t: TFunction) {
    let tOptions = undefined;

    const defectId = notification.data?.defectId ?? notification.data?.defect?.defectId;

    if (defectId) {
        tOptions = { 
            defectId: formatDefectId(defectId)
        };
    }

    return t(`notifications.titles.${NOTIFICATION_TITLE_MAP[notification.key as keyof NotificationTypes]}`, tOptions)
}

export function getNotificationButtonText(notification: FleetNotification, t: TFunction) {
    const EXCEL_DOWNLOAD = "defectExcelGeneratedPlan";

    if(notification.key === EXCEL_DOWNLOAD){
        return t('notifications.downloadNotificationButton');
    } else {
        return t('notifications.viewNotificationButton');
    }
}

const getDapFileName = (fileUrl: string) => {
    const fileNameBase = fileUrl.split(".com/")[1];
    const fileName = fileNameBase.split("-")[0];
    
    const now = new Date();

    const date = now.toISOString().split('T')[0];
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const formattedTime = `${hours}-${minutes}`;

    const filename = `${fileName}-${date}/${formattedTime}.xlsx`;

    return filename;
}
