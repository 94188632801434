import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { getTranslationObject } from "../../../services/Translation";
import { DefectDataItem } from "../../../types/DefectsDataItem";
import { FILTER_MAX_MULTISELECT_LABELS_TO_SHOW } from "../../../utils/Constants";

const t = getTranslationObject();

/**
 * This filter template provides a list of technical managers in a filter menu
 * It searches by string given
 * @param options 
 * @param defectData 
 * @returns 
 */
export const technicalManagerFilter = (options: any, defectData: DefectDataItem[] | undefined) => {
    let distinctTechnicalManagers = [] as string[];
    if (defectData) {
        const technicalManagers = defectData?.flatMap((e: DefectDataItem) => e.technicalManager);
        distinctTechnicalManagers = Array.from(new Set([...technicalManagers]));
    }

    return (
        <MultiSelect
            data-cy="technicalManager-filter-input"
            value={options.value}
            options={distinctTechnicalManagers}
            onChange={(e: MultiSelectChangeEvent) => options.filterApplyCallback(e.value)}
            placeholder={t("fleetTable.showAll")}
            className="p-column-filter"
            maxSelectedLabels={FILTER_MAX_MULTISELECT_LABELS_TO_SHOW}
            filter
            showClear
        />
    )
};
