import { DefectDataItem } from '../types/DefectsDataItem'
import { SharedTableColumnConfig } from '../types/SharedTableColumnConfig'
import { CUSTOM_COLUMN_PREFIX } from "./Constants";
import { prepareDefectDataForExport } from './ExportHelper'
import { getFriendlyInspectionStatusText } from "./FleetTableDataHelper";

export function generatePDFTableData (data: any[], selectedColumns: SharedTableColumnConfig[], isDefects?: boolean) {

// The fields can be 2-level nested references like vessel.name, inspection.dueDate etc.
// If the field contains a ".", we split on it to dig deeper into the object
// e.g. x[split1][split2] to access x["inspection"]["dueDate"]

    const body: string [][] = []
    data.forEach(row => {
        const row_data: string [] = []
        selectedColumns.forEach(col => {
            let cellContent = getCellContent(col, row)

            if (cellContent instanceof Date)
            {
                cellContent = cellContent.toLocaleDateString("en-GB");
            }

            if (isDefects) {
                cellContent = prepareDefectDataForExport(col.field, cellContent)    
            }

            row_data.push(cellContent);
        })
        body.push(row_data);

    })
    return body;
}


function getCellContent(col: SharedTableColumnConfig, row : any) : any {
    if (col.field === "gradeFilterField")
    {
        return row.gradeToDisplay;
    }

    if (col.field === "status")
    {
        const defectRow= row as DefectDataItem;
        return defectRow.status
    }

    if (col.field == "inspectionDate")
    {
        return row.inspectionDate;
    }

    if (col.field === "vesselLatestInspectionStatus") {
        return getFriendlyInspectionStatusText(row.vesselLatestInspectionStatus);
    }

    let cellField : any;

    if (col.field.includes(".") && !col.field.includes(CUSTOM_COLUMN_PREFIX))
    {
        const fields = col.field.split(".");

        cellField = row[fields[0]];
        if (!cellField) return "";

        cellField = cellField[fields[1]];
        if (!cellField) return "";

        if (col.field == "vessel.vesselName") {
            return `${row.vessel.vesselName} (${row.vessel.imo})`
        }

        if (col.field == "nameFilterField") {
            return `${row.vesselName} (${row.imo})`
        }

        if (col.field == "inspection.daysSince" && row.inspection.dateOfInspection)
        {
            return `${row.inspection.daysSince} (${row.inspection.dateOfInspection.toLocaleDateString("en-GB")})`
        }
    }

    if (!cellField) {
        cellField = row[col.field];
    }

    if (cellField)
    {
        if (isNaN(cellField) && !isNaN(Date.parse(cellField))) {
            return new Date(cellField);
        }

        return cellField;
    }
}