import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FleetDataItem } from '../../types/FleetDataItem';
import { ShipParticulars } from '../../types/Paraticulars';

export interface VesselViewState {
    selectedVessel: FleetDataItem | undefined;
    vesselViewOpen: boolean
    vesselParticulars: ShipParticulars | undefined;
    vesselParticularsLoading: boolean;
    vesselParticularsError: boolean;
    vesselImage: string;
    vesselImageLoading: boolean;
    vesselImageError: boolean;
}

export const initialState: VesselViewState = {
    selectedVessel: undefined,
    vesselViewOpen: false,
    vesselParticulars: undefined,
    vesselParticularsLoading: false,
    vesselParticularsError: false,
    vesselImage: "",
    vesselImageLoading: false,
    vesselImageError: false,
};

export const vesselViewSlice = createSlice({
    name: 'vesselView',
    initialState,
    reducers: {
        setSelectedVessel: (state, action: PayloadAction<FleetDataItem | undefined>) => {
            state.selectedVessel = action.payload;
        },
        setVesselViewOpen: (state, action: PayloadAction<boolean>) => {
            state.vesselViewOpen = action.payload;
        },
        setVesselParticulars: (state, action: PayloadAction<ShipParticulars | undefined>) => {
            state.vesselParticulars = action.payload;
        },
        setVesselParticularsLoading: (state, action: PayloadAction<boolean>) => {
            state.vesselParticularsLoading = action.payload;
        },
        setVesselParticularsError: (state, action: PayloadAction<boolean>) => {
            state.vesselParticularsError = action.payload;
        },
        setVesselImage: (state, action: PayloadAction<string>) => {
            state.vesselImage = action.payload;
        },
        setVesselImageLoading: (state, action: PayloadAction<boolean>) => {
            state.vesselImageLoading = action.payload;
        },
        setVesselImageError: (state, action: PayloadAction<boolean>) => {
            state.vesselImageError = action.payload;
        },
    },
});

export const { 
    setSelectedVessel,
    setVesselViewOpen,
    setVesselParticulars,
    setVesselParticularsLoading,
    setVesselParticularsError,
    setVesselImage,
    setVesselImageLoading,
    setVesselImageError,
} = vesselViewSlice.actions;

export default vesselViewSlice.reducer;
