import { getCurrentUserName } from "../queries/AuthToken";
import { dataRequest, postRequest } from "./DataRequest";

export const getSharedDefects = async (userId: string | undefined) => {
    return await dataRequest(`/Fleet/sharedDefects/${userId}`);
}

export const shareDefects = async (defects:any, name:string, email:string, message:string) => {
    const currentUser = await getCurrentUserName();

    const data = {
      defects: defects,
      name: name,
      email: email,
      sharerName: currentUser,
      message: message,
    };

    return await postRequest("/Defects/share", data);
}

export const getPreviousRecipients = async () => {
  return await dataRequest(`/Defects/technicalManagers`);
}