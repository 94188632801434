import { PlotData } from "plotly.js";
import { ClosedRatioData, CumulativeDefectData, DefectInsightFilterBy, AgeOfOpenDefectsData } from "../types/DefectInsightsData";
import { groupBy, sum, uniq } from "lodash";
import { DefectDataItem } from "../types/DefectsDataItem";

const closedRatioColours = ["#6A81FD", "#54D9B4", "#CB4FDF"];

const cumulativeDefectColours = {
    total: "#68658C",
    closed: "#53A440"
};

const ageOfOpenDefectPeriods = ['>1 year', '6-12 months', '3-6 months', '1-3 months', '<4 weeks'];

const highText = "<b style='color:#EF5A14'>HIGH:</b>";
const mediumText = "<b style='color:#E5940E'>MEDIUM:</b>";
const lowText = "<b style='color:#F8D311'>LOW:</b>";

const closedRatioPlotDefaults: Partial<PlotData> = {
    type: "scatter",
    mode: 'lines',
    hovertemplate: '%{x}: %{y}',
    line: { width: 3 },
}

const cumulativeDefectPlotDefaults: Partial<PlotData> = {
    type: "scatter",
    mode: "lines",
    line: { width: 3 }
}

const ageOpenPlotDefaults: Partial<PlotData> = {
    type: "bar",
    hovertemplate: '%{x}: %{y}',
}

export const mapClosedRatioToPlotData = (
    inputclosedRatioData: ClosedRatioData[] | undefined,
    filterBy: DefectInsightFilterBy,
    filterValues: string[] | undefined,
) => {
    if (inputclosedRatioData?.length) {
        // For prod now, remove later
        const closedRatioData = inputclosedRatioData.map((i) => ({...i, date: new Date(i.date).toISOString().split('T')[0]}));

        if (filterValues === undefined) {
            const closedRatiosByDate = groupBy(closedRatioData, 'date');

            const dates = Object.keys(closedRatiosByDate);
            const closedRatios = Object.values(closedRatiosByDate).map(calculateRatios);

            return [{
                ...closedRatioPlotDefaults,
                x: dates,
                y: closedRatios,
                marker: {
                    color: "#28235B",
                },
                name: "All",     
            }];
        }

        if (filterValues.length) {
            if (filterBy === DefectInsightFilterBy.TECHNICAL_MANAGERS) {
                const groupedClosedRatios = groupByTechnicalManager(closedRatioData, filterValues);

                for (const technicalManager in groupedClosedRatios) {
                    const closedRatiosByTechnicalManager = groupedClosedRatios[technicalManager];

                    if (closedRatiosByTechnicalManager?.length) {
                        const groupedByDate = groupBy(closedRatiosByTechnicalManager, "date");

                        const flattenedRatios = Object.values((groupedByDate)).map((byDate) => ({
                            ...byDate[0],
                            ratio: calculateRatios(byDate)
                        }));

                        groupedClosedRatios[technicalManager] = flattenedRatios;
                    }
                }


                return mapGroupedClosedRatios(groupedClosedRatios);
            }

            const closedRatiosByVessels = groupByVessel(closedRatioData, filterValues);

            return mapGroupedClosedRatios(closedRatiosByVessels);
        }
    }

    return [];
}

export const mapCumulativeDefectToPlotData = (
    inputcumulativeDefectData: CumulativeDefectData[] | undefined,
    filterBy: DefectInsightFilterBy,
    filterValues: string[] | undefined,
) => {
    if (inputcumulativeDefectData?.length) {
        // For prod now, remove later
        let cumulativeDefectData = inputcumulativeDefectData.map((i) => ({...i, date: new Date(i.date).toISOString().split('T')[0]}));

        if (filterValues !== undefined) {
            if (filterBy === DefectInsightFilterBy.TECHNICAL_MANAGERS) {
                cumulativeDefectData = cumulativeDefectData.filter((cumulativeDefect) =>
                    filterValues.includes(cumulativeDefect.manager)
                );
            }
            else {
                cumulativeDefectData = cumulativeDefectData.filter((cumulativeDefect) =>
                    filterValues.includes(cumulativeDefect.vessel_name)
                );
            }
        }
        
        if (cumulativeDefectData.length === 0) {
            return [];
        }
        
        const cumulativeDefectByDate = groupBy(cumulativeDefectData, "date");
        const dates = Object.keys(cumulativeDefectByDate);

        return mapCumulativeDefects(Object.values(cumulativeDefectByDate), dates);
    }
    return [];
}

export const mapAgeOfOpenDefectsToPlotData = (
    ageOfOpenDefectsData: AgeOfOpenDefectsData[] | undefined,
    filterBy: DefectInsightFilterBy,
    filterValues: string[] | undefined,
) => {
    if (ageOfOpenDefectsData?.length) {
        if (filterValues === undefined) {

            const periods = groupBy(ageOfOpenDefectsData, 'period');
            const periodTotals = calculatePeriodTotals(periods);

            return [{
                ...ageOpenPlotDefaults,
                x: Object.keys(periods).map((period) => ageOfOpenDefectPeriods[Number(period)]),
                y: periodTotals.map((period) => period.totalSum),
                marker: {
                    color: "#28235B",
                },
                name: "All",
                width: 0.4,
                hovertemplate: periodTotals.map((period) => `${highText} ${period.highSum}<br>${mediumText} ${period.mediumSum}<br>${lowText} ${period.lowSum}<br>TOTAL: ${period.totalSum}`),
            }];
        }

        if (filterValues.length) {
            if (filterBy === DefectInsightFilterBy.TECHNICAL_MANAGERS) {
                const groupedAgeOfOpenDefects = groupByTechnicalManager(ageOfOpenDefectsData, filterValues);

                for (const technicalManager in groupedAgeOfOpenDefects) {
                    const ageOfOpenDefectsByTechnicalManager = groupedAgeOfOpenDefects[technicalManager];

                    if (ageOfOpenDefectsByTechnicalManager?.length) {
                        const groupedByDate = groupBy(ageOfOpenDefectsByTechnicalManager, "date");

                        const flattenedTotals = Object.values((groupedByDate)).map((byDate) => ({
                            ...byDate[0],
                            total: sum(byDate.map((item) => item.total))
                        }));

                        groupedAgeOfOpenDefects[technicalManager] = flattenedTotals;
                    }
                }

                return mapGroupedAgeOfOpenDefects(groupedAgeOfOpenDefects);
            }
            const ageOfOpenDefectsByVessels = groupByVessel(ageOfOpenDefectsData, filterValues);

            return mapGroupedAgeOfOpenDefects(ageOfOpenDefectsByVessels);
        }
    }

    return [];
}

const groupByVessel = <T extends { vessel_name: string }>(data: T[], vessels: string[]) => {
    const filteredByVessel = data.filter((item) => vessels.includes(item.vessel_name));

    return groupBy(filteredByVessel, "vessel_name");
}

const groupByTechnicalManager = <T extends { manager: string }>(data: T[], technicalManagers: string[]) => {
    const filteredByManager = data.filter((item) => technicalManagers.includes(item.manager));

    return groupBy(filteredByManager, "manager");
}

const mapGroupedClosedRatios = (groupedClosedRatios: Record<string, ClosedRatioData[]>) => {
    const closedRatioEntries = Object.entries(groupedClosedRatios);

    const mappedClosedRatios = closedRatioEntries.map(([vessel, closedRatios], index) => ({
        ...closedRatioPlotDefaults,
        x: closedRatios.map((closedRatio) => closedRatio.date),
        y: closedRatios.map((closedRatio) => closedRatio.ratio),
        marker: {
            color: closedRatioColours[index],
        },
        name: addLinebreaksToName(vessel),
    }));

    return mappedClosedRatios;
};

const calculateRatios = (closedRatioData: ClosedRatioData[]) => {
    let closedSum = 0;
    let openSum = 0;

    closedRatioData.forEach((closedRatio) => {
        closedSum += closedRatio.closed;
        openSum += closedRatio.open;
    })

    return Math.floor((closedSum /  openSum) * 100);
};

const mapCumulativeDefects = (cumulativeDefectData: CumulativeDefectData[][], dates: string[]) => {
    const closed: number[] = [];
    const total: number[] = [];


    cumulativeDefectData.forEach((cumulativeDefects) => {
        let closedSum =  0;
        let totalSum =   0;

        cumulativeDefects.forEach((cumulativeDefect) => {
            closedSum += cumulativeDefect.closed;
            totalSum += cumulativeDefect.open;
        });
        
        closed.push(closedSum);
        total.push(totalSum);
    })

    return [
        {
            ...cumulativeDefectPlotDefaults,
            hovertemplate: "Closed: %{y}",
            x: dates,
            y: closed,
            marker: {
                color: cumulativeDefectColours.closed
            },
            name: "Closed"
        },
        {
            ...cumulativeDefectPlotDefaults,
            hovertemplate: "Total: %{y}",
            x: dates,
            y: total,
            marker: {
                color: cumulativeDefectColours.total
            },
            name: "Total"
        },
    ];    
}

const addLinebreaksToName = (name: string) => name.replace(/(^[\w\s,]{13,}?)\s?\b/g, "$1<br>")

const calculatePeriodTotals = (periods: any) => {
    const periodTotals = [];
    
    for (const period in periods) {
        const highSum = periods[period].reduce((acc: any, current: any) => acc + current.high, 0);
        const mediumSum = periods[period].reduce((acc: any, current: any) => acc + current.medium, 0);
        const lowSum = periods[period].reduce((acc: any, current: any) => acc + current.low, 0);
        const totalSum = periods[period].reduce((acc: any, current: any) => acc + current.total, 0);
   
        periodTotals.push({highSum: highSum, mediumSum: mediumSum, lowSum: lowSum, totalSum: totalSum});
    }
    
    return periodTotals;
}

const mapGroupedAgeOfOpenDefects = (groupedAgeOfOpenDefects: Record<string, AgeOfOpenDefectsData[]>) => {
    const ageOfOpenDefectsEntries = Object.entries(groupedAgeOfOpenDefects);

    const mappedAgeOfOpenDefectEntries = ageOfOpenDefectsEntries.map(([vessel, ageOfOpenDefects], index) => ({
        ...ageOpenPlotDefaults,
        x: ageOfOpenDefects.map((ageOfOpenDefects) => ageOfOpenDefectPeriods[ageOfOpenDefects.period]),
        y: ageOfOpenDefects.map((ageOfOpenDefect) => ageOfOpenDefect.total),
        marker: {
            color: closedRatioColours[index],
        },
        hovertemplate: ageOfOpenDefects.map((ageOfOpenDefects) => 
            `${highText} ${ageOfOpenDefects.high}<br>${mediumText} ${ageOfOpenDefects.medium}<br>${lowText} ${ageOfOpenDefects.low}<br>TOTAL: ${ageOfOpenDefects.total}`),
        name: addLinebreaksToName(vessel),
        width: 0.4
    }));

    return mappedAgeOfOpenDefectEntries;
};

export const getFilterValues = (defectData: DefectDataItem[], filterBy: DefectInsightFilterBy, selectedVessel: string) => {
    let filterValues = [];

    if (filterBy === DefectInsightFilterBy.VESSELS) {
        if (selectedVessel) {
            filterValues = [selectedVessel];
        } else {
            filterValues = defectData.map((defect) => defect.vesselName);
        }
    } else if (selectedVessel) {
            filterValues = defectData.reduce((managers: string[], defect) => {
                if (defect.vesselName === selectedVessel) {
                    managers.push(defect.technicalManager);
                }

                return managers;
            }, []);
    } else {
        filterValues = defectData.map((defect) => defect.technicalManager)
    }

    return uniq(filterValues);
}
