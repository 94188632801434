import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AgeOfOpenDefectsData, ClosedRatioData, CumulativeDefectData } from '../../types/DefectInsightsData';

export interface DefectInsightsState {
    closedRatioData: ClosedRatioData[];
    closedRatioLoading: boolean;
    closedRatioError: boolean;
    cumulativeDefectData: CumulativeDefectData[];
    cumulativeDefectLoading: boolean;
    cumulativeDefectError: boolean;
    ageOfOpenDefectsData: AgeOfOpenDefectsData[];
    ageOfOpenDefectsLoading: boolean;
    ageOfOpenDefectsError: boolean;
}

export const initialState: DefectInsightsState = {
    closedRatioData: [],
    closedRatioLoading: true,
    closedRatioError: false,
    cumulativeDefectData: [],
    cumulativeDefectLoading: true,
    cumulativeDefectError: false,
    ageOfOpenDefectsData: [],
    ageOfOpenDefectsLoading: true,
    ageOfOpenDefectsError: false
};

export const defectInsightsSlice = createSlice({
    name: 'defectInsightsSlice',
    initialState,
    reducers: {
        setClosedRatioData: (state, action: PayloadAction<ClosedRatioData[]>) => {
            state.closedRatioData = action.payload;
        },
        setClosedRatioLoading: (state, action: PayloadAction<boolean>) => {
            state.closedRatioLoading = action.payload;
        },
        setClosedRatioError: (state, action: PayloadAction<boolean>) => {
            state.closedRatioError = action.payload;
        },
        setCumulativeDefectData: (state, action: PayloadAction<CumulativeDefectData[]>) => {
            state.cumulativeDefectData = action.payload;
        },
        setCumulativeDefectLoading: (state, action: PayloadAction<boolean>) => {
            state.cumulativeDefectLoading = action.payload;
        },
        setCumulativeDefectError: (state, action: PayloadAction<boolean>) => {
            state.cumulativeDefectError = action.payload;
        },
        setAgeOfOpenDefectsData: (state, action: PayloadAction<AgeOfOpenDefectsData[]>) => {
            state.ageOfOpenDefectsData = action.payload;
        },
        setAgeOfOpenDefectsLoading: (state, action: PayloadAction<boolean>) => {
            state.ageOfOpenDefectsLoading = action.payload;
        },
        setAgeOfOpenDefectsError: (state, action: PayloadAction<boolean>) => {
            state.ageOfOpenDefectsError = action.payload;
        },
    },
});

export const { 
    setClosedRatioData,
    setClosedRatioLoading,
    setClosedRatioError,
    setCumulativeDefectData,
    setCumulativeDefectLoading,
    setCumulativeDefectError,
    setAgeOfOpenDefectsData,
    setAgeOfOpenDefectsLoading,
    setAgeOfOpenDefectsError
} = defectInsightsSlice.actions;

export default defectInsightsSlice.reducer;
