import { NavigateFunction } from "react-router-dom";

export const navigateToRectificationView = <T extends { defectId: number }>(defects: T[] | undefined, defectId: number, navigate: NavigateFunction, prefix: string) => {
    let defectToNavigate;

    if (defects?.length) {
        defectToNavigate = defects.find((defect: T) => defect.defectId.toString() === defectId.toString());
    }

    if (defectToNavigate) {
        navigate(`/${prefix}/${defectId}/rectification`);
    }
}

export const getRectificationUrl = <T extends { defectId: number }>(defects: T[] | undefined, defectId: number, navigate: NavigateFunction, prefix: string) => {
    let defectToNavigate;

    if (defects?.length) {
        defectToNavigate = defects.find((defect: T) => defect.defectId.toString() === defectId.toString());
    }

    if (defectToNavigate) {
        return `/${prefix}/${defectId}/rectification`;
    }

    return "";
}