import { getDateRangeDropDownConfig } from "../components/Filter/GlobalDateRange";
import { FilterInformation } from "../components/ShowAllFilters/ShowAllFiltersDialog";
import { INSPECTION_DATE,VESSEL_AGE, VESSEL_TYPE, filterNameToTranslatedLabel } from "../constants/filterNames";
import {
    AdvancedFilterStateType,
    BackgroundFilters,
    FilterType,
    ForegroundFilters,
    RangeFilterState
} from "../types/AdvancedFilters";
import { CombinedFilters } from "../types/CombinedFilters";

export const combineFiltersByLabel = (
    foregroundFilters: ForegroundFilters,
    backgroundFilters: BackgroundFilters,
): Array<CombinedFilters> => {
    const combinedFilters: Record<string, CombinedFilters> = {};

    const processObject = (
        filters: ForegroundFilters | BackgroundFilters
    ) => {
        Object.keys(filters).forEach((key) => {
            const label = filters[key as keyof (ForegroundFilters| BackgroundFilters)].label;

            if (!combinedFilters[label]) {
                combinedFilters[label] = {
                    label,
                    filters: [],
                };
            }

            combinedFilters[label].filters.push(filters[key as keyof (ForegroundFilters| BackgroundFilters)]);
        });
    };

    processObject(foregroundFilters);
    processObject(backgroundFilters);

    return Object.values(combinedFilters);
};

const rangeStateToString = (filter: RangeFilterState, t: any) =>
    filter.changed
        ? [`${filter.data[0]} ${t('showAllFilters.to')} ${filter.data[1]}`]
        : []

const filterTypeToString = (filterType: FilterType, t: any): Array<string> => {
    if (filterType.type === AdvancedFilterStateType.MultiSelectState) {
        return filterType.data
    }

    if (filterType.type === AdvancedFilterStateType.RangeState) {
        return rangeStateToString(filterType, t)
    }

    if (filterType.type === AdvancedFilterStateType.SingleSelect) {
        const selectedOption = filterType.data.find(e => e.checked);
        if (selectedOption?.default) {
            return [];
        }

        return selectedOption ? [selectedOption.name] : [];
    }

    return [""];
}

export const displayEquivalentText = (
    filterName: string
) =>
    (filterName === VESSEL_AGE || filterName === VESSEL_TYPE || filterName === INSPECTION_DATE)

export const combinedFilterStateToFilterInformation = (
    filters: Array<CombinedFilters>,
    t: any
): Array<FilterInformation> =>
    filters.map((combinedFilter) => {
        const leftHandPills =
            filterTypeToString(
                combinedFilter.filters[0],
                t
            )

        const rightHandPills =
            filterTypeToString(
                combinedFilter.filters[1],
                t
            )

        if(
            leftHandPills.length > 0 ||
            rightHandPills.length > 0
        ){
            return {
                title: filterNameToTranslatedLabel(t, combinedFilter.label),
                ...(leftHandPills.length > 0 && { leftHandPills: leftHandPills }),
                ...(rightHandPills.length > 0 && { rightHandPills: rightHandPills }),
                displayEquivalent:
                    displayEquivalentText(combinedFilter.label)
            };
            
        }
    }).filter(Boolean) as Array<FilterInformation>

export const getTotalFilterCount = (
    totalForegroundFilterCount: number,
    totalBackgroundFilterCount: number,
) => {
    const totalAdvanedFilterCount = totalForegroundFilterCount + totalBackgroundFilterCount;

    const totalFilterCount =
        totalAdvanedFilterCount

    return totalFilterCount;
}

export function getInspectionDateRangePill(code: number, t: any) {
    const inspectionDateDropdownConfig = getDateRangeDropDownConfig(t);
    const chosenDateRange = inspectionDateDropdownConfig.find(config => config.code === code);

    return chosenDateRange ? [chosenDateRange.name] : null;
}
