import { PlotData } from "plotly.js";
import { DefectStatus } from "../types/DefectStatus";
import { DefectDataItem } from "../types/DefectsDataItem";
import { addCustomFieldDefectsFiltersToData, getGradingAreaFilterValues } from "../utils/DefectsTableDataHelper";
import { groupBy, sum } from "lodash";

const defectGraphColours = ["#EF5A14", "#E5940E", "#F8D311"];
const defectGraphNames = ["High", "Medium", "Low"];

export const getDefectsGraphData = (defectDataItems: DefectDataItem[], selectedVessel?: string) => {
    let defects = [];

    if (selectedVessel) {
        defects = defectDataItems.filter((defect) => defect.status !== DefectStatus.closed && defect.vesselName === selectedVessel);
    } else {
        defects = defectDataItems.filter((defect) => defect.status !== DefectStatus.closed);
    }

    const defectFilterValues = getGradingAreaFilterValues(defects).reverse();

    const defectWithGradingAreas = addCustomFieldDefectsFiltersToData(defects);

    const defectsByGradingAreaAndPriority = getDefectsByGradingAreaAndPriority(defectWithGradingAreas, defectFilterValues);

    const defectsGraphData = mapDefectsToPlotData(defectsByGradingAreaAndPriority)

    const defectsMaxValue = defectsByGradingAreaAndPriority.reduce((maxValue, defectsByArea) => {
        const total = sum(Object.values(defectsByArea));

        if (total > maxValue) {
            return total;
        }

        return maxValue;
    }, 0);

    return {
        defectFilterValues,
        defectsGraphData,
        defectsMaxValue,
    }
}

const getDefectsByGradingArea = (defectDataItems: DefectDataItem[], defectFilterValues: string[]) => {
    const defectsByGradingArea: Record<string, number> = {};

    defectDataItems.forEach((defect) => {
        const gradingAreas = defect.gradingAreaField?.split(", ");

        gradingAreas?.forEach((value) =>{
            if (defectFilterValues.includes(value)) {
                if (defectsByGradingArea[value]) {
                    defectsByGradingArea[value]++;
                } else {
                    defectsByGradingArea[value] = 1;
                }
            }
        })
    });

    return defectsByGradingArea;
}

const getDefectsByGradingAreaAndPriority = (defectDataItems: DefectDataItem[], gradingAreaOrder: string[]) => {
    const defectsByPriority = groupBy(defectDataItems, "priority");
    const sortedPriorities = Object.keys(defectsByPriority).sort((a, b) => Number(a) - Number(b));

    const defectByGradingAreaAndPriority = sortedPriorities.map((defectPriority) =>
        getDefectsByGradingArea(defectsByPriority[defectPriority], gradingAreaOrder));

    return defectByGradingAreaAndPriority;
}

const mapDefectsToPlotData = (defectsByGradingAreaAndPriority: Record<string, number>[]) => {
    const defectsGraphData: Partial<PlotData>[] = defectsByGradingAreaAndPriority.map((defectArea, index) => ({
        type: "bar",
        x: Object.values(defectArea),
        y: Object.keys(defectArea),
        orientation: "h",
        marker: {
            color: defectGraphColours[index],
        },
        name: defectGraphNames[index]
    }));

    return defectsGraphData;
}