import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {Dialog} from "primereact/dialog";
import "./ShowAllFilters.css";
import {ShowAllFiltersRow} from "./ShowAllFiltersRow";

export interface FilterInformation {
    title: string;
    leftHandPills?: string[];
    rightHandPills?: string[];
    displayEquivalent: boolean;
}

export interface ShowAllFiltersDialogProps {
    visible: boolean;
    activeFilterCount: number;
    benchmarkingAgainstMarketData: boolean;
    filterInformation: FilterInformation[];
    
    onHide: () => void;
    clearAllClickHandler: () => void;
    editFiltersClickHandler: () => void;
}

export const ShowAllFiltersDialog = (props: ShowAllFiltersDialogProps) => {
    const {t} = useTranslation("locale");

    const footer = (
        <div
            className="flex justify-content-between"
        >
            <Button
                data-cy={`filter-modal-clear`}
                style={{width: "192px"}}
                className="p-button p-component p-button-text clearButtonGreyBorder"
                label={t("showAllFilters.clearAllFilters")}
                onClick={props.clearAllClickHandler}
            />
            <Button
                data-cy={`filter-modal-apply`}
                style={{width: "192px"}}
                className="p-button p-button-primary"
                label={t("showAllFilters.editFilters")}
                onClick={props.editFiltersClickHandler}
            />
        </div>
    );

    const header = (
        <>
            <div id={"title"}>{t("showAllFilters.showAllHeader") + ` (${props.activeFilterCount})`}</div>
            <p id={"subtitle"}>{props.benchmarkingAgainstMarketData ? t("showAllFilters.benchmarkingAgainstMarket") : t("showAllFilters.benchmarkingAgainstFleet")}</p>
        </>
    );

    return (
        <Dialog
            header={header}
            style={{ width: '80vw' }}
            visible={props.visible}
            onHide={props.onHide}
            blockScroll={true}
            footer={footer}
            draggable={false}
        >
            {
                props.filterInformation.map(
                    (filterInfo: FilterInformation, index: number) => 
                        <ShowAllFiltersRow 
                            className={index === props.filterInformation.length - 1 ? "bottomRow" : ""} 
                            key={filterInfo.title} title={filterInfo.title} 
                            leftHandPills={filterInfo.leftHandPills} 
                            rightHandPills={filterInfo.rightHandPills} 
                            displayEquivalent={filterInfo.displayEquivalent}
                        />
                )
            }
        </Dialog>
    )

}