import axios from "axios";
import { getCurrentSessionIdToken } from '../queries/AuthToken'
import { Auth } from "aws-amplify";
import { AssetFile } from "../types/AssetFile";

class AssetsService {
    assetsUrlsRequest = async (data: any) => {
        const url = `${process.env.REACT_APP_IDWAL_API_ENDPOINT}/Assets/urls`;
        const token = await getCurrentSessionIdToken();
        
        const headers = {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + token,
        }

        return axios.post(url, JSON.stringify(data), {
            headers
        })
        .then(response => response.data.data.filter((asset: any) => !!Object.values(asset)[0]))
        .catch(error => {
                throw new Error(error.message)
            }
        );
    }
    deleteAsset = async (
        assetId: string
    ) => {
        const url = `${process.env.REACT_APP_ASIMS_API_URL}/api/inspection/asset?assetId=${assetId}`;

        const token = await getCurrentSessionIdToken();

        const headers = {
            "idToken": token,
        }

        await axios.delete(url, { headers })
        .then((response) => response)
        .catch(error => {
            throw new Error(error.message);
        })
    }
    requestAssetMetaData = async (
        asset: AssetFile,
        imo: string,
        additionalHeaders?: Record<string, string>,
    ) => {
        let metaData = "&metadata=asset-type,asset-uploaded-by,imo";

        if (additionalHeaders) {
            metaData += `,${Object.keys(additionalHeaders).join(',')}`
        }
        
        const url = `${process.env.REACT_APP_ASIMS_API_URL}/api/upload-url?imo=${imo}&filename=${encodeURIComponent(
            asset.name
        )}${metaData}`;

        const { attributes: { email }} = await Auth.currentAuthenticatedUser({ bypassCache: false });
        const token = await getCurrentSessionIdToken();

        const headers: Record<string, string> = {
            "idToken": token,
            "x-metadata-imo": imo,
            "x-metadata-asset-type": asset.type,
            "x-metadata-asset-uploaded-by": email,
        }

        if (additionalHeaders) {
            Object.entries(additionalHeaders).forEach(([headerKey, headerValue]) => {
                headers[`x-metadata-${headerKey}`] = headerValue;
            })
        }
        
        return axios.get(url, {
            headers
        })
        .then(response => response.data.data)
        .catch(error => {
                throw new Error(error.message)
            }
        );
    }
    searchAssets = async (
        queryString: string,
    ) => {
        const url = `${process.env.REACT_APP_ASIMS_API_URL}/api/search?${queryString}`

        const token = await getCurrentSessionIdToken();
        
        const headers = {
            "Content-Type": "application/json",
            "idToken": token,
        }

        return axios.get(url, {
            headers
        })
        .then(response => response.data.data)
        .catch(error => {
                throw new Error(error.message)
            }
        );
    }
    uploadAsset = async (
        asset: AssetFile,
        assetMetadata: Record<string, string>,
        additionalHeaders?: Record<string, string>,
    ) => {
        const url = assetMetadata.putUrl;

        const headers: Record<string, string> = {
            "Content-Type": asset.type,
            "x-amz-meta-asset-id": assetMetadata.assetId,
            "x-amz-meta-asset-type": asset.type,
            "x-amz-meta-filename": encodeURIComponent(asset.name),
        }

        if (additionalHeaders) {
            Object.entries(additionalHeaders).forEach(([headerKey, headerValue]) => {
                headers[`x-amz-meta-${headerKey}`] = headerValue;
            })
        }

        await axios.put(url, asset, {
            headers,
        })
        .then(response => response)
        .catch(error => {
                throw new Error(error.message)
            }
        );
    }
}

export default AssetsService;
