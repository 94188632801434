import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefectRectificationData } from '../../types/DefectRectificationData';
import { DefectActivity } from '../../types/DefectActivity';
import { DefectDataItem } from '../../types/DefectsDataItem';
import { SharedDefectItem } from '../../types/SharedDefectItem';

export interface RectificationState {
    data: DefectRectificationData | undefined;
    loading: boolean;
    error: boolean;
    defect: DefectDataItem | SharedDefectItem | undefined;
    activityData: DefectActivity[];
    activityLoading: boolean;
    activityError: boolean;
}

export const initialState: RectificationState = {
    data: undefined,
    loading: false,
    error: false,
    defect: undefined,
    activityData: [],
    activityLoading: false,
    activityError: false,
};

export const rectificationSlice = createSlice({
    name: 'rectification',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<DefectRectificationData | undefined>) => {
            state.data = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload;
        },
        setDefect: (state, action: PayloadAction<DefectDataItem | SharedDefectItem | undefined>) => {
            state.defect = action.payload;
        },
        setActivityData: (state, action: PayloadAction<DefectActivity[]>) => {
            state.activityData = action.payload;
        },
        setActivityLoading: (state, action: PayloadAction<boolean>) => {
            state.activityLoading = action.payload;
        },
        setActivityError: (state, action: PayloadAction<boolean>) => {
            state.activityError = action.payload;
        },
    },
});

export const {
    setData,
    setLoading,
    setError,
    setDefect,
    setActivityData,
    setActivityLoading,
    setActivityError,
} = rectificationSlice.actions;

export default rectificationSlice.reducer;
