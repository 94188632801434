import { useTranslation } from "react-i18next";
import { DefectSummaryDataItem } from "../../types/DefectSummaryDataItem"

type DefectSummaryInspectionSinceProps = {
    rowData: DefectSummaryDataItem;
}

export const DefectSummaryInspectionSince = (props: DefectSummaryInspectionSinceProps) => {
    const { rowData } = props;

    const { t } = useTranslation("locale");

    if (!rowData.inspected) {
        return null;
    }

    const dateOfInspection = new Date(rowData.inspected);
    const daysSinceInspected = rowData.daysSinceInspected;

    const today = new Date();

    if (daysSinceInspected === null || daysSinceInspected === undefined || today < dateOfInspection) {
        return null;
    }

    return (
        <div>
            <p>{dateOfInspection.toLocaleDateString("en-GB")}</p>
            <small>{t("defectsTable.inspectionDaysAgo", { daysSince: daysSinceInspected})}</small>
        </div>
    )
}
