import { useRef, useState } from "react";
import { successToast, errorToast } from "@idwal/idwal-react-components";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Menu } from 'primereact/menu';
import { DataTable } from "primereact/datatable";
import { DefectDataItem } from "../../types/DefectsDataItem";
import { ANALYTICS_BUTTON_CLICK, DEFECTS_EXPORT, DEFECTS_EXPORT_DEFECT_ACTION_PLAN_CLICK, DEFECTS_EXPORT_DEFECT_ACTION_PLAN_IMAGES_CLICK, DEFECTS_EXPORT_DEFECT_TABLE_CLICK } from "../../constants/analytics";
import { sendGAEvent } from "../../AnalyticsService";
import { SharedDefectItem } from "../../types/SharedDefectItem";
import { useCapabilities } from "../../hooks/useCapabilities";
import { Capability } from "../../types/Capability";
import { triggerExportDap } from "../../services/DefectService";

const date = Date.now();
const formatedDate = new Date(date);

export const exportCsvFileName = "Fleet_Defects_Overview_" + formatedDate.getDate() + "_" + formatedDate.toLocaleDateString('en-GB', { month: 'long' }) + "_" + formatedDate.getFullYear();

export const ExportButton = ((props: {
    dataTableRef: React.RefObject<DataTable<any>>,
    filteredData: (DefectDataItem | SharedDefectItem)[]
}) => {
    const { t } = useTranslation("locale");
    const { capabilities } = useCapabilities([
        Capability.CAP_FLEETAPP_EXPORT_DAP
    ]);

    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const exportMenu = useRef<any>(null);
    
   /**
     * Export datatable to CSV
     */
    const exportCSV = () => {
        setExportLoading(true);
        if (props.dataTableRef && props.dataTableRef.current) {     
            props.dataTableRef.current.exportCSV();
            successToast(t("fleetTable.exportSuccessMessage"));
            setExportLoading(false);
            sendGAEvent(ANALYTICS_BUTTON_CLICK, DEFECTS_EXPORT_DEFECT_TABLE_CLICK);
            return;
        }

        setExportLoading(false);
        errorToast(t("fleetTable.exportErrorMessage"));    
    };

    const exportDap = async (images: boolean) => {
        sendGAEvent(ANALYTICS_BUTTON_CLICK, images ? DEFECTS_EXPORT_DEFECT_ACTION_PLAN_IMAGES_CLICK : DEFECTS_EXPORT_DEFECT_ACTION_PLAN_CLICK);

        setExportLoading(true);

        await triggerExportDap(props.filteredData, images)

        setExportLoading(false);
    }

    const handleExportButtonClick = (e: any) => {
        if (!props.filteredData.length) {
            //reset table state when filtered data is not set 
            props.dataTableRef.current?.restoreState();
        }
        sendGAEvent(ANALYTICS_BUTTON_CLICK, DEFECTS_EXPORT);
        exportMenu.current.toggle(e)
    }

    const getExportMenu = () => {
        const menu = [
            {
                label: t("defectsTable.exportCsv"),
                icon: <img className="pr-2" alt="CSV" src="./csv.svg" />,
                command: () => {
                    exportCSV()
                }
            }
        ];

        if (capabilities[Capability.CAP_FLEETAPP_EXPORT_DAP]) {
            menu.push({
                label: t("defectsTable.exportDap"),
                icon: <img className="pr-2" alt="export_dap" src="./export-dap.svg" />,
                command: () => {
                    exportDap(false)
                }
            });
            menu.push({
                label: t("defectsTable.exportDapImages"),
                icon: <img className="pr-2" alt="export_dap_images" src="./export-dap-images.svg" />,
                command: () => {
                    exportDap(true)
                }
            });
        }

        return menu;
    }

    return (
        <div className="defect-btn">              
            <Button icon="pi pi-file-export" iconPos="left" loading={exportLoading} onClick={(e) => handleExportButtonClick(e)} label={t("fleetTable.export")} data-cy="export-button" />
            <Menu
                className="w-full md:w-15rem"
                ref={exportMenu}
                popup
                model={getExportMenu()} />
        </div>     
    )
});