import { FilterListItemProps } from "../../types/FilterListItemProps";
import "./FilterListItem.css";

export const FilterListItem = (props: FilterListItemProps) => {
    const {
        filtername,
        vesselCount,
        marketCount,
        onClick,
        selected = false
    } = props;

    return (
        <li 
            data-cy={`filter-type-select-${filtername}`}
            key={filtername}
            className={selected ? 'filter-list-item--selected' : 'filter-list-item'}
            onClick={() => onClick(filtername)} 
            onKeyDown={(e) => e.keyCode === 13 && onClick(filtername)}
            id={filtername}
        >
            <span className='filter-list-item__title'>{filtername}</span>
            <div className='filter-list-item__count'>
                <span 
                    className='filter-list-item__vesselCount'
                    data-cy={`filter-type-vessel-count-${filtername}`}
                >
                    {vesselCount}
                </span>
                <span 
                    className='filter-list-item__marketCount'
                    data-cy={`filter-type-market-count-${filtername}`}
                >
                    {marketCount}
                </span>
            </div>
            <div className='filter-list-item__arrow-container'>
                {
                    selected && (
                        <i className="pi pi-arrow-right" style={{'fontSize': '1em'}}/>
                    )
                }
            </div>
        </li>
    )
}