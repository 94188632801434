import { useRef } from "react";
import { useTranslation } from "react-i18next";

import ImageViewer, { ImageViewerMethods } from "./ImageViewer";

import { AssetFile } from "../../types/AssetFile";

import './AssetGallery.css'
import { Skeleton } from "primereact/skeleton";
import { AssetGalleryItem } from "./AssetGalleryItem";

import { sortBy } from "lodash";

/**
 * AssetGallery for Asset Service
 * @param props
 * @returns
 */
export const AssetGallery = (props: {
    assets: AssetFile[];
    onDelete: (asset: AssetFile) => void;
    loading?: boolean;
    deletionState: Record<string, boolean>;
}) => {
    const { t } = useTranslation("locale");

    const imageViewerRef = useRef<ImageViewerMethods | null>(null);

    const imageUrls = props.assets.reduce((urls: string[], asset) => {
        if (asset.objectURL && asset.type.includes("image")) {
            urls.push(asset.objectURL);
        }

        return urls;
    }, []);

    const onSelect = (asset: AssetFile, selectedIndex: number) => {
        const isImage = asset.type.includes("image");
    
        if (isImage) {
            if (imageViewerRef.current) {                
                imageViewerRef.current.handleImageClick(imageUrls, selectedIndex);
            }
        } else {
            const a = document.createElement('a');
            a.href = asset.objectURL;
            a.download = asset.name;
            a.click();
        }

    }

    if (props.loading) {
        return <Skeleton shape="rectangle" width="100%" height="204px" />;
    }

    if (!props.assets.length) {
        return null;
    }

    const sortedAssets = sortBy(props.assets, (asset) => asset.type.includes("image") ? 0 : 1);

    return (
        <>
            <div className="col-12 pb-0">
                <label>{t("assets.uploadedImagesDocuments")}</label>
            </div>
            <div className="grid p-3 w-full">
            {sortedAssets.map((asset, index) => 
                <AssetGalleryItem key={asset.objectURL} asset={asset} onDelete={() => props.onDelete(asset)} onSelect={() => onSelect(asset, index)} deletionState={props.deletionState}/>
            )}
            </div>
            <ImageViewer ref={imageViewerRef} />
        </>
    );
};
