import { Checkbox } from "primereact/checkbox";
import "./MultiSelectList.css";
import { GenericSearchable } from "../GenericSearch/GenericSearch";

export type MultiSelectListItem = {
    name: string;
    value: string;
    checked: boolean;
};

export const MultiSelectList = ({
    items,
    onChange,
    disabled
}: GenericSearchable<MultiSelectListItem>) => {
    return (
        <>
            {items.map((item) => (
                <div 
                    id={item.name}
                    data-cy={`multiselect_item_${item.name}`}
                    key={item.name} 
                    className="flex align-items-center multiselect-list__item"
                >
                    <Checkbox 
                        inputId={item.name} 
                        name="category" 
                        value={item.value} 
                        onChange={onChange} 
                        checked={item.checked}
                        disabled={disabled}
                    />
                    <label 
                        htmlFor={item.name}
                        className="ml-2">{item.name}
                    </label>
                </div>
            ))}
        </>
    );
};
