export const FilterIcon = (
    <svg 
        width="21" 
        height="21" 
        viewBox="0 0 24 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="Prime Custom icons">
            <g id="Vector">
                <path d="M10.6319 10.37V8.41L15.1419 2.25C15.2319 2.13 15.2819 2 15.3019 1.85C15.3119 1.7 15.2919 1.56 15.2219 1.43H15.2119L15.1919 1.39C15.1319 1.28 15.0319 1.19 14.9219 1.12C14.8019 1.04 14.6519 1 14.5119 1H1.79188C1.64188 1 1.50188 1.04 1.38188 1.12C1.26188 1.2 1.16187 1.3 1.09188 1.43C1.02188 1.56 0.991875 1.71 1.00187 1.85C1.01187 2 1.07188 2.13 1.15188 2.25L5.67188 8.41V14.94C5.67188 15.0518 5.6974 15.1609 5.74242 15.2595L7.08311 13.9188L7.10187 8.15C7.10187 8.01 7.06187 7.88 6.97187 7.78L3.06187 2.42H13.2419L9.34188 7.77L9.33187 7.78C9.25187 7.89 9.20187 8.02 9.20187 8.15V11.8L10.6319 10.37Z" fill="white"/>
                <path d="M12.3872 14.6592L13.6719 16.41V22.94C13.6719 23.15 13.7619 23.35 13.9019 23.49C14.0519 23.64 14.2418 23.72 14.4518 23.72H17.8519C18.0619 23.72 18.2619 23.63 18.4018 23.49C18.5518 23.34 18.6319 23.15 18.6319 22.94V16.41L23.1419 10.25C23.2319 10.13 23.2819 10 23.3019 9.85C23.3119 9.7 23.2919 9.56 23.2219 9.43H23.2119L23.1919 9.39C23.1319 9.28 23.0318 9.19 22.9219 9.12C22.8018 9.04 22.6519 9 22.5119 9H18.0465L16.6265 10.42H21.2418L17.3419 15.77L17.3319 15.78C17.2519 15.89 17.2019 16.02 17.2019 16.15V22.3H15.0819L15.1019 16.15C15.1019 16.01 15.0619 15.88 14.9719 15.78L13.4089 13.6375L12.3872 14.6592Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M22.5322 1.53033C22.8251 1.82322 22.8251 2.2981 22.5322 2.59099L2.59288 22.5303C2.29999 22.8232 1.82512 22.8232 1.53222 22.5303C1.23933 22.2374 1.23933 21.7626 1.53222 21.4697L21.4716 1.53033C21.7645 1.23744 22.2393 1.23744 22.5322 1.53033Z" fill="white"/>
            </g>
        </g>
    </svg>
);