import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { getTranslationObject } from "../../../services/Translation";
import { FILTER_MAX_MULTISELECT_LABELS_TO_SHOW } from "../../../utils/Constants";

const t = getTranslationObject();

/**
 * This filter template provides a list of estimated cost ranges in a filter menu
 * It searches by string given
 * @param options 
 * @returns 
 */
export const estimatedCostFilter = (options: any) => {
    const dropdownOptions = [
        t("defectsTable.estimateCostFilter<1000"),
        t("defectsTable.estimateCostFilter1000-5000"),
        t("defectsTable.estimateCostFilter5000-20000"),
        t("defectsTable.estimateCostFilter20000-50000"),
        t("defectsTable.estimateCostFilter50000+"),
    ];

  return (
    <MultiSelect
      data-cy="defect-estimate-cost-filter-input"
      value={options.value}
      options={dropdownOptions}
      onChange={(e: MultiSelectChangeEvent) => options.filterApplyCallback(e.value)}
      placeholder={t("defectsTable.showAll")}
      className="p-column-filter"
      maxSelectedLabels={FILTER_MAX_MULTISELECT_LABELS_TO_SHOW}
      showClear
    />
  );
};

export const customEstimateDefectCostFilter = (value: string, filter: string[]) => {
  let match = false;

  if (filter === null || !filter.length) {
    return true;
  }

  filter.forEach((x: any) => {
    match = customEstimateDefectCostFilterSwitch(x, value, match);
  });

  return match;
};

const customEstimateDefectCostFilterSwitch = (
  filterQuery: string,
  value: any,
  match: boolean
): boolean => {
  if (match) {
    return match;
  }

  const isNumericalValue = (value: any) => {
    // Regex to check if string contains -, < or +.
    const re = /[-<+]/;
    return !re.test(value);
  };

  const evaluateNumericalValue = (
    value: string,
    lowerBoundary: number,
    upperBoundary: number | null
  ): boolean => {
    const numericalValue = Number(value.substring(1));

    if (upperBoundary === null && numericalValue >= lowerBoundary) {
      return true;
    }

    if (
      upperBoundary !== null &&
      numericalValue >= lowerBoundary &&
      numericalValue < upperBoundary
    ) {
      return true;
    }

    return false;
  };

  const evaluateStringValue = (value: string, filterString: string) => {
    return value === filterString;
  };

  const evaluateEstimateCostFilter = (
    value: any,
    lowerBoundary: number,
    upperBoundary: number | null,
    filterString: string
  ) => {
    if (value === null || value === undefined) {
      return false;
    }

    if (isNumericalValue(value)) {
      return evaluateNumericalValue(value, lowerBoundary, upperBoundary);
    }

    return evaluateStringValue(value, filterString);
  };

  switch (filterQuery) {
    case t("defectsTable.estimateCostFilter<1000"):
      match = evaluateEstimateCostFilter(value, 0, 1000, "<$1000");
      break;
    case t("defectsTable.estimateCostFilter1000-5000"):
      match = evaluateEstimateCostFilter(value, 1000, 5000, "$1000 - $5000");
      break;
    case t("defectsTable.estimateCostFilter5000-20000"):
      match = evaluateEstimateCostFilter(value, 5000, 20000, "$5000 - $20000");
      break;
    case t("defectsTable.estimateCostFilter20000-50000"):
      match = evaluateEstimateCostFilter(
        value,
        20000,
        50000,
        "$20000 - $50000"
      );
      break;
    case t("defectsTable.estimateCostFilter50000+"):
      match = evaluateEstimateCostFilter(value, 50000, null, "$50000+");
      break;
  }

  return match;
};

