import ReactGA from "react-ga4";
import { UserAnalyticsConfig } from "./types/UserAnalyticsConfig";
import { UaEventOptions } from "react-ga4/types/ga4";

const TRACKING_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
let USER_CONFIG: UserAnalyticsConfig;

export const initializeAnalytics = (userAnalyticsConfig: UserAnalyticsConfig, cognitoId?: string, userEmail?: string) => {
    if (!TRACKING_ID) {
        throw new Error("Cannot initialize analytics. Missing GA Measurement ID.");
    }

    USER_CONFIG = userAnalyticsConfig;

    ReactGA.initialize(TRACKING_ID, {
        gaOptions: {
            location: USER_CONFIG.timestamp,
            cognito_id: cognitoId,
            email: userEmail,
            debug_mode: true,
        }
    });

    ReactGA.set({ location: USER_CONFIG.timestamp });
    ReactGA.send("pageview");
}

export const sendGAEvent = (category: string, action: string) => {
    action = action.trim().replaceAll(" ", "_");

    const options: UaEventOptions = {
        category,
        action,
        label: USER_CONFIG?.timestamp.toLocaleString() || ""
    };

    ReactGA.event(options);
}
