import { MouseEvent } from "react";

import { Button } from "primereact/button";

import { AssetFile } from "../../types/AssetFile";

import './AssetGallery.css'
import { DOC_FILE_EXTENSIONS, DOC_FILE_TYPES, EXCEL_FILE_EXTENSIONS, EXCEL_FILE_TYPES } from "../../types/FileExtensionTypes";

/**
 * AssetGalleryItem for Asset Gallery
 * @param props
 * @returns
 */
export const AssetGalleryItem = (props: {
    asset: AssetFile,
    onDelete: () => void,
    onSelect: () => void,
    deletionState: Record<string, boolean>;
}) => {
    
    const isAssetOfType = (assetType: string, allowedExtensions: string[], allowedFileTypes: string[]): boolean => {
        
        return allowedFileTypes.some(extension => assetType.includes(extension)) || 
                allowedExtensions.some(extension => assetType.endsWith(extension.split(".")[1]));
    }

    const isImage = props.asset.type.includes("image");
    const selectIcon = isImage ? "pi-window-maximize" : "pi-download";

    let fileIcon = "";

    switch(true) {
        case isAssetOfType(props.asset.type, DOC_FILE_EXTENSIONS, DOC_FILE_TYPES):
            fileIcon = "pi-file-word";
            break;
        case isAssetOfType(props.asset.type, EXCEL_FILE_EXTENSIONS, EXCEL_FILE_TYPES):
            fileIcon = "pi-file-excel";
            break;
        case props.asset.type.includes('pdf'):
            fileIcon = "pi-file-pdf";
            break;
        default:
            fileIcon = "pi-file"
            break;
    }

    const onClickDelete = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        props.onDelete();
    }

    const onClickSelect = (event: MouseEvent<HTMLButtonElement | HTMLImageElement>) => {
        event.preventDefault();
        props.onSelect();
    }

    const isDeleting = props.deletionState[props.asset.objectURL];

    return (
        <div className="flex relative p-2 col-12 sm:col-6 md:col-12 lg:col-6 xl:col-4 asset-gallery-item">
        {isImage ? (
            <img className="asset-gallery-image" src={props.asset.objectURL} onClick={onClickSelect} />
            ) : (
                <div className="flex flex-column align-items-center p-4 asset-gallery-file-item">
                    <label className="asset-gallery-item-name mb-3">{props.asset.name}</label>
                    <i className={`pi ${fileIcon} asset-gallery-item-icon`} />
                </div>
            )
        }
        <div className="flex flex-row align-items-center justify-content-between asset-gallery-overlay" >
            <Button onClick={onClickDelete} loading={isDeleting} className="p-button-text" icon="pi pi-trash asset-gallery-overlay-icon" />
            <Button onClick={onClickSelect} className="p-button-text" icon={`pi ${selectIcon} asset-gallery-overlay-icon`} />
        </div>
    </div>
    );
};
