import {Column} from "primereact/column";
import React from "react";
import {FleetTableFields} from "../FleetTableFields";
import {getTranslationObject} from "../../../services/Translation";


const t = getTranslationObject();

export const buildVesselFrozenColumn = (field: string, filterField: string, bodyTemplate: any) => {
    return (
        <Column
            key={field}
            field={field}
            dataType="text"
            header={t("fleetTable.vessel")}
            headerClassName="vesselName-header"
            sortable
            filter
            frozen
            showFilterMenu
            showFilterMatchModes
            filterField={filterField}
            body={bodyTemplate}
            bodyStyle={{textAlign: "left", justifyContent: "start"}}
            style={{minWidth: "14rem"}}
            filterPlaceholder={t("fleetTable.searchByName")}
        />
    )
}

export const vesselColumn = (bodyTemplate: any) => {
    const field = FleetTableFields.VESSEL_NAME;
    return buildVesselFrozenColumn(field, "nameFilterField", bodyTemplate);
}

export const actionColumn = (bodyTemplate: any) => {
    const field = FleetTableFields.ACTIONS;
    return (
        <Column
            key={field}
            field={field}
            dataType="text"
            header={t("fleetTable.actions")}
            headerClassName="vesselName-actions"
            showFilterMenu
            showFilterMatchModes
            body={bodyTemplate}
        />
    )
}