import { useState } from "react";
import { FleetGraph} from "../components/FleetGraph/FleetGraph";
import { TabPanel, TabView, TabViewTabChangeEvent } from "primereact/tabview";
import { useTranslation } from "react-i18next";
import { ANALYTICS_TAB_CLICK, GRADE_CONDITION_GRADE_CLICK, GRADE_MANAGEMENT_GRADE_CLICK } from "../constants/analytics";
import { sendGAEvent } from "../AnalyticsService";
import { FleetModeGraph } from "../types/FleetGraphMode";

const Grade = () => {
    const {t} = useTranslation("locale");
    const [activeIndex, setActiveIndex] = useState<number>(0);

    const tabPanelData: Array<{translation: string, mode: FleetModeGraph}> = [
        {
            translation: "fleetGraph.idwalGrade",
            mode: "grade"
        },
        {
            translation: "fleetGraph.conditionGrade",
            mode: "condition"
        },
        {
            translation: "fleetGraph.managementGrade",
            mode: "management"
        }
    ];

    const handleTabChange = (e: TabViewTabChangeEvent) => {
        setActiveIndex(e.index);
        switch (e.index) {
            case tabPanelData.findIndex((tab) => tab.mode === 'condition'):
                sendGAEvent(ANALYTICS_TAB_CLICK, GRADE_CONDITION_GRADE_CLICK); 
                break;
            case tabPanelData.findIndex((tab) => tab.mode === 'management'):
                sendGAEvent(ANALYTICS_TAB_CLICK, GRADE_MANAGEMENT_GRADE_CLICK); 
                break;
        }
    };

    return(
        <>
            {
                <div className={"flex justify-content-end align-items-center mr-5"}></div>
            }
            <TabView renderActiveOnly={true} className={"tab-container lg:pt-0"} onTabChange={handleTabChange} activeIndex={activeIndex}>
                {
                    tabPanelData.map((tabPanelData, index) => {
                        return (
                            <TabPanel header={t(tabPanelData.translation)} key={`tab-${tabPanelData.mode}`}>
                                <FleetGraph mode={tabPanelData.mode} useMockData={false} />
                            </TabPanel>
                        )
                    })
                }
            </TabView>
        </>
    )
}

export default Grade;
