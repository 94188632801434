import i18next from "i18next";
import {TFunction} from "react-i18next";

i18next.init({
    ns: ['locale']
});


export function getTranslationObject(): TFunction<string> {
    return i18next.t;
}
