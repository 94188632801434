import React from "react";
import {MultiSelect} from "primereact/multiselect";
import {getTranslationObject} from "../../services/Translation";
import {FilterMatchMode, FilterService} from "primereact/api";
import {
    customVesselInspectionStatusFilter
} from "../Shared/FilterTemplates";
import {FleetTableFields} from "./FleetTableFields";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {DataTableFilterMeta} from "primereact/datatable";
import {RiskLevel} from "../../types/RiskLevel";
import {InputNumber} from "primereact/inputnumber";
import { FILTER_MAX_MULTISELECT_LABELS_TO_SHOW } from "../../utils/Constants";
import {customGradeFilter} from "@idwal/idwal-react-components";



const t = getTranslationObject();

export const rankFilterTemplate = (options: any) => {

    const dropdownOptions = [
        t("fleetTable.rankFilter25%"),
        t("fleetTable.rankFilter25%to50%"),
        t("fleetTable.rankFilter50%to75%"),
        t("fleetTable.rankFilter75%to100%")
    ]

    return <MultiSelect data-cy="rank-filter-input" value={options.value} options={dropdownOptions} onChange={(e) => options.filterApplyCallback(e.value)} placeholder={t("fleetTable.showAll")} className="p-column-filter" maxSelectedLabels={FILTER_MAX_MULTISELECT_LABELS_TO_SHOW} showClear />;
}

export const customRankFilter = (value: any, filter: any, recordCount: number) : boolean => {
    if (filter === null || !filter.length) return true;

    let match = false;

    filter.forEach((x : any) => {
        let thresholdTop = 0;
        let thresholdBottom = 0;

        switch (x) {
            case t("fleetTable.rankFilter25%"):
                thresholdTop = recordCount
                thresholdBottom = Math.floor(recordCount - (recordCount * 0.25))
                break;
            case t("fleetTable.rankFilter25%to50%"):
                thresholdTop = Math.floor(recordCount - (recordCount * 0.25))
                thresholdBottom = Math.floor(recordCount - (recordCount * 0.5))
                break;
            case t("fleetTable.rankFilter50%to75%"):
                thresholdTop = Math.floor(recordCount - (recordCount * 0.5))
                thresholdBottom = Math.floor(recordCount - (recordCount * 0.75))
                break;
            case t("fleetTable.rankFilter75%to100%"):
                thresholdTop =  Math.floor(recordCount - (recordCount * 0.75))
                break;
        }

        if (value <= thresholdTop && value >= thresholdBottom) {
            match = true;
        }
    });

    return match;
}

export const dateFilterTemplate = (options: any) => {

    const dropdownOptions = [
        t("fleetTable.inspectionDueFilterNextMonth"),
        t("fleetTable.inspectionDueFilterNext7Days"),
        t("fleetTable.inspectionDueFilterLast7Days"),
        t("fleetTable.inspectionDueFilterLastMonth")
    ]

    return <MultiSelect data-cy="daysSince-filter-input" value={options.value} options={dropdownOptions} onChange={(e) => options.filterApplyCallback(e.value)} placeholder={t("fleetTable.showAll")} className="p-column-filter" maxSelectedLabels={FILTER_MAX_MULTISELECT_LABELS_TO_SHOW} showClear />;
}


export const trendingFilterTemplate = (options: any) => {
    const dropdownOptions = [
        t("fleetTable.uptrend"),
        t("fleetTable.downtrend"),
        t("fleetTable.noChange")
    ]

    return <MultiSelect data-cy="trending-filter-input"
                        value={options.value}
                        options={dropdownOptions}
                        onChange={(e) => options.filterApplyCallback(e.value)}
                        placeholder={t("fleetTable.showAll")} className="p-column-filter" maxSelectedLabels={1}
    />;
}


export function addCustomFilters(customCols: string[], filters: DataTableFilterMeta) {
    const customFilters: DataTableFilterMeta = {};
    customCols.forEach(col => {
        customFilters[col] = {value: "", matchMode: FilterMatchMode.CONTAINS};
    });

    return { ...filters, ...customFilters };
}

export const customDateFilter = (value: any, filter: any) => {

    if (filter === null || !filter.length) return true;

    if (value === null || value === undefined) {
        return false;
    }

    let match = false;

    filter.forEach((x : any) => {
        let dateFrom = new Date();
        let dateTo = new Date();

        switch (x) {
            case t("fleetTable.inspectionDueFilterNextMonth"):
                dateTo.setDate(dateTo.getDate() + 31);
                break;
            case t("fleetTable.inspectionDueFilterNext7Days"):
                dateTo.setDate(dateTo.getDate() + 7);
                break;
            case t("fleetTable.inspectionDueFilterLast7Days"):
                dateFrom.setDate(dateFrom.getDate() - 7);
                break;
            case t("fleetTable.inspectionDueFilterLastMonth"):
                dateFrom.setDate(dateFrom.getDate() - 31);
                break;
        }

        if (value >= dateFrom && value <= dateTo) {
            match = true;
        }
    });

    return match;
}

export const daysSinceInspectedFilterTemplate = (options: any) => {

    const dropdownOptions = [
        t("fleetTable.daysSinceFilterOver2Years"),
        t("fleetTable.daysSinceBetween1And2Years"),
        t("fleetTable.daysSinceBetween6MonthsAnd1Year"),
        t("fleetTable.daysSinceUnder6Months")
    ]

    return <MultiSelect data-cy="daysSince-filter-input" value={options.value} options={dropdownOptions} onChange={(e) => options.filterApplyCallback(e.value)} placeholder={t("fleetTable.showAll")} className="p-column-filter" maxSelectedLabels={FILTER_MAX_MULTISELECT_LABELS_TO_SHOW} showClear />;
}

export const customDaysSinceInspectedFilter = (value: any, filter: any) => {
    if (filter === null || !filter.length) return true;

    let match = false;

    filter.forEach((x : any) => {
        if (value === null || value === undefined) {
            return false;
        }


        switch (x) {
            case t("fleetTable.daysSinceFilterOver2Years"):
                if (value > (365 * 2)) {
                    match = true;
                }
                break;
            case t("fleetTable.daysSinceBetween1And2Years"):
                if (value >= 365 && value <= (365 * 2)) {
                    match = true;
                }
                break;
            case t("fleetTable.daysSinceBetween6MonthsAnd1Year"):
                if (value > (365 / 2) && value <= 365) {
                    match = true;
                }
                break;
            case t("fleetTable.daysSinceUnder6Months"):
                if (value >= 0 && value <= (365 /2)) {
                    match = true;
                }
                break;
        }
    });

    return match;
}

export const customLastInspectedFilter = (value: any, filter: any) => {
    if (filter === null || !filter.length) return true;
    let match = false;

    filter.forEach((x : any) => {
        if (value === null || value === undefined) {
            return false;
        }

        const currentDate = new Date();
        const lastInspectedDate = new Date(value);
        const timeDifference = Math.abs(lastInspectedDate.getTime() - currentDate.getTime());
        const millisecondsInOneDay = 1000 * 60 * 60 * 24;
        const differenceInDays = Math.ceil(timeDifference / millisecondsInOneDay);

        switch (x) {
            case t("fleetTable.daysSinceFilterOver2Years"):
                if (differenceInDays > (365 * 2)) {
                    match = true;
                }
                break;
            case t("fleetTable.daysSinceBetween1And2Years"):
                if (differenceInDays >= 365 && differenceInDays <= (365 * 2)) {
                    match = true;
                }
                break;
            case t("fleetTable.daysSinceBetween6MonthsAnd1Year"):
                if (differenceInDays > (365 / 2) && differenceInDays <= 365) {
                    match = true;
                }
                break;
            case t("fleetTable.daysSinceUnder6Months"):
                if (differenceInDays >= 0 && differenceInDays <= (365 /2)) {
                    match = true;
                }
                break;
        }
    });

    return match;
}

export const trendingFilter = (value: number, filter: any) => {
    if (filter === null || !filter.length) return true;

    if (value === undefined) {
        return false;
    }
    return trendingFilterMatch(value, filter);
}

const trendingFilterMatch = (value: number, filter: any) => {
    let match = false;

    filter.forEach((x: any) => {
        switch (x) {
            case t("fleetTable.uptrend"):
                if (value > 0) {
                    match = true;
                }
                break;
            case t("fleetTable.downtrend"):
                if (value < 0) {
                    match = true;
                }
                break;
            case t("fleetTable.noChange"):
                if (value == 0) {
                    match = true;
                }
                break;
        }
    });
    return match;
}


export const defaultFleetFiltersConfig = {
    "global": { value: null, matchMode: FilterMatchMode.CONTAINS },
    [FleetTableFields.NAME_FILTER_FIELD]: { value: null, matchMode: FilterMatchMode.CONTAINS },
    [FleetTableFields.VESSEL_TYPE]: { value: null, matchMode: FilterMatchMode.IN },
    [FleetTableFields.RISK_LEVEL]: { value: null, matchMode: FilterMatchMode.IN },
    [FleetTableFields.VESSEL_TECHNICAL_MANAGER]: { value: null, matchMode: FilterMatchMode.IN },
    [FleetTableFields.INSPECTION_DAYS_SINCE]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [FleetTableFields.GRADE_FILTER_FIELD]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [FleetTableFields.INSPECTION_CONDITION_SCORE]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [FleetTableFields.INSPECTION_MANAGEMENT_SCORE]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [FleetTableFields.INSPECTION_INSPECTION_DUE]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [FleetTableFields.RANK]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [FleetTableFields.VESSEL_CLASS_SOCIETY]: { value: null, matchMode: FilterMatchMode.CONTAINS },
    [FleetTableFields.VESSEL_FLAG]: { value: null, matchMode: FilterMatchMode.IN },
    [FleetTableFields.VESSEL_DWT]: { value: null, matchMode: FilterMatchMode.CONTAINS },
    [FleetTableFields.VESSEL_COUNTRY_OF_BUILD]: { value: null, matchMode: FilterMatchMode.IN },
    [FleetTableFields.VESSEL_BUILD_YEAR]: { value: null, matchMode: FilterMatchMode.CONTAINS },
    [FleetTableFields.VESSEL_SPECIAL_SURVEY_DATE]: { value: null, matchMode: FilterMatchMode.CUSTOM },
    [FleetTableFields.VESSEL_NOTES]: { value: null, matchMode: FilterMatchMode.CONTAINS },
    [FleetTableFields.VESSEL_INSPECTION_STATUS]: { value: null, matchMode: FilterMatchMode.CUSTOM },

    [FleetTableFields.LAST_INSPECTED]: { value: null, matchMode: FilterMatchMode.CUSTOM },

    [FleetTableFields.TRENDING]: { value: null, matchMode: FilterMatchMode.CUSTOM }
};

export const riskLevelEditorTemplate = (columnData: any) => {

    const riskLevels = [
        { name: t("riskLevel.none"), code: RiskLevel.none },
        { name: t("riskLevel.veryLow"), code: RiskLevel.very_low },
        { name: t("riskLevel.low"), code: RiskLevel.low },
        { name: t("riskLevel.medium"), code: RiskLevel.medium },
        { name: t("riskLevel.high"), code: RiskLevel.high },
        { name: t("riskLevel.veryHigh"), code: RiskLevel.very_high },
    ];

    return(
        <>
            <Dropdown
                className={"risk-drop-down"}
                appendTo={'self'}
                value={columnData.value}
                options={riskLevels}
                onChange={(e) => {
                    columnData.value = e.value;
                    columnData.editorCallback(e.value)
                }}
                optionValue="code"
                optionLabel="name"
            />
        </>
    )
};

const getFieldValue = (rowData: any, field: string) => {
    const keys = field.split('.');
    return keys.reduce((acc, key) => acc && acc[key], rowData);
}

export const textEditorTemplate = (columnData: any) => {
    let { rowData, field } = columnData;
    let value = getFieldValue(rowData, field) ?? "";

    return(
        <>
            <InputText 
                type="text" 
                value={value} 
                className={
                    value.length > 500 
                    ? "p-invalid ml-1 w-full" 
                    : "ml-1 w-full"}
                onChange={(e) => {
                    columnData.editorCallback(e.target.value)
                }} />
            {value.length > 500 ? (<small className="p-error block ml-1"></small>) : null}
        </>
    )
};

export const numberEditorTemplate = (columnData: any) => {
    let { rowData, field } = columnData;
    let value = getFieldValue(rowData, field);
    if (value === null || value === undefined) {
        value = "";
    }

    return(
        <InputNumber 
            type="text" 
            value={value}
            className={"ml-2"}
            onChange={(e) => {
                columnData.editorCallback(e.value);
            }} />
    )
};

export const registerCustomFilters = () => {
    FilterService.register(`custom_${FleetTableFields.GRADE_FILTER_FIELD}`, customGradeFilter);
    FilterService.register(`custom_${FleetTableFields.INSPECTION_DAYS_SINCE}`, customDaysSinceInspectedFilter);
    FilterService.register(`custom_${FleetTableFields.LAST_INSPECTED}`, customLastInspectedFilter);
    FilterService.register(`custom_${FleetTableFields.INSPECTION_CONDITION_SCORE}`, customGradeFilter);
    FilterService.register(`custom_${FleetTableFields.INSPECTION_MANAGEMENT_SCORE}`, customGradeFilter);
    FilterService.register(`custom_${FleetTableFields.INSPECTION_INSPECTION_DUE}`, customDateFilter);
    FilterService.register(`custom_${FleetTableFields.VESSEL_SPECIAL_SURVEY_DATE}`, customDateFilter);
    FilterService.register(`custom_${FleetTableFields.VESSEL_INSPECTION_STATUS}`, customVesselInspectionStatusFilter);
    FilterService.register(`custom_${FleetTableFields.TRENDING}`, trendingFilter);
}
