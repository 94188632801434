import { Button } from "primereact/button"
import { useRef } from "react";
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputSwitch } from 'primereact/inputswitch';
import { Trans, useTranslation } from "react-i18next";
import "./AiToggle.css";
import { Message } from "primereact/message";
import { AIGradingAccess } from "../../types/AIGradingAccess";
import { SidebarIcon } from "@idwal/idwal-react-components";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { clearAllFilters } from "../../store/thunks/filtersThunks";
import { selectAiGradingAccess, selectAiGradingActive } from "../../store/selectors/userSelectors";
import { clearForShowAllModal } from "../../store/thunks/advancedFiltersThunks";
import { setAiGradingActive } from "../../store/slices/userSlice";

const LOCAL_AI_GRADING_ACTIVE_KEY = "ai-grading-active";

export const AiToggle = () => {
    const {t} = useTranslation("locale");
    const dispatch = useAppDispatch();

    const aiOverlayPanel = useRef<OverlayPanel>(null);

    const aiGradingAccess = useAppSelector(selectAiGradingAccess);
    const aiGradingActive = useAppSelector(selectAiGradingActive);

    const onAiToggleChange = (active: boolean) => {
        localStorage.setItem(LOCAL_AI_GRADING_ACTIVE_KEY, active.toString());
        dispatch(setAiGradingActive(active));
        dispatch(clearAllFilters());
        dispatch(clearForShowAllModal());
    }

    const handleFindOutMoreClick = () => {
        window.open('https://www.idwalmarine.com/idwal-ai', '_blank', 'noopener noreferrer');
    }

    return (
        <div>
            {aiGradingAccess !== AIGradingAccess.OFF && 
            <div>
                <Button
                onClick={(e) => aiOverlayPanel.current?.toggle(e)}
                id="ai-menu-button"
                className="ai-button p-button-text"
                icon={<SidebarIcon
                        src={`${aiGradingActive ? "ai-menu-active.svg" : "ai-menu-inactive.svg"}`}
                        fill={"#555555"}
                        style={"width: 52px; height: 52px; display: block;"} 
                        />}
                data-cy="ai-menu-button"
                />
                <OverlayPanel
                    ref={aiOverlayPanel}
                    id="ai-overlay-panel"
                    data-cy="ai-overlay-panel"
                    style={{ width: "400px" }}>
                        <div id="ai-overlay-header" className="flex align-items-center justify-content-between" data-cy="ai-overlay-header">
                            <h2>{t("aiToggle.header")}</h2>
                            <InputSwitch
                                className="ai-toggle-switch"
                                disabled={aiGradingAccess !== AIGradingAccess.PAID} checked={aiGradingActive} 
                                onChange={(e) => onAiToggleChange(e.value)} />
                        </div>
                        {aiGradingAccess !== AIGradingAccess.PAID && 
                            <Message
                                className="mt-3" 
                                severity="info" 
                                text={
                                    <Trans
                                        i18nKey={"aiToggle.infoMessage"} 
                                        components={[<a className="ai-contact-us" key="contact-us" href="https://www.idwalmarine.com/contact-us" target="_blank" />]} />
                                    }
                            />
                        }
                        <div id="ai-overlay-body" className="mt-3" data-cy="ai-overlay-body">
                            <p className="mt-2">{t("aiToggle.bodyDetails")}</p>
                            <Button className="p-button-sm mt-3" label={t("aiToggle.findOutMore")} onClick={handleFindOutMoreClick} />
                        </div>
                </OverlayPanel>
            </div>}
        </div>
    )
}