import { errorToast } from "@idwal/idwal-react-components";
import { createDefectRefectificationPlan, getDefectRefectificationPlan, getDefectStatusActivity, updateDefectRefectificationPlan } from "../../services/DefectRefectificationService";
import { getTranslationObject } from "../../services/Translation";
import { DefectDataItem } from "../../types/DefectsDataItem";
import { SharedDefectItem } from "../../types/SharedDefectItem";
import { setActivityData, setActivityError, setActivityLoading, setData, setDefect, setError, setLoading } from "../slices/rectificationSlice";
import { setData as setSharedDefectsData } from "../slices/sharedDefectsSlice";
import { AppThunk } from "../store";
import { updateData } from "../slices/defectSlice";
import { DefectStatus } from "../../types/DefectStatus";
import { selectDefects } from "../selectors/defectSelectors";
import { selectSharedDefectsData } from "../selectors/sharedDefectsSelectors";

const t = getTranslationObject();

export const fetchRectification = (defectId: string | undefined, sharedDefect: boolean | undefined): AppThunk => async (dispatch, getState) => {
    if (defectId) {
        const state = getState();
        const defects = selectDefects(state);
        const sharedDefects = selectSharedDefectsData(state);

        dispatch(setLoading(true));
        dispatch(setError(false));
        dispatch(setData(undefined));

        let matchingDefect = undefined;

        if (sharedDefect) {
            matchingDefect = sharedDefects.find((defect) => defect.defectId.toString() === defectId);
        } else {
            matchingDefect = defects.find((defect) => defect.defectId.toString() === defectId);
        }

        dispatch(setDefect(matchingDefect));

        if (matchingDefect) {
            try {
                const response = await getDefectRefectificationPlan(matchingDefect.inspectionId, matchingDefect.defectId);
        
                if (response) {
                    dispatch(setData(response));
                } else {
                    throw Error('no data');
                }
            } catch (e: any) {
                console.error(e);

                if (e.message !== "Request failed with status code 404") {
                    dispatch(setError(true));
                }
            } finally {
                dispatch(setLoading(false));
            }
        }
    }
};

export const fetchRectificationActivity = (defect: DefectDataItem | SharedDefectItem): AppThunk => async (dispatch) => {
    dispatch(setActivityLoading(true));

    try {
        const response = await getDefectStatusActivity(defect.defectId);

        if (response) {
            const defaultActivity = {
                activity: t("defectRectification.defectRaised"),
                createdAt: defect.createdAt ?? defect.inspectionDate ?? ""
            };

            const defectActivities = [defaultActivity].concat(response);
        
            if (!defectActivities[defectActivities.length - 1].activity.includes("Closed")) {

                defectActivities.push({
                    activity: t("defectRectification.awaitingCloseOut"),
                    createdAt: ""
                })
            }

            dispatch(setActivityData(defectActivities));
        } else {
            throw Error('no data');
        }
    } catch (e) {
        console.error(e);
        dispatch(setActivityError(true));
    } finally {
        dispatch(setActivityLoading(false));
    }
};


export const createRectification = (
    formData: Record<string, unknown>,
    rectificationDefect: DefectDataItem | SharedDefectItem,
    sharedDefect?: boolean,
): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
    const { defectId, inspectionId, rectificationId } = rectificationDefect;

    try {

        let response = undefined;
        let newRectificationId: number | undefined = undefined;
        
        if (rectificationId) {
            response = await updateDefectRefectificationPlan(inspectionId, defectId, formData);
        } else {
            response = await createDefectRefectificationPlan(inspectionId, defectId, formData);
            const newRectification = await getDefectRefectificationPlan(inspectionId, defectId)

            if (newRectification) {
                newRectificationId = newRectification.id;
            }
        }
                    
        if (response) {
            const state = getState();
            
            if (sharedDefect) {
                const updatedSharedDefects = state.sharedDefects.data.map((defect) => {
                    if (defect.defectId === defectId) {
                        return updateDefectItem(defect, newRectificationId, formData) as SharedDefectItem;
                    }

                    return defect;
                })

                dispatch(setSharedDefectsData(updatedSharedDefects));
            } else {
                const matchingDefect = state.defect.data.find((defect) => defect.defectId === defectId);
                
                if (matchingDefect) {
                    const updated = updateDefectItem(matchingDefect, newRectificationId, formData) as DefectDataItem;
                    dispatch(updateData([updated]));
                }
            }
        }
    } catch (e) {
        console.error(e);
        errorToast(t("defectRectification.createError"));

        return false;
    }

    return true;
}

const updateDefectItem = (
    defect: DefectDataItem | SharedDefectItem,
    newRectificationId: number | undefined,
    formData: Record<string, unknown>,
) => {
    const updated = { ...defect };

    if (newRectificationId) {
        updated.rectificationId = newRectificationId;
    }
    
    if (formData.defectStatus) {
        updated.status = formData.defectStatus as DefectStatus;
    }

    return updated;
}
