import { getCurrentSessionIdToken } from "../queries/AuthToken";
import { ShipParticulars } from "../types/Paraticulars";
import axios from "axios";

export const getShipParticulars = async (imo?: string) : Promise<ShipParticulars> => {
    const baseUrl: string = process.env.REACT_APP_IDWAL_API_ENDPOINT ?? "";
    const url = baseUrl + `/Fleet/Ship/${imo}`;

    const token = await getCurrentSessionIdToken();

    return axios.get<any>(url,  {
        headers: {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + token
        }
    }).then(result => {
        return result.data;
    }).catch(_err => {
        // Returning undefined here will stop the particulars component from displaying at all, if the server has errored
        return;
    });
}

export const getShipImage = async (imo?: string) : Promise<string>  => {
    const baseUrl: string = process.env.REACT_APP_IDWAL_API_ENDPOINT ?? "";
    const url = baseUrl + `/Fleet/mainShipImage/${imo}`;

    const token = await getCurrentSessionIdToken();

    return axios.get<any>(url,  {
        headers: {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + token
        }
    }).then(result => {
        return result.data;
    }).catch(err => {
        return null;
    });
}