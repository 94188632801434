import { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { Galleria } from "primereact/galleria";
import { errorToast } from "@idwal/idwal-react-components";
import { useTranslation } from "react-i18next";

export interface ImageViewerMethods {
    handleImageClick: (signedUrls: string[], selectedIndex?: number) => void;
}

const ImageViewer = forwardRef<ImageViewerMethods>((props, ref):any => {
    const { t } = useTranslation("locale");
    const galleria = useRef<Galleria | null>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [images, setImages] = useState<string[]>([""]);
    
    const itemTemplate = (item: string) => {
        return <img src={item} style={{ width: "100%", display: "block" }} />;
    };

    useImperativeHandle(ref, () => ({
        handleImageClick: (signedUrls: string[], selectedIndex = 0) => {
            setImages(signedUrls);
            setActiveIndex(selectedIndex);
            galleria.current?.show();
        }
    }));
    
    const handleImageError = (event: any) => {               
        setImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages[activeIndex] = "image-not-found.png";
            return updatedImages;
          });
        errorToast(t("imageViewer.errorMessage"));
    }
    
    return (
        <>
            <Galleria
                ref={galleria}
                value={images}
                numVisible={7}
                style={{ maxWidth: "80vw" }}
                activeIndex={activeIndex}
                onItemChange={(e) => setActiveIndex(e.index)}
                circular
                fullScreen
                showItemNavigators={images.length > 1}
                showThumbnails={false}
                item={itemTemplate}
                onError={handleImageError}
            />
        </>
    )
});

export default ImageViewer;
