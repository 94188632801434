import { graphFontSize } from "../constants/graphFontSize";
import { GRADING_AREA_ORDER } from "./Constants";
import {getTrendDecoration} from "./SubgradeDataHelper";

export const sectionCategoryArray = (sectionAverages: any,trendData : any) => {

    const gradingAreaOrderConfig = [...GRADING_AREA_ORDER].reverse();
    let categoryOrderWithValue : string[] = []

    gradingAreaOrderConfig.forEach(function(category) {

        const sectionAverage = sectionAverages[category] as number;
        if (sectionAverage >= 0) {
            const categoryName= category;
            category = category + ": " + sectionAverage.toFixed();
            category += getTrendDecoration(trendData[categoryName]);

            categoryOrderWithValue.push(category);
        }
    });

    const additionalSections = Object.keys(sectionAverages)
        .filter(section => !gradingAreaOrderConfig.includes(section));

    const mappedAdditionalSections = additionalSections.map(section => {
        const sectionAverage = sectionAverages[section] as number;
        if (sectionAverage >= 0) {
            return section + ": " + sectionAverage.toFixed();
        }
    });

    return [...mappedAdditionalSections, ...categoryOrderWithValue];
}

export const setDeviationGraphLayout = (sectionAverages: any, annotations: any,trendData : any) => {

   return {
        autosize: true,
        xaxis: {
            autorange: true,
            showgrid: false,
            visible: false,
            fixedrange: true,
        },
        yaxis: {
            autorange: true,
            showgrid: true,
            automargin: true,
            categoryarray: sectionCategoryArray(sectionAverages,trendData),
            categoryorder: "array",
            fixedrange: true,
            gridcolor: "#BFE2E4",
            ticklen: 310,
            tickson: "boundaries",
            tickcolor: "#BFE2E4",
            tickfont: {
                size: graphFontSize
            },
            tickmode: "linear",
        },
        legend: {
            font: {
                family: "Open Sans",
                size: graphFontSize,
          },
        },
        margin: {
            l: 330,
            t: 20,
            r: 20,
            b: 30
        },
        barmode: "overlay",
        font: {
            family: "Open Sans",
        },
        annotations: annotations,
        showlegend: false,
        plot_bgcolor: "#FCFCFC",
        paper_bgcolor: "#FCFCFC",
        bargap: 0.02,
        bargroupgap: 0.0975
    }
}