import {FleetDataItem} from "../../types/FleetDataItem";
import {StatTile} from "../../types/StatTile";

export class WorstPerformingClassSocietyTile implements StatTile {

    fleetInspections : FleetDataItem[]

    constructor(fleetInspections: FleetDataItem[]) {
        this.fleetInspections = fleetInspections;
    }

    getTileData() {
        let gradesPerClassSociety: Record<string, number[]> = {};

        this.fleetInspections
            .forEach((fleetEntry: FleetDataItem) : any => {
                let gradesForClassSociety = gradesPerClassSociety[fleetEntry.vessel.classSociety]
                if (!gradesForClassSociety) {
                    gradesPerClassSociety[fleetEntry.vessel.classSociety] = [];
                }
                if(fleetEntry.inspection.idwalGrade != 0){
                    gradesPerClassSociety[fleetEntry.vessel.classSociety].push(fleetEntry.inspection.idwalGrade!);
                }
            }, 0);

        let worstClassSociety= { grade : 100, classSociety: "" };

        for (const classSociety in gradesPerClassSociety) {
            const grades = gradesPerClassSociety[classSociety]
            const averageGrade = grades.reduce(( p: any, c : any ) => p + c, 0) / grades.length;
            if (worstClassSociety.grade > averageGrade) {
                worstClassSociety = {
                    grade: averageGrade,
                    classSociety: classSociety
                }
            }
        }

        if (worstClassSociety?.classSociety) {
            return {
                data: worstClassSociety,
                tileKey: "worstPerformingClassSociety",
                statistic: "Class society",
                statisticFontSize: "1.68rem",
                details: worstClassSociety.classSociety,
                icon: "worst-class-society-icon"
            };
        }

        return null;
    }
}
