import {DefectDataItem} from "../../types/DefectsDataItem";
import {StatTile} from "../../types/StatTile";

export class AverageDefectsPerVesselAcrossFleetTile implements StatTile {

    defectsData : DefectDataItem[]

    constructor(defectsData: DefectDataItem[]) {
        this.defectsData = defectsData;
    }

    getTileData() {
        const defectsPerVessel = new Map();
        this.defectsData.forEach((item) => {
            const key = item.vessel.imo;
            const value = defectsPerVessel.get(key);
            if (!value) {
                defectsPerVessel.set(key, 1);
            } else {
                defectsPerVessel.set(key, value + 1);
            }
        });

        let sum = 0;
        defectsPerVessel.forEach(value => sum += value);
        let averageDefectsPerVessel = sum / defectsPerVessel.size

        if (averageDefectsPerVessel) {
            return {
                data: averageDefectsPerVessel,
                tileKey: "averageDefectsPerVesselAcrossFleet",
                statistic: Math.round(averageDefectsPerVessel),
                details: "ACROSS ENTIRE FLEET",
                icon: "average-fleet-defects-icon"
            };
        }

        return null;
    }
}
