import { AssetFile } from "../types/AssetFile";

export const mapSearchResponseToSignedUrls = async (searchResponse: any, signedUrlsResponse: any,signal : AbortSignal) => {
    const assetFiles: AssetFile[] = [];

    if (signedUrlsResponse?.length) {
        for (const signedUrlResponse of signedUrlsResponse) {
            const [assetId, signedUrl] = Object.entries(signedUrlResponse)[0] as [string, string];
    
            const blob = await fetch(signedUrl, {signal}).then((r) => r.blob());
    
            const objectURL = window.URL.createObjectURL(blob);

            const matchingSearchResponse = 
                searchResponse.find((response: any) => response.asset_id === assetId);
    
            assetFiles.push({
                ...blob,
                objectURL,
                name: matchingSearchResponse.name,
                type: matchingSearchResponse.content_type,
                metadata: { assetId }
            } as unknown as AssetFile)
        }
    }

    return assetFiles;
}

export function downloadFile(url: string, filename: string) {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(error => console.error('Error downloading file:', error));
}
