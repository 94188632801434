import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { idwalGradeBody, vesselTypeBodyTemplate } from "../Shared/BodyTemplates";
import { inspectionDateTemplate } from "./BodyTemplates";
import { SubgradeTableFields } from "./SubgradeTableFields";
import { gradeFilterTemplate, inspectionDateFilterTemplate, shipTypeFilterTemplate, technicalManagerFilterTemplate } from "../Shared/FilterTemplates";

export const keywordSearchFilterFields = [
    SubgradeTableFields.NAME_FILTER_FIELD,
    SubgradeTableFields.TYPE,
    SubgradeTableFields.IDWAL_GRADE,
    SubgradeTableFields.TECHNICAL_MANAGER,
    SubgradeTableFields.CONDITION_SCORE,
    SubgradeTableFields.MANAGEMENT_SCORE,
    SubgradeTableFields.LOCATION_FILTER_FIELD,
    SubgradeTableFields.SUBGRADES.DESIGN_AND_CONSTRUCTION,
    SubgradeTableFields.SUBGRADES.HULL,
    SubgradeTableFields.SUBGRADES.MOORING_DECKS,
    SubgradeTableFields.SUBGRADES.WEATHER_DECKS_AND_FITTINGS,
    SubgradeTableFields.SUBGRADES.BALLAST_TANK_AND_SYSTEMS,
    SubgradeTableFields.SUBGRADES.ACCOMMODATION,
    SubgradeTableFields.SUBGRADES.BRIDGE_AND_NAVIGATION_EQUIPMENT,
    SubgradeTableFields.SUBGRADES.ENGINE_ROOM_AND_MACHINERY,
    SubgradeTableFields.SUBGRADES.FIRE_FIGHTING_EQUIPMENT_AND_SYSTEMS,
    SubgradeTableFields.SUBGRADES.LIFESAVING_APPLIANCES,
    SubgradeTableFields.SUBGRADES.SAFE_WORKING_ENVIRONMENT,
    SubgradeTableFields.SUBGRADES.POLLUTION_CONTROL,
    SubgradeTableFields.SUBGRADES.ONBOARD_MANAGEMENT,
    SubgradeTableFields.SUBGRADES.VESSEL_CAPABILITIES_AND_CARGO_SYSTEMS,
    SubgradeTableFields.SUBGRADES.FORTHCOMING_REGULATORY_COMPLIANCE,
    SubgradeTableFields.SUBGRADES.CREW_WELFARE,
    SubgradeTableFields.SUBGRADES.CREW_PERFORMANCE,
    SubgradeTableFields.SUBGRADES.SAFETY_MANAGEMENT,
    SubgradeTableFields.SUBGRADES.PLANNED_MAINTENANCE_SYSTEM,
    SubgradeTableFields.SUBGRADES.CLASSIFICATION_AND_CERTIFICATION,
    SubgradeTableFields.SUBGRADES.PSC_PERFORMANCE,
];

export const columnTemplates = (aiGradingActive: boolean) => {
    return [
        {column: SubgradeTableFields.TYPE, bodyTemplate: vesselTypeBodyTemplate },
        {column: SubgradeTableFields.IDWAL_GRADE, bodyTemplate: (e: any) => idwalGradeBody(e, aiGradingActive) },
        {column: SubgradeTableFields.INSPECTION_DATE, bodyTemplate: inspectionDateTemplate }
    ]
}

export const filterTemplates = (shipTypes: string[], technicalManagers: string[]) => {
    return [
        { column: SubgradeTableFields.TYPE, filterTemplate: (options: ColumnFilterElementTemplateOptions) => shipTypeFilterTemplate(options, shipTypes) },
        { column: SubgradeTableFields.TECHNICAL_MANAGER, filterTemplate: (options: ColumnFilterElementTemplateOptions) => technicalManagerFilterTemplate(options, technicalManagers) },
        { column: SubgradeTableFields.IDWAL_GRADE, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.INSPECTION_DATE, filterTemplate: inspectionDateFilterTemplate },
        { column: SubgradeTableFields.CONDITION_SCORE, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.MANAGEMENT_SCORE, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.BRIDGE_AND_NAVIGATION_EQUIPMENT, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.ACCOMMODATION, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.LIFESAVING_APPLIANCES, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.MOORING_DECKS, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.ENGINE_ROOM_AND_MACHINERY, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.VESSEL_CAPABILITIES_AND_CARGO_SYSTEMS, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.BALLAST_TANK_AND_SYSTEMS, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.WEATHER_DECKS_AND_FITTINGS, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.HULL, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.POLLUTION_CONTROL, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.ONBOARD_MANAGEMENT, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.SAFE_WORKING_ENVIRONMENT, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.FORTHCOMING_REGULATORY_COMPLIANCE, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.CREW_WELFARE, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.CREW_PERFORMANCE, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.SAFETY_MANAGEMENT, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.PLANNED_MAINTENANCE_SYSTEM, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.CLASSIFICATION_AND_CERTIFICATION, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.PSC_PERFORMANCE, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.FIRE_FIGHTING_EQUIPMENT_AND_SYSTEMS, filterTemplate: gradeFilterTemplate },
        { column: SubgradeTableFields.SUBGRADES.DESIGN_AND_CONSTRUCTION, filterTemplate: gradeFilterTemplate }
    ];
}
