import {FleetGraphDataItem} from "../../types/FleetGraphDataItem";
import {FleetModeGraph} from "../../types/FleetGraphMode";

export const getMinGrade = (x : FleetGraphDataItem, mode : FleetModeGraph) : number => {
    switch (mode) {
        case 'condition':
            return x.condition_score!
        case 'management':
            return x.management_score!
        default:
            return x.grade ?? x.grade_min!
    }
}

export const getMaxGrade = (x : FleetGraphDataItem, mode : FleetModeGraph) : number => {
    switch (mode) {
        case 'condition':
            return x.condition_score!
        case 'management':
            return x.management_score!
        default:
            return x.grade ?? x.grade_max!
    }
}

export const getGrade = (x: FleetGraphDataItem, mode: FleetModeGraph) : number => {
    switch (mode) {
        case 'condition':
            return x.condition_score!
        case 'management':
            return x.management_score!
        default:
            return x.grade && x.grade > 0 ? x.grade : (x.grade_min! + x.grade_max!)/2
    }
}


export const hasGrade = (x: FleetGraphDataItem, mode: FleetModeGraph, aiGradeActive: boolean): boolean | null | 0 | undefined => {
    switch (mode) {
        case 'condition':
            return x.condition_score && !isNaN(x.condition_score)
        case 'management':
            return x.management_score && !isNaN(x.management_score)
        default: {
            if (aiGradeActive && !x.grade
                && (!isNaN(x.grade_min!) && !isNaN(x.grade_max!))) {
                return true;
            }

            return x.grade && !isNaN(x.grade)
        }
    }
}

export const calculateAverage = (array: number[]) => {
    if (!array.length) return 0;

    let total = 0;
    let count = 0;

    array.forEach(function(item) {
        total += item;
        count++;
    });

    return total / count;
}