import { Skeleton } from "primereact/skeleton";
import { ReactElement } from "react";

export const LoadingStatTiles = ():ReactElement => {
    const loadingTile = <Skeleton width="100%" height="170px"/>;
    return (
        <div className="col-12">
            <div className="grid">
                <div className="col-12 md:col-6 lg:col-3">
                    {loadingTile}
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    {loadingTile}
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    {loadingTile}
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    {loadingTile}
                </div>
            </div>
        </div>
    )
}