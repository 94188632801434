import { sendGAEvent } from "../../AnalyticsService";
import { ADVANCED_FILTER_CATEGORY, ADVANCED_FILTER_SELECTION_CHANGED } from "../../constants/analytics";
import { BuildSingleSelectFilterSectionProps } from "../../types/BuildFilterSectionProps";
import { FilterSection, FilterType, SingleSelectItem } from "../../types/FilterDialog";
import { generateNewSingleSelectState, sectionIsSelected } from "./helpers";

export const buildSingleSelectSection: (props: BuildSingleSelectFilterSectionProps) => FilterSection = ({
    selectedSection,
    selectedFilterScope,
    sectionName,
    filterScope,
    gaForegroundTag,
    gaBackgroundFleetTag,
    gaBackgroundMarketTag,
    foregroundOptions,
    backgroundMarketOptions,
    backgroundFleetOptions,
    foregroundSelectionState,
    backgroundMarketSelectionState,
    backgroundFleetSelectionState,
    foregroundCount,
    backgroundMarketCount,
    backgroundFleetCount,
    onSelectSection,
    onUpdateForegroundSelection,
    onUpdateBackgroundFleetSelection,
    onUpdateBackgroundMarketSelection,
    backgroundFleetDataMessage,
    backgroundMarketDataMessage
}: BuildSingleSelectFilterSectionProps) => {
    const selectedItem = foregroundSelectionState && foregroundSelectionState.length > 0 
        ? foregroundSelectionState.find((item: SingleSelectItem) => item.checked) 
        : foregroundOptions.find((item: SingleSelectItem) => item.checked);

    return {
        onClick: () => {
            sendGAEvent(ADVANCED_FILTER_CATEGORY, ADVANCED_FILTER_SELECTION_CHANGED);
            onSelectSection(sectionName);
        },
        type: FilterType.SingleSelect,
        selected: sectionIsSelected(sectionName, selectedSection),
        name: sectionName,
        filterScope,
        gaForegroundTag,
        gaBackgroundFleetTag,
        gaBackgroundMarketTag,
        fleetFilterState: {
            items: foregroundOptions,
            selectedItemKey: selectedItem?.key ?? foregroundOptions.find(e => e.default)?.key ?? 0,
            onUpdateSelection: (e: any) => {
                sendGAEvent(ADVANCED_FILTER_CATEGORY, gaForegroundTag);
                const newState = generateNewSingleSelectState(
                    e.value,
                    foregroundOptions
                );
                onUpdateForegroundSelection(newState);
            }
        },
        backgroundFleetFilterState: {
            items: [],
            selectedItemKey: 0,
            onUpdateSelection: () => {}
        },
        backgroundMarketFilterState: {
            items: [],
            selectedItemKey: 0,
            onUpdateSelection: () => {}
        },
        selectedFleetItemCount: foregroundCount,
        selectedBackgroundFleetDataItemCount: backgroundMarketCount,
        selectedBackgroundMarketDataItemCount: backgroundFleetCount,
        backgroundFleetMessage: backgroundFleetDataMessage,
        backgroundMarketMessage: backgroundMarketDataMessage
    }
};