import { DefectSummaryDataItem } from "../../types/DefectSummaryDataItem";
import LOCAL_TABLE_CONFIG_KEY from "../../types/TableLocalConfigKeys";
import { DefectTabs } from "../../types/DefectTabs";
import { DefectPriority } from "../../types/DefectPriority";
import { sendGAEvent } from "../../AnalyticsService";
import { 
    DEFECTS_SUMMARY,
    DEFECTS_SUMMARY_HIGH_PRIORITY_CLICK,
    DEFECTS_SUMMARY_MEDIUM_PRIORITY_CLICK,
    DEFECTS_SUMMARY_LOW_PRIORITY_CLICK,
 } from "../../constants/analytics";
import { defaultDefectsFiltersConfig } from "../DefectsTable/Filters/FilterTemplates";
import { FilterMatchMode } from "primereact/api";
import { DefectTableFields } from "../DefectsTable/DefectTableFields";
import { classNames } from "primereact/utils";
import { useAppDispatch } from "../../hooks/storeHooks";
import { setActiveTab } from "../../store/slices/defectSlice";
import { resetTableFilters } from "../../store/thunks/filtersThunks";

type DefectSummaryPriorityLinkProps = {
    rowData: DefectSummaryDataItem;
    priority: DefectPriority;
}

export const DefectSummaryPriorityLink = (props: DefectSummaryPriorityLinkProps) => {
    const { rowData, priority } = props;

    const dispatch = useAppDispatch();

    let value = rowData.highPriorityCount;
    let icon = "/high.svg";
    let gaEvent = DEFECTS_SUMMARY_HIGH_PRIORITY_CLICK;

    if (priority === DefectPriority.medium) {
        value = rowData.mediumPriorityCount;
        icon = "/medium.svg";
        gaEvent = DEFECTS_SUMMARY_MEDIUM_PRIORITY_CLICK;
    }

    if (priority === DefectPriority.low) {
        value = rowData.lowPriorityCount;
        icon = "/low.svg";
        gaEvent = DEFECTS_SUMMARY_LOW_PRIORITY_CLICK;
    }

    const navigateToDefectsTab = () => {
        if (value) {
            dispatch(resetTableFilters(LOCAL_TABLE_CONFIG_KEY.DEFECTS, { 
                ...defaultDefectsFiltersConfig,
                [DefectTableFields.NAME_FILTER_FIELD]: { value: rowData.vesselName },
                [DefectTableFields.STATUS]: { value: [], matchMode: FilterMatchMode.IN },
                [DefectTableFields.PRIORITY]: { value: [priority], matchMode: FilterMatchMode.IN },
            }));
            dispatch(setActiveTab(DefectTabs.defects));
            sendGAEvent(DEFECTS_SUMMARY, gaEvent);
        }
    }

    return (
        <button 
            className={classNames("font-semibold defect-priority-link-btn", value ? "cursor-pointer read-more-btn underline" : null)}
            onClick={navigateToDefectsTab}
        >
            <div className="mr-3">
                <img src={icon} className="priority-icon-size-small" alt={`priority-icon-${priority}`} />
            </div>
            {value}
        </button>
    )
};
