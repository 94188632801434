import { AppThunk } from "../store";
import { setData, setError, setFilteredDefects, setLoading, updateData } from "../slices/defectSlice";
import { DefectStatus } from "../../types/DefectStatus";
import { getDefects } from "../../services/DefectService";
import { createRectification } from "./rectificationThunks";
import { selectDefects, selectFilteredDefects } from "../selectors/defectSelectors";
import { getDefectRefectificationPlan } from "../../services/DefectRefectificationService";
import { selectInspectionAndGradeData } from "../selectors/fleetSelectors";
import { selectSelectedGroupName } from "../selectors/userSelectors";
import { setGlobalFiltersLoading } from "../slices/filtersSlice";
import { getDefectsForGroup } from "../../utils/GroupDropDownHelper";
import { constructDefectData } from "../../utils/DefectsTableDataHelper";
import { applyGlobalFilter } from "../../components/Shared/GlobalFilter";
import { selectGlobalFilters } from "../selectors/filtersSelectors";

export const fetchDefects = (): AppThunk<Promise<boolean>> => async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(false));

    try {
        const response = await getDefects();

        if (response?.defects?.length >= 0) {
            dispatch(setData(response.defects));
        } else {
            throw Error('no data');
        }
    } catch (e) {
        console.error(e);
        dispatch(setError(true));
    } finally {
        dispatch(setLoading(false));
    }

    return true;
};

export const updateDefectItem = (defectId: number, columnName: string, value: unknown): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
    const filteredDefects = selectFilteredDefects(getState());

    const matchingDefect = filteredDefects.find((filteredDefect) => filteredDefect.defectId === defectId);

    if (matchingDefect) {
        dispatch(setLoading(true));

        const updatedDefect = { ...matchingDefect, [columnName]: value };

        if (columnName === 'status' && value === DefectStatus.rectified) {
            if (updatedDefect.rectificationId) {
                const matchingRectification = await getDefectRefectificationPlan(updatedDefect.inspectionId, updatedDefect.defectId);

                if (!matchingRectification.resolutionDate) {
                    const formData = {
                        resolutionDate: new Date(),
                    }

                    await dispatch(createRectification(formData, updatedDefect));   
                }
            } else {
                const formData = {
                    actionNotes: "",
                    resolutionDate: new Date(),
                    actualCost: null,
                    costArea: null,
                    defectStatus: updatedDefect.status,
                }
    
                await dispatch(createRectification(formData, updatedDefect));
            }
        }
        
        dispatch(updateData([updatedDefect]));

        dispatch(setLoading(false));

        return true;
    }

    return false
}

export const setupFilteredDefects = (initialSetup?: boolean): AppThunk => (dispatch, getState) => {
    const state = getState();
    const defects = selectDefects(state);
    const inspectionAndGradeData = selectInspectionAndGradeData(state);
    const selectedGroupName = selectSelectedGroupName(state);
    const globalFilters = selectGlobalFilters(state);

    if (inspectionAndGradeData) {
        dispatch(setGlobalFiltersLoading(true));
        let data = getDefectsForGroup(selectedGroupName, defects, inspectionAndGradeData);
        data = constructDefectData(data, inspectionAndGradeData.fleetDataItems);
        data = data.filter(item => applyGlobalFilter(globalFilters, item.vessel.vesselName, item.vessel.type, item.technicalManager, item.vessel.shipAge));

        if (initialSetup) {
            dispatch(setData(data));
        }

        dispatch(setFilteredDefects(data));
        dispatch(setGlobalFiltersLoading(false));
    }
}