import {FleetTableFields} from "../components/FleetTable/FleetTableFields";
import {SubgradeTableFields} from "../components/SubGrades/SubgradeTableFields";
import LOCAL_TABLE_CONFIG_KEY from "../types/TableLocalConfigKeys";
import {TableConfig} from "../types/TableStates";


const fleetSessionStorageFilters = [
    FleetTableFields.NAME_FILTER_FIELD,
    FleetTableFields.VESSEL_NAME,
    FleetTableFields.GRADE_FILTER_FIELD,
    FleetTableFields.TRENDING,
    FleetTableFields.VESSEL_INSPECTION_STATUS,
    FleetTableFields.VESSEL_TECHNICAL_MANAGER,
    FleetTableFields.DATE_SINCE_FILTER_FIELD,
    FleetTableFields.VESSEL_TYPE,
    FleetTableFields.VESSEL_NOTES,
    FleetTableFields.VESSEL_CLASS_SOCIETY,
    FleetTableFields.VESSEL_FLAG,
    FleetTableFields.VESSEL_DWT,
    FleetTableFields.VESSEL_BUILD_YEAR,
    FleetTableFields.VESSEL_GRADES_PREDICTED_GRADE_MIN,
    FleetTableFields.VESSEL_GRADES_PREDICTED_GRADE_MAX,
    FleetTableFields.VESSEL_GRADES_AI_GRADE_FILTER_FIELD,
    FleetTableFields.INSPECTION_LOCATION_FILTER_FIELD,
    FleetTableFields.INSPECTION_CONDITION_SCORE,
    FleetTableFields.INSPECTION_MANAGEMENT_SCORE,
    FleetTableFields.VESSEL_COUNTRY_OF_BUILD,
    FleetTableFields.VESSEL_SPECIAL_SURVEY_DATE,
    FleetTableFields.VESSEL_SPECIAL_SURVEY_DATE_FILTER_FIELD,
    FleetTableFields.RISK_LEVEL,
    FleetTableFields.LAST_INSPECTED
]

const subGradeSessionStorageFilters = [
    SubgradeTableFields.NAME_FILTER_FIELD,
    SubgradeTableFields.LOCATION_FILTER_FIELD,
    SubgradeTableFields.TECHNICAL_MANAGER,
    SubgradeTableFields.INSPECTION_DATE,
    SubgradeTableFields.CONDITION_SCORE,
    SubgradeTableFields.MANAGEMENT_SCORE,
    SubgradeTableFields.SUBGRADES.DESIGN_AND_CONSTRUCTION,
    SubgradeTableFields.SUBGRADES.HULL,
    SubgradeTableFields.SUBGRADES.MOORING_DECKS,
    SubgradeTableFields.SUBGRADES.WEATHER_DECKS_AND_FITTINGS,
    SubgradeTableFields.SUBGRADES.BALLAST_TANK_AND_SYSTEMS,
    SubgradeTableFields.SUBGRADES.ACCOMMODATION,
    SubgradeTableFields.SUBGRADES.BRIDGE_AND_NAVIGATION_EQUIPMENT,
    SubgradeTableFields.SUBGRADES.ENGINE_ROOM_AND_MACHINERY,
    SubgradeTableFields.SUBGRADES.FIRE_FIGHTING_EQUIPMENT_AND_SYSTEMS,
    SubgradeTableFields.SUBGRADES.LIFESAVING_APPLIANCES,
    SubgradeTableFields.SUBGRADES.SAFE_WORKING_ENVIRONMENT,
    SubgradeTableFields.SUBGRADES.POLLUTION_CONTROL,
    SubgradeTableFields.SUBGRADES.ONBOARD_MANAGEMENT,
    SubgradeTableFields.SUBGRADES.VESSEL_CAPABILITIES_AND_CARGO_SYSTEMS,
    SubgradeTableFields.SUBGRADES.FORTHCOMING_REGULATORY_COMPLIANCE,
    SubgradeTableFields.SUBGRADES.CREW_PERFORMANCE,
    SubgradeTableFields.SUBGRADES.SAFETY_MANAGEMENT,
    SubgradeTableFields.SUBGRADES.PLANNED_MAINTENANCE_SYSTEM,
    SubgradeTableFields.SUBGRADES.CLASSIFICATION_AND_CERTIFICATION,
    SubgradeTableFields.SUBGRADES.PSC_PERFORMANCE
]

interface SessionFilterDictionary {
    [key: string]: string[]
}

export const sessionFilterDictionary: SessionFilterDictionary = {
    [LOCAL_TABLE_CONFIG_KEY.FLEET]: fleetSessionStorageFilters,
    [LOCAL_TABLE_CONFIG_KEY.SUBGRADE]: subGradeSessionStorageFilters
}

export const sessionFilterDictionaryHasKey = (key: string) => {
    return key in sessionFilterDictionary;
}


export const resetSessionStorage = () => {
    const sessionKeys = Object.keys(sessionFilterDictionary);
    sessionKeys.forEach((key: string) => {
        sessionStorage.removeItem(key);

    })
}


export function appendSessionState(localFiltersConfig: TableConfig, key: string) {
    try {
        const sessionTableConfig = sessionStorage.getItem(key);
        if (sessionTableConfig) {
            const sessionFiltersConfig = JSON.parse(sessionTableConfig);
            localFiltersConfig.filters = {...localFiltersConfig.filters, ...sessionFiltersConfig.filters};
            if(sessionTableConfig.includes("columnWidths")){
                localFiltersConfig.columnWidths = sessionFiltersConfig.columnWidths;
            }
        }
    } catch (error) {
        console.error(error);
        console.info("failed to append " + key + " session state");
    }
}

export const onSessionSaveState = (state: any, key: string) => {
    if(!state){
        return false;
    }

    const sessionTableConfig = sessionStorage.getItem(key);
    const parsedOldState = sessionTableConfig ? JSON.parse(sessionTableConfig) : {};

    if (JSON.stringify(parsedOldState) === JSON.stringify(state)) {
        return false;
    }
    sessionStorage.setItem(key, JSON.stringify(state));
}



export const getTableConfigs = (tableConfig: any, storageKey: string) => {

    if(!sessionFilterDictionaryHasKey(storageKey)){
        return {
            cloudTableConfig : tableConfig,
            sessionTableConfig : null
        };
    }

    const sessionStorageFilters = sessionFilterDictionary[storageKey];

    const sessionFilters = sessionStorageFilters.reduce((filters, key) => {
        if (key in tableConfig.filters) {
            // @ts-ignore
            filters[key] = tableConfig.filters[key];
        }
        return filters;
    }, {});

    const cloudFilters = Object.keys(tableConfig.filters).reduce((filters, key) => {
        if (!sessionStorageFilters.includes(key)) {
            // @ts-ignore
            filters[key] = tableConfig.filters[key];
        }
        return filters;
    }, {});

    const sessionTableConfig = {
        ...tableConfig,
        filters: sessionFilters,
    };

    const cloudTableConfig = {
        ...tableConfig,
        filters: cloudFilters,
        columnWidths: ""
    };

    return {
        cloudTableConfig,
        sessionTableConfig
    };
};
