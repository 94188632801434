import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { getTranslationObject } from "../../../services/Translation";
import { FILTER_MAX_MULTISELECT_LABELS_TO_SHOW } from "../../../utils/Constants";

const t = getTranslationObject();

/**
 * Filters that gets defects by if they have rectify details or not
 * @param options 
 * @returns 
 */
export const rectificationFilter = (options: any) => {
  const filterOptions = [ 
    { value: false, label: t("defectsTable.defectRectificationBlank") },
    { value: true, label: t("defectsTable.defectRectificationProvided") }
  ]

  return (
    <MultiSelect
      data-cy="rectification-filter-input"
      value={options.value}
      options={filterOptions}
      onChange={(e: MultiSelectChangeEvent) => options.filterApplyCallback(e.value)}
      placeholder={t("defectsTable.showAll")}
      className="p-column-filter"
      maxSelectedLabels={FILTER_MAX_MULTISELECT_LABELS_TO_SHOW}
      showClear
    />
  );
}
