import axios from "axios";
import { getCurrentSessionIdToken } from "../queries/AuthToken";

const updateDefectRequest = async (endpoint: string, bodyContent: any): Promise<any> => {
  const url: string = process.env.REACT_APP_IDWAL_API_ENDPOINT ?? "";
  const token = await getCurrentSessionIdToken();

  return axios
    .put(url + endpoint, 
        bodyContent,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        }
    )
    .then((result) => {
        return result
    });
};

export default updateDefectRequest;

