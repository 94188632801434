import { defaultDefectsFiltersConfig } from "../components/DefectsTable/Filters/FilterTemplates";
import { defaultFleetFiltersConfig } from "../components/FleetTable/FilterTemplates";
import LOCAL_TABLE_CONFIG_KEY from "../types/TableLocalConfigKeys";
import { defaultSubgradeFiltersConfig } from "../components/SubGrades/FilterTemplates";
import { SharedTableColumnConfig } from "../types/SharedTableColumnConfig";
import { defaultDefectsSummaryFilterConfig } from "../components/DefectsSummary/DefectsSummaryConfig";
import { UserDetails } from "../types/UserDetails";
import { cloneDeep } from "lodash";
import {appendSessionState} from "./SessionStorage";
import {TableConfig} from "../types/TableStates";

const USE_CLOUD_STORAGE = process.env.REACT_APP_USE_CLOUD_STORAGE === "1";

export const onCustomSaveState = (state: any, key: string) => {
    const localTableConfig = localStorage.getItem(key);
    const parsedOldState = localTableConfig ? JSON.parse(localTableConfig) : {};

    // Are states different?
    if (JSON.stringify(parsedOldState) === JSON.stringify(state)) {
        return;
    }

    localStorage.setItem(key, JSON.stringify(state));
}

export function onCustomRestoreState(
    key: string, keywordSearch: string, userDetails?: UserDetails, stateTableKey?: string) {
    const defaultTableConfig = {
        filters: {},
        multiSortMeta: [],
        sortField: key === LOCAL_TABLE_CONFIG_KEY.FLEET ? "rank" : null,
        first: 0,
        rows: 10
    };

    switch (key) {
        case LOCAL_TABLE_CONFIG_KEY.DEFECTS:
            defaultTableConfig.filters = defaultDefectsFiltersConfig;
            break;
        case LOCAL_TABLE_CONFIG_KEY.FLEET:
            defaultTableConfig.filters = defaultFleetFiltersConfig;
            break;
        case LOCAL_TABLE_CONFIG_KEY.SUBGRADE:
            defaultTableConfig.filters = defaultSubgradeFiltersConfig;
            break;
        case LOCAL_TABLE_CONFIG_KEY.DEFECTS_SUMMARY:
            defaultTableConfig.filters = defaultDefectsSummaryFilterConfig;
            break;
    }

    let localFiltersConfig;

    if (USE_CLOUD_STORAGE) {

        if (stateTableKey) {
            const storedTableConfig = cloneDeep(userDetails?.appState?.[stateTableKey]?.state);
            if (!storedTableConfig || Object.keys(storedTableConfig).length === 0) {
                localFiltersConfig = defaultTableConfig;
            } else {
                localFiltersConfig = storedTableConfig;
            }
        } else {
            localFiltersConfig = defaultTableConfig;
        }

        appendSessionState(localFiltersConfig as TableConfig , key)

    } else {
        const localTableConfig = localStorage.getItem(key);
        if (!localTableConfig) {
            localFiltersConfig = defaultTableConfig;
        } else {
            localFiltersConfig = JSON.parse(localTableConfig);
        }
    }

    if (localFiltersConfig.filters && 'global' in localFiltersConfig.filters && 'value' in localFiltersConfig.filters.global) {
        //Add any missing filters to the user's saved local storage filters
        for (const filterField in defaultTableConfig.filters) {
            if (!localFiltersConfig.filters[filterField]) {
                localFiltersConfig.filters[filterField] = (defaultTableConfig.filters as any)[filterField];
            }
        }

        //Remove any filters that are not present in default filters
        for (const filterField in localFiltersConfig.filters) {
            if (!(defaultTableConfig as any).filters[filterField]) {
                delete localFiltersConfig.filters[filterField];
            }
        }

        return {
            ...localFiltersConfig,
            filters: {
                ...localFiltersConfig.filters,
                global: {
                    ...localFiltersConfig.filters.global,
                    value: keywordSearch
                }
            }
        }
    }

    return defaultTableConfig;
}

export function getLocalStorageOrderedColumns(key: string, columns: SharedTableColumnConfig[]) : SharedTableColumnConfig[] | null  {
    const localOrderedColumnsConfig = localStorage.getItem(key);
    if (!localOrderedColumnsConfig) return null;

    const storedColumns = JSON.parse(localOrderedColumnsConfig) as SharedTableColumnConfig[]

    for(const column of columns)
    {
        if (!storedColumns.find(x => x.filterField == column.filterField))
        {
            //If a column is missing, add it to the end of the ordered config
            storedColumns.push(column);
        }
    }
    return storedColumns;
}

export function addEllipsis(text: string, maxLength: number): string {
    if (text.length <= maxLength) {
        return text;
    }

    let lastSpaceIndex = text.lastIndexOf(' ', maxLength);

    if (lastSpaceIndex === -1) {
        return text.substring(0, maxLength) + '...';
    }

    return text.substring(0, lastSpaceIndex) + '...';
}