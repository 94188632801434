import "./RangeFilter.css";
import { Slider } from "primereact/slider";
import { RangeFilterProps } from "../../types/RangeFilterprops";
import { InputNumber } from "primereact/inputnumber";

export const RangeFilter = ({
    title,
    subtitle,
    seperatorText,
    step,
    range,
    min,
    max,
    onUpdateRange
}: RangeFilterProps) => {
    return (
        <>
            <div className={"advanced-filter-range"}> {title} </div>
            <div className={"advanced-filter-range-text"}> {subtitle} </div>
            <Slider
                className={"advanced-filter-range-slider"}
                step={step}
                value={range}
                min={min}
                max={max}
                onChange={(e) => onUpdateRange(e.value as [number, number])}
                range
            />
            <div className={"advanced-filter-range-input-container"}>
                <div className="advanced-filter-range-number-box-wrapper">
                    <InputNumber 
                        value={range[0]}
                        onValueChange={
                            (e) => {
                                let newValue = e.target.value;

                                if (newValue === 0) {
                                    newValue = min;
                                }

                                if (newValue === undefined || newValue === null || newValue > range[1]) {
                                    newValue = range[1] - step;
                                }

                                if (newValue < min) {
                                    newValue = min;
                                }

                                onUpdateRange([newValue, range[1]]);
                            }
                        }
                        max={range[1] < max ? range[1] - step : max - step}
                        min={min}
                    />
                </div>
                <div className={"advanced-filter-range-seperator-text"}>{seperatorText}</div>
                <div className="advanced-filter-range-number-box-wrapper">
                    <InputNumber 
                        value={range[1]}
                        onValueChange={
                            (e) => {
                                let newValue = e.target.value;
                                if (newValue === 0) {
                                    newValue = max;
                                }

                                if (newValue === undefined || newValue === null || newValue < range[0]) {
                                    newValue = range[0] + step;
                                }

                                if (newValue > max) {
                                    newValue = max
                                }

                                onUpdateRange([range[0], newValue]);
                            }
                        }
                        max={max}
                        min={range[0] > min ? range[0] + step : min + step}
                    />
                </div>
            </div>
        </>
    )
}

