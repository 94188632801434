import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { DefectDataItem } from "../../types/DefectsDataItem";
import { Tooltip } from "primereact/tooltip";
import { useNavigate } from "react-router-dom";
import { navigateToRectificationView } from "../../utils/DefectRectificationHelpers";
import { DEFECTS, RECTIFICATIONS } from "../../types/RouteNames";
import { useAppSelector } from "../../hooks/storeHooks";
import { selectSharedDefects } from "../../store/selectors/sharedDefectsSelectors";
import { selectFilteredDefects } from "../../store/selectors/defectSelectors";

/**
 * Add or edit defect rectification button
 * @param data
 * @param isSharedDefect
 * @returns
 */
export const DefectRectificationButton = ( data: DefectDataItem, isSharedDefect: boolean) => {
    const { t } = useTranslation("locale");
    const navigate = useNavigate();
    const filteredDefects = useAppSelector(selectFilteredDefects);
    const { data: sharedDefects } = useAppSelector(selectSharedDefects);

    /**
     * Needs to open window in "add" or "edit" mode
     */
    const openRectificationWindow = () => {
        if (isSharedDefect) {
            navigateToRectificationView(sharedDefects, data.defectId, navigate, RECTIFICATIONS);
        } else {
            navigateToRectificationView(filteredDefects, data.defectId, navigate, DEFECTS);
        }
    }

    /**
     * Returns the button for add or edit mode dependent on if rectificationId
     * @returns 
     */
    const getRectificationButton = () => {
           return (
            <>
                <Tooltip
                    target={
                        data.rectificationId
                            ? `.defect-rectify-plan-${data.defectId}`
                            : `.defect-blank-rectify-plan-${data.defectId}`
                    }
                    position="left"
                />
                <Button
                    onClick={openRectificationWindow}
                    className={`p-button-rounded p-button-text defect-rectification-icon ${data.rectificationId
                            ? `defect-rectify-plan-${data.defectId}`
                            : `defect-blank-rectify-plan-${data.defectId}`
                        }`}
                    icon={
                        data.rectificationId
                            ? "pi pi-pencil"
                            : "pi pi-plus"
                    }
                    data-pr-tooltip={
                        data.rectificationId
                            ? t("defectsTable.editRectificationTableButton")
                            : t("defectsTable.addRectificationTableButton")
                    }
                ></Button>
            </>
        );
    }
    return (
            <React.Fragment>
                {getRectificationButton()}
            </React.Fragment>
        );
};
