import { GroupedItem } from "../types/GroupedItem";

export function groupByNumberRange<T>(
    objects: Array<T>, 
    stepAmount: number,
    extractGroupByProperty: (arg: T) => number,
    splitFunction: (arg: string) => Array<string>
): Array<GroupedItem<T>>  {
    const groupedObjects = objects.reduce((groups: { [key: string]: Array<T> }, dataItem) => {
      const extractedProperty = extractGroupByProperty(dataItem);
      const groupStart = Math.floor(extractedProperty / stepAmount) * stepAmount;
      const groupEnd = groupStart + stepAmount;
      const group = `${groupStart}/${groupEnd}`;

      if (!groups[group]) {
        groups[group] = [];
      }

      if (extractedProperty !== 0 && extractedProperty === groupStart) {
        groups[`${groupStart-stepAmount}/${groupEnd-stepAmount}`].push(dataItem);
      }

      if (extractedProperty > groupStart && extractedProperty <= groupEnd) {
        groups[group].push(dataItem);
      }

      return groups;
    }, {});

    const result = Object.keys(groupedObjects).map(key => {
      return {
        range: key,
        items: groupedObjects[key],
      };
    });

    return result.sort((a, b) => parseInt(splitFunction(a.range)[0]) - parseInt(splitFunction(b.range)[0]))
}
  