import { VesselViewSidebar } from "../components/VesselView/VesselViewSidebar";
import { toVesselViewProps } from "../utils/toVesselViewProps";
import { useAppDispatch, useAppSelector } from "../hooks/storeHooks";
import { selectVesselView } from "../store/selectors/vesselViewSelectors";
import { setVesselViewOpen } from "../store/slices/vesselViewSlice";
import { setRequestInspectionDialog } from "../store/slices/fleetSlice";

export function VesselViewAdapter() {
    const dispatch = useAppDispatch();
    const vesselView = useAppSelector(selectVesselView);
    const { selectedVessel, vesselViewOpen} = vesselView;

    const vesselDetails = toVesselViewProps(vesselView);

    const closeVesselView = () => {
        dispatch(setVesselViewOpen(false));
    }

    const onRequestInspection = () => {
        if (selectedVessel) {
            dispatch(setRequestInspectionDialog({
                show: true,
                vessels: [selectedVessel],
                fromVesselView: true,
            }));
        }
    }

    return (
        <VesselViewSidebar 
            open={vesselViewOpen}
            vesselDetails={vesselDetails}
            onClose={closeVesselView}
            onRequestInspection={onRequestInspection}
        />
    )
}