export const FleetTableFields = {
    NAME_FILTER_FIELD: 'nameFilterField',
    VESSEL_NAME: "vessel.vesselName",
    INSPECTION_DATE_FILTER_FIELD: 'inspectionDateFilterField',
    DATE_SINCE_FILTER_FIELD: 'dateSinceFilterField',
    VESSEL_TYPE: 'vessel.type',
    VESSEL_SUBTYPE: 'vessel.subtype',
    VESSEL_TECHNICAL_MANAGER: 'vessel.technicalManager',
    INSPECTION_INSPECTION_DUE: 'inspection.inspectionDue',
    INSPECTION_DAYS_SINCE: 'inspection.daysSince',
    GRADE_FILTER_FIELD: 'gradeFilterField',
    RANK: 'rank',
    VESSEL_CLASS_SOCIETY: 'vessel.classSociety',
    VESSEL_FLAG: 'vessel.flag',
    VESSEL_DWT: 'vessel.dwt',
    VESSEL_BUILD_YEAR: 'vessel.buildYear',
    VESSEL_GRADES_PREDICTED_GRADE_MIN: 'aiGrades.predictedGradeMin',
    VESSEL_GRADES_PREDICTED_GRADE_MAX: 'aiGrades.predictedGradeMax',
    VESSEL_GRADES_AI_GRADE_FILTER_FIELD: 'aiGrades.aiGradeFilterField',
    INSPECTION_LOCATION_FILTER_FIELD: 'inspection.locationFilterField',
    INSPECTION_DATE_OF_INSPECTION_FILTER_FIELD: 'inspection.dateOfInspectionFilterField',
    INSPECTION_CONDITION_SCORE: 'inspection.conditionScore',
    INSPECTION_MANAGEMENT_SCORE: 'inspection.managementScore',
    VESSEL_COUNTRY_OF_BUILD: 'vessel.countryOfBuild',
    VESSEL_SPECIAL_SURVEY_DATE: 'vessel.specialSurveyDate',
    VESSEL_SPECIAL_SURVEY_DATE_FILTER_FIELD: 'specialSurveyDateFilterField',
    RISK_LEVEL: 'vessel.riskLevel',
    VESSEL_NOTES: 'vessel.notes',
    VESSEL_INSPECTION_STATUS:  'vesselLatestInspectionStatus',
    TRENDING: "trending",
    ACTIONS: "actions",
    LAST_INSPECTED: 'inspection.lastInspected'
};

export const FleetTableDefaultColumns = [
    FleetTableFields.GRADE_FILTER_FIELD,
    FleetTableFields.TRENDING,
    FleetTableFields.INSPECTION_INSPECTION_DUE,
    FleetTableFields.VESSEL_INSPECTION_STATUS,
    FleetTableFields.VESSEL_TECHNICAL_MANAGER,
    FleetTableFields.INSPECTION_DAYS_SINCE,
    FleetTableFields.VESSEL_TYPE,
    FleetTableFields.VESSEL_NOTES,
    FleetTableFields.LAST_INSPECTED
];