import { Dialog } from "primereact/dialog";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import "./ColumnReorderDialog.css";
import {AddNewColumn} from "../AddNewColumn/AddNewColumn";
import {AddNewColumnFunction} from "../../types/AddNewColumnFunction";

export interface AddNewColumnDialogMethods {
    showDialog: (show: boolean) => void;
}

export interface AddNewColumnDialogProps {
    columns: any;
    onAddColumn: AddNewColumnFunction;
}

export const AddNewColumnDialog = forwardRef<AddNewColumnDialogMethods, AddNewColumnDialogProps>((props, ref): any => {
    const {t} = useTranslation("locale");
    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => ({
        showDialog: (show: boolean) => {
            setVisible(show);
        }
    }));

    return (
        <Dialog id="reorder-dialog" data-cy="reorder-dialog" headerClassName="reorder-header" header={t("columnEdit.dialogHeader")} blockScroll={true} visible={visible} onHide={() => setVisible(false)} style={{ width: '40rem' }} draggable={false}>
            <AddNewColumn setDialogVisible={setVisible} columns={props.columns} onAddColumn={props.onAddColumn}/>
        </Dialog>
    )
});
