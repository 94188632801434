import "./ShowAllFilters.css";
import {Button} from "primereact/button";
import {useCapabilities} from "../../hooks/useCapabilities";
import {Capability} from "../../types/Capability";
import {ReactElement, useState} from "react";
import {getTotalFilterCount} from "../../utils/FilterInformation";
import {ShowAllFiltersDialogAdapter} from "../../componentAdapters/ShowAllFiltersDialogAdapter";
import {useTranslation} from "react-i18next";
import { useAppSelector } from "../../hooks/storeHooks";
import { selectTotalBackgroundFilterCount, selectTotalForegroundFilterCount } from "../../store/selectors/advancedFiltersSelectors";

export const ShowAllFiltersButton = (): ReactElement => {
    const {t} = useTranslation("locale");

    const totalForegroundFilterCount = useAppSelector(selectTotalForegroundFilterCount);
    const totalBackgroundFilterCount = useAppSelector(selectTotalBackgroundFilterCount);


    const [showAllVisible, setShowAllVisible] = useState<boolean>(false);

    const toggleShowAllFiltersModal = () => {
        setShowAllVisible(!showAllVisible);
    }

    const { capabilities } = useCapabilities([
        Capability.CAP_FLEETAPP_ADVANCED_BENCHMARKING
    ]);

    const totalFilterCount = getTotalFilterCount(
        totalForegroundFilterCount,
        totalBackgroundFilterCount
    );

    const hasAdvancedBenchmarkingCapability = capabilities.CAP_FLEETAPP_ADVANCED_BENCHMARKING;

    return (totalFilterCount > 0 && hasAdvancedBenchmarkingCapability) ?
        <>
            <ShowAllFiltersDialogAdapter
                visible={showAllVisible}
                setVisible={toggleShowAllFiltersModal}
            />
            <Button
                data-cy={`show-all-filters-button`}
                style={{width: "192px"}}
                className={`p-button p-component p-button-text clearButtonGreyBorder`}
                label={`${totalFilterCount} ${t("showAllFilters.filtersApplied")}`}
                onClick={toggleShowAllFiltersModal}
            />
        </>
    :
        <Button
            data-cy={`no-filters-button`}
            className={`no-filters-button`}
            label={`${t("showAllFilters.noFilters")}`}
        />
}