import Plot from "react-plotly.js"
import { useTranslation } from "react-i18next";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useMemo, useState } from "react";
import { Config, Layout, PlotData } from "plotly.js";
import { mapClosedRatioToPlotData } from "../../utils/DefectInsightsGraphHelper";
import { DefectInsightFilterBy } from "../../types/DefectInsightsData";
import "./DefectInsights.css";
import { useAppSelector } from "../../hooks/storeHooks";
import { selectDefectInsights } from "../../store/selectors/defectInsightsSelectors";

type ClosedRatioGraphProps = {
    filterBy: DefectInsightFilterBy;
    filterValues: string[] | undefined;
}

export const ClosedRatioGraph = (props: ClosedRatioGraphProps) => {
    const { filterBy, filterValues } = props;

    const { t } = useTranslation("locale");

    const { closedRatioData, closedRatioLoading } = useAppSelector(selectDefectInsights);
    
    const [data, setData] = useState<Partial<PlotData>[]>([]);

    useEffect(() => {
        if (!closedRatioLoading) {
            setData(mapClosedRatioToPlotData(closedRatioData, filterBy, filterValues));
        }
    }, [closedRatioData, filterBy, filterValues, closedRatioLoading]);

    const layout = useMemo(() => {
        const graphLayout: Partial<Layout> = {
            autosize: true,
            xaxis: {
                showgrid: false,
                zerolinecolor: "#B0E2E4",
                tickfont: {
                    color: "#777",
                    size: 13,
                },
                tickformat: "%b %y",
                title: t("defectInsights.monthAndYear"),
                titlefont: {
                    color: "#555",
                    size: 13,
                }
            },
            yaxis: {
                showgrid: true,
                automargin: true,
                categoryarray: filterValues?.length ? filterValues : ["All"],
                categoryorder: "array",
                ticksuffix: "%",
                rangemode: "tozero",
                tickmode: "array",
                tickvals: [0, 25, 50, 75, 100],
                gridcolor: "#B0E2E4",
                zerolinecolor: "#B0E2E4",
                tickfont: {
                    color: '#777',
                    size: 13,
                },
                title: t("defectInsights.closedDefects%"),
                titlefont: {
                    color: "#555",
                    size: 13,
                }
            },
            margin: {
                pad: 10,
                l: 60,
                t: 20,
                b: 60,
                r: 0,
            },
            font: {
                family: "Open Sans",
                color: "#555",
                size: 12,
            },
            showlegend: true,
            legend: {
                title: {
                    font: {
                        color: "#555",
                        size: 13,
                    },
                    text: t(`defectInsights.${filterBy}`)
                },
            },
            plot_bgcolor: "#FCFCFC",
            hovermode: "closest",
            hoverlabel: { 
                bgcolor: '#1C1940',
                bordercolor: '#1C1940',
                font: {
                    color: '#FFF',
                    size: 13,
                 }
            },
        };

        return graphLayout;
    }, [data]);

    const config = useMemo(() => {
        const graphConfig: Partial<Config> = {
            displayModeBar: true,
            responsive: true,
            displaylogo: false,
            scrollZoom: false,
            showTips: false,
            modeBarButtonsToRemove: ['pan2d','zoom2d','autoScale2d','lasso2d','select2d']
        }

        return graphConfig;
    }, []);

    if (closedRatioLoading) {
        return (
            <div className="flex h-screen justify-items-center align-items-center">
                <ProgressSpinner />
            </div>
        );
    }

    if (!data?.length) {
        return (
            <div>
                <p className="defect-insight-graph-title mb-4">
                    {t("defectInsights.closedDefectsRatio")}
                </p>
                <div className="flex justify-content-center">
                    <p>{t("defectsGraph.noDefectsDataAvailable")}</p>
                </div>
            </div>
        );
    }

    return (
        <div>
            <p className="defect-insight-graph-title">
                {t("defectInsights.closedDefectsRatio")}
            </p>
            <Plot
                useResizeHandler={true}
                data={data}
                layout={layout}
                config={config}
                className="defect-insight-graph"
            />
      </div>
    )
}