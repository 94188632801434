import {Button} from "primereact/button";
import "./InspectionReportCard.css";
import {useTranslation} from "react-i18next";
import {ReportViewClicked} from "./ReportViewClicked";
import {getGradeComponent, GradeType} from "@idwal/idwal-react-components";

export interface InspectionReportCardProps {
    grade?: number;
    inspectionType: string;
    inspectionReference: string;
    issuedAt: Date | undefined;
    inspectionGuid: string;
}

export const InspectionReportCard = ({
                                         grade,
                                         inspectionType,
                                         inspectionReference,
                                         issuedAt,
                                         inspectionGuid
                                     }: InspectionReportCardProps) => {

    const {t} = useTranslation("locale");

    const gradeComponent = getGradeComponent({gradeType: GradeType.IDWAL, grade: grade});

    const getFormattedDateElement = () => {
        const formattedDate = issuedAt ? new Date(issuedAt).toLocaleDateString() : "";
        if (!formattedDate) {
            return null
        }

        return (
            <>
                <p className="inspection-report-card-detail"> | </p>
                <p className="inspection-report-card-detail">{t("vesselView.accordions.issued")}: {formattedDate}</p>
            </>
        )
    }

    const getDetails = () => {
        return (
            <div data-testid={"inspection-report-card-details"} id="inspection-report-card-details"
                 className="flex align-items-center inspection-report-card-details">
                <p data-testid={"inspection-report-card-detail"} className="inspection-report-card-detail">{t("vesselView.accordions.ref")}: {inspectionReference}</p>
                {getFormattedDateElement()}
            </div>
        )
    };

    const getViewReportButton = () => {
        return (
            <Button
                id="inspection-report-card-view-report-button"
                className="p-button p-button-sm p-button-secondary p-button-text"
                label={"View"}
                onClick={() => ReportViewClicked(inspectionGuid)}
            />
        )
    }

    return (
        <div id={`inspection-report-card-${inspectionGuid}`}>
            <div id="inspection-report-card-header" className="flex justify-content-between">
                <div className="flex align-items-center">
                    {gradeComponent}
                    <p id="inspection-type" className="inspection-details-type ml-3">{inspectionType}</p>
                </div>
                {getViewReportButton()}
            </div>
            <div id="inspection-report-card-body" className="mt-2">
                {getDetails()}
            </div>
        </div>
    )
}
