import { Trans, useTranslation } from "react-i18next";

const Unauthorised = () => {
    const {t} = useTranslation("locale");
    return (
        <div className="flex flex-column align-items-center justify-content-center">
            <p>
                <Trans i18nKey="unauthorisedUser.message" t={t}>
                    You are not authorised to access this application. Please <a href="https://www.idwalmarine.com/contact-us" target="_blank">
                        contact us
                    </a>.
                </Trans>
            </p>
        </div>
    )
}

export default Unauthorised;