import { GRADING_AREA_ORDER } from "../utils/Constants";

const GradingAreaOrderConfig: string[] = (() => {
    const gradingAreaOrderConfig = [...GRADING_AREA_ORDER];
    gradingAreaOrderConfig.push("General");
    return gradingAreaOrderConfig;
})();

export const sortGradingAreas = (distinctGradingAreaFilterValues: string[]): string[] => {
    const sortedValues = distinctGradingAreaFilterValues.filter(value => GradingAreaOrderConfig.includes(value));
    return sortedValues.sort((a: string, b: string) => {
        const indexA = GradingAreaOrderConfig.indexOf(a);
        const indexB = GradingAreaOrderConfig.indexOf(b);
        return indexA - indexB;
    });
};
