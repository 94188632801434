import {FilterDialog} from "../components/FilterDialog/FilterDialog";
import {BackgroundFilterScopeSelection, FilterScope, FilterType, SingleSelectItem} from "../types/FilterDialog";
import {useTranslation} from "react-i18next";
import {sendGAEvent} from "../AnalyticsService";
import {
    ADVANCED_FILTER_BROADER_FILTER_WARNING_MESSAGE_DISMISSED,
    ADVANCED_FILTER_CATEGORY,
    ADVANCED_FILTER_RESET,
    AdvancedFilterSelectionChanged,
} from "../constants/analytics";
import {
    defaultFleetInspectionDateRangeOptions,
    getBackgroundFilterIndex,
    getDwtRangeText,
    getSingleSelectCount,
    getTeuRangeText, 
    toDefaultSelection,
    toVesselTypeOptions
} from "../utils/advancedFilter/helpers";
import {buildMultiSelectSection} from "../utils/advancedFilter/buildMultiSelectSection";
import {buildRangeSection} from "../utils/advancedFilter/buildRangeSection";
import {BACKGROUND_FLEET_DATA, BACKGROUND_MARKET_DATA} from "../constants/backgroundFilterIndex";
import {storeGroupLocalStorageItem} from "../utils/UserLocalStorage";
import LOCAL_TABLE_CONFIG_KEY from "../types/TableLocalConfigKeys";
import {buildSingleSelectSection} from "../utils/advancedFilter/buildSingleSelectSection";
import {getAgeRangeMultiSelect} from "../components/Filter/GlobalAgeRangeBands";
import {SortType} from "../types/BuildFilterSectionProps";
import {getDateRangeFromAdvancedFilterInspectionDate} from "../utils/AdvancedFilterHelper";
import { useAppDispatch, useAppSelector } from "../hooks/storeHooks";
import { selectGlobalFilters } from "../store/selectors/filtersSelectors";
import { setGlobalFilters, setSelectedTechnicalManagers, setSelectedVesselAgeBands, setSelectedVessels, setSelectedVesselTypes } from "../store/slices/filtersSlice";
import { selectSelectedGroupName, selectUserDetails } from "../store/selectors/userSelectors";
import { selectAdvancedFilters, selectShowWarningMessage } from "../store/selectors/advancedFiltersSelectors";
import { setBackgroundFilterState, setForegroundFilterState, setSelectedSection, setShowWarningMessage } from "../store/slices/advancedFiltersSlice";
import { onApply, onMarketDataToggle, setupAdvancedFilters, toggleOpenState } from "../store/thunks/advancedFiltersThunks";
import { BackgroundFilters, ForegroundFilters } from "../types/AdvancedFilters";
import { selectFleet } from "../store/selectors/fleetSelectors";
import { setupFilteredDefects } from "../store/thunks/defectThunks";

export function FilterDialogStateAdapter() {
    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();

    const {
        marketDataFilteringOptions,
        distinctTechnicalManagers,
        distinctVesselsWithCount,
        distinctVessels,
        inspectionAndGradeData,
        distinctCountriesOfBuild,
    } = useAppSelector(selectFleet);

    const { 
        open,
        selectedSection,
        filterState,
        loading,
    } = useAppSelector(selectAdvancedFilters);

    const globalFilters = useAppSelector(selectGlobalFilters);
    const userDetails = useAppSelector(selectUserDetails);
    const selectedGroupName = useAppSelector(selectSelectedGroupName);
    const showWarningMessage = useAppSelector(selectShowWarningMessage);

    const selectedFilterScope = filterState.marketDataToggleSelected 
        ? BackgroundFilterScopeSelection.MARKET 
        : BackgroundFilterScopeSelection.FLEET

    const backgroundFilterIndex = getBackgroundFilterIndex(
        filterState.marketDataToggleSelected 
    )

    const getCount = (data: Array<string>) => {
        return data.length !== undefined ? data.length : 0;
    }

    const onHide = () => {
        dispatch(setShowWarningMessage(false));
        dispatch(toggleOpenState());
    }

    const onCloseWarning = () => {
        sendGAEvent(ADVANCED_FILTER_CATEGORY, ADVANCED_FILTER_BROADER_FILTER_WARNING_MESSAGE_DISMISSED);
        dispatch(setShowWarningMessage(!showWarningMessage));
    }

    const onSelectSection = (sectionName: string) => {
        dispatch(setSelectedSection(sectionName))
    }

    const onUpdateForegroundFilterState = (foregroundFilters: ForegroundFilters) => {
        dispatch(setForegroundFilterState(foregroundFilters));
    }

    const onUpdateFleetBackgroundFilterState = (fleetBackgroundFilters: BackgroundFilters) => {
        dispatch(setBackgroundFilterState([fleetBackgroundFilters, filterState.background[BACKGROUND_MARKET_DATA]]));
    }

    const onUpdateMarketBackgroundFilterState = (marketBackgroundFilters: BackgroundFilters) => {
        dispatch(setBackgroundFilterState([filterState.background[BACKGROUND_FLEET_DATA], marketBackgroundFilters]));
    }

    const countryOfBuildForegroundOptions = distinctCountriesOfBuild.map(toDefaultSelection);
    const countryOfBuildSection = buildMultiSelectSection({
        selectedSection,
        selectedFilterScope,
        sectionName: t("fleetGraph.countryOfBuild"),
        allLabelText: t("fleetGraph.allCountries"),
        multiselectType: FilterType.MultiSelectWithSearch,
        filterScope: FilterScope.ALL,
        defaultSearchTerm: t("benchmarkModal.findACountry"),
        gaForegroundTag: AdvancedFilterSelectionChanged.ForegroundCountryOfBuild,
        gaBackgroundTag: AdvancedFilterSelectionChanged.BackgroundCountryOfBuild,
        sortType: SortType.Alphabetical,

        foregroundOptions: countryOfBuildForegroundOptions,
        backgroundMarketOptions: marketDataFilteringOptions?.buildCountries.map(toDefaultSelection) ?? [],
        backgroundFleetOptions: countryOfBuildForegroundOptions,

        foregroundSelectionState: filterState.foreground.countryOfBuild.data,
        backgroundFleetSelectionState: filterState.background[BACKGROUND_FLEET_DATA].countryOfBuild.data,
        backgroundMarketSelectionState: filterState.background[BACKGROUND_MARKET_DATA].countryOfBuild.data,

        foregroundCount:  getCount(filterState.foreground.countryOfBuild.data),
        backgroundFleetCount: getCount(filterState.background[BACKGROUND_FLEET_DATA].countryOfBuild.data),
        backgroundMarketCount: getCount(filterState.background[BACKGROUND_MARKET_DATA].countryOfBuild.data),

        onSelectSection,
        onUpdateForegroundSelection: (items: Array<string>) => {
            onUpdateForegroundFilterState({
                ...filterState.foreground,
                countryOfBuild: {
                    ...filterState.foreground.countryOfBuild,
                    data: items
                }
            });
        },
        onUpdateBackgroundFleetSelection: (items: Array<string>) => {
            onUpdateFleetBackgroundFilterState({
                ...filterState.background[BACKGROUND_FLEET_DATA],
                countryOfBuild: {
                    ...filterState.background[BACKGROUND_FLEET_DATA].countryOfBuild,
                    data: items
                }
            });
        },
        onUpdateBackgroundMarketSelection: (items: Array<string>) => {
            onUpdateMarketBackgroundFilterState({
                ...filterState.background[BACKGROUND_MARKET_DATA],
                countryOfBuild: {
                    ...filterState.background[BACKGROUND_MARKET_DATA].countryOfBuild,
                    data: items
                }
            });
        }
    });

    const vesselTypeBackgroundMarketSelection = distinctVesselsWithCount.map(toVesselTypeOptions);
    const vesselTypeSection = buildMultiSelectSection({
        selectedSection,
        selectedFilterScope,
        sectionName: t("fleetTable.vesselType"),
        allLabelText: t("fleetGraph.allVesselTypes"),
        multiselectType: FilterType.Multiselect,
        filterScope: FilterScope.ForegroundOnly,
        defaultSearchTerm: "",
        gaForegroundTag: AdvancedFilterSelectionChanged.ForegroundVesselType,
        gaBackgroundTag: "",
        sortType: SortType.Alphabetical,


        foregroundOptions: vesselTypeBackgroundMarketSelection,
        backgroundMarketOptions: [],
        backgroundFleetOptions: [],

        foregroundSelectionState: filterState.foreground.vesselType.data,
        backgroundFleetSelectionState: [],
        backgroundMarketSelectionState: [],

        foregroundCount: getCount(filterState.foreground.vesselType.data),
        backgroundMarketCount: getCount(filterState.foreground.vesselType.data),
        backgroundFleetCount: getCount(filterState.foreground.vesselType.data),
        backgroundMarketDataMessage: t("benchmarkModal.vesselTypeBackgroundMessage"),
        backgroundFleetDataMessage: t("benchmarkModal.vesselTypeBackgroundMessage"),
        onSelectSection,
        onUpdateForegroundSelection: (items: Array<string>) => {
            onUpdateForegroundFilterState({
                ...filterState.foreground,
                vesselType: {
                    ...filterState.foreground.vesselType,
                    data: items
                }
            });
        },
        onUpdateBackgroundFleetSelection: () => {},
        onUpdateBackgroundMarketSelection: () => {}
    });

    const foregroundTechnicalManagerSelection = distinctTechnicalManagers.map(toDefaultSelection);
    const technicalManagerSection = buildMultiSelectSection({
        selectedSection,
        selectedFilterScope,
        sectionName: t("benchmarkModal.technicalManager"),
        allLabelText: t("fleetGraph.allTechnicalManagers"),
        multiselectType: FilterType.MultiSelectWithSearch,
        filterScope: FilterScope.ForegroundAndFleetData,
        defaultSearchTerm: t("benchmarkModal.findAManager"),
        gaForegroundTag: AdvancedFilterSelectionChanged.ForegroundTechnicalManager,
        gaBackgroundTag: AdvancedFilterSelectionChanged.BackgroundTechnicalManager,
        sortType: SortType.Alphabetical,


        foregroundOptions: foregroundTechnicalManagerSelection,
        backgroundMarketOptions: [],
        backgroundFleetOptions: foregroundTechnicalManagerSelection,

        foregroundSelectionState: filterState.foreground.technicalManager.data,
        backgroundFleetSelectionState: filterState.background[
            backgroundFilterIndex
        ].technicalManager.data,
        backgroundMarketSelectionState: [],

        foregroundCount: getCount(filterState.foreground.technicalManager.data),
        backgroundFleetCount: getCount(filterState.background[
            BACKGROUND_FLEET_DATA
        ].technicalManager.data),
        backgroundMarketCount: getCount(filterState.background[
            BACKGROUND_MARKET_DATA
        ].technicalManager.data),

        onSelectSection,
        onUpdateForegroundSelection: (items: Array<string>) => {
            onUpdateForegroundFilterState({
                ...filterState.foreground,
                technicalManager: {
                    ...filterState.foreground.technicalManager,
                    data: items
                }
            });
        },
        onUpdateBackgroundFleetSelection: (items: Array<string>) => {
            onUpdateFleetBackgroundFilterState({
                ...filterState.background[BACKGROUND_FLEET_DATA],
                technicalManager: {
                    ...filterState.background[BACKGROUND_FLEET_DATA].technicalManager,
                    data: items
                }
            });
        },
        onUpdateBackgroundMarketSelection: () => {},
        backgroundFleetDataMessage: t("benchmarkModal.technicalManagerMarketBackgroundMessage")
    });

    const defaultDwtStep = 1000;
    const dwtSection = buildRangeSection({
        sectionName: t("benchmarkModal.dwtSection"),
        selectedSection,
    
        foregroundTitle: t("benchmarkModal.dwtSentence"),
        backgroundTitle: t("benchmarkModal.dwtSentence"),
        foregroundSubtitle: getDwtRangeText(
            t, 
            filterState.foreground.dwt.data
        ),
        backgroundSubtitle: getDwtRangeText(
            t, 
            filterState.background[backgroundFilterIndex].dwt.data
        ),
    
        filterScope: FilterScope.ALL,
    
        foregroundRangeState: filterState.foreground.dwt,
        backgroundFleetRangeState: filterState.background[BACKGROUND_FLEET_DATA].dwt,
        backgroundMarketRangeState: filterState.background[BACKGROUND_MARKET_DATA].dwt,
    
        step: defaultDwtStep,
    
        gaForegroundTag: AdvancedFilterSelectionChanged.ForegroundDwt,
        gaBackgroundFleetTag: AdvancedFilterSelectionChanged.BackgroundFleetDwt,
        gaBackgroundMarketTag: AdvancedFilterSelectionChanged.BackgroundMarketDwt,


        onSelectSection,
        onUpdateForegroundSelection: (newDwtState: [number, number]) => {
            onUpdateForegroundFilterState({
                ...filterState.foreground,
                dwt: {
                    ...filterState.foreground.dwt,
                    changed: true,
                    data: newDwtState
                }
            });
        },
        onUpdateBackgroundFleetSelection: (newDwtState: [number, number]) => {
            onUpdateFleetBackgroundFilterState({
                ...filterState.background[BACKGROUND_FLEET_DATA],
                dwt: {
                    ...filterState.background[BACKGROUND_FLEET_DATA].dwt,
                    changed: true,
                    data: newDwtState
                }
            });
        },
        onUpdateBackgroundMarketSelection: (newDwtState: [number, number]) => {
            onUpdateMarketBackgroundFilterState({
                ...filterState.background[BACKGROUND_MARKET_DATA],
                dwt: {
                    ...filterState.background[BACKGROUND_MARKET_DATA].dwt,
                    changed: true,
                    data: newDwtState
                }
            });
        }
    });

    const defaultTeuStep = 100;
    const teuSection = buildRangeSection({
        sectionName: t("benchmarkModal.teuSection"),
        selectedSection,
        foregroundTitle: t("benchmarkModal.teuSentence"),
        backgroundTitle: t("benchmarkModal.teuSentence"),
        foregroundSubtitle: getTeuRangeText(
            t,
            filterState.foreground.teu.data
        ),
        backgroundSubtitle: getTeuRangeText(
            t,
            filterState.background[backgroundFilterIndex].teu.data
        ),
        filterScope: FilterScope.ALL,
        foregroundRangeState: filterState.foreground.teu,
        backgroundFleetRangeState: filterState.background[0].teu,
        backgroundMarketRangeState: filterState.background[1].teu,
        step: defaultTeuStep,
        gaForegroundTag: AdvancedFilterSelectionChanged.ForegroundTeu,
        gaBackgroundFleetTag: AdvancedFilterSelectionChanged.BackgroundFleetTeu,
        gaBackgroundMarketTag: AdvancedFilterSelectionChanged.BackgroundMarketTeu,
        onSelectSection,
        onUpdateForegroundSelection: (newTeuState: [number, number]) => {
            onUpdateForegroundFilterState({
                ...filterState.foreground,
                teu: {
                    ...filterState.foreground.teu,
                    changed: true,
                    data: newTeuState
                }
            });
        },
        onUpdateBackgroundFleetSelection: (newTeuState: [number, number]) => {
            onUpdateFleetBackgroundFilterState({
                ...filterState.background[BACKGROUND_FLEET_DATA],
                teu: {
                    ...filterState.background[BACKGROUND_FLEET_DATA].teu,
                    changed: true,
                    data: newTeuState
                }
            });
        },
        onUpdateBackgroundMarketSelection: (newTeuState: [number, number]) => {
            onUpdateMarketBackgroundFilterState({
                ...filterState.background[BACKGROUND_MARKET_DATA],
                teu: {
                    ...filterState.background[BACKGROUND_MARKET_DATA].teu,
                    changed: true,
                    data: newTeuState
                }
            });
        }
    });

    const vesselOptions = distinctVessels.map(toDefaultSelection);
    const vesselsSection = buildMultiSelectSection({
        selectedSection,
        selectedFilterScope,
        sectionName: t("fleetGraph.vessels"),
        allLabelText: t("fleetGraph.allVessels"),
        multiselectType: FilterType.MultiSelectWithSearch,
        filterScope: FilterScope.ForegroundAndFleetData,
        defaultSearchTerm: t("benchmarkModal.findAVessel"),
        gaForegroundTag: AdvancedFilterSelectionChanged.ForegroundVessel,
        gaBackgroundTag: AdvancedFilterSelectionChanged.BackgroundFleetVessel,
        sortType: SortType.Alphabetical,

        foregroundOptions: vesselOptions,
        backgroundMarketOptions: [],
        backgroundFleetOptions: vesselOptions,

        foregroundSelectionState: filterState.foreground.selectedVessels.data,
        backgroundFleetSelectionState: filterState.background[backgroundFilterIndex].selectedVessels.data,
        backgroundMarketSelectionState:  [],

        foregroundCount: filterState.foreground.selectedVessels.data.length,
        backgroundFleetCount: getCount(filterState.background[BACKGROUND_FLEET_DATA].selectedVessels.data),
        backgroundMarketCount: 0,

        onSelectSection,
        onUpdateForegroundSelection: (items: Array<string>) => {
            onUpdateForegroundFilterState({
                ...filterState.foreground,
                selectedVessels: {
                    ...filterState.foreground.selectedVessels,
                    data: items
                }
            });
        },
        onUpdateBackgroundFleetSelection: (items: Array<string>) => {
            onUpdateFleetBackgroundFilterState({
                ...filterState.background[BACKGROUND_FLEET_DATA],
                selectedVessels: {
                    ...filterState.background[BACKGROUND_FLEET_DATA].selectedVessels,
                    data: items
                }
            });
        },
        onUpdateBackgroundMarketSelection: () => {},
        backgroundMarketDataMessage: t("benchmarkModal.vesselSelectionInvalidPanel"),
        backgroundFleetDataMessage: t("benchmarkModal.vesselSelectionInvalidPanel"),
    });

    const vesselAgeSection = buildMultiSelectSection({
        selectedSection,
        selectedFilterScope,
        sectionName: t("benchmarkModal.vesselAge"),
        allLabelText: t("fleetGraph.allVesselAges"),
        multiselectType: FilterType.Multiselect,
        filterScope: FilterScope.ForegroundOnly,
        defaultSearchTerm: "N/A",
        gaForegroundTag: AdvancedFilterSelectionChanged.ForegroundVesselAge,
        gaBackgroundTag: "",
        sortType: SortType.None,
        foregroundOptions: getAgeRangeMultiSelect(t),
        backgroundMarketOptions: [],
        backgroundFleetOptions: [],
        foregroundSelectionState: filterState.foreground.vesselAge.data,
        backgroundFleetSelectionState: [],
        backgroundMarketSelectionState:  [],

        foregroundCount: getCount(filterState.foreground.vesselAge.data),
        backgroundFleetCount: getCount(filterState.foreground.vesselAge.data),
        backgroundMarketCount: getCount(filterState.foreground.vesselAge.data),

        onSelectSection,
        onUpdateForegroundSelection: (items: Array<string>) => {
            onUpdateForegroundFilterState({
                ...filterState.foreground,
                vesselAge: {
                    ...filterState.foreground.vesselAge,
                    data: items
                }
            });
        },
        onUpdateBackgroundFleetSelection: () => {},
        onUpdateBackgroundMarketSelection: () => {},
        backgroundMarketDataMessage: t("benchmarkModal.vesselAgeSelectionInvalidPanel"),
        backgroundFleetDataMessage: t("benchmarkModal.vesselAgeSelectionInvalidPanel")

    });

    const inspectionDateRangeOptions = defaultFleetInspectionDateRangeOptions(t);

    const inspectionDateSection = buildSingleSelectSection({
        selectedSection,
        selectedFilterScope,
        sectionName: t("benchmarkModal.inspectionDate"),
        filterScope: FilterScope.ForegroundOnly,
        gaForegroundTag: AdvancedFilterSelectionChanged.ForegroundInspectionDateRange,
        gaBackgroundFleetTag: AdvancedFilterSelectionChanged.BackgroundFleetInspectionDateRange,
        gaBackgroundMarketTag: AdvancedFilterSelectionChanged.BackgroundMarketInspectionDateRange,
        foregroundOptions: inspectionDateRangeOptions,
        backgroundMarketOptions: [],
        backgroundFleetOptions: [],
        foregroundSelectionState: filterState.foreground.inspectionDateRange.data,
        backgroundFleetSelectionState: [],
        backgroundMarketSelectionState: [],

        foregroundCount: getSingleSelectCount(filterState.foreground.inspectionDateRange.data),
        backgroundMarketCount: getSingleSelectCount(filterState.foreground.inspectionDateRange.data),
        backgroundFleetCount: getSingleSelectCount(filterState.foreground.inspectionDateRange.data),

        onSelectSection,
        onUpdateForegroundSelection: (update: SingleSelectItem[]) => {
            onUpdateForegroundFilterState({
                ...filterState.foreground,
                inspectionDateRange: {
                    ...filterState.foreground.inspectionDateRange,
                    data: update
                }
            });
        },
        onUpdateBackgroundFleetSelection: () => {},
        onUpdateBackgroundMarketSelection: (update: SingleSelectItem[]) => {
            onUpdateMarketBackgroundFilterState({
                ...filterState.background[BACKGROUND_MARKET_DATA],
                inspectionDateRange: {
                    ...filterState.background[BACKGROUND_MARKET_DATA].inspectionDateRange,
                    data: update
                }
            });
        },
        backgroundFleetDataMessage: t("benchmarkModal.inspectionDateBackgroundMessage"),
        backgroundMarketDataMessage: t("benchmarkModal.inspectionDateBackgroundMessage")
    })

    const sections = [
        vesselsSection,
        vesselTypeSection,
        vesselAgeSection,
        technicalManagerSection,
        inspectionDateSection,
        countryOfBuildSection,
        dwtSection,
        teuSection
    ];

    const onApplyClick = () => {
        dispatch(setSelectedVesselTypes(filterState.foreground.vesselType.data));
        dispatch(setSelectedVessels(filterState.foreground.selectedVessels.data));
        dispatch(setSelectedTechnicalManagers(filterState.foreground.technicalManager.data));

        const newInspectionDateRange = getDateRangeFromAdvancedFilterInspectionDate(t, filterState);

        dispatch(setSelectedVesselAgeBands(filterState.foreground.vesselAge.data));
        dispatch(setGlobalFilters({
            ...globalFilters, 
            vessels: filterState.foreground.selectedVessels.data,
            vesselTypes: filterState.foreground.vesselType.data,
            vesselAgeBands: filterState.foreground.vesselAge.data,
            technicalManagers: filterState.foreground.technicalManager.data
        }));
        dispatch(setupFilteredDefects());

        userDetails?.userName && selectedGroupName && storeGroupLocalStorageItem(
            userDetails?.userName,
            selectedGroupName,
            LOCAL_TABLE_CONFIG_KEY.GLOBAL_FILTER_STATE,
            {
                ...globalFilters, 
                vessels: filterState.foreground.selectedVessels.data,
                vesselTypes: filterState.foreground.vesselType.data,
                inspectionDateRange: newInspectionDateRange,
                vesselAgeBands: filterState.foreground.vesselAge.data,
                technicalManagers: filterState.foreground.technicalManager.data
            }
        );
        dispatch(onApply());
    };

    const onClearClick = () => {
        sendGAEvent(ADVANCED_FILTER_CATEGORY, ADVANCED_FILTER_RESET);
        dispatch(setupAdvancedFilters(inspectionAndGradeData, marketDataFilteringOptions));
    }

    const onChangeBackgroundFilter = () => {
        dispatch(onMarketDataToggle(marketDataFilteringOptions, distinctCountriesOfBuild));
    }

    return (
        <FilterDialog
            visible={open}
            backgroundFilterScopeSelection={selectedFilterScope}
            filterSections={sections}
            showWarningMessage={showWarningMessage}
            saving={loading}
            onHide={onHide}
            onApply={onApplyClick}
            onClear={onClearClick}
            onChangeBackgroundFilter={onChangeBackgroundFilter}
            onCloseWarningMessage={onCloseWarning}
        />
    );
}