import "./FilterTypeToggle.css";
import { FilterTypeToggleProps } from '../../types/FilterTypeToggleProps';
import { RadioButton } from 'primereact/radiobutton';

export const FilterTypeToggle = ({
    text,
    checked,
    disabled,
    onChange
}: FilterTypeToggleProps) => {
    return (
        <div className="filterTypeToggle filterTypeToggle__text">
                <div className="flex align-items-center myFleetToggle">
                    <RadioButton 
                        inputId="My Fleet"
                        name="My Fleet" 
                        value={!checked} 
                        onChange={(e) => onChange(e.value)} 
                        checked={!checked}
                    />
                    <label htmlFor="My Fleet" className={`${!checked ? "filterTypeToggle__checked" : ""} clickableLabel ml-2`}>My Fleet</label>
                </div>
                <div className="flex align-items-center ml-3">
                    <RadioButton 
                        inputId="market"
                        name="market" 
                        value={checked} 
                        onChange={(e) => onChange(e.value)} 
                        checked={checked}
                    />
                    <label htmlFor="market" className={`${checked ? "filterTypeToggle__checked" : ""} clickableLabel ml-2`}>Market</label>
                </div>
        </div>
    )
}