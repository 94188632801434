import {ReactElement, useRef, useState} from "react";
import {DefaultHeaderProps} from "../../types/DefaultHeaderProps";
import {InputText} from "primereact/inputtext";
import {updateKeywordSearchFilter} from "./TableFunctions";
import {MultiSelect} from "primereact/multiselect";
import {useTranslation} from "react-i18next";
import {Button} from "primereact/button";
import {ColumnReorderDialog, ColumnReorderDialogMethods} from "./ColumnReorderDialog";
import {SelectItemOptionsType} from "primereact/selectitem";
import {AddNewColumnDialog} from "./AddNewColumnDialog";
import {HeaderType} from "../../types/HeaderType";
import LOCAL_TABLE_CONFIG_KEY from "../../types/TableLocalConfigKeys";
import { ResetTableButton } from "./ResetTableButton";
import { useDebounceCallback } from "usehooks-ts";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { setShowCloseDefectsDialog } from "../../store/slices/defectSlice";
import { selectKeywordSearch } from "../../store/selectors/filtersSelectors";
import { DataTableFilterMeta } from "primereact/datatable";
import { setDefectsSummaryTableFilterData, setDefectsTableFilterData, setFleetTableFilterData, setKeywordSearchViaHeaderType, setSubGradeTableFilterData } from "../../store/slices/filtersSlice";
import { selectShowStatsTilesPanel } from "../../store/selectors/fleetSelectors";
import { toggleStatsTiles } from "../../store/thunks/fleetThunks";

export const DefaultHeader = (props: DefaultHeaderProps): ReactElement => {
    
    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();

    const columnReorderDialog = useRef<ColumnReorderDialogMethods>(null);
    const addNewColumnDialog = useRef<ColumnReorderDialogMethods>(null);

    const keywordSearch = useAppSelector(selectKeywordSearch);

    const showStatsTilesPanel = useAppSelector(selectShowStatsTilesPanel);

    const getKeywordSearchValue = () => {
        if (props.headerType == HeaderType.TABLE) {
            return keywordSearch.table;
        }

        if (props.headerType == HeaderType.DEFECTS) {
            return keywordSearch.defects;
        }
        if (props.headerType == HeaderType.SUB_GRADE) {
            return keywordSearch.subGrade;
        }

        if (props.headerType == HeaderType.DEFECT_SUMMARY) {
            return keywordSearch.defectsSummary
        }

        throw new Error("HeaderType is not specified.");
    }

    const setFilters = (filters: DataTableFilterMeta) => {
        if (props.headerType == HeaderType.TABLE) {
            dispatch(setFleetTableFilterData(filters));
        }

        if (props.headerType == HeaderType.DEFECTS) {
            dispatch(setDefectsTableFilterData(filters));
        }
        if (props.headerType == HeaderType.SUB_GRADE) {
            dispatch(setSubGradeTableFilterData(filters));
        }

        if (props.headerType == HeaderType.DEFECT_SUMMARY) {
            dispatch(setDefectsSummaryTableFilterData(filters));
        }
    }

    const [debouncedKeywordSearch, setDebouncedKeywordSearch] = useState<string>(getKeywordSearchValue());

    const clearKeywordSearchInput = () => {
        setDebouncedKeywordSearch("");
    }

    const handleColumnReorderClick = () => {
        if (columnReorderDialog.current) {
            columnReorderDialog.current.showDialog(true);
        }
    }

    const handleAddNewColumnClick = () => {
        if (addNewColumnDialog.current) {
            addNewColumnDialog.current.showDialog(true);
        }
    }

    const debounceSearch = useDebounceCallback((value: string) => {
        const updatedFilters = updateKeywordSearchFilter(value, props.filters);
        setFilters(updatedFilters);
        dispatch(setKeywordSearchViaHeaderType({ keyword: value, headerType: props.headerType }));
    }, 300);

    const handleOnKeywordSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setDebouncedKeywordSearch(value); // Update local state

        debounceSearch.cancel(); // Cancel previous debounce
        debounceSearch(value);
    };


    const multiSelectFooterTemplate = () => {
        return (
            <div className="flex justify-content-between p-3">
                <Button className="w-full" label={t("columnReorder.changeColumnOrder")} onClick={handleColumnReorderClick} />
                {props.onAddColumn ? <Button className="w-full ml-5" label={t("columnEdit.addNew")} onClick={handleAddNewColumnClick} /> : <></>}
            </div>
        )
    }

    const getResetTableButton = () => {
        if (props.headerType == HeaderType.TABLE) {
            return <ResetTableButton tableKey={LOCAL_TABLE_CONFIG_KEY.FLEET} 
                clearKeywordSearchInput={clearKeywordSearchInput} />
        }
        if (props.headerType == HeaderType.DEFECTS) {
            return <ResetTableButton tableKey={LOCAL_TABLE_CONFIG_KEY.DEFECTS} 
                clearKeywordSearchInput={clearKeywordSearchInput} />
        }
        if (props.headerType == HeaderType.DEFECT_SUMMARY) {
            return <ResetTableButton tableKey={LOCAL_TABLE_CONFIG_KEY.DEFECTS_SUMMARY} 
                clearKeywordSearchInput={clearKeywordSearchInput} dataCy="defects-summary-reset-table-button" />
        }

        if (props.headerType == HeaderType.SUB_GRADE) {
            return <ResetTableButton tableKey={LOCAL_TABLE_CONFIG_KEY.SUBGRADE} 
                clearKeywordSearchInput={clearKeywordSearchInput} dataCy="sub-grade-reset-table-button" />
        }
        return <></>
    }

    const getCloseDefectsButton = () => {
        if (props.headerType == HeaderType.DEFECTS) {
            return (
                    <Button
                        id={"closeDefects"}
                        icon="pi pi-check-circle"
                        iconPos="left"
                        label={t("closeDefectsDialog.closeDefects")}
                        className="ml-3"
                        onClick={() => dispatch(setShowCloseDefectsDialog(true))}
                    />
            )
        }
        return <></>
    }

    const getToggleStatsTilesButton = () => {
        if (props.headerType == HeaderType.TABLE) {
            const iconSrc = !showStatsTilesPanel ? "stats_tiles_open.svg" : "stats_tiles_close.svg";
            const tooltipText = !showStatsTilesPanel ? t("fleetTable.showStatsTiles") : t("fleetTable.hideStatsTiles");

            return (
                <Button
                    id={"toggleStatsTiles"}
                    iconPos="left"
                    className="toggle-stat-tiles p-button-text p-button-icon-only p-button-outlined p-button ml-3"
                    onClick={() => dispatch(toggleStatsTiles())}
                    tooltip={tooltipText}
                >
                    <img src={iconSrc} />
                </Button>
            )
        }
        return <></>
    }

    return (
        <div className={"grid"}>
            <div className="col-12 lg:col-6 mb-3">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={debouncedKeywordSearch} onChange={handleOnKeywordSearchChange} className="w-full max-w-19rem" disabled={props.disabled}
                        placeholder={t("fleetTable.keywordSearch")} data-cy={props.keywordDataCy ?? "keyword-search"} />
                </span>
                {getResetTableButton()}
                {getCloseDefectsButton()}
            </div>
            {props.columns.length ? (
                <div className="col-12 lg:col-6 flex lg:justify-content-end align-items-center formgroup-inline" style={{ textAlign:'left'}}>
                    <div className="field max-w-full mr-0">
                        <label className="font-weight-normal" htmlFor="#column-dropdown">{t("fleetTable.editColumns")}</label>
                        <MultiSelect
                            id={"column-dropdown"}
                            value={props.selectedColumns}
                            options={props.columns as SelectItemOptionsType}
                            disabled={props.disabled}
                            optionLabel="header" 
                            onChange={props.onColumnToggle}
                            className={"w-full lg:w-23rem max-w-23rem"}
                            panelFooterTemplate={multiSelectFooterTemplate}
                            scrollHeight="18rem" />
                        <ColumnReorderDialog ref={columnReorderDialog} columns={props.columns} onColumnReorder={props.onColumnReorder} onRemoveColumn={props.onRemoveColumn} />
                        {props.onAddColumn ? <AddNewColumnDialog ref={addNewColumnDialog} columns={props.columns} onAddColumn={props.onAddColumn} /> : <></>}
                        {getToggleStatsTilesButton()}
                    </div>
                </div>
            ): null}
        </div>
    )
}
