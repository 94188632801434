import {VesselSubgradeResponse} from "../../types/VesselSubgradeResponse";
import {FleetDataItem} from "../../types/FleetDataItem";

export const getVesselWithLowestSubgrade = (subgradeData: VesselSubgradeResponse, inspectionData: FleetDataItem[], area: string) => {
    let lowest = { subgrade: 100, vessel: "" };

    Object.entries(subgradeData).forEach(x => {
        const imo = x[0];
        const subgradeDataForVessel = x[1];

        const subgrade = subgradeDataForVessel.subGrades.find((subGrade) => {
            return subGrade.name.toUpperCase() === area.toUpperCase()
        });

        if (lowest.subgrade > (subgrade?.score || 0)) {
            let vesselNames = inspectionData.filter(x => x.vessel.imo == imo);

            let vesselName = "";
            if(vesselNames.length > 0){
                vesselName = vesselNames[0].vessel.vesselName;
            }

            lowest =  { subgrade: (subgrade?.score ?? 0), vessel: vesselName };
        }

    });

    if(lowest.subgrade == 0){
        lowest.vessel = "";
    }

    return lowest;
}