import {Button} from "primereact/button";
import {Menu} from "primereact/menu";
import {useTranslation} from "react-i18next";
import {useRef} from "react";
import {MenuItem} from "primereact/menuitem";
import "./menuButton.css";

export type MenuButtonProps = {
    menu: MenuItem[],
    secondary?: boolean
}

export const MenuButton = ({
    menu,
    secondary
}: MenuButtonProps) => {
    const { t } = useTranslation("locale");
    const exportMenu = useRef<any>(null);

    const onClick = (e: any) => {
        exportMenu.current.toggle(e)
    }

    return <div className="menu-btn" onMouseLeave={(e)=> {
        exportMenu.current.hide(e);
    }} >
        <Button 
            size="small" 
            className={secondary ? "menu-button-secondary" : ""}
            icon="pi pi-chevron-down"  
            iconPos="right" 
            onClick={(e) => onClick(e)} 
            label={t("menuButton.actions")} 
            data-cy="menuButton-actions" 
            data-testid={"menu-button"}
        >
            <Menu
                className="w-full md:w-15rem"
                ref={exportMenu}
                popup
                model={menu} 
            />
        </Button>
    </div>
}