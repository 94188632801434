import axios from "axios";
import {getCurrentSessionIdToken} from '../queries/AuthToken'


const baseUrl  : string = process.env.REACT_APP_IDWAL_API_ENDPOINT ?? "";

const getHeader = async () => {
    const token = await getCurrentSessionIdToken();

    return {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": 'Bearer ' + token
    }
}

export const dataRequest = async (endpoint: string, data?: any, params?: any) => {

    return axios.get(baseUrl + endpoint, {
        headers: await getHeader(),
        data,
        params
    }).then(result => result.data);
}

export const postRequest = async (endpoint: string, data?: any) => {
    return axios.post(baseUrl + endpoint, data,{
        headers: await getHeader()
    }).then(result => result.data);
}

export const putRequest = async (endpoint: string, data?: any) => {
    return axios.put(baseUrl + endpoint, data,{
        headers: await getHeader()
    }).then(result => result.data);
}

export const postRequestBlob = async (endpoint: string, data?: any) => {
    const headers = await getHeader();
    headers['Accept'] = "text/csv";
    
    return await axios.post(baseUrl + endpoint, data,{
        headers,
        ...{responseType: 'blob'}
    });
}
