import { ChangeEvent, useState } from "react";
import { InputText } from "primereact/inputtext";
import { updateKeywordSearchFilter } from "./TableFunctions";
import { useTranslation } from "react-i18next";
import { SharedDefectsHeaderProps } from "../../types/SharedDefectsHeaderProps";
import { SharedExportButton } from "../DefectsTable/SharedExportButton";
import { ResetTableButton } from "./ResetTableButton";
import { defaultSharedDefectsFiltersConfig } from "../DefectsTable/Filters/FilterTemplates";
import LOCAL_TABLE_CONFIG_KEY from "../../types/TableLocalConfigKeys";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectKeywordSearch } from "../../store/selectors/filtersSelectors";
import { HeaderType } from "../../types/HeaderType";
import { setKeywordSearchViaHeaderType, setSharedDefectsTableFilterData } from "../../store/slices/filtersSlice";

export const SharedDefectsHeader = (props: SharedDefectsHeaderProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("locale");
    const keywordSearch = useAppSelector(selectKeywordSearch);

    const [keywordValue, setKeywordValue] = useState<string>(keywordSearch.sharedDefects);

    const handleOnKeywordSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const updatedFilters = updateKeywordSearchFilter(value, props.filters);
        dispatch(setSharedDefectsTableFilterData(updatedFilters));
        setKeywordValue(value);
        dispatch(setKeywordSearchViaHeaderType({ keyword: value, headerType: HeaderType.SHARED_DEFECTS }));
    }

    const onResetClick = () => {
        const updatedFilters = updateKeywordSearchFilter("", defaultSharedDefectsFiltersConfig);
        dispatch(setSharedDefectsTableFilterData(updatedFilters));
        setKeywordValue("");
    }

    return (
        <div className="flex flex-column sm:flex-row justify-content-between">
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={keywordValue} onChange={handleOnKeywordSearchChange} className="w-full max-w-19rem" 
                        disabled={props.disabled} placeholder={t("fleetTable.keywordSearch")} data-cy="keyword-search" />
                </span>
                <ResetTableButton onClick={onResetClick} tableKey={LOCAL_TABLE_CONFIG_KEY.DEFECTS} />
            </div>
            <SharedExportButton
                dataTableRef={props.dataTableRef}
                filteredData={props.filteredData}
            />
        </div>
    )
}