import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonProps } from "primereact/button";
import LOCAL_TABLE_CONFIG_KEY from "../../types/TableLocalConfigKeys";
import { classNames } from "primereact/utils";
import { useAppDispatch } from "../../hooks/storeHooks";
import { resetTableFilters } from "../../store/thunks/filtersThunks";

interface ResetTableButtonProps extends ButtonProps {
    tableKey: LOCAL_TABLE_CONFIG_KEY,
    clearKeywordSearchInput?: () => void;
    dataCy?: string;
}

export const ResetTableButton = (props: ResetTableButtonProps) => {
    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();

    const onClick = (event: MouseEvent<HTMLButtonElement>) => {
        if (props.onClick) {
            props.onClick(event);
        } else {
            dispatch(resetTableFilters(props.tableKey));
            props.clearKeywordSearchInput && props.clearKeywordSearchInput();
        }
    };

    return (
        <Button
            label={t("fleetTable.resetTable")}
            data-cy={props.dataCy ?? "reset-table-button"}
            className={classNames(["p-button p-button-outlined p-button-secondary secondary-outlined-button ml-3", props.className])}
            onClick={onClick}
        />
    )
}