import { RootState } from "../store";

export const selectFleetGraph = (state: RootState) => state.fleetGraph;

export const selectFleetGraphData = (state: RootState) => state.fleetGraph.data;

export const selectFleetGraphLoading = (state: RootState) => state.fleetGraph.loading;

export const selectSubgradeData = (state: RootState) => state.fleetGraph.subgradeData;

export const selectSubgradeLoading = (state: RootState) => state.fleetGraph.subgradeLoading;

export const selectSubgradeError = (state: RootState) => state.fleetGraph.subgradeError;