import { SSOUser } from "../types/SSOUser";
import { hotjar } from 'react-hotjar';
import HotJarConfig from "../config/hotjar";

/**
 * Initialize Hotjar analytics and send user identity information.
 * @param {SSOUser} user - The user object containing identity information.
 */
export function initHotJar(user: SSOUser) : boolean {
    if (!user) {
       console.warn("Session issue, cannot configure HotJar.");
       return false; 
    }

    const cognitoId = user.username;
    const email = user.attributes?.email;

    if (!cognitoId || !email) {
        console.warn("Session issue, cannot configure HotJar.");
        return false;
    }

    const domain = getDomainFromEmail(email!);
    const organisationId = user.attributes?.['custom:organisation'];
    const phone = user.attributes?.phone_number;
    const name = user.attributes?.name;

    if (HotJarConfig.trackingId && HotJarConfig.trackingId !== 0) {
        console.info("Initialising Hotjar and indentity...");
        hotjar.initialize(HotJarConfig.trackingId, HotJarConfig.version);
        hotjar.identify(cognitoId!, {
            email,
            domain,
            asims_organisation_id: organisationId,
            phone,
            name
        });
        return true;
    } else {
        console.warn("HotJar analytics could not be configured");
        return false;
    }
}

/**
 * Gets domain from email address
 * @param email
 * @returns string
 */
export const getDomainFromEmail = (email: string): string => {
    return email.substring(email.lastIndexOf("@") + 1);
};
