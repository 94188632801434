import axios from "axios";
import {getCurrentSessionIdToken} from '../queries/AuthToken'
import {HttpRequest} from "../types/HttpRequest";


class GroupService implements HttpRequest {
    private url: string;

    constructor() {
        this.url = process.env.REACT_APP_IDWAL_API_ENDPOINT + '/Users/Groups/FleetApp';
    }

    async post(data: string): Promise<boolean | void> {
        const token = await getCurrentSessionIdToken();

        return axios.post(this.url, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.status === 200) {
                    return true;
                }

                console.info(response);
                return false;
            })
            .catch(error => {
                    throw new Error(error.message)
                }
            );
    }
}

export default GroupService;
