import { RadioButton } from "primereact/radiobutton";
import "./SingleSelectList.css";

export type SingleSelectListItem = {
    name: string;
    value: number;
    checked: boolean;
    key: number;
};

export interface SingleSelectListProps {
    items: SingleSelectListItem[];
    onChange: (event: any) => void;
    disabled: boolean;
    selectedItemKey: number;
}

export const SingleSelectList = ({
    items,
    onChange,
    disabled,
    selectedItemKey
}: SingleSelectListProps) => {
    const selectedItem = items.find(item => item.key === selectedItemKey) ?? items[0];

    return (
        <>
            {items.map((item) => (
                <div
                    id={item.name}
                    key={item.key}
                    data-cy={`singleselect_item_${item.name}`}
                    className="single-select-item"
                >
                    <RadioButton 
                        inputId={item.key.toString()}
                        name="category"
                        value={item.key}
                        onChange={onChange}
                        checked={item.key === selectedItem.key}
                        disabled={disabled} />
                    <label 
                        htmlFor={item.key.toString()}
                        className="ml-2"
                    >
                        {item.name}
                    </label>
                </div>
            ))}
        </>
    );
}