import { Calendar } from "primereact/calendar";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { useTranslation } from "react-i18next";
import { DefectStatus } from "../../types/DefectStatus";
import { DefectDataItem } from "../../types/DefectsDataItem";
import { CostAreaData, DefectRectificationData } from "../../types/DefectRectificationData";
import { Dispatch, SetStateAction, useEffect, useImperativeHandle, useState } from "react";
import { getDefectStatusValue } from "../../utils/DefectsTableDataHelper";
import { defectStatusDropdownOptionTemplate, defectStatusDropdownValueTemplate } from "../Shared/BodyTemplates";
import { AssetUpload } from "../Shared/AssetUpload";
import { SharedDefectItem } from "../../types/SharedDefectItem";
import { useCapabilities } from "../../hooks/useCapabilities";
import { Capability } from "../../types/Capability";

/**
 * Form for defect rectification
 * Uses a ref so that its form can be captrued externally
 * @param props 
 * @returns 
 */
export const DefectRectificationForm = ( props: {
    defect: DefectDataItem | SharedDefectItem;
    disabled?: boolean;
    rectificationDetails: DefectRectificationData | null | undefined;
    fromRef: any;
    setHasUnsavedChanges: Dispatch<SetStateAction<boolean>>;
}) => {
    const { t } = useTranslation("locale"); 

    const [actionNotes, setActionNotes] = useState<string | null>(props.rectificationDetails ? props.rectificationDetails.actionNotes: null);
    const [resolutionDate, setResolutionDate] = useState<Date | null | undefined>();
    const [actualCost, setActualCost] = useState<number | undefined | null>(props.rectificationDetails ? props.rectificationDetails.actualCost: null);
    const [costArea, setCostArea] = useState<CostAreaData | null>(props.rectificationDetails ? props.rectificationDetails.costArea : null);
    const [status, setStatus] = useState<number>(props.defect.status);

    const { capabilities } = useCapabilities([
        Capability.CAP_FLEETAPP_CLOSE_DEFECTS
    ]);

    // Allows the parent component to submit this form
    useImperativeHandle(props.fromRef, () => ({
        submitForm,
    }));

    useEffect(() => {
        if(props.rectificationDetails){
            const notes = props.rectificationDetails?.actionNotes ? props.rectificationDetails.actionNotes : "";

            setActionNotes(notes);
            if (props.rectificationDetails.resolutionDate) {
                setResolutionDate(new Date(props.rectificationDetails.resolutionDate));
            }    
            setActualCost(props.rectificationDetails?.actualCost);
            setStatus(props.defect.status);
        }

    }, [props.rectificationDetails])

    /**
     * Handle submitting form
     */
    const submitForm = () => {
        const formData = {
            actionNotes,
            resolutionDate,
            actualCost,
            costArea,
        } as DefectRectificationData;
        
        if (!actionNotes) {
            formData.actionNotes = null;
        }

        if (status !== props.defect.status) {
            formData.defectStatus = status;
        }

        return formData;
    };

    const statusText = getDefectStatusValue(props.defect.status);
    const additionalUploadHeaders = {
        'defect-id': `${props.defect.defectId}`
    }

    const getDefectImo = () => {
        if ("vessel" in props.defect && "imo" in props.defect.vessel) {
            return props.defect.vessel.imo;
        }

        if ("imo" in props.defect) {
            return props.defect.imo;
        }

        return ""
    }

    const getDefectsStatusOptions = () => {
        const options = [
            { name: t("defectsTable.defectStatusOpen"), code: DefectStatus.open },
            { name: t("defectsTable.defectStatusInProgress"), code: DefectStatus.inProgress },
            { name: t("defectsTable.defectStatusRectified"), code: DefectStatus.rectified },
        ];

        if (capabilities[Capability.CAP_FLEETAPP_CLOSE_DEFECTS]) {
            options.push({ name: t("defectsTable.defectStatusClosed"), code: DefectStatus.closed });
        }

        return options;
    }

    const getRectificationMinDate = () => {
        if (props.defect.inspectionDate) {
            const dateString = props.defect.inspectionDate;
            const parts = dateString.split('/');
            const day = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10) - 1;
            const year = parseInt(parts[2], 10);

            return new Date(year, month, day);
        }

        //if date is not available unset the minDate 
        return undefined;
    }

    const onStatusChange = (event: DropdownChangeEvent) => {
        props.setHasUnsavedChanges(true);
        
        setStatus(event.value.code)

        if (!resolutionDate && event.value.code === DefectStatus.rectified) {
            setResolutionDate(new Date());
        }
    }

    return (
        <form>
            <div className="mt-1 grid">
                <div className="col-12">
                    <div className="grid pb-3">
                        <div className="pt-3 col-3">
                            <label>{t("defectRectification.actionNotesLabel")}</label>
                        </div>
                        <div className="pt-0 col-9">
                            <InputTextarea value={actionNotes ?? ""} onChange={(e) => { props.setHasUnsavedChanges(true); setActionNotes(e.target.value);}} 
                                style={{maxWidth: "100%", minHeight: 162}} rows={8} className={`col-12`} data-cy="rectification-input-notes" disabled={props.disabled} />
                        </div>
                    </div>

                    <div className="grid align-items-center pb-3">
                        <div className="pt-0 col-3">
                            <label>{t("defectRectification.resolutionDateLabel")}</label>
                        </div>
                        <div className="pt-0 col-9">
                            <Calendar 
                                value={resolutionDate}
                                readOnlyInput
                                minDate={getRectificationMinDate()}
                                maxDate={new Date()}
                                onChange={(e) => { props.setHasUnsavedChanges(true); setResolutionDate(e.value as Date)}}
                                dateFormat="dd/mm/yy"
                                data-cy="rectification-input-inspection-date-calender"
                                showIcon className="w-full"
                                disabled={props.disabled}
                            />
                        </div>
                    </div>

                    <div className="grid align-items-center pb-3">
                        <div className="pt-0 col-3">
                            <label>{t("defectRectification.estimatedCostLabel")}</label>
                        </div>
                        <div data-cy="rectification-value-cost" className="pt-0 col-9">
                            {props.defect.estimateDefectCostField}
                        </div>
                    </div>

                    <div className="grid align-items-center pb-3">
                        <div className="pt-0 col-3">
                            <label>{t("defectRectification.actualCostLabel")}</label>
                        </div>
                        <div className="pt-0 col-9">
                            <InputNumber value={actualCost} onChange={(e) => { props.setHasUnsavedChanges(true);setActualCost(e.value)}} data-cy="rectification-input-actual-cost"
                            inputId="currency-us" placeholder="$" mode="currency" currency="USD" locale="en-US" className="w-full" disabled={props.disabled}/>
                        </div>
                    </div>

                    <div className="grid align-items-center pb-3">
                        <div className="pt-0 col-3">
                            <label>{t("defectRectification.costAreaLabel")}</label>
                        </div>
                        <div className="pt-0 col-9">
                            <Dropdown value={costArea ? costArea.code : costArea} onChange={(e) => { props.setHasUnsavedChanges(true); setCostArea({code: e.value, name: e.value}); }} data-cy="rectification-input-cost-dropdown" options={[
                                    { name: t("defectRectification.costTypeCaex"), value: 'Capex' },
                                    { name: t("defectRectification.costTypeOpex"), value: 'Opex' }
                                ]} optionLabel="name" placeholder={t("defectRectification.defaultSelect")} className="w-full" disabled={props.disabled} />
                        </div>
                    </div>

                    <div className="grid align-items-center pb-3">
                        <div className="pt-0 col-3">
                            <label>{t("defectRectification.statusLabel")}</label>
                        </div>
                        <div className="pt-0 col-9">
                            <Dropdown onChange={onStatusChange} data-cy="rectification-input-status-dropdown" 
                            value={status} options={getDefectsStatusOptions()} valueTemplate={defectStatusDropdownValueTemplate} itemTemplate={defectStatusDropdownOptionTemplate}
                            optionLabel="name" placeholder={statusText} className="w-full" disabled={props.disabled} />
                        </div>
                    </div>

                    <div className="grid pb-3">
                        <AssetUpload additionalHeaders={additionalUploadHeaders} disabled={props.disabled} imo={getDefectImo()} label={t("defectRectification.uploadLabel")} />
                    </div>
                </div>
            </div>
        </form>
    );
};
