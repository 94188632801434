import { Auth } from "aws-amplify";

export const getCurrentSessionIdToken = async (): Promise<string> => {
    let token = "";

    const session = await Auth.currentSession();

    if (session) {
        token = session.getIdToken().getJwtToken();
    }

    return token;
}

export const getCurrentUserName = async (): Promise<string> => {
    const session = await Auth.currentSession();

    if (session) {
        const user = await Auth.currentAuthenticatedUser();

        const { attributes } = user;
        return attributes.name;
    }

    return "";
}

export const getCurrentUserId = async (): Promise<string> => {
    const session = await Auth.currentSession();

    if (!session) {
        return "";
    }

    const user = await Auth.currentAuthenticatedUser();
        const { username } = user;

        return username;
};