import "./ShowAllFilters.css";
import {useTranslation} from "react-i18next";


export interface ShowAllFiltersRowProps {
    title: string;
    leftHandPills?: string[];
    rightHandPills?: string[];
    displayEquivalent: boolean;
    className?: string;
}

export const ShowAllFiltersRow = (props: ShowAllFiltersRowProps) => {
    const {t} = useTranslation("locale");

    let rightHandPills: string[] | undefined;

    if (props.displayEquivalent) {
        rightHandPills = [t("showAllFilters.equivalent")]
    }
     else {
        rightHandPills = props.rightHandPills;
    }

    return (
        <div className={`grid w-100 mt-1 showAllFiltersContainer ${props.className ?? ""}`}>
            <div className={"col-1 md:col-2 pl-0 pr-0"}>
                <p className={"showAllFiltersTitle"}>{props.title}</p>
            </div>
            <div className={"col-14 md:col-10 pl-0 flex flex-wrap"}>
                {
                    props.leftHandPills ? props.leftHandPills.map(
                        (pill) => <p key={`lefthand-${pill}`} className={"showAllFiltersPill leftHandPill"}>{pill}</p>
                    ) :
                        <p className={"showAllFiltersPill leftHandPill"}>{`${t("showAllFilters.all")} ${props.title}`}</p>
                }
                {rightHandPills ? <p className={"versus"}> - vs - </p> : <></>}
                {
                    rightHandPills ? rightHandPills.map(
                        (pill) => <p key={`righthand-${pill}`} className={"showAllFiltersPill rightHandPill"}>{pill}</p>
                    ) :
                        <></>
                }
            </div>

        </div>
    )
}