import { sendGAEvent } from "../../AnalyticsService";
import { VESSEL_VIEW, VESSEL_VIEW_OPENED } from "../../constants/analytics";
import { getShipImage, getShipParticulars } from "../../services/VesselViewService";
import {  selectFleetDataItems } from "../selectors/fleetSelectors";
import { setSelectedVessel, setVesselImage, setVesselImageError, setVesselImageLoading, setVesselParticulars, setVesselParticularsError, setVesselParticularsLoading, setVesselViewOpen } from "../slices/vesselViewSlice";
import { AppThunk } from "../store";

export const selectVesselByImo = (vesselImo: string): AppThunk => async (dispatch, getState) => {
    const fleetDataItems = selectFleetDataItems(getState());

    const selectedVessel = fleetDataItems.find(
        (fleetDataItem) => fleetDataItem.vessel.imo.toUpperCase() === vesselImo.toUpperCase()
    );

    if (selectedVessel) {
        dispatch(setSelectedVessel(selectedVessel));

        sendGAEvent(VESSEL_VIEW, VESSEL_VIEW_OPENED);
        dispatch(setVesselViewOpen(true));
        dispatch(getVesselParticulars(selectedVessel.vessel.imo));
        dispatch(getVesselImage(selectedVessel.vessel.imo));
    } else {
        console.warn("Unable to find vessel with IMO: ", vesselImo);
    }
};

const getVesselParticulars = (imo: string): AppThunk => async (dispatch) => {
    dispatch(setVesselParticularsLoading(true));
    dispatch(setVesselParticularsError(false));

    try {
        const response = await getShipParticulars(imo);

        if (response) {
            dispatch(setVesselParticulars(response));
        } else {
            throw Error('no data');
        }
    } catch (e) {
        console.error(e);
        dispatch(setVesselParticularsError(true));
    } finally {
        dispatch(setVesselParticularsLoading(false));
    }
}

const getVesselImage = (imo: string): AppThunk => async (dispatch) => {
    dispatch(setVesselImageLoading(true));
    dispatch(setVesselImageError(false));

    try {
        const response = await getShipImage(imo);

        if (response) {
            dispatch(setVesselImage(response));
        } else {
            throw Error('no data');
        }
    } catch (e) {
        console.error(e);
        dispatch(setVesselImageError(true));
    } finally {
        dispatch(setVesselImageLoading(false));
    }
}
