import { sendGAEvent } from "../../AnalyticsService";
import { ADVANCED_FILTER_CATEGORY, ADVANCED_FILTER_SELECTION_CHANGED } from "../../constants/analytics";
import { BuildSelectionFilterSectionProps, MultiSelectOptions, SortType } from "../../types/BuildFilterSectionProps";
import { FilterSection } from "../../types/FilterDialog";
import { CheckboxState, createSelection, generateNewMultiSelectState, sectionIsSelected, toCheckboxState } from "./helpers";

export const buildMultiSelectSection: (props: BuildSelectionFilterSectionProps) => FilterSection = ({
    selectedSection,
    selectedFilterScope,
    sectionName,
    allLabelText,
    multiselectType,
    filterScope,
    defaultSearchTerm,
    gaForegroundTag,
    gaBackgroundTag,
    sortType,

    foregroundOptions,
    backgroundMarketOptions,
    backgroundFleetOptions,

    foregroundCount,
    backgroundFleetCount,
    backgroundMarketCount,

    foregroundSelectionState,
    backgroundFleetSelectionState,
    backgroundMarketSelectionState,

    backgroundMarketDataMessage,
    backgroundFleetDataMessage,

    onSelectSection,
    onUpdateForegroundSelection,
    onUpdateBackgroundFleetSelection,
    onUpdateBackgroundMarketSelection,
}: BuildSelectionFilterSectionProps) => {
    const allForegoundOptionsSelected = 
        foregroundSelectionState.length === foregroundOptions.length;

     const allBackgroundFleetOptionsSelected = 
        backgroundFleetSelectionState.length === backgroundFleetOptions.length;

    const allBackgroundMarketOptionsSelected = 
        backgroundMarketSelectionState.length === backgroundMarketOptions.length;

    const allForegroundSelection = createSelection(allLabelText, allForegoundOptionsSelected);
    const allBackgroundFleetSelection = createSelection(allLabelText, allBackgroundFleetOptionsSelected);
    const allBackgroundMarketSelection = createSelection(allLabelText, allBackgroundMarketOptionsSelected);

    const sortByName = (a: CheckboxState, b: CheckboxState) => a.name.localeCompare(b.name);
    const selectedForegroundState = foregroundOptions.map(
        (item: MultiSelectOptions) => 
            toCheckboxState(
                item, 
                foregroundSelectionState
            )
    )

    if(sortType == SortType.Alphabetical){
        selectedForegroundState.sort(sortByName);
    }

    const selectedBackgroundFleetState = backgroundFleetOptions?.map(
        (item: MultiSelectOptions) => 
            toCheckboxState(
                item,
                backgroundFleetSelectionState
            )
    )
    selectedBackgroundFleetState.sort(sortByName);

    const selectedBackgroundMarketState = backgroundMarketOptions?.map(
        (item: MultiSelectOptions) => 
            toCheckboxState(
                item, 
                backgroundMarketSelectionState
            )
    );
    selectedBackgroundMarketState.sort(sortByName);

    return {
        name: sectionName,
        selected: sectionIsSelected(sectionName, selectedSection),
        onClick: () => {
            sendGAEvent(ADVANCED_FILTER_CATEGORY, ADVANCED_FILTER_SELECTION_CHANGED);
            onSelectSection(sectionName)
        },
        selectedFleetItemCount: foregroundCount,
        selectedBackgroundFleetDataItemCount: backgroundFleetCount,
        selectedBackgroundMarketDataItemCount: backgroundMarketCount,
        filterScope: filterScope,
        type: multiselectType,
        fleetFilterState: {
            items: [
                allForegroundSelection,
                ...selectedForegroundState,
            ],
            onUpdateSelction: (e: any) => {
                sendGAEvent(ADVANCED_FILTER_CATEGORY, gaForegroundTag);
                const generatedState = generateNewMultiSelectState(
                    e.target.value,
                    allLabelText,
                    foregroundSelectionState,
                    foregroundOptions,
                );
                onUpdateForegroundSelection(generatedState)
            }
        },
        backgroundFleetFilterState: {
            items: [
                allBackgroundFleetSelection,
                ...selectedBackgroundFleetState,
            ],
            onUpdateSelction: (e: any) => {
                sendGAEvent(ADVANCED_FILTER_CATEGORY, gaBackgroundTag);
                const generatedState = generateNewMultiSelectState(
                    e.target.value,
                    allLabelText,
                    backgroundFleetSelectionState,
                    backgroundFleetOptions,
                );
                onUpdateBackgroundFleetSelection(generatedState)
            }
        },
        backgroundMarketFilterState: {
            items: [
                allBackgroundMarketSelection,
                ...selectedBackgroundMarketState,
            ],
            onUpdateSelction: (e: any) => {
                sendGAEvent(ADVANCED_FILTER_CATEGORY, gaBackgroundTag);
                const generatedState = generateNewMultiSelectState(
                    e.target.value,
                    allLabelText,
                    backgroundMarketSelectionState,
                    backgroundMarketOptions,
                );
                onUpdateBackgroundMarketSelection(generatedState)
            }
        },
        defaultSearchTerm,
        backgroundMarketMessage: backgroundMarketDataMessage,
        backgroundFleetMessage: backgroundFleetDataMessage,
    };
};
