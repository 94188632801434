import React from "react";
import {getVesselGrade, idwalGradeBodyTemplate} from "@idwal/idwal-react-components";
import { Tooltip } from "primereact/tooltip";
import { getTranslationObject } from "../../services/Translation";
import { INSPECTION_STATUS_FULL_REPORT } from "../../utils/Constants";
import { RiskLevel } from "../../types/RiskLevel";
import { Tag } from "primereact/tag";
import { DefectStatus } from "../../types/DefectStatus";
import { SharedDefectItem } from "../../types/SharedDefectItem";
import { getFriendlyInspectionStatusText } from "../../utils/FleetTableDataHelper";
import {FleetDataItem} from "../../types/FleetDataItem";
import {MenuButton} from "../MenuButton/MenuButton";

export const CUSTOMER_PORTAL_ROUTE = "inspection";
const t = getTranslationObject();


export const shipBodyTemplate = ( rowData: SharedDefectItem ) => { 
    let name;
    let imoNum;
    if (rowData.shipName) {
        name = rowData.shipName;
        imoNum = rowData.imo;
    }
    else {
        name = rowData.shipName
        imoNum = rowData.imo
    }

    return (
        <div className="flex justify-content-start flex-wrap">
            <div>
                <p>{name}</p>
                <small>{imoNum}</small>
            </div>
        </div>
    );
};

/**
 * Shows status tag in the status dropdown value
 * @param option 
 * @param props 
 * @returns 
 */
export const sharedDefectStatusDropdownValueTemplate = (status: number) => {        
    if (status !== null && status !== undefined) {
        if (status === DefectStatus.closed) {
            return <Tag severity="success" value={t("defectsTable.defectStatusClosed")}/>
        }

        if (status === DefectStatus.inProgress) {
            return <Tag severity="info" value={t("defectsTable.defectStatusInProgress")}/>
        }

        if (status === DefectStatus.rectified) {
            return <Tag severity="warning" value={t("defectsTable.defectStatusRectified")}/>
        }

        if (status === DefectStatus.open) {
            return <Tag severity="danger" value={t("defectsTable.defectStatusOpen")}/>
        }
    }  

    return <Tag severity="danger" value={t("defectsTable.defectStatusOpen")}/>
};

export const riskLevelBodyTemplate = (rowData: any) => {
    const riskLevel = rowData.vessel.riskLevel ?? 0;
    let riskString = t("riskLevel.none");

    switch (riskLevel) {
        case RiskLevel.very_low:
            riskString = t("riskLevel.veryLow");
            break;
        case RiskLevel.low:
            riskString = t("riskLevel.low");
            break;
        case RiskLevel.medium:
            riskString = t("riskLevel.medium");
            break;
        case RiskLevel.high:
            riskString = t("riskLevel.high");
            break;
        case RiskLevel.very_high:
            riskString = t("riskLevel.veryHigh");
            break;
    }

    return (
        <p>{riskString}</p>
    );
}

export const generateTextBodyTemplate = (fieldName: string, placeholderLocale: string) => {
    return (rowData: any) => {
        let text = rowData[fieldName] ?? "";

        if (typeof text === "number") {
            text = text.toLocaleString();
        }

        if (text.trim().length === 0){
            return <p className="p-inputtext line-height-2 p-component ml-1 w-full defect-note">{t(placeholderLocale)}</p>
        } else {
            return <p className="line-height-2 p-component ml-1 w-full defect-note">{text}</p>
        }
    }
}

export const idwalGradeBody = (rowData: any, aiGradingActive: boolean) => {
    const idwalGrade = rowData.inspection?.idwalGrade ?? rowData.idwalGrade;
    let status = rowData.inspection?.status ?? rowData.inspectionStatus;
    const guid = rowData.inspection?.guid ?? rowData.inspectionGuid;
    const imo = rowData.vessel?.imo ?? rowData.imo;
    const aiGrades = Object.keys(rowData.aiGrades).length > 0 ? rowData.aiGrades : undefined;

    if (status === INSPECTION_STATUS_FULL_REPORT) {
        status = "FULL_REPORT";
    }

    const href = `${process.env.REACT_APP_ID_PLATFORM_CUSTOMER_REPORT_URL_ROOT}/${CUSTOMER_PORTAL_ROUTE}/${guid}`;

    return idwalGradeBodyTemplate({idwalGrade, status, guid, aiGrades, imo}, aiGradingActive, href);
}


export const noteBodyTemplate = (rowData: any) => {
    const notes = rowData.vessel.notes ?? "";

    if(notes.trim().length === 0){
        return <p className="p-inputtext line-height-2 p-component ml-1 w-full defect-note">{t('defectsTable.notesPlaceholder')}</p>
    } else {
        return <p className="line-height-2 p-component ml-1 w-full defect-note">{notes}</p>
    }
}

export const vesselTypeBodyTemplate = (rowData: any) => {
    let type;
    let subtype;

    if (rowData.type) {
        type = rowData.type;
        subtype = rowData.subType;
    }
    else {
        type = rowData.vessel.type;
        subtype = rowData.vessel.subtype;
    }
   
    return (
        <div>
            <p>{type}</p>
            <small>{subtype}</small>
        </div>
    );
};

export const idwalTrendingTemplate = (rowData: FleetDataItem) => {
    let arrow = rowData.trending != null && rowData.trending <= 0 ? "⬇" : "⬆";
    let trendingValue =  rowData.trending;
    let arrowClass = "trending-down-arrow"
    let trendingString = "";

    if (trendingValue == null) {
        return <></>;
    }

    trendingString = trendingValue.toString();

    if (trendingValue == 0) {
        arrow = "⮕"
        arrowClass = "trending-arrow-equal"
    }

    if (trendingValue != null && trendingValue > 0) {
        arrowClass = "trending-up-arrow"
        trendingString = "+" + trendingValue
    }

    const date = rowData.trendingLastInspected ? new Date(rowData.trendingLastInspected) : new Date();
    const targetClass = "target_" + rowData.vessel.imo;

    return <div className="flex justify-content-center">
        <Tooltip className={"trending-tool-tip"}  autoHide={false} target={`.${targetClass}`} data-cy={targetClass}>
            {t("fleetTable.previouslyInspected")} {date.toLocaleDateString()}
        </Tooltip>

        <div>
            <div className={targetClass}>
                <span className={arrowClass}>{arrow}</span>
                <span className={"trending-text"}>{trendingString} </span>
            </div>
        </div>
    </div>
}


const openUrl = (url : string) => {
    const win = window.open(url, '_blank');
    if (win != null) {
        win.focus();
    }
}

export const actionBodyTemplate = (rowData: any, aiGradingActive: boolean, requestInspectionCallback : any) => {
    const status = rowData.inspection?.status ?? rowData.inspectionStatus;
    const guid = rowData.inspection?.guid ?? rowData.inspectionGuid;
    const idwalGrade = rowData.inspection?.idwalGrade ?? rowData.idwalGrade;
    const grades = getVesselGrade(aiGradingActive, idwalGrade, status, rowData.aiGrades);
    const reportAvailable = grades && guid && status === INSPECTION_STATUS_FULL_REPORT;
    const reportUrl = `${process.env.REACT_APP_ID_PLATFORM_CUSTOMER_REPORT_URL_ROOT}/${CUSTOMER_PORTAL_ROUTE}/${guid}`

    const menuItems = [
        {
            label: t("menuButton.requestInspection"),
            icon: <img className="pr-2" alt="request_inspection" src="./request-inspection-menu.svg"></img>,
            command: () => {
                requestInspectionCallback(rowData);
            }
        }
    ];

    if(reportAvailable) {
        menuItems.push({
            label: t("menuButton.viewReport"),
                icon: <img className="pr-2" alt="view_report" src="./view-report-menu.svg"></img>,
                command: () => openUrl(reportUrl)
            }
        )
    }

    return <MenuButton menu={menuItems}/>
}

export const subGradeActionBodyTemplate = (rowData: any) => {
    const guid = rowData.inspection?.guid ?? rowData.inspectionGuid;
    const reportUrl = `${process.env.REACT_APP_ID_PLATFORM_CUSTOMER_REPORT_URL_ROOT}/${CUSTOMER_PORTAL_ROUTE}/${guid}`

    const menuItems = [
        {
            label: t("menuButton.viewReport"),
            icon: <img className="pr-2" alt="view_report" src="./view-report-menu.svg"></img>,
            command: () => openUrl(reportUrl)
        }
    ];
    return <MenuButton menu={menuItems}/>
}


/**
 * Shows status tag in the status dropdown value
 * @param option 
 * @param props 
 * @returns 
 */
export const defectStatusDropdownValueTemplate = (option: any, props: any) => {
    if (props.value !== null && props.value !== undefined) {
        if (props.value === DefectStatus.closed) {
            return <Tag severity="success" value={t("defectsTable.defectStatusClosed")}/>
        }

        if (props.value === DefectStatus.inProgress) {
            return <Tag severity="info" value={t("defectsTable.defectStatusInProgress")}/>
        }

        if (props.value === DefectStatus.rectified) {
            return <Tag severity="warning" value={t("defectsTable.defectStatusRectified")}/>
        }

        if (props.value === DefectStatus.open) {
            return <Tag severity="danger" value={t("defectsTable.defectStatusOpen")}/>
        }
    }  

    return <Tag severity="danger" value={t("defectsTable.defectStatusOpen")}/>
};

/**
 * Shows status tag in the status dropdown option row
 * @param option 
 * @param props 
 * @returns 
 */
export const defectStatusDropdownOptionTemplate = (option: any) => {
    if (option.code === DefectStatus.closed) {
        return <Tag severity="success" value={t("defectsTable.defectStatusClosed")}/>
    }

    if (option.code === DefectStatus.inProgress) {
        return <Tag severity="info" value={t("defectsTable.defectStatusInProgress")}/>
    }

    if (option.code === DefectStatus.rectified) {
        return <Tag severity="warning" value={t("defectsTable.defectStatusRectified")}/>
    }

    if (option.code === DefectStatus.open) {
        return <Tag severity="danger" value={t("defectsTable.defectStatusOpen")}/>
    }
};

export const vesselInspectionStatusTemplate = (rowData: any) => {
    const statusText = getFriendlyInspectionStatusText(rowData.vesselLatestInspectionStatus);
    return <span>{statusText}</span>;
}

export const lastInspectedTemplate = (rowData: any) => {
    const date = new Date(rowData.inspection?.lastInspected);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    if (isNaN(year)) {
        return <span></span>
    }

    return <span>{formattedDate}</span>
}
