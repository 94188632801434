
export const VESSELS = "vessels";
export const VESSEL_TYPE = "vessel_type";
export const DWT = "DWT";
export const COUNTRY_OF_BUILD = "countryOfBuild";
export const TECHNICAL_MANAGER = "technicalmanger";
export const TEU = "teu";
export const INSPECTION_DATE = "inspectionDate";
export const VESSEL_AGE = "vesselAge"

export const filterNameToTranslatedLabel = (t: any, name: string) => {
    switch(name) {
        case VESSELS:
            return t("fleetGraph.vessels")
        case VESSEL_TYPE:
            return t("fleetGraph.vesselType")
        case DWT:
            return t("fleetTable.dwt")
        case COUNTRY_OF_BUILD:
            return t("fleetTable.countries")
        case TECHNICAL_MANAGER:
            return t("benchmarkModal.technicalManager")
        case TEU:
            return t("fleetTable.teu")
        case VESSEL_AGE:
            return t("fleetTable.vesselAge")
        case INSPECTION_DATE:
            return t("fleetTable.inspectionDate")
    }
}
