import { dataRequest, postRequest, putRequest } from "./DataRequest";
import { DefectRectificationData } from "../types/DefectRectificationData";

export const getDefectRefectificationPlan = async (inspectionId: string, defectId: number) => {
    const request: any = await dataRequest(`/Defects/rectification/${inspectionId}/${defectId}`);
    return request.data as DefectRectificationData;
}

export const updateDefectRefectificationPlan = async (inspectionId: string, defectId: number, body: any) => {
    return await putRequest(`/Defects/rectification/${inspectionId}/${defectId}`, body);
}

export const createDefectRefectificationPlan = async (inspectionId: string, defectId: number, body: any) => {
    return await postRequest(`/Defects/rectification/${inspectionId}/${defectId}`, body);
}

export const getDefectStatusActivity = async (defectId: number) => {
    return await dataRequest(`/Fleet/defectStatusActivity/${defectId}`);
}
