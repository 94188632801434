export const DefectSummaryTableFields = {
    VESSEL_NAME: "vesselName",
    IMO: "imo",
    ACTIONS: "actions",
    TOTAL_DEFECTS: "totalDefects",
    PRIORITY: "priority",
    STATUS: "status",
    CLOSED_STATUS: "closedStatusCount",
    RECTIFIED_STATUS: "rectifiedStatusCount",
    IN_PROGRESS_STATUS: "inProgressStatusCount",
    OPEN_STATUS: "openStatusCount",
    HIGH_PRIORITY_COUNT: "highPriorityCount",
    MEDIUM_PRIORITY_COUNT: "mediumPriorityCount",
    LOW_PRIORITY_COUNT: "lowPriorityCount",
    INSPECTED: "inspected",
    TECHNICAL_MANAGER: "technicalManager",
    VESSEL_TYPE: "vesselType"
};