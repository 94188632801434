export const FleetSelectionIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <rect x="2" y="2" width="20" height="20" stroke="white" strokeWidth="4"/>
        <path d="M3 3H21V21H3V3Z" fill="#28235B"/>
    </svg>
)

export const MarketSelectionIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <path d="M13 24C19.0751 24 24 19.0751 24 13C24 6.92487 19.0751 2 13 2C6.92487 2 2 6.92487 2 13C2 19.0751 6.92487 24 13 24Z" fill="#BBBFC3" stroke="white" strokeWidth="4"/>
    </svg>
)