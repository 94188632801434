import { RootState } from "../store";

export const selectDefect = (state: RootState) => state.defect;

export const selectDefects = (state: RootState) => state.defect.data;

export const selectDefectsLoading = (state: RootState) => state.defect.loading;

export const selectFilteredDefects = (state: RootState) => state.defect.filteredDefects;

export const selectActiveTab = (state: RootState) => state.defect.activeTab;

export const selectShowCloseDefectsDialog = (state: RootState) => state.defect.showCloseDefectsDialog;

export const selectShowShareDefectsDialog = (state: RootState) => state.defect.showShareDefectsDialog;

export const selectSelectedVessel = (state: RootState) => state.defect.selectedVessel;

export const selectInsightsFilterBy = (state: RootState) => state.defect.insightsFilterBy;
