import { FleetGraphDataItem } from "../types/FleetGraphDataItem";

const fleetItemUnderAgeLimit = (ageLimit: number) => (item: FleetGraphDataItem) => 
    item.ship_age < ageLimit

const fleetItemOverAgeLimit = (ageLimit: number) => (item: FleetGraphDataItem) => 
    item.ship_age > ageLimit

const toAge = (item: FleetGraphDataItem) => 
    item.ship_age

const byNonNegativeAge = (age: number) => 
    age > 0

export const getDefaultGraphXAxisMaxValue = (
    fleetData: Array<FleetGraphDataItem>,
    ageLimit: number,
    ageIncrement: number
): number =>
    fleetData.every(fleetItemUnderAgeLimit(ageLimit))
        ? ageLimit
        : Math.ceil(Math.max(...fleetData.map(toAge)) / ageIncrement) * ageIncrement

export const getScaledMinXAxisValueBasedOnFleetData = (
    fleetData: Array<FleetGraphDataItem>,
    backgroundData: Array<FleetGraphDataItem>,
    ageIncrement: number
): number => {
    const minFleetAgeValue = Math.min(...fleetData.map(toAge).filter(byNonNegativeAge));
    const minBackgroundAgeValue =  Math.min(...backgroundData.map(toAge).filter(byNonNegativeAge));

    return Math.floor(Math.min(minFleetAgeValue, minBackgroundAgeValue) / ageIncrement) * ageIncrement;
}

export const getScaledMaxXAxisValueBasedOnFleetData = (
    fleetData: Array<FleetGraphDataItem>,
    backgroundData: Array<FleetGraphDataItem>,
    maxValue: number,
    ageIncrement: number
): number => {
    const allFleetItemsUnderThreshold = fleetData.every(fleetItemUnderAgeLimit(maxValue));
    const allBackgroundItemsUnderThreshold = backgroundData.every(fleetItemUnderAgeLimit(maxValue));
    const someFleetItemsOverThreshold  = fleetData.some(fleetItemOverAgeLimit(maxValue));

    if (someFleetItemsOverThreshold) {
        return Math.ceil(Math.max(...fleetData.map(toAge)) / ageIncrement) * ageIncrement
    }

    if (allFleetItemsUnderThreshold && allBackgroundItemsUnderThreshold) {
        return Math.ceil(Math.max(...fleetData.map(toAge), ...backgroundData.map(toAge)) / ageIncrement) * ageIncrement;
    }

    if (allFleetItemsUnderThreshold && !allBackgroundItemsUnderThreshold) {
        return maxValue
    }

    return Math.ceil(Math.max(...fleetData.map(toAge)) / ageIncrement) * ageIncrement;
}