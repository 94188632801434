import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { ReactElement, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { getDefaultSubgradeColumnsConfig, getStaticColumnsConfig, registerCustomFilters } from "./FilterTemplates";
import {errorToast, vesselBodyTemplate} from "@idwal/idwal-react-components";
import {subGradeActionBodyTemplate} from "../Shared/BodyTemplates";
import { SharedTableColumnConfig } from "../../types/SharedTableColumnConfig";
import LOCAL_TABLE_CONFIG_KEY from "../../types/TableLocalConfigKeys";
import { onCustomRestoreState } from "../../utils/TableUtils";
import { VesselSubgradeDataItem } from "../../types/VesselSubgradeDataItem";
import { applyGlobalFilter } from "../Shared/GlobalFilter";
import {
    buildColumns,
    exportTable,
    loadSavedColumnConfigurationsV2,
    onColumnReorder,
    onColumnToggle,
    updateStoredTableConfigState,
} from "../Shared/TableFunctions";
import { DefaultHeader } from "../Shared/DefaultHeader";
import { SubgradeTableFields } from "./SubgradeTableFields";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { columnTemplates, filterTemplates, keywordSearchFilterFields } from "./Constants";
import { addCustomFieldFleetFiltersToData, mapVesselSubgradeData } from "../../utils/SubgradeDataHelper";
import { VesselSubgradeData } from "../../types/VesselSubgradeResponse";
import { getSubGradesForGroup } from "../../utils/GroupDropDownHelper";
import { ANALYTICS_TAB_CLICK, SUB_GRADE_OVERVIEW_EXPORT_CLICK } from "../../constants/analytics";
import { sendGAEvent } from "../../AnalyticsService";
import { HeaderType } from "../../types/HeaderType";
import {actionColumn, vesselColumn} from "../FleetTable/Columns/StaticColumns";
import { USER_APP_STATE_SUBGRADE_TABLE_KEY } from "../../utils/Constants";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectDataTableFilterData, selectGlobalFilters, selectKeywordSearch } from "../../store/selectors/filtersSelectors";
import { selectAiGradingActive, selectSelectedGroupName, selectUserDetails } from "../../store/selectors/userSelectors";
import { setSubGradeTableFilterData } from "../../store/slices/filtersSlice";
import { selectVesselByImo } from "../../store/thunks/vesselViewThunks";
import { selectFleetGraph } from "../../store/selectors/fleetGraphSelectors";
import { selectFleet } from "../../store/selectors/fleetSelectors";
import { UserDetails } from "../../types/UserDetails";
import { setUserDetails } from "../../store/slices/userSlice";

export const SubgradeTable = (): ReactElement => {
    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();
    const globalFilters = useAppSelector(selectGlobalFilters);
    const userDetails = useAppSelector(selectUserDetails);
    const selectedGroupName = useAppSelector(selectSelectedGroupName);
    const keywordSearch = useAppSelector(selectKeywordSearch);
    const dataTableFilterData = useAppSelector(selectDataTableFilterData);
    const aiGradingActive = useAppSelector(selectAiGradingActive);

    const [shipTypes, setShipTypes] = useState<string[]>([]);
    const [technicalManagers, setTechnicalManagers] = useState<string[]>([]);
    const [filteredData, setFilteredData] = useState<VesselSubgradeDataItem[]>([]);
    const [subgradeData, setSubgradeData] = useState<VesselSubgradeDataItem[]>([]);
    const [columns, setColumns] = useState<SharedTableColumnConfig[]>(getDefaultSubgradeColumnsConfig());
    const [selectedColumns, setSelectedColumns] = useState(columns);
    const subgradeTableRef = useRef<any>(null);

    const {
        subgradeData: subgradeResponse,
        subgradeError,
        subgradeLoading,
    } = useAppSelector(selectFleetGraph);

    const {
        loading: loadingFleetData,
        inspectionAndGradeData,
        inspectionAndGradeDataLoading,
        inspectionAndGradeDataError,
    } = useAppSelector(selectFleet);

    registerCustomFilters();

    useEffect(() => {
        if (!subgradeResponse || !inspectionAndGradeData) {
            return;
        }

        let groupSubGrades: { [key: string]: VesselSubgradeData } = getSubGradesForGroup(
            selectedGroupName,
            subgradeResponse,
            inspectionAndGradeData,
        );
        let data: VesselSubgradeDataItem[] = mapVesselSubgradeData(
            groupSubGrades,
            inspectionAndGradeData.fleetDataItems,
        );

        addCustomFieldFleetFiltersToData(data);

        data = data.filter(applyFilter);

        setSubgradeData(data);
        setFilteredData(data);

        const distinctShipTypes = Array.from(new Set([...data.flatMap((e: VesselSubgradeDataItem) => e.type)]));
        setShipTypes(distinctShipTypes);

        const distinctTechnicalManagers = Array.from(
            new Set([...data.flatMap((e: VesselSubgradeDataItem) => e.technicalManager)]),
        )
            .filter((e) => !!e)
            .sort((a, b) => a.localeCompare(b));
        setTechnicalManagers(distinctTechnicalManagers);

        loadSavedColumnConfigurationsV2(
            columns, setColumns, setSelectedColumns, userDetails, userDetails?.appState?.subgradeTable, USER_APP_STATE_SUBGRADE_TABLE_KEY, LOCAL_TABLE_CONFIG_KEY.SUBGRADE_COLUMNS);
    }, [inspectionAndGradeData, subgradeResponse, globalFilters, selectedGroupName]);

    if (subgradeError || inspectionAndGradeDataError) {
        errorToast(t("subgradeTable.tableLoadErrorMessage"));
        return <div />;
    }

    const applyFilter = (dataItem: VesselSubgradeDataItem) => {
        return (
            applyGlobalFilter(globalFilters, dataItem.vesselName, dataItem.type, dataItem.technicalManager, dataItem.shipAge)
        );
    };

    const onFilter = (event: DataTableStateEvent) => {
        dispatch(setSubGradeTableFilterData(event.filters));
    }

    const selectVessel = (rowData: any) => {
        dispatch(selectVesselByImo(rowData.vessel.imo));
    }

    const header = () => (
        <DefaultHeader
            onColumnToggle={(e: MultiSelectChangeEvent) =>
                onColumnToggle(e, LOCAL_TABLE_CONFIG_KEY.SUBGRADE_COLUMNS, columns, setSelectedColumns, userDetails, USER_APP_STATE_SUBGRADE_TABLE_KEY)
            }
            columns={columns}
            disabled={inspectionAndGradeDataLoading || subgradeLoading}
            selectedColumns={selectedColumns}
            filters={dataTableFilterData.subGrade}
            headerType={HeaderType.SUB_GRADE}
            onColumnReorder={(orderedColumns: any) =>
                onColumnReorder(
                    orderedColumns,
                    LOCAL_TABLE_CONFIG_KEY.SUBGRADE_COLUMNS,
                    LOCAL_TABLE_CONFIG_KEY.SUBGRADE_ORDERED_COLUMNS,
                    columns,
                    selectedColumns,
                    setColumns,
                    setSelectedColumns,
                    userDetails,
                    USER_APP_STATE_SUBGRADE_TABLE_KEY
                )
            }
        />
    );

    const onExport = () => {
        exportTable(getStaticColumnsConfig().concat(columns), filteredData, "Sub_Grade_Overview_", "csv");
        sendGAEvent(ANALYTICS_TAB_CLICK, SUB_GRADE_OVERVIEW_EXPORT_CLICK);
    };

    const isLoading = inspectionAndGradeDataLoading || subgradeLoading || loadingFleetData;

    const columnComponents = buildColumns(
        selectedColumns,
        columnTemplates(aiGradingActive),
        filterTemplates(shipTypes, technicalManagers),
        (e: SharedTableColumnConfig) =>
            e.field.includes("subgrades.") || e.field == SubgradeTableFields.IDWAL_GRADE ? "12rem" : "14rem",
    );

    const updateUserDetails = (userDetails: UserDetails) => {
        dispatch(setUserDetails(userDetails));
    }

    const customSaveState = (state: any) => {
        updateStoredTableConfigState(
            USER_APP_STATE_SUBGRADE_TABLE_KEY,
            columns,
            selectedColumns,
            userDetails,
            updateUserDetails,
            userDetails?.appState?.subgradeTable,
            state,
            LOCAL_TABLE_CONFIG_KEY.SUBGRADE
        );
    }

    const customRestoreState = () => {
        return onCustomRestoreState(
            LOCAL_TABLE_CONFIG_KEY.SUBGRADE, keywordSearch.subGrade, userDetails, USER_APP_STATE_SUBGRADE_TABLE_KEY);
    }

    return (
        <>
            <div className={"table-container subgrade-table-container m-0 p-0"} data-cy="subgrade-table-container">
                <DataTable
                    ref={subgradeTableRef}
                    globalFilterFields={keywordSearchFilterFields}
                    scrollable
                    data-cy="subgrade-table"
                    filters={dataTableFilterData.subGrade}
                    filterDisplay="row"
                    header={header()}
                    value={subgradeData}
                    onValueChange={setFilteredData}
                    responsiveLayout="scroll"
                    onFilter={onFilter}
                    stateStorage="custom"
                    customSaveState={(state) => customSaveState(state)}
                    customRestoreState={customRestoreState}
                    loading={isLoading}
                    emptyMessage={t("subgrades.noDataMessage")}
                    removableSort
                    stripedRows
                    resizableColumns
                    columnResizeMode="expand"
                >
                    {vesselColumn((e: any) => vesselBodyTemplate({ vessel: e }, selectVessel))}
                    {actionColumn( (e: any) => subGradeActionBodyTemplate(e))}
                    {columnComponents}
                </DataTable>
            </div>
            <div className="flex justify-content-center mb-3 mt-3">
                <Button
                    icon="pi pi-file-export"
                    iconPos="left"
                    disabled={inspectionAndGradeDataLoading || subgradeLoading}
                    onClick={onExport}
                    label={t("fleetTable.export")}
                    data-cy="export-button"
                />
            </div>
        </>
    );
};
