import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { getTranslationObject } from "../../../services/Translation";
import { DefectDataItem } from "../../../types/DefectsDataItem";
import { getGradingAreaFilterValues } from "../../../utils/DefectsTableDataHelper";
import { SharedDefectItem } from "../../../types/SharedDefectItem";

const t = getTranslationObject();

/**
 * This filter template provides a list of grading areas in a filter menu
 * It searches by string given
 * @param options 
 * @param defectData 
 * @returns 
 */
export const gradingAreaFilter = (options: any, defectData: DefectDataItem[] | SharedDefectItem[] | undefined) => {
    let gradingAreaFilterValues = [] as string[];
    if (defectData) {
        gradingAreaFilterValues = getGradingAreaFilterValues(defectData);
    }
    
    return (
        <MultiSelect
            data-cy="grading-area-filter-input"
            value={options.value}
            options={gradingAreaFilterValues}
            onChange={(e: MultiSelectChangeEvent) => options.filterApplyCallback(e.value)}
            placeholder={t("defectsTable.showAll")}
            className="p-column-filter"
            filter
            multiple
            showClear
        />
    );
};

/**
 * Handles searching for multiple and single grading areas
 * @param value 
 * @param filter 
 * @returns 
 */
export const customGradingAreaFilter = (value: string, filter: string[]) : boolean => {
    if (!filter || filter.length === 0) {
        return true; // Empty filter, return all data
    }

    const areas = value.split(',').map(area => area.trim());

    return filter.some(searchString => areas.includes(searchString));
};

  
    