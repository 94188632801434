import { SubgradeAreaMapping } from "../components/SubGrades/SubgradeAreaMapping";
import { SubgradeTableFields } from "../components/SubGrades/SubgradeTableFields";
import { FleetDataItem } from "../types/FleetDataItem";
import { SubgradeBackingData } from "../types/SubgradeBackingData";
import { VesselSubgradeDataItem } from "../types/VesselSubgradeDataItem";
import {VesselSubgradeResponse} from "../types/VesselSubgradeResponse";
export const getSubgradeBackingDataSectionAverages = (data: SubgradeBackingData) => {
    const averageScores: Record<string, number> = {};

    for (const [key, value] of Object.entries(data)) {
        averageScores[key] = value as number;
    }
    return averageScores;
}

export const calculateDeviationPercentages = (sectionAverages: any, backgroundData: any) => {
    const sectionDeviationPercentages: { [section: string]: number } = {};
    const backgroundAverages = getSubgradeBackingDataSectionAverages(backgroundData);

    for (const section in sectionAverages) {
        const backgroundSectionAverage = backgroundAverages[section];
        if (backgroundSectionAverage === null || backgroundSectionAverage === undefined || backgroundSectionAverage === 0) {
            continue;
        }

        let percentage = ((sectionAverages[section] - backgroundSectionAverage) / backgroundSectionAverage) * 100;

        if(!percentage){
            percentage = 0;
        }

        sectionDeviationPercentages[section] = percentage;
    }

    return sectionDeviationPercentages;
}

export const calculateAverageSectionGrade = (data: VesselSubgradeResponse) => {
    const sectionAverages: { [section: string]: number } = {};
    const sectionCounts: { [section: string]: number } = {};

    for (const vesselData of Object.values(data)) {
        for (const gradeTuple of Object.entries(vesselData.subGrades)) {
            const grade = gradeTuple[1];

            sectionCounts[grade.name] = (sectionCounts[grade.name] || 0) + 1;

            if (grade.score != 0) {
                sectionAverages[grade.name] = (sectionAverages[grade.name] || 0) + grade.score;
            }
        }
    }

    for (const section in sectionAverages) {
        sectionAverages[section] /= sectionCounts[section] || 1;
    }

    return sectionAverages;
}

export const mapToGraphAxis = (deviationData: any, sectionAverages: any, trendData : any) => {
    const xValues: string[] = [];
    const yValues: number[] = [];

    Object.entries(deviationData).forEach(([section, deviation]) => {
        let xValue = section;

        const sectionAverage = sectionAverages[section] as number;
        if (sectionAverage >= 0) {
            xValue = xValue + ": " + sectionAverage.toFixed();
        }

        if(trendData){
            xValue += getTrendDecoration(trendData[section]);
        }

        xValues.push(xValue);

        const deviationPercentage = Math.round((deviation as number) * 100) / 100;
        yValues.push(deviationPercentage);
    });

    return {
        x: xValues,
        y: yValues,
    };
}

export const getTrendDecoration = (trend : string) => {
    const trendNumber = parseInt(trend);

    if(trendNumber > 0) {
        return '<span style="color: #53A440; font-size: 1rem"> <span style="color: black; font-size: 1rem"> |</span> ⬆</span>+' + trendNumber;
    }

    if(trendNumber == 0) {
        return '<span style="color: #68658C; font-size: 1rem"> <span style="color: black; font-size: 1rem"> |</span> ⮕</span>    ' + trendNumber;
    }

    if(trendNumber < 0) {
        return '<span style="color: #EC1E17; font-size: 1rem"> <span style="color: black; font-size: 1rem"> |</span> ⬇ </span>' + trendNumber;
    }
    return "";
}

export const mapVesselSubgradeData = (subgradeResponse: VesselSubgradeResponse, inspectionAndGradeResponse: FleetDataItem[]) => {
    let data : VesselSubgradeDataItem[] = [];
    Object.entries(subgradeResponse).forEach(x => {
        const imo = x[0];
        const subgradeDataForVessel = x[1];

        const inspectionData = inspectionAndGradeResponse.filter(x => x.vessel.imo == imo)[0];

        if (!inspectionData?.inspection?.idwalGrade){
            console.warn(`no inspection data found for imo ${imo}`)
            return;
        }

        const getSubgrade = (key: string): number | string => {
            const fullName = (SubgradeAreaMapping)[key];
            const foundSubgrade = subgradeDataForVessel.subGrades.find((subgrade) => subgrade.name.toUpperCase() === fullName.toUpperCase());

            if(!foundSubgrade){
                console.warn("No matching subgrade found for ", key)
            }

            if(!foundSubgrade?.score){
                return "N/A"
            }
            return foundSubgrade?.score;
        }

        const dataItem : VesselSubgradeDataItem = {
            idwalGrade: inspectionData.inspection.idwalGrade,
            imo: subgradeDataForVessel.shipIMO,
            countryOfBuild: subgradeDataForVessel.countryOfBuild,
            shipAge: subgradeDataForVessel.shipAge,
            vesselName: inspectionData.vessel.vesselName,
            id: subgradeDataForVessel.id,
            subgrades: {
                bridgeAndNavigationEquipment: getSubgrade(SubgradeTableFields.SUBGRADES.BRIDGE_AND_NAVIGATION_EQUIPMENT),
                accomodation: getSubgrade(SubgradeTableFields.SUBGRADES.ACCOMMODATION),
                lifesavingAppliances: getSubgrade(SubgradeTableFields.SUBGRADES.LIFESAVING_APPLIANCES),
                mooringDecks: getSubgrade(SubgradeTableFields.SUBGRADES.MOORING_DECKS),
                engineRoomAndMachinery: getSubgrade(SubgradeTableFields.SUBGRADES.ENGINE_ROOM_AND_MACHINERY),
                vesselCapabilitiesAndCargoSystems: getSubgrade(SubgradeTableFields.SUBGRADES.VESSEL_CAPABILITIES_AND_CARGO_SYSTEMS),
                ballastTankAndSystems: getSubgrade(SubgradeTableFields.SUBGRADES.BALLAST_TANK_AND_SYSTEMS),
                weatherDecksAndFittings: getSubgrade(SubgradeTableFields.SUBGRADES.WEATHER_DECKS_AND_FITTINGS),
                hull: getSubgrade(SubgradeTableFields.SUBGRADES.HULL),
                pollutionControl: getSubgrade(SubgradeTableFields.SUBGRADES.POLLUTION_CONTROL),
                onboardManagement: getSubgrade(SubgradeTableFields.SUBGRADES.ONBOARD_MANAGEMENT),
                safeWorkingEnvironment: getSubgrade(SubgradeTableFields.SUBGRADES.SAFE_WORKING_ENVIRONMENT),
                forthcomingRegulatoryCompliance: getSubgrade(SubgradeTableFields.SUBGRADES.FORTHCOMING_REGULATORY_COMPLIANCE),
                crewWelfare: getSubgrade(SubgradeTableFields.SUBGRADES.CREW_WELFARE),
                crewPerformance: getSubgrade(SubgradeTableFields.SUBGRADES.CREW_PERFORMANCE),
                safetyManagement: getSubgrade(SubgradeTableFields.SUBGRADES.SAFETY_MANAGEMENT),
                plannedMaintenanceSystem: getSubgrade(SubgradeTableFields.SUBGRADES.PLANNED_MAINTENANCE_SYSTEM),
                classificationAndCertification: getSubgrade(SubgradeTableFields.SUBGRADES.CLASSIFICATION_AND_CERTIFICATION),
                pscPerformance: getSubgrade(SubgradeTableFields.SUBGRADES.PSC_PERFORMANCE),
                fireFightingEquipmentAndSystems: getSubgrade(SubgradeTableFields.SUBGRADES.FIRE_FIGHTING_EQUIPMENT_AND_SYSTEMS),
                designAndConstruction: getSubgrade(SubgradeTableFields.SUBGRADES.DESIGN_AND_CONSTRUCTION),
            },
            inspectionDate: subgradeDataForVessel.inspectionDate,
            location: inspectionData.inspection.location?.name,
            country:inspectionData.inspection.location?.country?.name,
            type: inspectionData.vessel.type,
            inspectionStatus: inspectionData.inspection.status,
            technicalManager: inspectionData.vessel.technicalManager,
            inspectionGuid: inspectionData.inspection.guid!,
            aiGrades: inspectionData.aiGrades ?? undefined,
            dwt: inspectionData.vessel.dwt,
            teu: inspectionData.vessel.teu,
            conditionScore: inspectionData.inspection.conditionScore!,
            managementScore: inspectionData.inspection.managementScore!,
        }

        data.push(dataItem);
    });

    return data;
}

export const addCustomFieldFleetFiltersToData = (data: VesselSubgradeDataItem[]) => 
    data.map(x => {
        x.nameFilterField = x.vesselName + " " + x.imo;
        x.locationFilterField = new Date(x.inspectionDate).toLocaleDateString("en-GB") + " " + x.location + x.country
    });