import { NOTIFICATION_ICON_MAP, NOTIFICATION_TYPE_MAP } from "./Constants";
import { useTranslation } from "react-i18next";
import { FleetNotification } from "../../types/NotificationsModalProps";
import { NOTIFICATION_CARD_TYPES } from "@idwal/idwal-react-components/dist/Notifications/Constants";
import { NotificationTypes } from "../../types/NotificationTypes";
import {navigateToRectificationView} from "../DefectRectificationHelpers";
import {useNavigate} from "react-router-dom";
import { NotificationDto } from "@idwal/idwal-react-components/dist/types/NotificationModalProps";
import { markNotificationsAsRead } from "../../services/NotificationService";
import { getNotificationAction, getNotificationSubTexts, getNotificationTitle, getNotificationButtonText } from "./utils";
import { DEFECTS } from "../../types/RouteNames";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectDefect } from "../../store/selectors/defectSelectors";
import { selectNotifications } from "../../store/selectors/notificationsSelectors";
import { fetchNotifications } from "../../store/thunks/notificationsThunks";
import { useEffect } from "react";

export const Notifications = () => {
    const {t} = useTranslation("locale");
    const dispatch = useAppDispatch();

    const { read, unread, loading, error } = useAppSelector(selectNotifications)

    const { data: defects } = useAppSelector(selectDefect);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => 
            dispatch(fetchNotifications())
        , 30000);

        return () => {
          clearInterval(interval);
        }
    }, [])

    const markAllNotificationsAsRead = async (notifications: NotificationDto[]) => {
        const response = await markNotificationsAsRead(notifications);

        if (response) {
            dispatch(fetchNotifications());
        }
    }

    if (error) {
        console.error(t("notifications.error"));
        return {
            mappedNotifications: [],
            loading,
            markAllNotificationsAsRead
        };
    }

    const mergedNotifications = read.concat(unread);

    async function notificationAction(notification: FleetNotification, context: any) {
        const { action, markAsRead } = getNotificationAction(notification);

        if (markAsRead) {
            await markNotificationsAsRead([notification]);
            dispatch(fetchNotifications());
        }

        action(context);
    }

    const mappedNotifications = mergedNotifications?.map((notification: FleetNotification) => {
        const title = getNotificationTitle(notification, t);
        const subTexts = getNotificationSubTexts(notification);
        const buttonText = getNotificationButtonText(notification, t);

        return {
            ...notification,
            title,
            buttonText,
            iconSrc: `${process.env.PUBLIC_URL}/${NOTIFICATION_ICON_MAP[notification.key as keyof NotificationTypes]}`,
            read: notification.read,
            dateTime: notification.dateTime,
            action: () => notificationAction(notification, {
                navigateToRectificationView,
                navigate,
                defects,
                DEFECTS
            }),
            type: NOTIFICATION_TYPE_MAP[notification.key as keyof NotificationTypes] as NOTIFICATION_CARD_TYPES,
            subText1: subTexts.subText1,
            subText2: subTexts.subText2
        } as NotificationDto;
    });

    return {
        mappedNotifications,
        loading,
        markAllNotificationsAsRead
    }
}