import { SharedTableColumnConfig } from "../../../types/SharedTableColumnConfig";
import {COLUMN_DATA_TYPES, specialSurveyDateConfig} from "./ColumnConfigs";
import {FleetDataItem} from "../../../types/FleetDataItem";
import {buildColumnConfig} from "../../Shared/TableFunctions";
import {generateTextBodyTemplate} from "../../Shared/BodyTemplates";
import {onCellEditCompleteGenerator} from "../Utils";
import {numberEditorTemplate, textEditorTemplate} from "../FilterTemplates";
import {errorToast} from "@idwal/idwal-react-components";
import {getTranslationObject} from "../../../services/Translation";
import {FilterMatchMode} from "primereact/api";
import { CUSTOM_COLUMN_PREFIX } from "../../../utils/Constants";

const t = getTranslationObject();

interface ColumnNameAndDataType {
    name: string;
    dataType: string;
}

export const addEnabledColumns = (enabledColumns: any[], columns: SharedTableColumnConfig[]) => {
    if (!enabledColumns) {
        return columns;
    }
    
    if (enabledColumns.includes("Special Survey Date")) {
        const specialSurveyDateColumnConfig = specialSurveyDateConfig();
        if (!columns.find(e => e.field === specialSurveyDateColumnConfig.field)) {
            columns.push(specialSurveyDateColumnConfig)
        }
    }

    return columns;
}

export function createCustomColumnConfiguration(columnName: string, fieldName: string, dataType: string) {
    const matchMode = FilterMatchMode.CONTAINS;

    let primeReactDataType;
    let bodyTemplate;
    let editorTemplate;
    const friendlyName = columnName.replace(CUSTOM_COLUMN_PREFIX, "");

    const filterConfig = {
        filterField: fieldName,
        showFilterMenu: true,
        showFilterMatchModes: true,
        filterPlaceholder: `${t("searchBy")} ${friendlyName}`,
        filterMatchMode: matchMode
    };

    switch (dataType) {
        case "Text":
            primeReactDataType = COLUMN_DATA_TYPES.text;
            editorTemplate = textEditorTemplate;
            bodyTemplate = generateTextBodyTemplate(fieldName, "columnEdit.textInputPlaceholder");
            break;
        case "Numerical":
            primeReactDataType = COLUMN_DATA_TYPES.numeric;
            bodyTemplate = generateTextBodyTemplate(fieldName, "columnEdit.numberInputPlaceholder");
            editorTemplate = numberEditorTemplate;
            break;
        default:
            errorToast(t("columnEdit.unrecognisedColumnType"));
            break;
    }

    let callBacks = {
        onCellEditComplete: onCellEditCompleteGenerator(fieldName),
        editor: editorTemplate
    };

    return buildColumnConfig(fieldName, friendlyName, primeReactDataType, filterConfig, undefined, undefined, callBacks, bodyTemplate);
}

export const createCustomColumns = (fleetDataItems: FleetDataItem[]): SharedTableColumnConfig[] => {
    const customColumns: SharedTableColumnConfig[] = [];
    const columnNameAndDataTypes: ColumnNameAndDataType[] = getCustomColumnInfo(fleetDataItems);

    columnNameAndDataTypes.forEach(element => {
        const customColumn = createCustomColumnConfiguration(element.name, element.name, element.dataType);
        customColumns.push(customColumn);
    });

    return customColumns;
}

export const getCustomColumnInfo = (fleetDataItems: FleetDataItem[]): ColumnNameAndDataType[] => {
    const columnNameAndDataTypes: ColumnNameAndDataType[] = [];

    fleetDataItems.forEach((fleetItem: FleetDataItem) => {
        Object.keys(fleetItem).forEach(fleetItemKey => {
            const columnNameAndDataType = {
                name: "",
                dataType: ""
            };

            if (fleetItemKey.startsWith(CUSTOM_COLUMN_PREFIX)) {
                columnNameAndDataType.name = fleetItemKey;
                columnNameAndDataType.dataType = fleetItem[`${fleetItemKey}.dataType`];
            }

            if (columnNameAndDataType.name && columnNameAndDataType.dataType && !columnNameAndDataTypes.some(element => element.name === columnNameAndDataType.name)) {
                columnNameAndDataTypes.push(columnNameAndDataType);
            }
        })
    })

    return columnNameAndDataTypes;
}