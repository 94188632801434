import { useTranslation } from "react-i18next";
import Plot from "react-plotly.js"
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useMemo, useState } from "react";
import { getDefectsGraphData } from "../../utils/DefectGraphHelper";
import { Config, Layout, PlotData } from "plotly.js";
import { ClearSelectedVesselButton } from "../Shared/ClearSelectedVesselButton";
import "./DefectsGraph.css";
import { useAppSelector } from "../../hooks/storeHooks";
import { selectFilteredDefects, selectSelectedVessel } from "../../store/selectors/defectSelectors";

type DefectsGraphProps = {
    isLoading: boolean;
}

export const DefectsGraph = (props: DefectsGraphProps) => {
    const { isLoading } = props;

    const { t } = useTranslation("locale");

    const filteredDefects = useAppSelector(selectFilteredDefects);
    const selectedVessel = useAppSelector(selectSelectedVessel);

    const [categoryArray, setCategoryArray] = useState<string[]>([]);
    const [data, setData] = useState<Partial<PlotData>[]>([]);
    const [maxValue, setMaxValue] = useState(0);

    useEffect(() => {
        const { defectFilterValues, defectsGraphData, defectsMaxValue } = getDefectsGraphData(filteredDefects, selectedVessel);

        setCategoryArray(defectFilterValues);
        setData(defectsGraphData);
        setMaxValue(defectsMaxValue);
    }, [filteredDefects, selectedVessel]);

    const axisTicks = useMemo(() => {
         if (maxValue <= 4 && maxValue > 0) {
            const arr = [];
            for (let i = 1; i <= maxValue; i++) {
                arr.push(i);
            }
            return arr;
        }
        return undefined;
    }, [data]);

    const layout = useMemo(() => {
        const layout: Partial<Layout> = {
            autosize: true,
            xaxis: {
                autorange: true,
                showgrid: true,
                visible: true,
                fixedrange: true,
                tickvals: axisTicks,
                gridcolor: "#B0E2E4",
                tickfont: {
                    color: '#777',
                    size: 12,
                },
            },
            yaxis: {
                autorange: true,
                showgrid: false,
                automargin: true,
                categoryarray: categoryArray,
                categoryorder: "array",
                fixedrange: true,
                tickfont: {
                    color: '#555',
                    size: 14,
              },
            },
            margin: {
                pad: 10,
                l: 100,
                t: 20,
                b: 20,
                r: 0,
            },
            barmode: "stack",
            font: {
                family: "Open Sans",
            },
            showlegend: false,
            plot_bgcolor: "#FCFCFC",
            hovermode: "y unified",
            hoverlabel: { 
                bgcolor: '#1C1940',
                bordercolor: '#1C1940',
                font: {
                    color: '#FFF',
                    size: 14,
                 }
            },
        };

        return layout;
    }, [data]);

    const config = useMemo(() => {
        const graphConfig: Partial<Config> = {
            displayModeBar: false,
            responsive: true,
            showTips: false
        }
        return graphConfig;
    }, []);

    const renderDefectsGraph = () => {
      if (!data.length) {
        return (
          <div>
              <ClearSelectedVesselButton className="mb-4" />
              <p className="mb-4 defect-graph-title">
                {t("defectsGraph.defectsPreamble")}
              </p>
              <div className="flex justify-content-center">
                <p>{t("defectsGraph.noDefectsDataAvailable")}</p>
              </div>
          </div>
        );
      }
      return (
        <>
          <ClearSelectedVesselButton className="mb-4" />
          <div className="flex flex-row justify-content-between align-items-center">
            <p className="defect-graph-title">
              {t("defectsGraph.defectsPreamble")}
            </p>
            <div className="flex flex-row">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="8" r="8" fill="#EF5A14"/>
              </svg>
              <p className="defect-graph-legend-title mr-3">
                {t("defectsGraph.highPriority")}
              </p>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="8" r="8" fill="#E5940E"/>
              </svg> 
              <p className="defect-graph-legend-title mr-3">
                {t("defectsGraph.mediumPriority")}
              </p>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="8" r="8" fill="#F8D311"/>
              </svg> 
              <p className="defect-graph-legend-title">
                {t("defectsGraph.lowPriority")}
              </p>
            </div>
          </div>
          <Plot
            useResizeHandler={true}
            data={data}
            layout={layout}
            config={config}
            className="defects-graph"
          />
          <p className="mt-4 defect-graph-xaxis-title">
            {t("defectsGraph.numberOfDefects")}
          </p>
        </>
      );
    }

    return isLoading ? (
      <div className="flex h-screen justify-items-center align-items-center">
        <ProgressSpinner />
      </div>
    ) : (
        renderDefectsGraph()
    );
}