import  "./FilterButtonRow.css";
import { Button } from "primereact/button";
import { ShowAllFiltersButton } from "../ShowAllFilters/ShowAllFiltersButton";
import { FilterIcon } from "../Icons/FilterIcon";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../hooks/storeHooks";
import { toggleOpenState } from "../../store/thunks/advancedFiltersThunks";

export type FilterButtonContainerProps = {
    enableFilterButton: boolean;
}

export const FilterButtonRow = ({
    enableFilterButton
}: FilterButtonContainerProps) => {
    const {t} = useTranslation("locale");
    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(toggleOpenState());
    }

    return (
        <div className={"filter_button_row__button-container"}>
        <ShowAllFiltersButton />
        {
            enableFilterButton ?
                <Button
                    className={`p-button p-button-primary w-14rem`}
                    label={t("benchmarkModal.benchmarkFilters")}
                    icon={FilterIcon}
                    onClick={onClick}
                />
                :
                <></>
        }
        </div>
    )
}