import { errorToast, infoToast } from "@idwal/idwal-react-components";
import { DefectDataItem } from "../types/DefectsDataItem";
import { SharedDefectItem } from "../types/SharedDefectItem";
import { postRequest } from "./DataRequest";
import updateDefectRequest from "./UpdateDefectRequest";
import { getTranslationObject } from "./Translation";
import { Auth } from "aws-amplify";

export const getDefects = async () => {
    return await postRequest("/Fleet/defects"); 
}

export const updateDefect = async (defectId: number, body: any) => {
    return await updateDefectRequest(`/Defects/${defectId}`, body); 
}

export const updateDefects = async (body: any) => {
    return await updateDefectRequest(`/Defects/BULK`, body); 
}

export const triggerExportDap = async (filteredData: (DefectDataItem | SharedDefectItem)[], images: boolean) => {    
    const t = getTranslationObject();

    try { 
        const { attributes: { email } } = await Auth.currentAuthenticatedUser({ bypassCache: false });

        let requestData = { data: [], email, app: 'fleet', fileType: 'excel', images };
        
        requestData = filteredData.reduce((accumulator: any, { inspectionId, defectId }: DefectDataItem | SharedDefectItem) => {
            let item = accumulator.data.find((entry: any) => entry.inspectionId === inspectionId);
 
           if (!item) {
                item = { inspectionId, defects: [] };
                accumulator.data.push(item);
            }
 
            item.defects.push(defectId);
            return accumulator;
        }, requestData);

        await postRequest("/Defects/extract/excel", requestData);

        infoToast(t("defectsTable.successExcelExport"));
    } catch (error) {
        errorToast(t("defectsTable.errorExcelExport"));
    }
}