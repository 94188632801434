import React, { ReactElement, useEffect, useState } from "react";
import "./GenericSearch.css";
import { InputText } from "primereact/inputtext";

export type GenericSearchable<T> = {
    items: Array<T>,
    disabled: boolean;
    onChange: (e: any) => void;
}

export type GenericSearchProps<T> = {
    readonly searchableItems: GenericSearchable<T>;
    readonly defaultSearchTerm: string;
    readonly extractSearchTerm: (arg: T) => string;
    readonly children: ReactElement<GenericSearchable<T>, "">;
};

export function GenericSearch<T>({
    searchableItems,
    defaultSearchTerm,
    extractSearchTerm,
    children,
}: GenericSearchProps<T>) {
    const [searchTerm, setSearchTerm] = useState("");

    const performSearch = () => {
        return searchableItems.items.filter(
            (item) =>
                extractSearchTerm(item).toLowerCase().includes(searchTerm.toLowerCase())
        )
    }

    let filteredItems = performSearch();

    useEffect(() => {
        filteredItems = performSearch()
    }, [searchableItems]);

    const handleSearchChange = (e: any) => {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
    };

    return (
        <>
            <span className="p-input-icon-right generic-search__container">
                <i className="pi pi-search" />
                <InputText 
                    data-cy={"searchable-input"}
                    className={"generic-search__input"}
                    value={searchTerm} 
                    onChange={handleSearchChange} 
                    placeholder={defaultSearchTerm}
                />
            </span>
            <div>
                {
                    React.cloneElement(
                        children, 
                        {
                            items: filteredItems, 
                            onChange: searchableItems.onChange, 
                            disabled: searchableItems.disabled
                        }
                    )
                }
            </div>
        </>
    );
}
