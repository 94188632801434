import { FleetSelectionIcon, MarketSelectionIcon } from '../../components/FilterSelectionContainer/Icons';
import "./FilterSelectionContainer.css";
import { FilterSelectionContainerType } from "../../types/FilterSelectionContainerType";
import { FilterSelectionContainerProps } from "../../types/FilterSelectionContainerProps";

const renderIcon = (mode: FilterSelectionContainerType) =>  
    mode === FilterSelectionContainerType.Foreground 
        ? <FleetSelectionIcon />
        : <MarketSelectionIcon />

const modeStyleModifier = (mode: FilterSelectionContainerType) => {
    switch(mode) {
        case(FilterSelectionContainerType.Foreground):
            return "foreground";
        case(FilterSelectionContainerType.BackgroundMarket):
            return "background-market";
        case(FilterSelectionContainerType.BackgroundFleet):
           return "background-fleet";
    }
}

export const renderDisabledPanel = (text: string) => (
    <div className='filterSelectionContainer__disabled'>
        {text}
    </div>
)

export const FilterSelectionContainer = ({
    title,
    children,
    mode
}: FilterSelectionContainerProps) => {
    const styleModifier = modeStyleModifier(mode);
    return (
        <div className='filterSelectionContainer'>
            <div className={
                    `filterSelectionContainer__title-container filterSelectionContainer__title-container--${styleModifier}`
                }>
                <div className='filterSelectionContainer__title-icon'>
                    {renderIcon(mode)}
                </div>
                <div 
                    className={
                        `filterSelectionContainer__title-text--${styleModifier}`
                    }
                >
                    {title}
                </div>
            </div>
            <div 
                className={
                    `filterSelectionContainer__content-container--${styleModifier}`
                }
            >
                {
                    children
                }
            </div>
        </div>
    )
}