const userGroupKey = (userName: string, groupName: string, key: any) => `${userName}_${groupName}_${key}`;

const userKey = (userName: string, key: any) => `${userName}_${key}`;

export const storeGroupLocalStorageItem = (
    userName: string,
    groupName: string,
    key: string,
    storageItem: any
) => localStorage.setItem(
    userGroupKey(userName, groupName, key), JSON.stringify(storageItem)
);

export const getGroupLocalStorageItem = 
    (userName: string, groupName: string, key: string): string | null => 
        localStorage.getItem(
            userGroupKey(userName, groupName, key)
        );

export const removeGroupLocalStorageItem = 
    (userName: string, groupName: string, key: string) => 
        localStorage.removeItem(
            userGroupKey(userName, groupName, key)
        );

export const storeUserLocalStorageItem = (
        userName: string,
        key: string,
        storageItem: any
    ) => localStorage.setItem(
        userKey(userName, key), JSON.stringify(storageItem)
    );

export const getUserLocalStorageItem = (
    userName: string,
    key: string,
) => localStorage.getItem(
        userKey(userName, key)
);
