import {SubgradeTableFields} from "./SubgradeTableFields";

export const SubgradeAreaMapping = {
    [SubgradeTableFields.SUBGRADES.BRIDGE_AND_NAVIGATION_EQUIPMENT]: "Bridge and Navigation Equipment",
    [SubgradeTableFields.SUBGRADES.ACCOMMODATION]: "Accommodation",
    [SubgradeTableFields.SUBGRADES.LIFESAVING_APPLIANCES]: "Lifesaving Appliances",
    [SubgradeTableFields.SUBGRADES.MOORING_DECKS]: "Mooring Decks",
    [SubgradeTableFields.SUBGRADES.ENGINE_ROOM_AND_MACHINERY]: "Engine Room and Machinery",
    [SubgradeTableFields.SUBGRADES.VESSEL_CAPABILITIES_AND_CARGO_SYSTEMS]: "Vessel Capabilities and Cargo Systems",
    [SubgradeTableFields.SUBGRADES.BALLAST_TANK_AND_SYSTEMS]: "Ballast Tanks and Systems",
    [SubgradeTableFields.SUBGRADES.WEATHER_DECKS_AND_FITTINGS]: "Weather Decks and Fittings",
    [SubgradeTableFields.SUBGRADES.HULL]: "Hull",
    [SubgradeTableFields.SUBGRADES.POLLUTION_CONTROL]: "Pollution Control",
    [SubgradeTableFields.SUBGRADES.ONBOARD_MANAGEMENT]: "Onboard Management",
    [SubgradeTableFields.SUBGRADES.SAFE_WORKING_ENVIRONMENT]: "Safe Working Environment",
    [SubgradeTableFields.SUBGRADES.FORTHCOMING_REGULATORY_COMPLIANCE]: "Forthcoming Regulatory Compliance",
    [SubgradeTableFields.SUBGRADES.CREW_WELFARE]: "Crew Welfare",
    [SubgradeTableFields.SUBGRADES.CREW_PERFORMANCE]: "Crew Performance",
    [SubgradeTableFields.SUBGRADES.SAFETY_MANAGEMENT]: "Safety Management",
    [SubgradeTableFields.SUBGRADES.PLANNED_MAINTENANCE_SYSTEM]: "Planned Maintenance System (PMS)",
    [SubgradeTableFields.SUBGRADES.CLASSIFICATION_AND_CERTIFICATION]: "Classification and Certification",
    [SubgradeTableFields.SUBGRADES.PSC_PERFORMANCE]: "PSC Performance",
    [SubgradeTableFields.SUBGRADES.FIRE_FIGHTING_EQUIPMENT_AND_SYSTEMS]: "Fire Fighting Equipment and Systems",
    [SubgradeTableFields.SUBGRADES.DESIGN_AND_CONSTRUCTION]: "Design and Construction",
};