import { Dialog } from "primereact/dialog";
import { forwardRef, useImperativeHandle, useState } from "react";
import { SharedTableColumnConfig } from "../../types/SharedTableColumnConfig";
import { useTranslation } from "react-i18next";
import "./ColumnReorderDialog.css";
import { ColumnReorder } from "../ColumnReorder/ColumnReorder";
import { RemoveCustomColumnFunction } from "../../types/RemoveCustomColumnFunction";

export interface ColumnReorderDialogMethods {
    showDialog: (show: boolean) => void;
}

export interface ColumnReorderDialogProps {
    columns: any;
    onColumnReorder?: (e: any) => void;
    onRemoveColumn?: RemoveCustomColumnFunction;
}

export const ColumnReorderDialog = forwardRef<ColumnReorderDialogMethods, ColumnReorderDialogProps>((props, ref): any => {
    const {t} = useTranslation("locale");
    const [visible, setVisible] = useState(false);
    const [columns, setColumns] = useState<SharedTableColumnConfig[]>([]);

    useImperativeHandle(ref, () => ({
        showDialog: (show: boolean) => {
            setVisible(show);
            setColumns(props.columns);
        }
    }));

    return (
        <Dialog id="reorder-dialog" data-cy="reorder-dialog" headerClassName="reorder-header" header={t("columnReorder.dialogHeader")} blockScroll={true} visible={visible} onHide={() => setVisible(false)} style={{ width: '40rem' }}>
            <ColumnReorder 
                onColumnReorder={props.onColumnReorder} 
                setDialogVisible={setVisible} 
                columns={columns}
                onRemoveColumn={props.onRemoveColumn} />
        </Dialog>
    )
});
