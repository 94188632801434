export const SubgradeTableFields = {
  NAME_FILTER_FIELD: 'nameFilterField',
  SHIP_NAME: 'shipName',
  TYPE: 'type',
  IDWAL_GRADE: 'idwalGrade',
  TECHNICAL_MANAGER: 'technicalManager',
  LOCATION_FILTER_FIELD: 'locationFilterField',
  INSPECTION_DATE: "inspectionDate",
  CONDITION_SCORE: "conditionScore",
  MANAGEMENT_SCORE: "managementScore",
  SUBGRADES: {
    DESIGN_AND_CONSTRUCTION: 'subgrades.designAndConstruction',
    HULL: 'subgrades.hull',
    MOORING_DECKS: 'subgrades.mooringDecks',
    WEATHER_DECKS_AND_FITTINGS: 'subgrades.weatherDecksAndFittings',
    BALLAST_TANK_AND_SYSTEMS: 'subgrades.ballastTankAndSystems',
    ACCOMMODATION: 'subgrades.accomodation',
    BRIDGE_AND_NAVIGATION_EQUIPMENT: 'subgrades.bridgeAndNavigationEquipment',
    ENGINE_ROOM_AND_MACHINERY: 'subgrades.engineRoomAndMachinery',
    FIRE_FIGHTING_EQUIPMENT_AND_SYSTEMS: 'subgrades.fireFightingEquipmentAndSystems',
    LIFESAVING_APPLIANCES: 'subgrades.lifesavingAppliances',
    SAFE_WORKING_ENVIRONMENT: 'subgrades.safeWorkingEnvironment',
    POLLUTION_CONTROL: 'subgrades.pollutionControl',
    ONBOARD_MANAGEMENT: 'subgrades.onboardManagement',
    VESSEL_CAPABILITIES_AND_CARGO_SYSTEMS: 'subgrades.vesselCapabilitiesAndCargoSystems',
    FORTHCOMING_REGULATORY_COMPLIANCE: 'subgrades.forthcomingRegulatoryCompliance',
    CREW_WELFARE: 'subgrades.crewWelfare',
    CREW_PERFORMANCE: 'subgrades.crewPerformance',
    SAFETY_MANAGEMENT: 'subgrades.safetyManagement',
    PLANNED_MAINTENANCE_SYSTEM: 'subgrades.plannedMaintenanceSystem',
    CLASSIFICATION_AND_CERTIFICATION: 'subgrades.classificationAndCertification',
    PSC_PERFORMANCE: 'subgrades.pscPerformance',
  },
};

export const SubgradeTableDefaultColumns = [
  SubgradeTableFields.TYPE,
  SubgradeTableFields.IDWAL_GRADE,
  SubgradeTableFields.TECHNICAL_MANAGER,
  SubgradeTableFields.INSPECTION_DATE,
  SubgradeTableFields.CONDITION_SCORE,
  SubgradeTableFields.MANAGEMENT_SCORE,
  SubgradeTableFields.SUBGRADES.DESIGN_AND_CONSTRUCTION,
  SubgradeTableFields.SUBGRADES.HULL,
  SubgradeTableFields.SUBGRADES.MOORING_DECKS,
  SubgradeTableFields.SUBGRADES.WEATHER_DECKS_AND_FITTINGS,
  SubgradeTableFields.SUBGRADES.BALLAST_TANK_AND_SYSTEMS,
  SubgradeTableFields.SUBGRADES.ACCOMMODATION,
  SubgradeTableFields.SUBGRADES.BRIDGE_AND_NAVIGATION_EQUIPMENT,
  SubgradeTableFields.SUBGRADES.ENGINE_ROOM_AND_MACHINERY,
  SubgradeTableFields.SUBGRADES.FIRE_FIGHTING_EQUIPMENT_AND_SYSTEMS,
  SubgradeTableFields.SUBGRADES.LIFESAVING_APPLIANCES,
  SubgradeTableFields.SUBGRADES.SAFE_WORKING_ENVIRONMENT,
  SubgradeTableFields.SUBGRADES.POLLUTION_CONTROL,
  SubgradeTableFields.SUBGRADES.ONBOARD_MANAGEMENT,
  SubgradeTableFields.SUBGRADES.VESSEL_CAPABILITIES_AND_CARGO_SYSTEMS,
  SubgradeTableFields.SUBGRADES.FORTHCOMING_REGULATORY_COMPLIANCE,
  SubgradeTableFields.SUBGRADES.CREW_WELFARE,
  SubgradeTableFields.SUBGRADES.CREW_PERFORMANCE,
  SubgradeTableFields.SUBGRADES.SAFETY_MANAGEMENT,
  SubgradeTableFields.SUBGRADES.PLANNED_MAINTENANCE_SYSTEM,
  SubgradeTableFields.SUBGRADES.CLASSIFICATION_AND_CERTIFICATION,
  SubgradeTableFields.SUBGRADES.PSC_PERFORMANCE
];