import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefectDataItem } from '../../types/DefectsDataItem';
import { DefectTabs } from '../../types/DefectTabs';
import { DefectInsightFilterBy } from '../../types/DefectInsightsData';

export interface DefectState {
    data: DefectDataItem[];
    loading: boolean;
    error: boolean;
    filteredDefects: DefectDataItem[];
    activeTab: DefectTabs;
    showCloseDefectsDialog: boolean;
    showShareDefectsDialog: boolean;
    selectedVessel: string;
    insightsFilterBy: DefectInsightFilterBy;
}

export const initialState: DefectState = {
    data: [],
    loading: true,
    error: false,
    filteredDefects: [],
    activeTab: DefectTabs.summary,
    showCloseDefectsDialog: false,
    showShareDefectsDialog: false,
    selectedVessel: "",
    insightsFilterBy: DefectInsightFilterBy.VESSELS,
};

export const defectSlice = createSlice({
    name: 'defect',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<DefectDataItem[]>) => {
            state.data = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload;
        },
        setFilteredDefects: (state, action: PayloadAction<DefectDataItem[]>) => {
            state.filteredDefects = action.payload;
        },
        setActiveTab: (state, action: PayloadAction<DefectTabs>) => {
            state.activeTab = action.payload;
        },
        setShowCloseDefectsDialog: (state, action: PayloadAction<boolean>) => {
            state.showCloseDefectsDialog = action.payload;
        },
        setShowShareDefectsDialog: (state, action: PayloadAction<boolean>) => {
            state.showShareDefectsDialog = action.payload;
        },
        setSelectedVessel: (state, action: PayloadAction<string>) => {
            state.selectedVessel = action.payload;
        },
        setInsightsFilterBy: (state, action: PayloadAction<DefectInsightFilterBy>) => {
            state.insightsFilterBy = action.payload;
        },
        updateData: (state, action: PayloadAction<DefectDataItem[]>) => {     
            state.data = state.data.map((defect) => {
                const matchingDefect = action.payload.find((d) => d.defectId === defect.defectId);

                if (matchingDefect) {
                    return matchingDefect;
                }

                return defect;
            });
                  
            state.filteredDefects = state.filteredDefects.map((filteredDefect) => {
                const matchingDefect = action.payload.find((d) => d.defectId === filteredDefect.defectId);

                if (matchingDefect) {
                    return matchingDefect;
                }

                return filteredDefect;
            });
        },
    },
});

export const {
    setData,
    setLoading,
    setError,
    setFilteredDefects,
    setActiveTab,
    setShowCloseDefectsDialog,
    setShowShareDefectsDialog,
    setSelectedVessel,
    setInsightsFilterBy,
    updateData,
} = defectSlice.actions;

export default defectSlice.reducer;
