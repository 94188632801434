import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { useEffect, useRef, useState } from "react";
import "./VesselViewAccordion.css";

export interface VesselViewAccordionProps {
    title: string;
    readonly children?: React.ReactNode;
}

export const VesselViewAccordion = (props: VesselViewAccordionProps) => {
    const [panelVisible, setPanelVisible] = useState<boolean>(true);
    const panel = useRef<Panel>(null);

    useEffect(() => {
        if(!panelVisible){
            panel.current?.collapse(undefined);
            return;
        }

        panel.current?.expand(undefined);
    }, [panelVisible])
    
    return (
        <div id={`vessel-view-accordion-${props.title}`} className="vessel-view__accordion mt-2">
            <div className="vessel-view-accordion__title">
                <div onClick={() => setPanelVisible(!panelVisible)} className="flex align-items-center justify-content-between">
                    <p className="vesselView__labelTitle ml-3">{props.title}</p>
                    <Button onClick={() => setPanelVisible(!panelVisible)} icon={panelVisible ? "pi pi-minus" : "pi pi-plus"} className="p-button-text accordion-button" />
                </div>
            </div>
            <div className="vessel-view-accordion__content">
                <Panel className="vessel-view-accordion-panel" ref={panel} toggleable headerTemplate={<></>}>
                    {props.children}
                </Panel>
            </div>
        </div>
    )
}