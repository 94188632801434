export const getAgeRangeConfig = (t: any) => {
    const ageRangeLabels = getAgeRanges(t);
    const rangeValues = getAgeRangeValues();
    return [
        {label: ageRangeLabels[0], value: rangeValues[0]},
        {label: ageRangeLabels[1], value: rangeValues[1]},
        {label: ageRangeLabels[2], value: rangeValues[2]},
        {label: ageRangeLabels[3], value: rangeValues[3]},
        {label: ageRangeLabels[4], value: rangeValues[4]},
        {label: ageRangeLabels[5], value: rangeValues[5]},
        {label: ageRangeLabels[6], value: rangeValues[6]},
        {label: ageRangeLabels[7], value: rangeValues[7]},
        {label: ageRangeLabels[8], value: rangeValues[8]}
    ];
}

export const getAgeRangeMultiSelect = (t: any) => {
    const ageRangeLabels = getAgeRanges(t);
    const rangeValues = getAgeRangeValues();
    return [
        {name: ageRangeLabels[0], value: rangeValues[0]},
        {name: ageRangeLabels[1], value: rangeValues[1]},
        {name: ageRangeLabels[2], value: rangeValues[2]},
        {name: ageRangeLabels[3], value: rangeValues[3]},
        {name: ageRangeLabels[4], value: rangeValues[4]},
        {name: ageRangeLabels[5], value: rangeValues[5]},
        {name: ageRangeLabels[6], value: rangeValues[6]},
        {name: ageRangeLabels[7], value: rangeValues[7]},
        {name: ageRangeLabels[8], value: rangeValues[8]}
    ];
}


export const getAgeRangeLabels = (ageRangeValues: string[], t: any) => {
    return getAgeRangeConfig(t).filter(
        ageRange => ageRangeValues.includes(ageRange.value))
        .map(d => d.label);
}

export const getAgeRangeValues = () => {
    return [
        "0-5",
        "5.01-10",
        "10.01-15",
        "15.01-20",
        "20.01-25",
        "25.01-30",
        "30.01-35",
        "35.01-40",
        "40.01-70"
    ];
}

export const getAgeRanges = (t: any) => {
    return [
        `0 ${t("vesselAgeDropdown.upTo")} 5`,
        `5 ${t("vesselAgeDropdown.upTo")} 10`,
        `10 ${t("vesselAgeDropdown.upTo")} 15`,
        `15 ${t("vesselAgeDropdown.upTo")} 20`,
        `20 ${t("vesselAgeDropdown.upTo")} 25`,
        `25 ${t("vesselAgeDropdown.upTo")} 30`,
        `30 ${t("vesselAgeDropdown.upTo")} 35`,
        `35 ${t("vesselAgeDropdown.upTo")} 40`,
        `40 ${t("vesselAgeDropdown.andUp")}`
    ];
}