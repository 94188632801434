import { useAppSelector } from "./storeHooks";
import { selectUserCapabilities } from "../store/selectors/userSelectors";

export const useCapabilities = (checkCapabilities?: string[]) => {
    const userCapabilities = useAppSelector(selectUserCapabilities);

    let capabilities: Record<string, boolean> = {};

    if (checkCapabilities?.length && userCapabilities?.length) {
        capabilities = Object.fromEntries(
            checkCapabilities.map((capability) => [capability, userCapabilities.includes(capability)]),
        );
    }

    return { capabilities };
} 