import { RootState } from "../store";

export const selectFilters = (state: RootState) => state.filters;

export const selectGlobalFilters = (state: RootState) => state.filters.globalFilters;

export const selectGlobalFiltersLoading = (state: RootState) => state.filters.globalFiltersLoading;

export const selectKeywordSearch = (state: RootState) => state.filters.keywordSearch;

export const selectSelectedVesselTypes = (state: RootState) => state.filters.selectedVesselTypes;

export const selectSelectedVesselAgeBands = (state: RootState) => state.filters.selectedVesselAgeBands;

export const selectSelectedTechnicalManagers = (state: RootState) => state.filters.selectedTechnicalManagers;

export const selectSelectedCountries = (state: RootState) => state.filters.selectedCountries;

export const selectSelectedVessels = (state: RootState) => state.filters.selectedVessels;

export const selectDataTableFilterData = (state: RootState) => state.filters.dataTableFilterData;