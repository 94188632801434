import NotificationClient from "@idwal/idwal-notification-client";
import { getCurrentUserId } from "../queries/AuthToken";
import { getCurrentSessionIdToken } from "../cognito/cognitoCookie";
import { DEFECT_RECTIFIED_NOTIFICAITON_KEY, FLEET_DEFECT_EXTRACTION_EXCEL_GENERATED, PERMITTED_NOTIFICATIONS } from "../utils/Notifications/Constants";
import { FleetNotification } from "../types/NotificationsModalProps";
import { NotificationTypes } from "../types/NotificationTypes";

export const getUserNotifications = async (showRead: boolean = false) => {
    const username = await getCurrentUserId();
    const token = await getCurrentSessionIdToken();
    const notificationClient = new NotificationClient(process.env.REACT_APP_NOTIFICATIONS_API_URL?.toString() ?? "", token);
    const notifications = await notificationClient.getNotifications({
        type: "NOTIFICATION",
        app: "FleetApp",
        user: username,
        read: showRead
    });

    const mapKey = (key: string): keyof NotificationTypes | undefined => {
        switch (key) {
            case DEFECT_RECTIFIED_NOTIFICAITON_KEY:
                return "defectRectifiedFleet";
            case FLEET_DEFECT_EXTRACTION_EXCEL_GENERATED:
                return "defectExcelGeneratedPlan"
            default:
                return undefined;
        }
    };

    const mappedNotifications: FleetNotification[] = [];
    notifications.forEach((notification: any) => {
        if (PERMITTED_NOTIFICATIONS.includes(notification.key)) {
            const mappedKey = mapKey(notification.key);
            if (mappedKey) {
                mappedNotifications.push({
                    id: notification.id,
                    key: mappedKey,
                    read: notification.status === 3,
                    dateTime: new Date(notification.date),
                    data: notification.data
                } as FleetNotification);
            }
        }
    });

    return mappedNotifications;
}

export const markNotificationsAsRead = async (notifications: FleetNotification[]) => {
    const token = await getCurrentSessionIdToken();
    const notificationClient = new NotificationClient(process.env.REACT_APP_NOTIFICATIONS_API_URL?.toString() ?? "", token);

    try {
        await notificationClient.markNotificationsAsRead(notifications.map((notification) => notification.id));

        return true;
    } catch (error) {
        console.error(error);

        return false;
    }
}