
import React from "react";
import { useTranslation } from "react-i18next";
import { DisplayModalContent } from "../Shared/DisplayModal";

/**
 * Read more button that
 * @param data 
 * @returns 
 */
export const ReadMoreButton = (props: {
    vesselName: string,
    content:string, 
    contentType: string,
    handleReadMoreClick: (content: DisplayModalContent) => void
}) => {
    const { t } = useTranslation("locale");

    return (
        <React.Fragment>
              <span
                    data-cy="read-more-btn"
                    className="font-semibold underline cursor-pointer read-more-btn"
                    onClick={() => props.handleReadMoreClick({
                        title: `${props.vesselName} ${t('defectsTable.defect')} ${props.contentType}`,
                        body: props.content
                    })}
                >
                    {t('displayModal.readMore')}
                </span>
        </React.Fragment>
     );
};
