import { GroupedItem } from "../types/GroupedItem";

export function buildGraphDataForStepAverage<T>(
    groupedItems: Array<GroupedItem<T>>,
    extractScore: (arg: T) => number
): {
    x: Array<number>, 
    y: Array<number>
} {
    const x: Array<number> = [];
    const y: Array<number> = [];

    groupedItems.forEach((item) => {
        const totalScore = item.items.reduce((sum, item) => sum + extractScore(item), 0);
        const averageScore = totalScore / item.items.length;
        const ageBounds = item.range.split('/');
  
        x.push(parseInt(ageBounds[0], 10));
        x.push(parseInt(ageBounds[1], 10));
        y.push(averageScore);
        y.push(averageScore);
    });

    return {
        x: x,
        y: y,
    };
}