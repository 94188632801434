import { Dialog } from "primereact/dialog";
import "./FilterDialog.css";
import { FilterTypeToggle } from "../FilterTypeToggle/FilterTypeToggle";
import { useTranslation } from "react-i18next";
import { FilterList } from "../FilterList/FilterList";
import { FilterSelectionContainer, renderDisabledPanel } from "../FilterSelectionContainer/FilterSelectionContainer";
import { renderFilterSection } from "./renderFilterSection";
import { Button } from "primereact/button";
import { WarningMessage } from "../WarningMessage/WarningMessage";
import {
    BackgroundFilterScopeSelection,
    FilterDialogProps,
    FilterScope,
    FilterSection,
} from "../../types/FilterDialog";
import { FilterListItemProps } from "../../types/FilterListItemProps";
import { FilterSelectionContainerType } from "../../types/FilterSelectionContainerType";

export const FilterDialog = ({
    visible,
    filterSections,
    onHide,
    backgroundFilterScopeSelection,
    onChangeBackgroundFilter,
    onApply,
    onClear,
    showWarningMessage,
    onCloseWarningMessage,
    saving = false,
}: FilterDialogProps) => {
    const { t } = useTranslation("locale");

    const toFilterListProps = (
        section: FilterSection, 
        filterScopeSelection: BackgroundFilterScopeSelection
    ): FilterListItemProps => ({
        filtername: section.name,
        vesselCount: section.selectedFleetItemCount,
        marketCount: 
            backgroundFilterScopeSelection === BackgroundFilterScopeSelection.FLEET 
                ? section.selectedBackgroundFleetDataItemCount
                : section.selectedBackgroundMarketDataItemCount,
        selected: section.selected,
        onClick: section.onClick,
    });

    const selectedFilter = filterSections.find((filterOption: FilterSection) => filterOption.selected === true);

    const renderFleetFilterselection = (selectedFilter: FilterSection) =>
        selectedFilter && (
            <div className={"filterDialog__filter-container"}>
                {renderFilterSection(
                    selectedFilter,
                    FilterSelectionContainerType.Foreground,
                    saving,
                    selectedFilter.defaultSearchTerm ?? "",
                    t
                )}
            </div>
        );

    const footer = (
        <div className="filterDialog__footer">
            <button
                id={"clearAllFilters"}
                className={"p-button p-button-outlined p-button-secondary secondary-outlined-button"}
                data-cy="clear-all-filters"
                onClick={onClear}
                style={{
                    width: "192px",
                    display: "flex",
                    justifyContent: "center",
                }}
                title={t("benchmarkModal.resetAdvancedFilters")}
            >
                {t("benchmarkModal.reset")}
            </button>
            <Button
                data-cy={`filter-modal-apply`}
                style={{ width: "192px" }}
                className="p-button p-button-primary"
                label={t("benchmarkModal.apply")}
                icon={saving && "pi pi-spin pi-spinner"}
                disabled={saving}
                onClick={onApply}
            />
        </div>
    );

    const renderBackgroundSelection = () =>
        backgroundFilterScopeSelection === BackgroundFilterScopeSelection.FLEET ? (
            <FilterSelectionContainer
                title={t("benchmarkModal.myfleet")}
                mode={FilterSelectionContainerType.BackgroundFleet}
            >
                <div className="filterDialog__filter-content-container">
                    {selectedFilter &&
                    (selectedFilter.filterScope === FilterScope.ForegroundOnly ||
                        selectedFilter.filterScope === FilterScope.ForegroundAndMarketData) ? (
                        renderDisabledPanel(
                            selectedFilter.backgroundMarketMessage ?? t("benchmarkModal.defaultMarketDataMessage"),
                        )
                    ) : (
                        <div className={"filterDialog__filter-container"}>
                            {
                                selectedFilter && renderFilterSection(
                                    selectedFilter, 
                                    FilterSelectionContainerType.BackgroundFleet, 
                                    saving, 
                                    selectedFilter.defaultSearchTerm ?? "",
                                    t
                                )
                            }
                        </div>
                    )}
                </div>
            </FilterSelectionContainer>
        ) : (
            <FilterSelectionContainer
                title={t("benchmarkModal.marketvessels")}
                mode={FilterSelectionContainerType.BackgroundMarket}
            >
                <div className="filterDialog__filter-content-container">
                    {selectedFilter &&
                    (selectedFilter.filterScope === FilterScope.ForegroundOnly ||
                        selectedFilter.filterScope === FilterScope.ForegroundAndFleetData) ? (
                        renderDisabledPanel(
                            selectedFilter.backgroundFleetMessage ?? t("benchmarkModal.defaultMarketDataMessage"),
                        )
                    ) : (
                        <div className={"filterDialog__filter-container"}>
                            {
                                selectedFilter && renderFilterSection(
                                    selectedFilter, 
                                    FilterSelectionContainerType.BackgroundMarket, 
                                    saving, 
                                    selectedFilter.defaultSearchTerm ?? "",
                                    t
                                )
                            }
                        </div>
                    )}
                </div>
            </FilterSelectionContainer>
        );

    return (
        <div className={"advancedFilterWrapper"}>
            <Dialog
                header={t("benchmarkModal.benchmarkFilters")}
                headerClassName="filter-dialog-style"
                visible={visible}
                style={{ width: "80vw" }}
                onHide={onHide}
                blockScroll={true}
                footer={footer}
                className={"advancedFilterWrapper"}
                draggable={false}
            >
                {showWarningMessage && (
                    <WarningMessage message={t("benchmarkModal.warningText")} onClose={onCloseWarningMessage} />
                )}
                <p className="pb-2 selection-message">
                    {backgroundFilterScopeSelection === BackgroundFilterScopeSelection.MARKET
                        ? t("benchmarkModal.marketSelectionMessage")
                        : t("benchmarkModal.myFleetSelectionMessage")}
                </p>
                <div className="filterDialog__content-container">
                    <div className="filterDialog__selection-container">
                        <FilterTypeToggle
                            text={t("benchmarkModal.benchmarktoggle")}
                            checked={backgroundFilterScopeSelection === BackgroundFilterScopeSelection.MARKET}
                            disabled={saving}
                            onChange={onChangeBackgroundFilter}
                        />
                        <FilterList items={filterSections.map(toFilterListProps)} />
                    </div>
                    <div className="filterDialog__fleet-filter-container">
                        <FilterSelectionContainer
                            title={t("benchmarkModal.myvessels")}
                            mode={FilterSelectionContainerType.Foreground}
                        >
                            <div className="filterDialog__filter-content-container">
                                {selectedFilter && renderFleetFilterselection(selectedFilter)}
                            </div>
                        </FilterSelectionContainer>
                    </div>
                    <div className="filterDialog__market-filter-container">{renderBackgroundSelection()}</div>
                </div>
            </Dialog>
        </div>
    );
};
