import { sendGAEvent } from "../../AnalyticsService";
import {
    ADVANCED_FILTER_CATEGORY, ADVANCED_FILTER_SELECTION_CHANGED,
} from "../../constants/analytics";
import { BuildRangeSectionProps } from "../../types/BuildRangeSectionProps";
import { FilterSection, FilterType } from "../../types/FilterDialog";
import { sectionIsSelected } from "./helpers";

export const buildRangeSection = ({
    sectionName,
    selectedSection,

    foregroundTitle,
    backgroundTitle,
    foregroundSubtitle,
    backgroundSubtitle,

    filterScope,

    foregroundRangeState,
    backgroundFleetRangeState,
    backgroundMarketRangeState,

    step,

    gaForegroundTag,
    gaBackgroundFleetTag,
    gaBackgroundMarketTag,

    onSelectSection,
    onUpdateForegroundSelection,
    onUpdateBackgroundFleetSelection,
    onUpdateBackgroundMarketSelection
}: BuildRangeSectionProps): FilterSection => {
    return {
        name: sectionName,
        selected: sectionIsSelected(sectionName, selectedSection),
        onClick: () => {
            sendGAEvent(ADVANCED_FILTER_CATEGORY, ADVANCED_FILTER_SELECTION_CHANGED);
            onSelectSection(sectionName)
        },
        selectedFleetItemCount: foregroundRangeState.changed ? 1 : 0,
        selectedBackgroundFleetDataItemCount: backgroundFleetRangeState.changed ? 1 : 0,
        selectedBackgroundMarketDataItemCount: backgroundMarketRangeState.changed ? 1 : 0,
        filterScope: filterScope,
        type: FilterType.Range,
        fleetFilterState: {
            title: foregroundTitle,
            subtitle: foregroundSubtitle,
            step,
            rangeValue: foregroundRangeState.data,
            rangeLimits: foregroundRangeState.limit,
            onUpdateRange: (e: [number, number]) => {
                sendGAEvent(ADVANCED_FILTER_CATEGORY, gaForegroundTag);
                onUpdateForegroundSelection(e)
            }
        },
        backgroundFleetFilterState: {
            title: backgroundTitle,
            subtitle: backgroundSubtitle,
            step,
            rangeValue: backgroundFleetRangeState.data,
            rangeLimits: backgroundFleetRangeState.limit,
            onUpdateRange: (e: [number, number]) => {
                sendGAEvent(ADVANCED_FILTER_CATEGORY, gaBackgroundFleetTag);
                onUpdateBackgroundFleetSelection(e)
            }
        },
        backgroundMarketFilterState: {
            title: backgroundTitle,
            subtitle: backgroundSubtitle,
            step,
            rangeValue: backgroundMarketRangeState.data,
            rangeLimits: backgroundMarketRangeState.limit,
            onUpdateRange: (e: [number, number]) => {
                sendGAEvent(ADVANCED_FILTER_CATEGORY, gaBackgroundMarketTag);
                onUpdateBackgroundMarketSelection(e)
            }
        },
        defaultSearchTerm: ""
    };
}