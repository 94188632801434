export const SharedDefectTableFields = {
    DEFECT_ID: "formattedDefectId",
    PRIORITY: "priority",
    DESCRIPTION: "description",
    RECOMMENDATIONS: "recommendations",
    ESTIMATE_DEFECT_COST_FIELD: "estimateDefectCostField",
    VESSEL_NAME: "shipName",
    IMO: "imo",
    TYPE: "type",
    SUB_TYPE: "subType",
    INSPECTION_DATE_FILTER_FIELD: "inspectionDateFilterField",
    TECHNICAL_MANAGER: "technicalManager",
    NOTES: "notes",
    RECTIFICATION: "rectificationId",
    IDWAL_GRADE: "idwalGrade",
    STATUS: "status",
    IMAGES: "images",
    CONTAINS_IMAGES: "containsImages",
    INSPECTION_DATE: "inspectionDate",
    NAME_FILTER_FIELD: "nameFilterField",
    GRADING_AREA_FIELD: "gradingAreaField",
    TYPE_CODE: "typeCode",
    SHORT_GEN_NAME: "shortGenName",
    ACTIONS: "actions"
};