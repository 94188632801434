import { Button } from "primereact/button"
import { ShareDefectModal } from "./ShareDefectModal";
import { useTranslation } from "react-i18next";
import { useCapabilities } from "../../hooks/useCapabilities";
import { Capability } from "../../types/Capability";
import { useAppDispatch } from "../../hooks/storeHooks";
import { setShowShareDefectsDialog } from "../../store/slices/defectSlice";
import { DefectDataItem } from "../../types/DefectsDataItem";

type ShareDefectButtonProps = {
    filteredData: DefectDataItem[];
}

export const ShareDefectButton = (props: ShareDefectButtonProps) => {  
    const { filteredData } = props;  
    
    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();
    const { capabilities } = useCapabilities([
        Capability.CAP_FLEETAPP_SHARE_DAP
    ]);

    if (!(capabilities[Capability.CAP_FLEETAPP_SHARE_DAP])) {
        return null;
    }

    const handleOnClick = () => dispatch(setShowShareDefectsDialog(true));

    return (
      <>
        <ShareDefectModal filteredData={filteredData} />
        <div className="defect-btn">
          <Button
            label={t("defectShareActionPlan.shareDefectsButton")}
            icon="pi pi-share-alt"
            onClick={() => handleOnClick()}
          />
        </div>
      </>
    );
}