import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { classNames } from "primereact/utils";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { selectSelectedVessel } from "../../store/selectors/defectSelectors";
import { setSelectedVessel } from "../../store/slices/defectSlice";

type ClearSelectedVesselButtonProps = {
    className?: string;
}

export const ClearSelectedVesselButton = (props: ClearSelectedVesselButtonProps) => {
    const { className } = props;

    const { t } = useTranslation("locale");
    const dispatch = useAppDispatch();
    const selectedVessel = useAppSelector(selectSelectedVessel);

    if (!selectedVessel) {
        return null;
    }

    const onClick = () => dispatch(setSelectedVessel(""));

    return (
        <Button className={classNames("p-button p-button-outlined p-button-secondary secondary-outlined-button", className)}
            label={t("showAllFilters.clearX", { x: selectedVessel })} onClick={onClick} />
    )
}