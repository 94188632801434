import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDetails } from '../../types/UserDetails';
import { getGroupNameFromLocalStore } from '../../utils/GroupDropDownHelper';
import { AIGradingAccess } from '../../types/AIGradingAccess';

export interface UserState {
    userId: string | undefined;
    userCapabilities: string[];
    userDetails: UserDetails | undefined;
    userLoaded: boolean;
    userToken: string | undefined;
    hasFleetAccess: boolean
    selectedGroupName: string;
    aiGradingAccess: AIGradingAccess;
    aiGradingActive: boolean;
}

export const initialState: UserState = {
    userId: undefined,
    userCapabilities: [],
    userDetails: undefined,
    userLoaded: false,
    userToken: undefined,
    hasFleetAccess: false,
    selectedGroupName: getGroupNameFromLocalStore(),
    aiGradingAccess: 0,
    aiGradingActive: false,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserId: (state, action: PayloadAction<string | undefined>) => {
            state.userId = action.payload;
        },
        setUserCapabilities: (state, action: PayloadAction<string[]>) => {
            state.userCapabilities = action.payload;
        },
        setUserDetails: (state, action: PayloadAction<UserDetails | undefined>) => {
            state.userDetails = action.payload;
        },
        setUserLoaded: (state, action: PayloadAction<boolean>) => {
            state.userLoaded = action.payload;
        },
        setUserToken: (state, action: PayloadAction<string | undefined>) => {
            state.userToken = action.payload;
        },
        setHasFleetAccess: (state, action: PayloadAction<boolean>) => {
            state.hasFleetAccess = action.payload;
        },
        setSelectedGroupName: (state, action: PayloadAction<string>) => {
            state.selectedGroupName = action.payload;
        },
        setAiGradingAccess: (state, action: PayloadAction<AIGradingAccess>) => {
            state.aiGradingAccess = action.payload;
        },
        setAiGradingActive: (state, action: PayloadAction<boolean>) => {
            state.aiGradingActive = action.payload;
        },
    },
});

export const { 
    setUserId,
    setUserCapabilities,
    setUserDetails,
    setUserLoaded,
    setUserToken,
    setHasFleetAccess,
    setSelectedGroupName,
    setAiGradingAccess,
    setAiGradingActive,
} = userSlice.actions;

export default userSlice.reducer;
