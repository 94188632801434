import { graphFontSize } from "../constants/graphFontSize";
import {getTranslationObject} from "../services/Translation";
import { FleetGraphDataItem } from "../types/FleetGraphDataItem";
import { FleetModeGraph } from "../types/FleetGraphMode";
import { 
    getDefaultGraphXAxisMaxValue, 
    getScaledMaxXAxisValueBasedOnFleetData,
    getScaledMinXAxisValueBasedOnFleetData
} from "./getFleetGraphXAxisValue";

const t = getTranslationObject();

export type SetGraphLayoutProps = {
    defaultXAxisMin: number, 
    xAxisThreshold: number, 
    xAxisStepAmount: number,
    yAxisMin: number, 
    yAxisMax: number, 
    mode : 'grade' | 'condition' | 'management',
    fleetdata: Array<FleetGraphDataItem>,
    backgroundData: Array<FleetGraphDataItem>,
    filtersApplied: boolean,
}

const axisOffset = 0.5;

export const setGraphLayout = ({
    defaultXAxisMin, 
    xAxisThreshold, 
    xAxisStepAmount,
    yAxisMin, 
    yAxisMax, 
    mode,
    fleetdata,
    backgroundData,
    filtersApplied,
}: SetGraphLayoutProps) => {
    const defaultXAxisMaxValue = getDefaultGraphXAxisMaxValue(
        fleetdata, 
        xAxisThreshold,
        xAxisStepAmount
    );

    const scaledXAxisMaxValue = getScaledMaxXAxisValueBasedOnFleetData(
        fleetdata, 
        backgroundData,
        xAxisThreshold, 
        xAxisStepAmount
    );

    const scaledXAxisMinValue = getScaledMinXAxisValueBasedOnFleetData(
        fleetdata, 
        backgroundData,
        xAxisStepAmount
    );

    const layout = {
        autosize:true,
        dragmode: "pan",
        plot_bgcolor: "#FCFCFC",
        margin: {
            t: 0,
            r: 1,
            l: 50
        },
        hoverlabel: {
            bgcolor: "#555555",
            font: {
                family: "Open Sans",
                size: graphFontSize
            }
        },
        legend: {
            xanchor: "center",
            x: 0.5,
            y: -0.15,
            orientation: "h" as any,
            font: {
                family: 'Open Sans',
                size: graphFontSize,
                color: '#555555'
            },
        },
        xaxis: {
            range: [
                (filtersApplied ? scaledXAxisMinValue : defaultXAxisMin),
                (filtersApplied ? scaledXAxisMaxValue : defaultXAxisMaxValue) + axisOffset
            ],
            showline: false,
            zeroline: false,
            showgrid: false,
            title: {
                text: t('fleetGraph.vesselAgeTitle'),
                font: {
                    family: 'Open Sans',
                    size: graphFontSize,
                    color: '#555555'
                }
            }

        },
        yaxis: {
            range: [yAxisMin - 1, Math.max(yAxisMax, 100) + 1],
            showline: false,
            zeroline: false,
            gridcolor: '#B0E2E4',
            title: {
                text: '',
                font: {
                    family: 'Open Sans',
                    size: graphFontSize,
                    color: '#555555'
                }
            }

        }};

    switch (mode) {
        case 'grade':
            layout.yaxis.title.text = t('fleetGraph.idwalGrade');
            break;
        case 'condition':
            layout.yaxis.title.text = t('fleetGraph.conditionGrade');
            break;
        case 'management':
            layout.yaxis.title.text = t('fleetGraph.managementGrade');
            break;
    }

    return layout;
};

export const getHoverText = (data : FleetGraphDataItem[], mode: FleetModeGraph) => {
    return data.map(x => {

        let text = `${x.vessel_name}` +
            '<br>' +
            `${x.imo_number}` +
            '<br>' +
            `${x.vessel_type}` +
            '<br>';

        switch (mode) {
            case 'grade':
                text += `${t("fleetTable.idwalGrade")}: ` + (x.grade ? `${x.grade}` : `${x.grade_min}-${x.grade_max}`);
                break;
            case 'condition':
                text += `${t("fleetTable.conditionGrade")}: ${x.condition_score}`;
                break;
            case 'management':
                text += `${t("fleetTable.managementGrade")}: ${x.management_score}`;
                break;
        }

        return text;
    })
}