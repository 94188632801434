export enum GlobalDateRange {
    LAST_SIX_MONTHS,
    LAST_YEAR,
    LAST_FIVE_YEARS,
    ALL_TIME
}

export const getDateRangeDropDownConfig = (t: any) => {
    return [
        {name: `${t("inspectionDateDropdown.lastSixMonths")}`, code: GlobalDateRange.LAST_SIX_MONTHS},
        {name: `${t("inspectionDateDropdown.lastYear")}`, code: GlobalDateRange.LAST_YEAR},
        {name: `${t("inspectionDateDropdown.lastFiveYears")}`, code: GlobalDateRange.LAST_FIVE_YEARS},
        {name: `${t("inspectionDateDropdown.allTime")}`, code: GlobalDateRange.ALL_TIME}
    ];
}

export const getDateRange = (dateRange: GlobalDateRange, currentTime: Date = new Date()) => {
    const lowestStartDate = 'Jan 01, 2019 00:00:00';
    let startDate;
    let endDate;

    switch (dateRange) {

        case GlobalDateRange.ALL_TIME:
            endDate = currentTime
            endDate.setHours(23, 59, 59, 999);
            startDate = new Date(lowestStartDate);
            break;

        case GlobalDateRange.LAST_SIX_MONTHS:
            endDate = currentTime
            endDate.setUTCHours(23, 59, 59, 999);
            startDate = new Date(endDate);
            startDate.setMonth(startDate.getMonth() - 6);
            startDate.setHours(0, 0, 0, 0);
            break;

        case GlobalDateRange.LAST_FIVE_YEARS:
            endDate = currentTime
            endDate.setHours(23, 59, 59, 999);
            startDate = new Date(endDate);
            startDate.setUTCFullYear(endDate.getFullYear() - 5);
            startDate.setHours(0, 0, 0, 0);
            break;

        case GlobalDateRange.LAST_YEAR:
            endDate = currentTime
            endDate.setHours(23, 59, 59, 999);
            startDate = new Date(endDate);
            startDate.setHours(0, 0, 0, 0);
            startDate.setFullYear(startDate.getFullYear() - 1);
            break;
    }

    return {
        endDate,
        startDate
    }
}




