
export const EXCEL_FILE_TYPES = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
];

export const DOC_FILE_TYPES = [
    "application/msword", 
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", 
];

export const EXCEL_FILE_EXTENSIONS = [
    ".xlsx",
    ".xls",
    ".csv"
]

export const DOC_FILE_EXTENSIONS = [
    ".doc", 
    ".docx",
]

export const OTHER_FILE_TYPE = [
    "text/rtf", 
    "text/plain", 
    "image/*",  
    "image",
]

export const OTHER_FILE_EXTENSIONS = [
    ".pdf"
]
