import { FilterMatchMode } from "primereact/api";
import { getTranslationObject } from "../../services/Translation";
import { DefectSummaryTableFields } from "./DefectSummaryTableFields";
import { Column } from "primereact/column";
import { 
    defectSummaryActionMenuTemplate,
    defectSummaryInspectionSinceTemplate,
    defectSummaryPriorityLinkTemplate,
    defectSummaryStatusLinkTemplate,
    defectSummaryTotalDefectsLinkTemplate,
    vesselNameTemplate,
} from "./BodyTemplates";
import { DefectPriority } from "../../types/DefectPriority";
import { DefectStatus } from "../../types/DefectStatus";

const t = getTranslationObject();

export const defaultDefectsSummaryFilterConfig = {
    "global": { value: null, matchMode: FilterMatchMode.CONTAINS },
	[DefectSummaryTableFields.VESSEL_NAME]: { value: null, matchMode: FilterMatchMode.IN },
};

export const keywordSearchFilterFields = [
    DefectSummaryTableFields.VESSEL_NAME,
    DefectSummaryTableFields.IMO,
    DefectSummaryTableFields.HIGH_PRIORITY_COUNT,
    DefectSummaryTableFields.MEDIUM_PRIORITY_COUNT,
    DefectSummaryTableFields.LOW_PRIORITY_COUNT,
    DefectSummaryTableFields.INSPECTED,
    DefectSummaryTableFields.TECHNICAL_MANAGER,
    DefectSummaryTableFields.VESSEL_TYPE
];

export const getDefectsSummaryStaticHeaders = () => ([
    <Column
        key={DefectSummaryTableFields.VESSEL_NAME}
        field={DefectSummaryTableFields.VESSEL_NAME}
        header={t("defectsSummary.vessel")}
        headerClassName="defects-summary-large-column"
        frozen
        sortable
        rowSpan={2}
    />,
    <Column
        key={DefectSummaryTableFields.ACTIONS}
        field={DefectSummaryTableFields.ACTIONS}
        header={t("defectsSummary.actions")}
        frozen
        headerClassName={"defects-summary-small-column defects-summary-right-border"}
        className="justify-content-center"
        sortable
        rowSpan={2}
    />,
])

export const getDefectsSummaryHeaders = () => ([
    <Column
        key={DefectSummaryTableFields.TOTAL_DEFECTS}
        field={DefectSummaryTableFields.TOTAL_DEFECTS}
        header={t("defectsSummary.totalDefects")}
        headerClassName="defects-summary-small-column defects-summary-right-border"
        sortable
        rowSpan={2}
    />,
    <Column
        key={DefectSummaryTableFields.PRIORITY}
        field={DefectSummaryTableFields.PRIORITY}
        header={t("defectsSummary.priority")}
        headerClassName="defects-summary-no-bottom-border defects-summary-right-border"
        rowSpan={1}
        colSpan={3}
    />,
    <Column
        key={DefectSummaryTableFields.STATUS}
        field={DefectSummaryTableFields.STATUS}
        header={t("defectsSummary.status")}
        headerClassName="defects-summary-no-bottom-border defects-summary-right-border"
        rowSpan={1}
        colSpan={4}
    />,
    <Column
        key={DefectSummaryTableFields.INSPECTED}
        field={DefectSummaryTableFields.INSPECTED}
        header={t("defectsSummary.inspected")}
        headerClassName="defects-summary-large-column"
        sortable
        rowSpan={2}
    />,
    <Column
        key={DefectSummaryTableFields.TECHNICAL_MANAGER}
        field={DefectSummaryTableFields.TECHNICAL_MANAGER}
        header={t("defectsSummary.technicalManager")}
        headerClassName="defects-summary-large-column"
        sortable
        rowSpan={2}
    />,
    <Column
        key={DefectSummaryTableFields.VESSEL_TYPE}
        field={DefectSummaryTableFields.VESSEL_TYPE}
        header={t("defectsSummary.vesselType")}
        headerClassName="defects-summary-large-column"
        sortable
        rowSpan={2}
    />,
]);

export const getDefectsSummarySubHeaders = () => ([
    <Column
        key={DefectSummaryTableFields.HIGH_PRIORITY_COUNT}
        field={DefectSummaryTableFields.HIGH_PRIORITY_COUNT}
        header={t("defectsSummary.highPriority")}
        headerClassName="defects-summary-small-column"
        sortable
    />,
    <Column
        key={DefectSummaryTableFields.MEDIUM_PRIORITY_COUNT}
        field={DefectSummaryTableFields.MEDIUM_PRIORITY_COUNT}
        header={t("defectsSummary.mediumPriority")}
        headerClassName="defects-summary-small-column"
        sortable
    />,
    <Column
        key={DefectSummaryTableFields.LOW_PRIORITY_COUNT}
        field={DefectSummaryTableFields.LOW_PRIORITY_COUNT}
        header={t("defectsSummary.lowPriority")}
        headerClassName="defects-summary-small-column defects-summary-right-border"
        sortable
    />,
    <Column
        key={DefectSummaryTableFields.CLOSED_STATUS}
        field={DefectSummaryTableFields.CLOSED_STATUS}
        header={t("defectsSummary.statusClosed")}
        headerClassName="defects-summary-small-column"
        sortable
    />,
    <Column
        key={DefectSummaryTableFields.RECTIFIED_STATUS}
        field={DefectSummaryTableFields.RECTIFIED_STATUS}
        header={t("defectsSummary.statusRectified")}
        headerClassName="defects-summary-small-column"
        sortable
    />,
    <Column
        key={DefectSummaryTableFields.IN_PROGRESS_STATUS}
        field={DefectSummaryTableFields.IN_PROGRESS_STATUS}
        header={t("defectsSummary.statusInProgress")}
        headerClassName="defects-summary-small-column"
        sortable
    />,
    <Column
        key={DefectSummaryTableFields.OPEN_STATUS}
        field={DefectSummaryTableFields.OPEN_STATUS}
        header={t("defectsSummary.statusOpen")}
        headerClassName="defects-summary-small-column defects-summary-right-border"
        sortable
    />,
]);

export const getDefectsSummaryStaticColumns = () => ([
    <Column
        key={DefectSummaryTableFields.VESSEL_NAME}
        field={DefectSummaryTableFields.VESSEL_NAME}
        className="justify-content-center"
        body={vesselNameTemplate}
        frozen
    />,
    <Column
        key={DefectSummaryTableFields.ACTIONS}
        field={DefectSummaryTableFields.ACTIONS}
        className="justify-content-center defects-summary-right-border"
        body={defectSummaryActionMenuTemplate}
        frozen
    />,
]);

export const getDefectsSummaryColumns = () => ([
    <Column
        key={DefectSummaryTableFields.TOTAL_DEFECTS}
        field={DefectSummaryTableFields.TOTAL_DEFECTS}
        className="text-center defects-summary-right-border"
        body={defectSummaryTotalDefectsLinkTemplate}
    />,
    <Column
        key={DefectSummaryTableFields.HIGH_PRIORITY_COUNT}
        field={DefectSummaryTableFields.HIGH_PRIORITY_COUNT}
        body={defectSummaryPriorityLinkTemplate(DefectPriority.high)}
    />,
    <Column
        key={DefectSummaryTableFields.MEDIUM_PRIORITY_COUNT}
        field={DefectSummaryTableFields.MEDIUM_PRIORITY_COUNT}
        body={defectSummaryPriorityLinkTemplate(DefectPriority.medium)}
    />,
    <Column
        key={DefectSummaryTableFields.LOW_PRIORITY_COUNT}
        field={DefectSummaryTableFields.LOW_PRIORITY_COUNT}
        className="defects-summary-right-border"
        body={defectSummaryPriorityLinkTemplate(DefectPriority.low)}
    />,
    <Column
        key={DefectSummaryTableFields.CLOSED_STATUS}
        field={DefectSummaryTableFields.CLOSED_STATUS}
        body={defectSummaryStatusLinkTemplate(DefectStatus.closed)}
    />,
    <Column
        key={DefectSummaryTableFields.RECTIFIED_STATUS}
        field={DefectSummaryTableFields.RECTIFIED_STATUS}
        body={defectSummaryStatusLinkTemplate(DefectStatus.rectified)}
    />,
    <Column
        key={DefectSummaryTableFields.IN_PROGRESS_STATUS}
        field={DefectSummaryTableFields.IN_PROGRESS_STATUS}
        body={defectSummaryStatusLinkTemplate(DefectStatus.inProgress)}
    />,
    <Column
        key={DefectSummaryTableFields.OPEN_STATUS}
        field={DefectSummaryTableFields.OPEN_STATUS}
        className="defects-summary-right-border"
        body={defectSummaryStatusLinkTemplate(DefectStatus.open)}
    />,
    <Column
        key={DefectSummaryTableFields.INSPECTED}
        field={DefectSummaryTableFields.INSPECTED}
        body={defectSummaryInspectionSinceTemplate}
    />,
    <Column
        key={DefectSummaryTableFields.TECHNICAL_MANAGER}
        field={DefectSummaryTableFields.TECHNICAL_MANAGER}
    />,
    <Column
        key={DefectSummaryTableFields.VESSEL_TYPE}
        field={DefectSummaryTableFields.VESSEL_TYPE}
    />,
]);
