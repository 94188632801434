import {SharedTableColumnFilterConfig} from "../../types/SharedTableColumnFilterConfig";
import {SharedTableColumnTextConfig} from "../../types/SharedTableColumnTextConfig";
import {SharedTableColumnCallbacks, SharedTableColumnConfig} from "../../types/SharedTableColumnConfig";
import {generatePDFTableData} from "../../utils/GetPDFTableData";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {errorToast, successToast} from "@idwal/idwal-react-components";
import {getTranslationObject} from "../../services/Translation";
import React from "react";
import {DataTableFilterMeta} from "primereact/datatable";
import {Column, ColumnEvent} from "primereact/column";
import {ColumnTemplateConfig} from "../../types/ColumnTemplateConfig";
import {FilterTemplateConfig} from "../../types/FilterTemplateConfig";
import GroupService from "../../services/GroupService";
import {UserDetails} from "../../types/UserDetails";
import {createCustomColumnConfiguration} from "../FleetTable/Columns/ColumnHelper";
import { FilterMatchMode } from "primereact/api";
import { RemoveCustomColumnParams } from "../../types/RemoveColumnProps";
import { CUSTOM_COLUMN_PREFIX, USER_APP_STATE_DEFECTS_TABLE_KEY, USER_APP_STATE_HOME_TABLE_KEY, USER_APP_STATE_SUBGRADE_TABLE_KEY } from "../../utils/Constants";
import { FleetTableDefaultColumns } from "../FleetTable/FleetTableFields";
import { TableState, UserAppState } from "../../types/UserAppState";
import { TableConfig } from "../../types/TableStates";
import { DefectTableDefaultColumns } from "../DefectsTable/DefectTableFields";
import { SubgradeTableDefaultColumns } from "../SubGrades/SubgradeTableFields";
import {onCustomSaveState} from "../../utils/TableUtils";
import { cloneDeep, isEqual } from "lodash";
import { updateFleetAppState } from "../../services/FleetDataService";
import {getTableConfigs,onSessionSaveState} from "../../utils/SessionStorage";

const t = getTranslationObject();

const USE_CLOUD_STORAGE = process.env.REACT_APP_USE_CLOUD_STORAGE === "1";

export const buildColumnConfig = (
    field : string,
    headerText: string,
    dataType : string | undefined,
    filterConfig : SharedTableColumnFilterConfig,
    textConfig?: SharedTableColumnTextConfig,
    frozen?: boolean,
    callbacks? : SharedTableColumnCallbacks,
    bodyTemplate?: ((rowData: any) => (React.JSX.Element | undefined)) | undefined,
    sortFunction?: any,
    filter : boolean = true,
    sortable: boolean = true,
    exportable: boolean = true,
) : SharedTableColumnConfig =>
{
    const config: SharedTableColumnConfig = {
        bodyTemplate: bodyTemplate ?? undefined,
        dataType: dataType,
        field: field,
        header: headerText,
        justifyContent: textConfig?.justifyContent ?? "left",
        textAlign: textConfig?.textAlign ?? "left",
        sortable: sortable,
        filter: filter,
        sortFunction,
        filterField: filterConfig.filterField,
        showFilterMenu: filterConfig.showFilterMenu ?? false,
        showFilterMatchModes: filterConfig.showFilterMatchModes ?? false,
        filterPlaceholder: filterConfig.filterPlaceholder,
        filterMatchMode: filterConfig.filterMatchMode,
        frozen: frozen,
        onCellEditComplete: callbacks?.onCellEditComplete,
        editor : callbacks?.editor,
        exportable: exportable,

    };

    if (filterConfig.filterFunction) {
        config.filterMatchMode = FilterMatchMode.CONTAINS;
        config.filterFunction = filterConfig.filterFunction;
    }

    return config;
}

export const exportTable = (columns: SharedTableColumnConfig[], data: any, filePrefix: string, format: 'csv' | 'pdf') => {
    const headers = columns.map(col => col.header);
    const body = generatePDFTableData(data, columns);
    const date = Date.now();
    const formattedDate = new Date(date);
    const fileName = filePrefix + formattedDate.getDate() + "_" + formattedDate.toLocaleDateString('en-GB', {month: 'long'}) + "_" + formattedDate.getFullYear();

    try {
        if (format == "csv") {
            const csvContent = arrayToCsv([headers, ...body])
            downloadBlob(csvContent, `${fileName}.csv`, 'text/csv;charset=utf-8;')
        } else if (format == "pdf") {
            const doc = new jsPDF({orientation:"landscape"});
            autoTable(doc, {head: [headers], body: body});
            doc.save(`${fileName}.pdf`);
        } else {
            throw new Error(`Invalid format specified: ${format}`)
        }

        successToast(t("fleetTable.exportSuccessMessage"));
    } catch (e) {
        console.log(e)
        errorToast(t("fleetTable.exportErrorMessage"));
    }
};

function arrayToCsv(data: string[][]){
    return data.map((row: any) =>
        row
            .map(String)
            .map((v : string) => v.replaceAll('"', '""'))
            .map((v: string) => `"${v}"`)
            .join(',')
    ).join('\r\n');
}

function downloadBlob(content: any, filename: string, contentType: string) {
    const blob = new Blob([content], { type: contentType });
    const url = URL.createObjectURL(blob);

    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
}

export const updateKeywordSearchFilter = (value: string, filters : DataTableFilterMeta) => {     
    const filterState = cloneDeep(filters);
    
    if (filterState['global'] && 'value' in filterState['global']) {
        filterState['global'].value = value;
    }

    return filterState;
}

export const buildColumns = (
    selectedColumns: SharedTableColumnConfig[], 
    columnTemplates: ColumnTemplateConfig[], 
    filterTemplates: FilterTemplateConfig[], 
    minWidth: (e: SharedTableColumnConfig) => string
) => {
    const emptyFunction = (_e: ColumnEvent) => { /* Used as default value for onCellEditComplete if no function is passed in for onCellEditComplete */ };

    return selectedColumns.map(
            col =>
                <Column
                    key={col.field}
                    field={col.field}
                    dataType={col.dataType}
                    header={col.header}
                    filterMatchMode={col.filterMatchMode}
                    className={(col.showFilterMenu ?? false) ? "has-filter-menu" : ""}
                    headerClassName={`${col.field.split(".").slice(-1).pop()}-header`}
                    sortable={col.sortable ?? false}
                    showFilterMenu={col.showFilterMenu ?? false}
                    showFilterMatchModes={col.showFilterMatchModes ?? false}
                    filter={col.filter ?? false}
                    filterField={col.filterField}
                    filterFunction={col.filterFunction}
                    body={col.bodyTemplate ?? columnTemplates.find(e => e.column === col.field)?.bodyTemplate ?? null}
                    bodyStyle={{textAlign: col.textAlign ?? "left", justifyContent: col.justifyContent ?? "start"}}
                    style={{ minWidth: minWidth(col), whiteSpace: "normal" }}
                    filterElement={filterTemplates.find(e => e.column === col.field)?.filterTemplate ?? null}
                    filterPlaceholder={col.filterPlaceholder ?? ""}
                    editor={col.editor ? (rowData) => col.editor!(rowData) : ""}
                    onCellEditComplete={col.onCellEditComplete ?? emptyFunction}
                />
    );
}

export const onColumnToggle = (
    event: any, 
    localStorageKey: string, 
    columns: SharedTableColumnConfig[], 
    setSelectedColumns: React.Dispatch<React.SetStateAction<SharedTableColumnConfig[]>>,
    userDetails?: UserDetails,
    tableKey?: string
) => {
    const selectedColumns = event.value;
    const selectedColumnsList:string[] = selectedColumns.map((col:SharedTableColumnConfig ) => col.field);
    let orderedSelectedColumns = columns.filter(col => selectedColumns.some((sCol: { field: string; }) => sCol.field === col.field));
    setSelectedColumns(orderedSelectedColumns);

    if ((tableKey && USE_CLOUD_STORAGE) || 
    (tableKey && tableKey === USER_APP_STATE_HOME_TABLE_KEY)) {
        updateFleetTableAppState(tableKey, columns, orderedSelectedColumns, userDetails);
    } else {
        localStorage.setItem(localStorageKey, JSON.stringify( selectedColumnsList ));
    }
}

export const loadSavedColumnConfigurations = (
    localStorageKey: string, 
    columns: SharedTableColumnConfig[], 
    setSelectedColumns: React.Dispatch<React.SetStateAction<SharedTableColumnConfig[]>>, 
    customCols?: SharedTableColumnConfig[], 
    localStorageSelectedColumnsKey?: string, 
    setColumns?: any
) => {
    const localColumnsConfig = localStorage.getItem(localStorageKey);


    // If there is a local config, then set the selected columns using the local config. If there is no local config, then select all columns by default.
    if (localColumnsConfig !== null) {
        const localColumns = JSON.parse(localColumnsConfig);
        const values:SharedTableColumnConfig[] = [];

        columns.forEach((col:SharedTableColumnConfig) => {
            if (col.field && localColumns.includes(col.field)) {
                values.push(col)
            }
        });

        setSelectedColumns(values);

    } else {
        if (setColumns) {
            // This call is required to ensure the custom columns appear in the "select columns/ reorder columns" dropdown menus
            setColumns(columns);
        }

        setSelectedColumns(columns);
    }
}

export const loadSavedColumnConfigurationsV2 = (
    columns: SharedTableColumnConfig[],
    setColumns: React.Dispatch<React.SetStateAction<SharedTableColumnConfig[]>>,
    setSelectedColumns: React.Dispatch<React.SetStateAction<SharedTableColumnConfig[]>>,
    userDetails?: UserDetails,
    savedUserTableState?: TableState,
    tableKey?: string,
    localStorageKey?: string) => {
    
    if (!USE_CLOUD_STORAGE && tableKey !== USER_APP_STATE_HOME_TABLE_KEY) {
        localStorageKey && loadSavedColumnConfigurations(localStorageKey, columns, setSelectedColumns);
        return;
    }



    if (!savedUserTableState?.columns?.order || !savedUserTableState?.columns?.selected) {
        let defaultColumns: string[] = [];
        let defaultSelectedColumns: string[] = [];

        switch (tableKey) {
            case USER_APP_STATE_HOME_TABLE_KEY:
                defaultColumns = FleetTableDefaultColumns;
                defaultSelectedColumns = FleetTableDefaultColumns;
                break;
            case USER_APP_STATE_DEFECTS_TABLE_KEY:
                defaultColumns = DefectTableDefaultColumns;
                defaultSelectedColumns = DefectTableDefaultColumns;
                break;
            case USER_APP_STATE_SUBGRADE_TABLE_KEY:
                defaultColumns = SubgradeTableDefaultColumns;
                defaultSelectedColumns = SubgradeTableDefaultColumns;
                break;
            default:
                errorToast(t("appState.updateError"));
                return;
        }
        
        setDefaultTableState(columns, tableKey, userDetails)
            .then((result) => {
                setColumnsFromAppState(
                    columns,
                    result?.columnOrder ?? defaultColumns,
                    result?.selectedColumns ?? defaultSelectedColumns,
                    setColumns,
                    setSelectedColumns);
            })
            .catch(() => {
                errorToast(t("appState.updateError"));
                setColumnsFromAppState(
                    columns,
                    defaultColumns,
                    defaultSelectedColumns,
                    setColumns,
                    setSelectedColumns);
            });

        return;
    }

    setColumnsFromAppState(
        columns,
        savedUserTableState.columns.order,
        savedUserTableState.columns.selected,
        setColumns,
        setSelectedColumns);
}

export const onColumnReorder = (
    orderedColumns: any,
    localStorageSelectedColumnsKey: string,
    localStorageOrderedColumnsKey: string,
    columns: SharedTableColumnConfig[],
    selectedColumns: SharedTableColumnConfig[],
    setColumns: React.Dispatch<React.SetStateAction<SharedTableColumnConfig[]>>,
    setSelectedColumns: React.Dispatch<React.SetStateAction<SharedTableColumnConfig[]>>,
    userDetails?: UserDetails,
    tableKey?: string) => {
    // Create a new array of columns based on the order of allColumns
    const reorderedColumns = reorderColumns(columns, orderedColumns) as SharedTableColumnConfig[];

    let reorderedSelectedColumns = getSelectedColumns(reorderedColumns, selectedColumns);

    setColumns(reorderedColumns);
    setSelectedColumns(reorderedSelectedColumns);

    const reorderedSelectedColumnsList: string[] = reorderedSelectedColumns.map((col:SharedTableColumnConfig ) => col.field);

    const USE_CLOUD_STORAGE = process.env.REACT_APP_USE_CLOUD_STORAGE === "1" || tableKey === "homeTable";
    
    if (USE_CLOUD_STORAGE && tableKey) {
        updateFleetTableAppState(tableKey, reorderedColumns, reorderedSelectedColumns, userDetails);
    } else {
        localStorage.setItem(localStorageSelectedColumnsKey, JSON.stringify( reorderedSelectedColumnsList ));
        localStorage.setItem(localStorageOrderedColumnsKey, JSON.stringify(reorderedColumns));
    }
}


async function seedCustomColumnDataInUserService(userDetails: UserDetails, fieldName: string, dataType: string) {
    const groupService = new GroupService();

    const groups = Object.keys(userDetails.groupDetails!);

    for (const groupName of groups) {
        const newColumnSeedPost = {
            [groupName]: {
                [fieldName]: {
                    dataType
                },
            }
        };

        await groupService.post(JSON.stringify(newColumnSeedPost));
    }
}

async function removeCustomColumnDataInUserService(userDetails: UserDetails, fieldName: string) {
    const groupService = new GroupService();

    const groups = Object.keys(userDetails.groupDetails!);

    const data = {
        "attributesToDelete": [fieldName],
        "userGroups": groups
    }

    await groupService.post(JSON.stringify(data));
}

export const addNewColumn = async (
    columnName: string,
    dataType: string,
    existingSelectedColumns: SharedTableColumnConfig[],
    userDetails: UserDetails | undefined,
    setColumns: (newColumns: SharedTableColumnConfig[], selected: SharedTableColumnConfig[]) => void,
    tableKey?: string,
    allColumns?: SharedTableColumnConfig[],
) => {
    if (!userDetails || !tableKey) {
        return;
    }

    const fieldName = `${CUSTOM_COLUMN_PREFIX}${columnName}`;
    const newColumnConfig = createCustomColumnConfiguration(columnName, fieldName, dataType);
    await seedCustomColumnDataInUserService(userDetails, fieldName, dataType);

    const newCols = allColumns ? allColumns.concat(newColumnConfig) : [];
    const newSelectedColumns = [...existingSelectedColumns, newColumnConfig];

    if (newCols.length > 0) {
        try {
            updateFleetTableAppState(tableKey, newCols, newSelectedColumns, userDetails);

            setColumns(newCols, newSelectedColumns);

            successToast(t("columnEdit.addedNew"));

        } catch {
            errorToast(t("appState.updateError"));
        }
    }
}

export const removeCustomColumn = async (props: RemoveCustomColumnParams) => {
    const { columnName, columns, selectedColumns, setColumns, setSelectedColumns, localStorageSelectedColumnsKey, userDetails } = props;
    if (!userDetails) {
        return;
    }

    await removeCustomColumnDataInUserService(userDetails, columnName);
    
    const columnIndexToDelete = columns.findIndex((col: SharedTableColumnConfig) => col.field === columnName);
    const selectedColumnIndexToDelete = selectedColumns.findIndex((col: SharedTableColumnConfig) => col.field === columnName);

    if (columnIndexToDelete !== -1) {
        columns.splice(columnIndexToDelete, 1);
        setColumns(columns);
    }

    if (selectedColumnIndexToDelete !== -1) {
        selectedColumns.splice(selectedColumnIndexToDelete, 1);
        setSelectedColumns(selectedColumns);
    }

    if (props.tableKey) {
        try {
            updateFleetTableAppState(props.tableKey, columns, selectedColumns, userDetails)
        } catch {
            errorToast(t("appState.updateError"));
        }
    } else {
        const selectedColumnFieldList: string[] = selectedColumns.map((col: SharedTableColumnConfig) => col.field);
        localStorage.setItem(localStorageSelectedColumnsKey, JSON.stringify( selectedColumnFieldList ));
    }

    successToast(t("deleteColumnDialog.deleteSuccess"));
}

export function reorderColumns(columns: SharedTableColumnConfig[], orderedColumns: SharedTableColumnConfig[]) {
    return orderedColumns.map((orderedCol: SharedTableColumnConfig) =>
        columns.find((col) => col.field === orderedCol.field)
    );
}

export function getSelectedColumns(columns: SharedTableColumnConfig[], selectedColumns: SharedTableColumnConfig[]) {
    return columns.filter(col => selectedColumns.some((sCol: { field: string; }) => sCol.field === col.field));
}

export async function updateStoredTableConfigState(
    tableKey: string,
    columns: SharedTableColumnConfig[],
    selectedColumns: SharedTableColumnConfig[],
    userDetails: UserDetails | undefined,
    updateUserDetails: (userDetails: UserDetails) => void, 
    existingTableState?: TableState,
    newTableConfig?: TableConfig,
    localStorageKey?: string
) {
    if (!USE_CLOUD_STORAGE) {
        onCustomSaveState(newTableConfig, localStorageKey ?? "");
        return;
    }

    if (!userDetails) {
        return;
    }

    if (isEqual(existingTableState?.state, newTableConfig)) {
        return;
    }

    try {
        const tableConfigs = getTableConfigs(newTableConfig,localStorageKey ?? "");

        onSessionSaveState(tableConfigs.sessionTableConfig,localStorageKey ?? "");

        updateFleetTableAppState(tableKey, columns, selectedColumns, userDetails, tableConfigs.cloudTableConfig);

        setUserDetailsTableState(tableKey, userDetails, updateUserDetails, newTableConfig);
    } catch {
        console.error("Failed to save state");
    }
}

export function updateFleetTableAppState(
    tableKey: string,
    columns: SharedTableColumnConfig[],
    selectedColumns: SharedTableColumnConfig[],
    userDetails: UserDetails | undefined,
    newTableConfig?: TableConfig)
{
    try {
        let existingAppState;
        if (!userDetails?.appState) {
            existingAppState = {
                [tableKey]: {
                    columns: {
                        order: columns.map((col: SharedTableColumnConfig) => col.field),
                        selected: selectedColumns.map((col: SharedTableColumnConfig) => col.field)
                    },
                    state: {
                        ...newTableConfig
                    }
                }
            } as UserAppState;
        } else {
            existingAppState = userDetails.appState;
        }

        const existingTableState = existingAppState[tableKey];

        const requestBody = {
            app: process.env.REACT_APP_FLEET_APP_GROUP,
            data: {
                appState: {
                    ...existingAppState,
                    [tableKey]: {
                        ...existingTableState,
                        columns: {
                            order: columns.map((col: SharedTableColumnConfig) => col.field),
                            selected: selectedColumns.map((col: SharedTableColumnConfig) => col.field)
                        },
                        state: {
                            ...existingTableState?.state
                        }
                    }
                }
            }
        }

        if (newTableConfig) {
            requestBody.data.appState[tableKey].state = newTableConfig;
        }

        updateFleetAppState(requestBody);
    } catch {
        errorToast(t("appState.updateError"));
    }
}

async function setDefaultTableState(
    allColumns: SharedTableColumnConfig[], tableKey?: string, userDetails?: UserDetails) {
    if (!userDetails) {
        return;
    }

    // Switch case to assign from tableKey to default columns string list
    let defaultColumns: string[] = [];
    switch (tableKey) {
        case USER_APP_STATE_HOME_TABLE_KEY:
            defaultColumns = FleetTableDefaultColumns;
            break;
        case USER_APP_STATE_DEFECTS_TABLE_KEY:
            defaultColumns = DefectTableDefaultColumns;
            break;
        case USER_APP_STATE_SUBGRADE_TABLE_KEY:
            defaultColumns = SubgradeTableDefaultColumns;
            break;
        default:
            errorToast(t("appState.updateError"));
            return;
    }

    // The order of the columns is whatever is defined in defaultColumns, followed by any other system columns, then any custom columns that have a field prefix of CUSTOM_COLUMN_PREFIX
    const defaultColumnOrder = defaultColumns.map(col => allColumns.find(c => c.field === col))
        .filter(Boolean)
        .concat(allColumns.filter(col => !defaultColumns.includes(col.field))) as SharedTableColumnConfig[];

    const defaultSelectedColumns = defaultColumns.map(col => allColumns.find(c => c.field === col))
        .filter(Boolean)
        .concat(allColumns.filter(col => col.field.startsWith(CUSTOM_COLUMN_PREFIX))) as SharedTableColumnConfig[];

    updateFleetTableAppState(tableKey, defaultColumnOrder, defaultSelectedColumns, userDetails)

    return {
        columnOrder: defaultColumnOrder.map(col => col.field),
        selectedColumns: defaultSelectedColumns.map(col => col.field)
    }
}

export function setUserDetailsTableState(
    stateTableKey: string,
    userDetails: UserDetails,
    updateUserDetails: (userDetails: UserDetails) => void,
    newTableConfig?: TableConfig,
) {
    if (!userDetails?.appState) {
        return;
    }

    updateUserDetails({
        ...userDetails,
        appState: {
            ...userDetails.appState,
            [stateTableKey]: {
                ...userDetails.appState[stateTableKey],
                state: {
                    ...newTableConfig
                }
            }
        }
    });
}

const setColumnsFromAppState = (
    columns: SharedTableColumnConfig[],
    orderedColumnsList: string[],
    selectedColumnsList: string[],
    setColumns: React.Dispatch<React.SetStateAction<SharedTableColumnConfig[]>>,
    setSelectedColumns: React.Dispatch<React.SetStateAction<SharedTableColumnConfig[]>>) => {
    const orderedColumns: SharedTableColumnConfig[] = [];
    const orderedSelectedColumns: SharedTableColumnConfig[] = [];


    if (orderedColumnsList.length != columns.length) {
        const columnNames = columns.map((column) => column.field);
        orderedColumnsList = [...new Set(orderedColumnsList.concat(columnNames))];
    }

    orderedColumnsList.forEach((field: string) => {
        const col = columns.find((col: SharedTableColumnConfig) => col.field === field);
        if (col) {
            orderedColumns.push(col);
        }
    });

    orderedColumns.forEach((col:SharedTableColumnConfig) => {
        if (col.field && selectedColumnsList.includes(col.field)) {
            orderedSelectedColumns.push(col)
        }
    });

    setColumns(orderedColumns);
    setSelectedColumns(orderedSelectedColumns);
}
