const enum LOCAL_TABLE_CONFIG_KEY {
    DEFECTS = "defects-table",
    FLEET = "fleet-table",
    SUBGRADE = "subgrade-table",
    DEFECTS_SUMMARY = "defects-summary-table",
    DEFECT_COLUMNS = "defects-table-columns",
    DEFECT_SUMMARY_COLUMNS = "defect-summary-table-column",
    FLEET_COLUMNS = "fleet-table-columns",
    SUBGRADE_COLUMNS = "subgrade-table-columns",
    FLEET_ORDERED_COLUMNS = "fleet-table-ordered-columns",
    DEFECT_ORDERED_COLUMNS = "defect-table-ordered-columns",
    DEFECT_SUMMARY_ORDERED_COLUMNS = "defect-summary-ordered-columns",
    SUBGRADE_ORDERED_COLUMNS = "subgrade-table-ordered-columns",
    GLOBAL_FILTER_STATE = "global-filter-state",
    ADVANCED_FILTER_MARKET_DATA_TOGGLE = "advaned-filter-market-data-toggle",
    ADVANCED_FILTER_STATE = "advanced-filter-state",
    ADVANCED_FILTER_APPLIED_STATE = "advanced-filter-applied-state",
}


export default LOCAL_TABLE_CONFIG_KEY;
