import { FilterSection, FilterType, MultiSelectFilterItems, RangeFilterItem, SingleSelectFilterItem } from "../../types/FilterDialog";
import { FilterSelectionContainerType } from "../../types/FilterSelectionContainerType";
import { GenericSearch } from "../GenericSearch/GenericSearch";
import { MultiSelectList } from "../MultiSelectList/MultiSelectList";
import { RangeFilter } from "../RangeFilter/RangeFilter";
import { SingleSelectList, SingleSelectListItem } from "../SingleSelectList/SingleSelectlist";

const selectFilterScope = (
    selectedFilter: FilterSection, 
    scope: FilterSelectionContainerType
) => {
    if (scope === FilterSelectionContainerType.Foreground) {
        return selectedFilter.fleetFilterState  
    }

    if (scope === FilterSelectionContainerType.BackgroundFleet) {
        return selectedFilter.backgroundFleetFilterState  
    }

    if (scope === FilterSelectionContainerType.BackgroundMarket) {
        return selectedFilter.backgroundMarketFilterState  
    }
}

export const renderFilterSection = (
    selectedFilter: FilterSection, 
    scope: FilterSelectionContainerType, 
    saving: boolean, 
    defaultSearchTerm: string,
    t: any
) => {
    switch (selectedFilter.type) {
        case FilterType.MultiSelectWithSearch: {
            const filterScope = selectFilterScope(selectedFilter, scope) as MultiSelectFilterItems;

            const searchableProps = {
                items: filterScope.items,
                onChange: filterScope.onUpdateSelction,
                disabled: saving
            };

            const vesselFilter = (
                <MultiSelectList 
                    items={searchableProps.items}
                    onChange={searchableProps.onChange}
                    disabled={saving}
                />
            );
        
            return (
                <GenericSearch 
                    searchableItems={{...searchableProps}} 
                    defaultSearchTerm={defaultSearchTerm} 
                    extractSearchTerm={(vessel) => vessel.name}
                    children={vesselFilter}        
                />
            );
        }
        case FilterType.Multiselect: {
            const filterScope = selectFilterScope(selectedFilter, scope) as MultiSelectFilterItems;

            const searchableProps = {
                items: filterScope.items,
                onChange: filterScope.onUpdateSelction
            };

            return (
                <MultiSelectList 
                    items={searchableProps.items}
                    onChange={searchableProps.onChange}
                    disabled={saving}
                />
            );
        }
        case FilterType.Range: {
            const filterState = selectFilterScope(selectedFilter, scope) as RangeFilterItem;

            return (
                <RangeFilter
                    title={filterState.title}
                    subtitle={filterState.subtitle}
                    seperatorText={t("showAllFilters.to")}
                    step={filterState.step}
                    range={filterState.rangeValue}
                    min={filterState.rangeLimits[0]}
                    max={filterState.rangeLimits[1]}
                    onUpdateRange={(e: [number, number]) => {
                        filterState.onUpdateRange(e);
                    }}
                />
            );
        }
        case FilterType.SingleSelect: {
            const filterState = selectFilterScope(selectedFilter, scope) as SingleSelectFilterItem;

            return (
                <SingleSelectList
                    items={filterState.items as SingleSelectListItem[]}
                    onChange={(e: any) => filterState.onUpdateSelection(e)}
                    disabled={saving}
                    selectedItemKey={filterState.selectedItemKey}
                />
            );
        }
    }
}