import {FleetDataItem} from "../../types/FleetDataItem";
import {StatTile} from "../../types/StatTile";

export class WorstPerformingTechnicalManagerTile implements StatTile {

    fleetInspections : FleetDataItem[]

    constructor(fleetInspections: FleetDataItem[]) {
        this.fleetInspections = fleetInspections;
    }

    getTileData() {
        let gradesPerTechnicalManager: Record<string, number[]> = {};

        this.fleetInspections
            .forEach((fleetEntry: FleetDataItem) : any => {
                let gradesForTechnicalManager = gradesPerTechnicalManager[fleetEntry.vessel.technicalManager]

                if (!gradesForTechnicalManager) {
                    gradesPerTechnicalManager[fleetEntry.vessel.technicalManager] = [];
                }
                if(fleetEntry.inspection.idwalGrade != 0) {
                    gradesPerTechnicalManager[fleetEntry.vessel.technicalManager].push(fleetEntry.inspection.idwalGrade!);
                }
            }, 0);

        let worstManager = { grade : 100, technicalManager: "" };

        for (const technicalManager in gradesPerTechnicalManager) {
            const grades = gradesPerTechnicalManager[technicalManager]
            const averageGrade = grades.reduce(( p: any, c : any ) => p + c, 0) / grades.length;
            if (worstManager.grade > averageGrade) {
                worstManager = {
                    grade: averageGrade,
                    technicalManager: technicalManager
                }
            }
        }

        if (worstManager?.technicalManager) {
            return {
                data: worstManager,
                tileKey: "worstPerformingTechnicalManager",
                statistic: Math.round(worstManager.grade),
                details: worstManager.technicalManager,
                icon: "worst-tech-manager-icon"
            };
        }

        return null;
    }
}
